<script setup lang="ts">
import { computed } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const authenticatedUser = useAuthenticatedUser()

const clientName = computed(() => authenticatedUser.client.name)
</script>

<template>
  <span class="client-name">{{ clientName }}</span>
</template>

<style lang="scss" scoped>
.client-name {
  margin-left: 15px;
}
</style>
