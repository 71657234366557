<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'

const unsavedChanges = inject("unsavedChanges")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

</script>


<template></template>