<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ref, Ref, inject } from 'vue'
import { Task } from '@/models/projects/Task'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseFileInput from '@/components/base/BaseFileInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { ProjectKey } from '@/models/symbols'
import TaskLine from '../../../TaskLine.vue'
import { Project } from '@/models/projects/Project'

interface Props {
  project: Project
  task: Task
  generatedFiles: GeneratedDocument[]
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const route = useRoute()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const step: Ref<number> = ref(1)
const files: Ref<Array<any>> = ref([])
const documentName: Ref<string> = ref(props.task.documentName)

function isDocumentNameAlreadyExists(name) {
  return props.generatedFiles.find((file) => {
    return file.name === name
  })
}

function setDefaultDocumentName() {
  files.value = []
  if (documentName.value !== props.task.documentName) {
    documentName.value = props.task.documentName
  }
  const isExists = isDocumentNameAlreadyExists(documentName.value)
  if (isExists) {
    renameDocument()
  }
}

function renameDocument() {
  let isDifferent = false
  for (let cpt = 1; !isDifferent; cpt++) {
    documentName.value = `${props.task.documentName} (${cpt})`
    isDifferent = !isDocumentNameAlreadyExists(documentName.value)
  }
}

function resetData() {
  isLoading.value = false
  step.value = 1
  documentName.value = ''
}

async function updateTaskStatus() {
  console.log('updateTaskStatus')
  if (props.task.status == 'not_started') {
    console.log('Set task as started')
    props.task.status = 'started'
    await userApi.putProjectTask(props.project?.value.id, props.task?.id, {
      status: 'started',
      client: route.params.clientId,
    })

    const new_event = await userApi.createProjectEvent(props.project?.value.id, {
      type: 1,
      user_project_id: props.project?.value.id,
      legacy_task_id: props.task?.id,
    })
    // emit("updateEvents", new_event);
  }
}

async function createDocument() {
  try {
    isLoading.value = true
    const document = await userApi.createDocument(documentName.value, props.task.id)

    console.log(props.project)
    if (props.project.value.id.length === 36) {
      await updateTaskStatus()
    } else {
      console.log('in staging - do not update task status')
    }

    isLoading.value = false
    emit('ok', document.id)
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: 'Erreur lors de la création du document',
      type: 'negative',
    })
  }
}

async function uploadFile() {
  try {
    isLoading.value = true
    const document = await userApi.createDocument(documentName.value, props.task.id)
    await userApi.uploadTaskDocument(document.id.toString(), files.value)

    if (props.project.value.id.length === 36) {
      await updateTaskStatus()
    }

    isLoading.value = false
    emit('ok')
    dialogRef.value?.hide()
    Notify.create({
      message: 'Nouveau document ajouté',
      type: 'primary',
    })
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: 'Une erreur est survenue lors de la création du document',
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="setDefaultDocumentName()" @hide="resetData()">
    <BaseDialog title="Ajouter un document" :is-dialog-loading="isLoading" :show-actions="true"
      loading-message="Veuillez patienter, nous créons le document..." @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <q-input outlined v-model="documentName" type="text" label="Nom du document"></q-input>
      </template>
      <template #actions>
        <q-btn outlined color="primary" class="q-ma-sm" label="Répondre au questionnaire de création du document"
          @click="createDocument()" :disable="isLoading" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
