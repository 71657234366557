<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import SelectSubProjectsTreeComponentBasicModels from '@/pages/common/projects/SelectSubProjectsTreeComponentBasicModels.vue'

interface Props {
  fileType: string,
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const newDocumentName: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)

const inputLabel = 'Nom du nouveau document ' + props.fileType

function createBasicDocuments(documentIds) {
  console.log('createBasicDocuments', documentIds)
  emit('ok', documentIds)
}
</script>
<template>
  <q-dialog ref="dialogRef" full-height>
    <BaseDialog title=" Nouveaux documents depuis modèles" :showCancelButton="false" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()" :is-full-content="true" :dialog-width="950">
      <template #full-content>
        <slot>
          <Suspense>
            <SelectSubProjectsTreeComponentBasicModels mode="createBasicDocuments"
              @createBasicDocuments="createBasicDocuments">
            </SelectSubProjectsTreeComponentBasicModels>
            <template #fallback>
              <q-inner-loading showing color="primary" label="Chargement en cours..." />
            </template>
          </Suspense>
        </slot>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
