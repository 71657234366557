import { Database } from '../Database'
import { Node } from '../Node'
import { NodeType } from '../NodeType'
import { FieldType } from '../FieldType'

let demoDatabase: Database = {
  clientId: 'client_id',
  objectTypes: [
    {
      name: 'Personne physique',
      fields: [
        {
          type: FieldType.String,
          label: 'Civilité',
        },
        {
          type: FieldType.String,
          label: 'Prénom',
        },
        {
          type: FieldType.String,
          label: 'Nom',
        },
        {
          type: FieldType.String,
          label: 'Adresse',
        },
      ],
      onChange: function (object: Node): void {
        let firstName = ''
        let lastName = ''

        if (object.fields[1].value != undefined && object.fields[1].value != '') {
          firstName = object.fields[1].value
        }
        if (object.fields[2].value != undefined && object.fields[2].value != '') {
          lastName = object.fields[2].value
        }

        if (firstName != '' && lastName != '') {
          object.label = firstName + ' ' + lastName
        }
        if (firstName != '' && lastName == '') {
          object.label = firstName
        }
        if (firstName == '' && lastName != '') {
          object.label = lastName
        }
      },
    },
    {
      name: 'Personne morale',
      fields: [
        {
          type: FieldType.String,
          label: 'Dénomination sociale',
        },
        {
          type: FieldType.String,
          label: 'Forme juridique',
        },
        {
          type: FieldType.String,
          label: 'Adresse',
        },
      ],
      onChange: function (object: Node): void {
        object.label = object.fields[0].value
      },
    },
    {
      name: 'Traitement',
      fields: [
        {
          type: FieldType.String,
          label: 'Intitulé',
        },
        {
          type: FieldType.String,
          label: 'Description',
        },
      ],
      onChange: function (object: Node): void {
        object.label = object.fields[0].value
      },
    },
  ],
  nodes: [
    {
      id: 'Salariés',
      label: 'Salariés',
      type: NodeType.Folder,
      fields: [],
      children: [
        {
          id: 'Jean Dupont',
          label: 'Jean Dupont',
          type: NodeType.Object,
          objectType: 'Personne physique',
          fields: [
            {
              type: FieldType.String,
              label: 'Civilité',
              value: 'Monsieur',
            },
            {
              type: FieldType.String,
              label: 'Prénom',
              value: 'Jean',
            },
            {
              type: FieldType.String,
              label: 'Nom',
              value: 'Dupont',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '7 avenue Ingres 75016 PARIS',
            },
          ],
          children: [],
        },
        {
          id: 'Cédric Dinont',
          label: 'Cédric Dinont',
          type: NodeType.Object,
          objectType: 'Personne physique',
          fields: [
            {
              type: FieldType.String,
              label: 'Civilité',
              value: 'Monsieur',
            },
            {
              type: FieldType.String,
              label: 'Prénom',
              value: 'Cédric',
            },
            {
              type: FieldType.String,
              label: 'Nom',
              value: 'Dinont',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '149 Bis rue Poincaré 59160 CAPINGHEM',
            },
          ],
          children: [],
        },
      ],
    },

    {
      id: 'Organisation',
      label: 'Organisation',
      type: NodeType.Folder,
      fields: [],
      children: [
        {
          id: 'Come On Law',
          label: 'Come On Law',
          type: NodeType.Object,
          objectType: 'Personne morale',
          fields: [
            {
              type: FieldType.String,
              label: 'Dénomination sociale',
              value: 'Come On Law',
            },
            {
              type: FieldType.String,
              label: 'Forme juridique',
              value: 'SAS',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '7 avenue Ingres 75016 PARIS',
            },
          ],
          children: [],
        },
        {
          id: 'Marvell',
          label: 'Marvell',
          type: NodeType.Object,
          objectType: 'Personne morale',
          fields: [
            {
              type: FieldType.String,
              label: 'Dénomination sociale',
              value: 'Marvell',
            },
            {
              type: FieldType.String,
              label: 'Forme juridique',
              value: 'AARPI',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '7 avenue Ingres 75016 PARIS',
            },
          ],
          children: [],
        },
      ],
    },

    {
      id: 'Registres',
      label: 'Registres',
      type: NodeType.Folder,
      fields: [],
      children: [
        {
          id: 'Registre des traitements',
          label: 'Registre des traitements',
          type: NodeType.Object,
          objectType: 'Registre des traitements',
          fields: [],
          children: [
            {
              id: 'Traitement 1',
              label: 'Traitement 1',
              type: NodeType.Object,
              objectType: 'Traitement',
              fields: [
                {
                  type: FieldType.String,
                  label: 'Intitulé',
                  value: 'Traitement 1',
                },
                {
                  type: FieldType.String,
                  label: 'Description',
                  value: 'Description du traitement 1',
                },
              ],
              children: [],
            },
            {
              id: 'Traitement 2',
              label: 'Traitement 2',
              type: NodeType.Object,
              objectType: 'Traitement',
              fields: [
                {
                  type: FieldType.String,
                  label: 'Intitulé',
                  value: 'Traitement 2',
                },
                {
                  type: FieldType.String,
                  label: 'Description',
                  value: 'Description du traitement 2',
                },
              ],
              children: [],
            },
          ],
        },
      ],
    },
  ],
}

export { demoDatabase }
