<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { useBrowserLocation } from '@vueuse/core'
import { Notify } from 'quasar'
import { ref, computed } from 'vue'
import { usePageTitle } from '@/store/useAppStore'
import BackOfficeApi from '@/services/api/BackOfficeApi'
import { isMobile } from '@/utils/detectMobile'

const store = useStore()
const router = useRouter()
const route = useRoute()

const logoUrl = ref('')
const logoStyle = ref('')
const password = ref('')
const confirmPassword = ref('')
const isPwd = ref(true)
const isConfirmPwd = ref(true)
const isLoading = ref(false)
const showConfirmMessage = ref(false)

usePageTitle('Authentification')

const location = useBrowserLocation()
// TODO: Replace with an API call
if (location.value.hostname.includes('bewiz')) {
  logoUrl.value = 'https://www.bewiz.fr/wp-content/uploads/2015/01/bewiz2.png'
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_bewiz.gif'
} else if (location.value.hostname.includes('marvellup')) {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/615b210de1a84a70208dd30e_MarvellUp-03%20(1)-p-500.png";
  // logoUrl.value =
  //   "https://app.staging.comeonlaw.com/static/images/logo_marvell_up.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvellup.png'
  if (isMobile()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    logoStyle.value = 'width: 80%;padding-left: 100px;'
  }
} else if (location.value.hostname.includes('karila')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_karila.png'
} else {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ae6b0c56ec71df6665d5a3_comeon_logofinal-p-500.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvellup.png'
  if (isMobile()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    logoStyle.value = 'width: 80%;padding-left: 100px;'
  }
}
async function handleSubmit() {
  try {
    if (password.value === confirmPassword.value) {
      isLoading.value = true
      const api = new BackOfficeApi()
      api.setStore(store)
      await api.resetPassword(route.query.token?.toString(), password.value)
      isLoading.value = false
      showConfirmMessage.value = true
    } else {
      Notify.create({
        message: 'Les mots de passe ne sont pas identiques',
        type: 'negative',
      })
      isLoading.value = false
    }
  } catch (error) {
    console.log(error)
    isLoading.value = false

    if (error.response?.data?.password[0].includes('This password is too short.')) {
      Notify.create({
        message: 'Le mot de passe doit contenir au moins 8 caractères.',
        type: 'negative',
      })
      return
    }

    if (error.response?.data?.password[0].includes('This password is too common.')) {
      Notify.create({
        message: 'Le mot de passe est trop simple.',
        type: 'negative',
      })
      return
    }

    Notify.create({
      message: 'Une erreur est survenue lors de la réinitialisation du mot de passe',
      type: 'negative',
    })
  }
}

var mainDivClass = computed({
  get: () => {
    if (isMobile()) {
      return 'q-pa-md page-content q-col-gutter-md centered-form-mobile'
    } else {
      return 'q-pa-md page-content q-col-gutter-md centered-form'
    }
  },
})

var titleDivClass = computed({
  get: () => {
    if (isMobile()) {
      return 'col-md-12 title-mobile'
    } else {
      return 'col-md-12 title'
    }
  },
})
</script>

<template>
  <q-page flex>
    <div :class="mainDivClass" style="max-width: 450px">
      <div class="row q-col-gutter-sd" :style="logoStyle">
        <q-img :src="logoUrl"></q-img>
      </div>
      <div class="row q-col-gutter-sd">
        <h1 :class="titleDivClass">Nouveau mot de passe</h1>
        <div v-if="showConfirmMessage">
          <span class="text-subtitle1"> Le mot de passe a été modifié avec succès. </span>
        </div>
      </div>
      <div class="columns" v-if="!showConfirmMessage">
        <q-input
          outlined
          id="password"
          :type="isPwd ? 'password' : 'text'"
          label="Mot de passe"
          v-model="password"
          required
          bottom-slots
        >
          <template #append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
        </q-input>
        <q-input
          outlined
          id="password"
          :type="isConfirmPwd ? 'password' : 'text'"
          label="Confirmer votre mot de passe"
          v-model="confirmPassword"
          required
          bottom-slots
        >
          <template #append>
            <q-icon
              :name="isConfirmPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isConfirmPwd = !isConfirmPwd"
            />
          </template>
        </q-input>
      </div>
      <div class="row">
        <q-btn
          class="col-md-12 submit-button justify-center full-width"
          color="primary"
          label="Valider"
          v-if="!showConfirmMessage"
          :disable="password !== confirmPassword || password === '' || confirmPassword === '' || isLoading"
          :loading="isLoading"
          @click="handleSubmit"
        />
        <q-btn
          class="col-md-12 submit-button justify-center full-width"
          color="primary"
          label="Aller sur la page d'authentification"
          v-if="showConfirmMessage"
          @click="router.push({ name: 'base/login' })"
        />
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.centered-form-mobile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.title {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

.title-mobile {
  margin-bottom: 10px;
  margin-top: 20px;
  line-height: 50px;
  text-align: center;
  width: 100%;
}

input {
  width: 400px;
}

.forgot-password {
  color: #3d73c5;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.submit-button {
  margin-top: 20px;
}
</style>
