import Main from './Main.vue'
import Calendar from './Calendar.vue'
import VisioPlanning from './VisioPlanning.vue'

const routes = [
  { name: 'activity/main', component: Main, path: '/activity' },
  {
    name: 'activity/calendar',
    component: Calendar,
    path: '/activity/calendar',
  },
  {
    name: 'activity/visio_planning',
    component: VisioPlanning,
    path: '/activity/visio_planning',
  },
]

export { routes }
