import Main from './Main.vue'
import Companies from './Companies.vue'
import Company from './Company.vue'
import Employees from './Employees.vue'

const routes = [
  { name: 'organization/main', component: Main, path: '/organization' },
  {
    name: 'organization/companies',
    component: Companies,
    path: '/organization/companies',
  },
  {
    name: 'organization/employees',
    component: Employees,
    path: '/organization/employees',
  },
  {
    name: 'organization/company',
    component: Company,
    path: '/organization/companys/{id}',
  },
]

export { routes }
