<script setup lang="ts">
import { ref, inject, Ref } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useDialogPluginComponent, Notify, Dialog } from 'quasar'
import BaseSkeletonTable from '@/components/base/BaseSkeletonTable.vue'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import UserAddEditForm from '@/components/common/administration/users/UserAddEditForm.vue'
import GroupAddEditForm from '@/components/common/administration/users/GroupAddEditForm.vue'
import GroupUsersForm from '@/components/common/administration/users/GroupUsersForm.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  clientId?: string
  selectedRows?: any
  environment: string
  rights: string[]
  tab: string
}
const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  environment: 'Client',
  tab: 'users',
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const pagination = {
  rowsPerPage: 8,
}

const userApi: UserApi = useApi()

const tab = ref(props.tab)

const availableUsers = ref([])
const usersInGroup = ref([])
const availableGroups = ref([])
const groupsInGroup = ref([])

const selectedAvailableGroup = ref([])
const selectedGroupsInGroup = ref([])
const selectedAvailableUsers = ref([])
const selectedUsersInGroup = ref([])
const filter = ref('')
const filter2 = ref('')
const isGroupLoading = ref(false)
const isUserLoading = ref(false)

const userColumns = [
  {
    name: 'fullName',
    label: 'Nom',
    field: 'fullName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'username',
    label: 'Utilisateurs',
    field: 'username',
    sortable: true,
    align: 'left',
  },
]

const groupsColumn = [
  {
    name: 'name',
    label: 'Groupe',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]

async function getData() {
  isUserLoading.value = true
  isGroupLoading.value = true
  await getUsers()
  await getGroups()
  selectedAvailableUsers.value = []
  selectedUsersInGroup.value = []
  selectedAvailableGroup.value = []
  selectedGroupsInGroup.value = []
  isUserLoading.value = false
  isGroupLoading.value = false
}

async function getUsers() {
  // isUserLoading.value = true
  if (props.selectedRows) {
    const selectedProjectId = props.selectedRows[0].id
    const users = await userApi.getUsersWithObjectPermissionsStrict(selectedProjectId, props.environment)
    usersInGroup.value = users
    const clientUsers = await userApi.getPortalUsers(props.clientId)
    const portalUsers = await userApi.getPortalUsers(null)
    const allUsers = clientUsers.results.concat(portalUsers.results)
    availableUsers.value = allUsers.filter((user) => {
      let keep = true
      users.forEach((groupUser) => {
        if (groupUser.id === user.id) {
          keep = false
        }
      })
      return keep
    })

    // remove duplicate availableUsers by username property
    availableUsers.value = availableUsers.value.filter(
      (v, i, a) => a.findIndex((v2) => v2.username === v.username) === i,
    )
  }
  // isUserLoading.value = false
}

async function getGroups() {
  // isGroupLoading.value = true
  if (props.selectedRows) {
    const selectedProjectId = props.selectedRows[0].id
    const groupsWithPermission = await userApi.getGroupsWithObjectPermissions(selectedProjectId, props.environment)

    groupsInGroup.value = groupsWithPermission.clientGroups.concat(groupsWithPermission.environmentGroups)


    console.log("get groups client", props.clientId)
    const clientGroups = await userApi.getPortalGroups(props.clientId)
    const portalGroups = await userApi.getPortalGroups(null)

    const allGroups = clientGroups.concat(portalGroups)

    availableGroups.value = allGroups.filter((group) => {
      let keep = true
      groupsInGroup.value.forEach((groupUser) => {
        if (groupUser.id === group.id) {
          keep = false
        }
      })
      return keep
    })
  }

  isGroupLoading.value = false
}

async function addGroupsToGroup(newGroup: any) {
  isGroupLoading.value = true
  const selectedClientId = props.selectedRows[0].id
  let groupIds = []

  console.log("newGroup", newGroup)
  if (newGroup) {
    groupIds.push(newGroup.id)
  } else {
    selectedAvailableGroup.value.forEach((group) => {
      groupIds.push(group.id)
    })
  }
  await userApi.updateObjectGroupsPermissions(selectedClientId, props.environment, groupIds, props.rights, [])
  await getGroups()

  Notify.create({
    message: groupIds.length > 1 ? 'Les groupes ont été ajoutés' : 'Le groupe a été ajouté',
    type: 'primary',
  })
  selectedAvailableGroup.value = []
  selectedGroupsInGroup.value = []
  isGroupLoading.value = false
}

async function removeGroupsFromGroup() {
  isGroupLoading.value = true
  const selectedClientId = props.selectedRows[0].id
  let groupIds = []
  selectedGroupsInGroup.value.forEach((group) => {
    groupIds.push(group.id)
  })
  await userApi.updateObjectGroupsPermissions(selectedClientId, props.environment, groupIds, [], props.rights)
  await getGroups()
  Notify.create({
    message: groupIds.length > 1 ? 'Les groupes ont été retirés' : 'Le groupe a été retiré',
    type: 'primary',
  })
  selectedAvailableGroup.value = []
  selectedGroupsInGroup.value = []
  isGroupLoading.value = false
}

async function addUsersToGroup(newUser: any) {
  isUserLoading.value = true
  const selectedProjectId = props.selectedRows[0].id
  let userIds = []
  if (newUser) {
    userIds.push(newUser.id)
  } else {
    selectedAvailableUsers.value.forEach((user) => {
      userIds.push(user.id)
    })
  }
  await userApi.updateObjectPermissions(selectedProjectId, props.environment, userIds, props.rights, [])
  await getUsers()
  Notify.create({
    message: userIds.length > 1 ? 'Les utilisateurs ont été ajoutés' : "L'utilisateur a été ajouté",
    type: 'primary',
  })
  selectedAvailableUsers.value = []
  selectedUsersInGroup.value = []
  isUserLoading.value = false
}

async function removeUsersFromGroup() {
  isUserLoading.value = true
  const selectedProjectId = props.selectedRows[0].id
  let userIds = []
  selectedUsersInGroup.value.forEach((user) => {
    userIds.push(user.id)
  })
  await userApi.updateObjectPermissions(selectedProjectId, props.environment, userIds, [], props.rights)
  await getUsers()

  Notify.create({
    message: userIds.length > 1 ? 'Les utilisateurs ont été retirés du groupe' : "L'utilisateur a été retiré du groupe",
    type: 'primary',
  })
  selectedAvailableUsers.value = []
  selectedUsersInGroup.value = []
  isUserLoading.value = false
}

function close() {
  emit('ok')
}

async function addUser(addUserToProject: boolean) {
  console.log("addUser", addUserToProject, props.clientId)

  const client = await userApi.getClient(authenticatedUser.clientId)
  console.log("client", client)

  const dialog = Dialog.create({
    component: UserAddEditForm,
    componentProps: {
      title: 'Nouvel utilisateur',
      buttonConfirmLabel: 'Ajouter',
      mode: 'ADD',
      clientId: props.clientId,
      clientName: client.name,
      showPortalSelector: true,
    },
  })
    .onOk(async (newUser) => {
      console.log("newUser", newUser)
      dialog.hide()
      await getUsers()
      if (addUserToProject) {
        await addUsersToGroup(newUser)
      }
    })
    .onCancel(() => {
      dialog.hide()
    })
}

const authenticatedUser = useAuthenticatedUser()

async function addGroup(addGroupToProject: boolean) {
  console.log("addGroup", addGroupToProject, props.clientId)

  const client = await userApi.getClient(authenticatedUser.clientId)
  console.log("client", client)

  const dialog = Dialog.create({
    component: GroupAddEditForm,
    componentProps: {
      title: 'Nouveau groupe',
      buttonConfirmLabel: 'Ajouter',
      mode: 'ADD',
      clientId: props.clientId,
      clientName: client.name,
      showPortalSelector: true,
    },
  })
    .onOk(async (newGroup) => {
      console.log("newGroup", newGroup)
      dialog.hide()
      await getGroups()
      if (addGroupToProject) {
        await addGroupsToGroup(newGroup)
      }
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageUsersClick() {
  console.log("onManageUsersClick", selectedAvailableGroup.value[0])
  const dialog = Dialog.create({
    component: GroupUsersForm,
    componentProps: {
      clientId: selectedAvailableGroup.value[0].clientId,
      selectedGroup: selectedAvailableGroup.value[0],
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageUsersClickRight() {
  console.log("onManageUsersClick", selectedGroupsInGroup.value[0])
  const dialog = Dialog.create({
    component: GroupUsersForm,
    componentProps: {
      clientId: selectedGroupsInGroup.value[0].clientId,
      selectedGroup: selectedGroupsInGroup.value[0],
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <q-dialog ref="dialogRef" full-width @before-show="getData">
    <BaseDialog title="Gestion des droits" :show-cancel-button="false" @on-dialog-cancel="close" @hide="close">
      <template #body>
        <q-tabs v-model="tab" align="left" active-color="primary" indicator-color="primary">
          <q-tab label="Gestion des utilisateurs" name="users" />
          <q-tab label="Gestion des groupes" name="groups" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="users">
            <q-form>
              <div class="row">
                <div class="col-5">
                  <h6 class="q-mt-sm q-mb-md">Utilisateurs disponibles</h6>
                </div>
                <div class="col-2 self-center">&nbsp;</div>
                <div class="col-5">
                  <h6 class="q-mt-sm q-mb-md">Utilisateurs ayant accès</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <q-table :columns="userColumns" :rows="availableUsers" :filter="filter" class="permission-table"
                    row-key="id" selection="multiple" v-model:selected="selectedAvailableUsers" :loading="isUserLoading"
                    :pagination="pagination">
                    <template v-slot:top-left>
                      <q-input filled debounce="300" color="primary" dense class="filter_input" v-model="filter">
                        <template v-slot:prepend>
                          <q-icon name="search" />
                        </template>
                      </q-input>
                    </template>


                    <template #top-right>
                      <q-btn color="primary" @click="addUser(false)">Nouveau</q-btn>
                    </template>

                    <template v-slot:loading>
                      <q-inner-loading showing color="primary" />
                      <BaseSkeletonTable v-if="availableUsers.length === 0" :size="8"></BaseSkeletonTable>
                    </template>
                  </q-table>
                </div>

                <div class="col-2 self-center">
                  <div class="button_container">
                    <q-btn :disabled="selectedAvailableUsers.length === 0 || isUserLoading"
                      @click="addUsersToGroup(null)" icon="chevron_right"></q-btn>
                    <br />
                    <q-btn :disabled="selectedUsersInGroup.length === 0 || isUserLoading" @click="removeUsersFromGroup"
                      icon="chevron_left"></q-btn>
                  </div>
                </div>

                <div class="col-5">
                  <q-table :columns="userColumns" :rows="usersInGroup" :filter="filter2" class="permission-table"
                    row-key="id" selection="multiple" v-model:selected="selectedUsersInGroup" :loading="isUserLoading"
                    :pagination="pagination">
                    <template v-slot:loading>
                      <q-inner-loading showing color="primary" />
                      <BaseSkeletonTable v-if="usersInGroup.length === 0" :size="8"></BaseSkeletonTable>
                    </template>
                    <template v-slot:top-left>
                      <q-input filled debounce="300" color="primary" dense class="filter_input" v-model="filter2">
                        <template v-slot:prepend>
                          <q-icon name="search" />
                        </template>
                      </q-input>
                    </template>
                    <template #top-right>
                      <q-btn color="primary" @click="addUser(true)">Nouveau</q-btn>
                    </template>
                  </q-table>
                </div>
              </div>
            </q-form>
          </q-tab-panel>

          <q-tab-panel name="groups">
            <q-form>
              <div class="row">
                <div class="col-5">
                  <h6>Groupes disponibles</h6>
                </div>
                <div class="col-2 self-center">&nbsp;</div>
                <div class="col-5">
                  <h6>Groupes ayant accès</h6>
                </div>
              </div>

              <div class="row">
                <div class="col-5">
                  <BaseTable :columns="groupsColumn" :rows="availableGroups" class="permission-table" row-key="id"
                    selection="multiple" v-model:selected="selectedAvailableGroup" hide-header
                    :is-loading="isGroupLoading" :pagination="pagination" :enableAdd="true" :enableActions="true"
                    @add-item-click="addGroup(false)">
                    <template v-slot:loading>
                      <q-inner-loading showing color="primary" />
                      <BaseSkeletonTable :size="8"></BaseSkeletonTable>
                    </template>
                    <template v-slot:action-menu-items>
                      <!-- <ItemActionMenuItem label="Modifier" @click="onEditClick" />
                      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" /> -->
                      <ItemActionMenuItem label="Associer des utilisateurs" @click="onManageUsersClick" />
                      <!-- <ItemActionMenuItem label="Gérer les droits globaux" @click="onManageRightsClick" /> -->
                    </template>
                  </BaseTable>
                </div>

                <div class="col-2 self-center">
                  <div class="button_container">
                    <q-btn :disabled="selectedAvailableGroup.length === 0 || isGroupLoading"
                      @click="addGroupsToGroup(null)" icon="chevron_right"></q-btn>
                    <br />
                    <q-btn :disabled="selectedGroupsInGroup.length === 0 || isGroupLoading"
                      @click="removeGroupsFromGroup" icon="chevron_left"></q-btn>
                  </div>
                </div>

                <div class="col-5">
                  <BaseTable :columns="groupsColumn" :rows="groupsInGroup" class="permission-table" row-key="id"
                    selection="multiple" v-model:selected="selectedGroupsInGroup" hide-header
                    :is-loading="isGroupLoading" :pagination="pagination" :enableAdd="true" :enableActions="true"
                    @add-item-click="addGroup(true)">
                    <template v-slot:loading>
                      <q-inner-loading showing color="primary" />
                      <BaseSkeletonTable :size="8"></BaseSkeletonTable>
                    </template>
                    <template v-slot:action-menu-items>
                      <!-- <ItemActionMenuItem label="Modifier" @click="onEditClick" />
                      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" /> -->
                      <ItemActionMenuItem label="Associer des utilisateurs" @click="onManageUsersClickRight" />
                      <!-- <ItemActionMenuItem label="Gérer les droits globaux" @click="onManageRightsClick" /> -->
                    </template>
                  </BaseTable>
                </div>
              </div>
            </q-form>
          </q-tab-panel>
        </q-tab-panels>
      </template>
      <template #actions>
        <q-btn flat label="Terminer" color="primary" @click="close"> </q-btn>
      </template>
    </BaseDialog>
  </q-dialog>

  <!-- <BaseDialog
    full-width
    :show-dialog="visible"
    :show-cancel-button="false"
    @before-show="getData"
    @before-hide="visible = false"
  >
    <template #title>Gestion des droits</template>
    <template #body>
      <q-tabs
        v-model="tab"
        align="left"
        active-color="primary"
        indicator-color="primary"
      >
        <q-tab label="Gestion des utilisateurs" name="users" />
        <q-tab label="Gestion des groupes" name="groups" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="users">
          <q-form>
            <div class="row">
              <div class="col-5">
                <h6 class="q-mt-sm q-mb-md">Utilisateurs disponibles</h6>
              </div>
              <div class="col-2 self-center">&nbsp;</div>
              <div class="col-5">
                <h6 class="q-mt-sm q-mb-md">Utilisateurs ayant accès</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-5">
                <q-table
                  :columns="userColumns"
                  :rows="availableUsers"
                  :filter="filter"
                  class="permission-table"
                  row-key="id"
                  selection="multiple"
                  v-model:selected="selectedAvailableUsers"
                  :loading="isUserLoading"
                  :pagination="pagination"
                >
                  <template v-slot:top-left>
                    <q-input
                      filled
                      debounce="300"
                      color="primary"
                      dense
                      class="filter_input"
                      v-model="filter"
                    >
                      <template v-slot:prepend>
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </template>

                  <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                    <BaseSkeletonTable
                      v-if="availableUsers.length === 0"
                      :size="8"
                    ></BaseSkeletonTable>
                  </template>
                </q-table>
              </div>

              <div class="col-2 self-center">
                <div class="button_container">
                  <q-btn @click="addUsersToGroup" icon="chevron_right"></q-btn>
                  <br />
                  <q-btn
                    @click="removeUsersFromGroup"
                    icon="chevron_left"
                  ></q-btn>
                </div>
              </div>

              <div class="col-5">
                <q-table
                  :columns="userColumns"
                  :rows="usersInGroup"
                  :filter="filter2"
                  class="permission-table"
                  row-key="id"
                  selection="multiple"
                  v-model:selected="selectedUsersInGroup"
                  :loading="isUserLoading"
                  :pagination="pagination"
                >
                  <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                    <BaseSkeletonTable
                      v-if="usersInGroup.length === 0"
                      :size="8"
                    ></BaseSkeletonTable>
                  </template>
                  <template v-slot:top-left>
                    <q-input
                      filled
                      debounce="300"
                      color="primary"
                      dense
                      class="filter_input"
                      v-model="filter2"
                    >
                      <template v-slot:prepend>
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </template>
                </q-table>
              </div>
            </div>
          </q-form>
        </q-tab-panel>

        <q-tab-panel name="groups">
          <q-form>
            <div class="row">
              <div class="col-5">
                <h6>Groupes disponibles</h6>
              </div>
              <div class="col-2 self-center">&nbsp;</div>
              <div class="col-5">
                <h6>Groupes ayant accès</h6>
              </div>
            </div>

            <div class="row">
              <div class="col-5">
                <BaseTable
                  :columns="groupsColumn"
                  :rows="availableGroups"
                  class="permission-table"
                  row-key="id"
                  selection="multiple"
                  v-model:selected="selectedAvailableGroup"
                  hide-header
                  :loading="isGroupLoading"
                  :pagination="pagination"
                  :enableAdd="false"
                  :enableActions="false"
                >
                  <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                    <BaseSkeletonTable
                      v-if="availableGroups.length === 0"
                      :size="8"
                    ></BaseSkeletonTable>
                  </template>
                </BaseTable>
              </div>

              <div class="col-2 self-center">
                <div class="button_container">
                  <q-btn @click="addGroupsToGroup" icon="chevron_right"></q-btn>
                  <br />
                  <q-btn
                    @click="removeGroupsFromGroup"
                    icon="chevron_left"
                  ></q-btn>
                </div>
              </div>

              <div class="col-5">
                <BaseTable
                  :columns="groupsColumn"
                  :rows="groupsInGroup"
                  class="permission-table"
                  row-key="id"
                  selection="multiple"
                  v-model:selected="selectedGroupsInGroup"
                  hide-header
                  :loading="isGroupLoading"
                  :pagination="pagination"
                  :enableAdd="false"
                  :enableActions="false"
                >
                  <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                    <BaseSkeletonTable
                      v-if="groupsInGroup.length === 0"
                      :size="8"
                    ></BaseSkeletonTable>
                  </template>
                </BaseTable>
              </div>
            </div>
          </q-form>
        </q-tab-panel>
      </q-tab-panels>
    </template>
    <template #actions>
      <q-btn flat label="Terminer" color="primary" @click="visible = false" />
    </template>
  </BaseDialog> -->
</template>
<style lang="scss" scoped>
.permission-table {
  .q-table__bottom--nodata {
    display: none;
  }
}

h6 {
  text-align: center;
  display: block;
  font-weight: 700;
}

.button_container {
  text-align: center;
  display: block;
}

.skeleton-table {
  box-shadow: none;
}
</style>
