export enum MailStatusEnum {
  Created = 1,
  Accepted = 2,
  FilingProof = 3,
  Sent = 4,
  Error = 5,
  InTransit = 6,
  WaitingToBeWithdrawn = 7,
  Distributed = 8,
  ReturnedToSender = 9,
  DeliveryProof = 10,
  WrongAddress = 11,
  Canceled = 12,
  NoStatus = 13,
}
