<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { UploadedFile } from '@/models/files/UploadedFile'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  file: UploadedFile | string
  documentName: string
  taskId: string
  fileUrl: string
}
const props = withDefaults(defineProps<Props>(), {
  documentName: 'Preview document',
  fileUrl: undefined,
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()

const isLoading = ref(false)

const iFrameRef = ref(undefined)

async function onShow() {
  try {
    isLoading.value = true
    let doc: any
    // if (props.file.name.endsWith(".docx")) {
    //   doc = await userApi.convertDocxToPdf(props.file.id);
    // } else {
    //   doc = await userApi.downloadUploadTaskFile(props.taskId, props.file.id);
    // }

    // var blob = new Blob([doc], {type: `application/pdf`});
    // iFrameRef.value.src = URL.createObjectURL(blob);
    // iFrameRef.value.data = URL.createObjectURL(blob);

    isLoading.value = false
  } catch (e) {
    isLoading.value = false
  }
}

function resizeIframe(obj) {
  obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px'
}

function getIframeSrc() {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let documentNameConverted = encodeURIComponent(props.documentName)

  let url
  if (props.fileUrl !== undefined) {
    url = props.fileUrl
  } else {
    const taskId = props.taskId
    const fileId = props.file.id
    if (props.file.name.endsWith('.docx')) {
      url = `${BASE_URL}api/v2/tasks/converttopdf/${props.file.id}/${documentNameConverted}?type=preview`
    } else {
      url = `${BASE_URL}api/v1/legacy/tasks/${taskId}/files/${fileId}/download/${documentNameConverted}?type=preview`
    }
  }

  return url
}
</script>

<template>
  <q-dialog ref="dialogRef" @show="onShow" full-width full-height>
    <BaseDialog
      :title="documentName"
      :show-actions="false"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
      style="max-width: 2000px"
      :is-full-content="true"
    >
      <!-- <template #body>
          <div v-if="isLoading" class="row justify-center">
            <q-spinner color="primary" size="2em" />
          </div>
          <div v-if="! isLoading" class="">
              <iframe src="/test.pdf" class="iframe"></iframe>
          </div>
        </template> -->

      <template #full-content>
        <div v-if="isLoading" class="row justify-center">
          <q-spinner color="primary" size="2em" />
        </div>
        <div v-if="!isLoading" class="scroll-area">
          <!-- <q-scroll-area class="scroll-area"> -->
          <!-- scrolling="no" onload="resizeIframe(this)"  -->
          <!-- <iframe src="/test.pdf" class="iframe" frameborder=0></iframe> -->
          <iframe :src="getIframeSrc()" class="iframe" frameborder="0" :ref="iFrameRef"></iframe>
          <!-- <iframe class="iframe" frameborder=0 :ref="iFrameRef" src="/"></iframe>           -->
          <!-- <object :ref="iFrameRef" data="" type="application/pdf" class="iframe"> -->
          <!-- <iframe src="https://docs.google.com/viewer?url=your_url_to_pdf&embedded=true"></iframe> -->
          <!-- </object>         -->
          <!-- </q-scroll-area> -->
        </div>
      </template>

      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.iframe {
  // margin-left: 1vw;
  width: calc(100% - 0px);
  height: calc(100% - 4px);
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23000000" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="16" text-anchor="middle">Veuillez patienter pendant la génération de la prévisualisation...</text></svg>')
    0px 0px no-repeat;
  // overflow:hidden;
  // display: block;
  // height: 1600px;
  // border: 1px solid red;
}

.scroll-area {
  width: calc(100% - 0px);
  height: calc(100% - 76px);
  // border: 1px solid blue;
}
</style>
