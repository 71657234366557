<script lang="ts" setup>
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

interface Props {
    element: any
    treeRef: any
    showPermissions: boolean,
    project: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['usersUpdated'])

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

async function removeUser() {
    console.log('removeUser', authenticatedUser)
    const rights = ['view_userproject', 'change_userproject', 'delete_userproject']
    await userApi.updateObjectPermissions(props.project.value.id, "UserProject", props.element.id, [], rights)
    emit('usersUpdated')
}
</script>

<template>
    <div v-if="!showPermissions" class="quick_access_item">
        {{ element.label }}
        <!-- <q-btn style="padding-right: 0px" size="sm" flat round dense icon="close" color="grey" @click.stop="removeUser"
            class="quick_access_remove_button" /> -->
    </div>


    <div v-if="showPermissions"
        class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node"
        style="border-bottom: 0.5px solid; border-bottom-color: #9e9e9e;">

        <div class="col-auto" style="overflow: hidden">
            <q-icon :name="element.icon || 'description'" size="24px" class="q-mr-sm"
                style="padding-right: 0px; color: #434440" />
        </div>
        <div class="col-shrink" style="overflow: hidden">
            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ element.label }}

                <q-tooltip>
                    {{ element.label }}
                </q-tooltip>
            </div>
        </div>

        <!-- space filler -->
        <div class="col" style="overflow: hidden"></div>

        <div class="col-auto" style="overflow: hidden">
            <span class="row items-center" style="text-align: center; line-height: 14px">
                <span class="col">
                    <span class="row">Voir</span>
                    <span class="row"><q-checkbox v-model="element.view"></q-checkbox></span>
                </span>
                <span class="col" style="margin-left: 50px">
                    <span class="row">Modifier&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span class="row"><q-checkbox v-model="element.edit"></q-checkbox></span>
                </span>
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.quick_access_remove_button {
    margin-left: 10px;
    visibility: hidden;
}

.quick_access_item:hover .quick_access_remove_button {
    visibility: visible;
}
</style>
