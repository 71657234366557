<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { useBrowserLocation } from '@vueuse/core'
import { onMounted, ref, computed } from 'vue'

import { getRoutes } from '../../../models/AuthenticatedUser'

const store = useStore()
const router = useRouter()
const route = useRoute()

const logoUrl = ref('')
const login = ref('')
const firstName = ref('')
const lastName = ref('')
const password = ref('')
const passwordCheck = ref('')

const passwordInputRef = ref(null)
const passwordCheckInputRef = ref(null)

onMounted(() => {
  store.commit('setPageTitle', 'Authentification')

  var api = store.state.api
  api
    .getInviteInfo({
      invite_key: route.params.invitation_key,
    })
    .then((inviteInfo) => {
      login.value = inviteInfo.email
      firstName.value = inviteInfo.firstName
      lastName.value = inviteInfo.lastName
    })
})

const location = useBrowserLocation()
// TODO: Replace with an API call
if (location.value.hostname.includes('bewiz')) {
  logoUrl.value = 'https://www.bewiz.fr/wp-content/uploads/2015/01/bewiz2.png'
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_bewiz.gif'
} else if (location.value.hostname.includes('marvellup')) {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/615b210de1a84a70208dd30e_MarvellUp-03%20(1)-p-500.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvell_up.png'
} else if (location.value.hostname.includes('karila')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_karila.png'
} else {
  logoUrl.value =
    'https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ae6b0c56ec71df6665d5a3_comeon_logofinal-p-500.png'
}

const arePasswordEqual = computed(() => {
  return password.value == passwordCheck.value
})

function onSubmit() {
  var api = store.state.api
  api
    .acceptInvitation({
      invite_key: route.params.invitation_key,
      email: login.value,
      firstName: firstName.value,
      lastName: lastName.value,
      password: password.value,
    })
    .then((user) => {
      router.push({ name: 'base/invitation_accepted' })
    })
    .catch((error) => {
      console.error(error)
      // TODO: Message to user
    })
}
</script>

<template>
  <q-page flex>
    <div class="q-pa-md page-content q-col-gutter-md centered-form" style="max-width: 450px">
      <div class="row q-col-gutter-sd">
        <q-img :src="logoUrl"></q-img>
      </div>
      <div class="row q-col-gutter-sd">
        <h1 class="col-md-12">Finalisation d'inscription</h1>
      </div>
      <q-form @submit="onSubmit">
        <div class="q-col-gutter-sd">
          <label class="col-md-12" for="login">Identifiant (non modifiable)</label>
          <div>
            <q-input
              class="col-md-12"
              id="login"
              type="text"
              v-model="login"
              required
              outlined
              dense
              autofocus
              :disable="true"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Le mail est requis']"
            />
          </div>
        </div>
        <div class="q-col-gutter-sd">
          <label class="col-md-12" for="firstName">Prénom</label>
          <div>
            <q-input
              class="col-md-12"
              id="firstName"
              type="text"
              v-model="firstName"
              required
              outlined
              dense
              autofocus
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Le prénom est requis']"
            />
          </div>
        </div>
        <div class="q-col-gutter-sd">
          <label class="col-md-12" for="lastName">Nom</label>
          <div>
            <q-input
              class="col-md-12"
              id="lastName"
              type="text"
              v-model="lastName"
              required
              outlined
              dense
              autofocus
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Le nom est requis']"
            />
          </div>
        </div>
        <div>
          <label class="col-md-12" for="password">Choisissez un mot de passe</label>
          <div>
            <q-input
              ref="passwordInputRef"
              class="col-md-12"
              id="password"
              type="password"
              v-model="password"
              required
              outlined
              dense
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Le mot de passe est requis']"
            />
          </div>
        </div>
        <div>
          <label class="col-md-12" for="passwordCheck">Retapez le mot de passe</label>
          <div>
            <q-input
              ref="passwordCheckInputRef"
              class="col-md-12"
              id="passwordCheck"
              type="password"
              v-model="passwordCheck"
              required
              outlined
              dense
              lazy-rules="ondemand"
              error-message="Les mots de passe doivent être identiques"
              :error="!arePasswordEqual"
            />
          </div>
        </div>
        <div class="row">
          <q-btn
            class="col-md-12 submit-button justify-center full-width"
            color="primary"
            type="submit"
            label="Terminer la création de mon compte"
          />
        </div>
      </q-form>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h1 {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
