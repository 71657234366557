<script setup lang="ts">
import { useStore } from '@/store/store'
import BasicModelsTree from './BasicModelsTree.vue'
import { Notify } from 'quasar'
const store = useStore()
store.commit('setPageTitle', 'Gestion des modèles de documents unitaires')

function updateFromGoogleDrive() {
  console.log('updateFromGoogleDrive')
  Notify.create({
    message: `Cette action sera bientôt disponible`,
    type: 'negative',
  })

}
</script>

<template>
  <q-page padding class="page">
    <h5 style="margin-top: 20px">Modèles de documents unitaires
      <!-- <q-btn dense label="Mettre à jour depuis le répertoire Google Drive" color="primary"
        style="margin-left: 16px; padding-left: 8px; padding-right: 8px;" @click="updateFromGoogleDrive"></q-btn> -->
    </h5>

    Le catalogue de modèles secs de l'ensemble des marques blanches est géré dans la GED du dossier
    "Come On Law" / "Catalogue de modèles secs" dans MarvellUp.
    <br />
    <br />
    Les catégories de modèles secs activées dans chaque marque blanche sont gérées dans le menu "Marquues blanches".

    <!-- <BasicModelsTree></BasicModelsTree> -->
  </q-page>
</template>
