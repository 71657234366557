<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { UploadedFile } from '@/models/files/UploadedFile'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ImageBlockView from '@/pages/platform/v2/components/DocumentEditor/elements/content/block/ImageBlockView.vue'
import FileSaver from 'file-saver'

interface Props {
  file: UploadedFile | string
  documentName: string
  taskId: string
  fileUrl: string
  project: any
  node: string
}
const props = withDefaults(defineProps<Props>(), {
  documentName: 'Preview document',
  fileUrl: undefined,
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()

const fitMode = ref('contain')
const scrollAreaRef = ref(null)
const imageHeight = ref(500)
const imageWidth = ref(500)
const imageNaturalSize = ref({ width: 0, height: 0 })

function zoomIn() {
  if (imageNaturalSize.value.height > imageNaturalSize.value.width) {
    console.log('vertical image')
    imageHeight.value = imageHeight.value / 0.9
  } else {
    console.log('horizontal image')
    imageWidth.value = imageWidth.value / 0.9
  }

  updateImageStyle()
}

function zoomOut() {
  if (imageNaturalSize.value.height > imageNaturalSize.value.width) {
    console.log('vertical image')
    imageHeight.value = imageHeight.value * 0.9
  } else {
    console.log('horizontal image')
    imageWidth.value = imageWidth.value * 0.9
  }
  updateImageStyle()
}

function fitImage() {
  if (imageNaturalSize.value.height > imageNaturalSize.value.width) {
    console.log('vertical image')
    imageHeight.value = scrollAreaRef.value?.$el.clientHeight
  } else {
    console.log('horizontal image')
    imageWidth.value = scrollAreaRef.value?.$el.clientWidth
  }

  updateImageStyle()
}

const imgStyle = ref()

function updateImageStyle() {
  console.log('update image style')

  if (imageNaturalSize.value.height > imageNaturalSize.value.width) {
    console.log('vertical image')
    imgStyle.value = {
      width: '100%',
      // border: "1px solid red",
      height: imageHeight.value + 'px',
    }
  } else {
    console.log('horizontal image')
    imgStyle.value = {
      height: '100%',
      // border: "1px solid red",
      width: imageWidth.value + 'px',
    }
  }
}

updateImageStyle()

watch(scrollAreaRef, () => {
  console.log('scroll area ref changed')
  fitImage()
})

onMounted(() => {
  console.log('mounted')
  // fitImage();
})

const imageRef = ref(null)

function onImageLoad(src) {
  console.log('image loaded')
  // console.log(imageRef.value.$el);
  const imgElement = imageRef.value.$el.querySelector('img')
  console.log('natural size', imgElement.naturalWidth, imgElement.naturalHeight)
  imageNaturalSize.value = {
    width: imgElement.naturalWidth,
    height: imgElement.naturalHeight,
  }
  fitImage()
}

async function downloadImage() {
  //console.log("props", props.project, props.node)
  try {
    const doc = await userApi.downloadDocumentTreeFile(props.project?.value.id, props.node.id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, props.node.name)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @show="onShow" full-width full-height>
    <BaseDialog
      :title="documentName"
      :show-actions="false"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
      style="max-width: 2000px"
      :is-full-content="true"
    >
      <template #full-content>
        <div class="" style="height: calc(100% - 120px)">
          <q-toolbar class="bg-grey-9 text-white">
            <q-space></q-space>
            <q-toolbar-title>
              <q-btn icon="zoom_in" @click="zoomIn"></q-btn>
              <q-btn icon="zoom_out" @click="zoomOut"></q-btn>
              <q-btn icon="fit_screen" @click="fitImage"></q-btn>
              <q-btn icon="download" @click="downloadImage"></q-btn>
            </q-toolbar-title>
          </q-toolbar>
          <q-scroll-area
            class="scroll-area"
            ref="scrollAreaRef"
            style="height: calc(100% - 6px); width: 100%"
            id="image-preview-scroll-area"
          >
            <q-img :src="fileUrl" :fit="fitMode" :style="imgStyle" ref="imageRef" @load="onImageLoad"></q-img>
          </q-scroll-area>
        </div>
      </template>

      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.scroll-area {
  width: 90vw;
  height: 90vh;
}
</style>
