<script lang="ts" setup>
import { ref, inject, Ref, computed } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const objectModelDatabase = ref('Personne physique')
const dataBaseOptions = ['Personne physique', 'Personne morale', 'SAS']

const mappingType = ref('Simple')
const mappingTypeOptions = ['Simple', 'Adresse', 'Liste choix simple', 'Liste choix multiple']
const objectModel = ref('')
const objectModelOptions = computed(() => {
  if (objectModelDatabase.value === 'Personne physique') {
    return [
      'Civilité',
      'Prénom',
      'Nom',
      'Date de naissance',
      'Ville de naissance',
      'Nationalité',
      'Adresse',
      'Profession',
      'Numéro de sécurité sociale',
    ]
  } else if (objectModelDatabase.value === 'Personne morale') {
    return ['Dénomitation sociale', 'Forme juridique', 'Adresse']
  } else if (objectModelDatabase.value === 'SAS') {
    return [
      'Statuts',
      'Commissaires aux comptes',
      'Baux',
      'Sociétés filles',
      'Actionnaires',
      'Membres du CSE',
      'Délégués du personnel',
      'Membres du conseil stratégique',
    ]
  }
})

const associateRole = ref('')
const mappingComplexe = ref('')
const autoFilledText = ref('')
</script>
<template>
  <div class="row">
    <BaseSelect
      v-model="objectModelDatabase"
      :options="dataBaseOptions"
      emit-value
      map-options
      label="Modèle d'objet de la base de donnée"
      class="col-4 q-my-md q-pr-sm"
    />

    <BaseSelect
      v-model="mappingType"
      :options="mappingTypeOptions"
      emit-value
      map-options
      label="Type de mapping"
      class="col-4 q-my-md q-px-sm"
    />

    <BaseSelect
      v-model="objectModel"
      :options="objectModelOptions"
      emit-value
      map-options
      label="Modèle d'objet"
      class="col-4 q-my-md q-pl-sm"
    />
  </div>
  <div class="row">
    <BaseInput v-model="associateRole" label="Rôle associé" class="col-4 q-mb-md q-pr-sm" />
    <BaseInput v-model="mappingComplexe" label="Mapping complexe" class="col-4 q-mb-md q-px-sm" />
    <BaseInput v-model="autoFilledText" label="Texte auto-rempli" class="col-4 q-mb-md q-pl-sm" type="date" />
  </div>
</template>
<style lang="scss" scoped></style>
