<script lang="ts" setup>
import { ref, watch } from 'vue'
import { QTableProps, Dialog } from 'quasar'

import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { FieldType } from '@/models/database/FieldType'

import StringField from './StringField.vue'
import FormattedTextField from './FormattedTextField.vue'
import FileField from './FileField.vue'
import DateField from './DateField.vue'
import SelectField from './SelectField.vue'
import ObjectField from './ObjectField.vue'
import ObjectLinkField from './ObjectLinkField.vue'
import ObjectListField from './ObjectListField.vue'
import ProjectLinkField from './ProjectLinkField.vue'
import EdiFileImportDialog from './EdiFileImportDialog.vue'

interface Props {
  database: Database
  environmentDatabase: Array<Database>
  node: Node
  field: Field
}

const props = defineProps<Props>()

const emit = defineEmits(['update:field'])

let columns: QTableProps['columns'] = []
const rows = ref([])

const userApi: UserApi = useApi()

updateColumns()
updateRows()

function updateColumns() {
  columns = props.field.fieldModel.linkFields.map((subField) => {
    return { name: subField.label, label: subField.label, field: subField.label, align: 'left' }
  })
  columns.push({ name: 'action', label: '', field: 'action', align: 'right' })
}

function updateRows() {
  if (props.field.value === null) {
    rows.value = []
    return
  }

  rows.value = props.field.value.links.map((field) => {
    // field.fieldModel = props.field.fieldModel.linkFields[index];

    field.fields.forEach((subField, index) => {
      subField.fieldModel = props.field.fieldModel.linkFields[index]
    })

    return field
  })

  emit('update:field', props.node, props.field)
}

watch(props, () => {
  updateColumns()
  updateRows()
})

function addRow() {
  const newRowFields = props.field.fieldModel.linkFields.map((field) => {
    return {
      label: field.label,
      value: undefined,
      fieldModel: field.fieldModel,
    }
  })

  const newRow = {
    fields: newRowFields,
  }

  if (props.field.value === null) {
    props.field.value = {
      links: [newRow],
    }
  } else {
    props.field.value.links.push(newRow)
  }

  updateRows()
}

function removeRow(rowIndex) {
  props.field.value.links.splice(rowIndex, 1)
  updateRows()
}

function onFieldChange(e) {
  emit('update:field', props.node, props.field)
}

function parseDate(date) {
  return date.substring(6, 8) + '/' + date.substring(4, 6) + '/' + date.substring(0, 4)
}

function getEdiValue(field) {
  if (field === undefined) {
    return ''
  }
  return field
}

function importEdiFile(fileInfos) {
  console.log("ediFileInfos", fileInfos)
  const newRowFields = props.field.fieldModel.linkFields.map((field) => {
    return {
      label: field.label,
      value: undefined,
      fieldModel: field.fieldModel,
    }
  })

  const newRow = {
    fields: newRowFields,
  }

  newRowFields.forEach((rowField) => {
    if (rowField.label === "Date de début d'exercice") {
      rowField.value = parseDate(fileInfos.CADTM)
    }
    if (rowField.label === "Date de fin d'exercice") {
      rowField.value = parseDate(fileInfos.CBDTM)
    }
    if (rowField.label === "Chiffre d'affaires") {
      rowField.value = getEdiValue(fileInfos.FL) // FL page 1
    }
    if (rowField.label === "Produit d'exploitation") {
      rowField.value = getEdiValue(fileInfos.FR) // FR page 1
    }
    if (rowField.label === "Charges d'exploitation") {
      rowField.value = getEdiValue(fileInfos.GF) // GF page 1
    }
    if (rowField.label === "Résultat d'exploitation") {
      rowField.value = getEdiValue(fileInfos.GG) // GG page 1
    }
    if (rowField.label === 'Total bilan') {
      rowField.value = getEdiValue(fileInfos.EE) // EE
    }
    if (rowField.label === 'Bénéfices') {
      rowField.value = getEdiValue(fileInfos.DI) // DI
      if (rowField.value.startsWith('-')) {
        rowField.value = ""
      }
    }
    if (rowField.label === 'Pertes') {
      rowField.value = getEdiValue(fileInfos.DI) // -DI
      if (!rowField.value.startsWith('-')) {
        rowField.value = ""
      } else {
        rowField.value = rowField.value.substring(1)
      }
    }
    if (rowField.label === 'Actif net immobilisé') {
      rowField.value = getEdiValue(fileInfos.EB) // EB
    }
    if (rowField.label === 'Actif circulant') {
      rowField.value = getEdiValue(fileInfos.EQ) // EQ
    }
    if (rowField.label === 'Capitaux propres') {
      rowField.value = getEdiValue(fileInfos.DL) // DL page 2
    }
    if (rowField.label === 'Emprunts et dettes') {
      rowField.value = getEdiValue(fileInfos.EC) // EC page 2
    }
    if (rowField.label === 'Traitements et salaires') {
      rowField.value = getEdiValue(fileInfos.FY) // FY page 3
    }
    if (rowField.label === 'Charges sociales') {
      rowField.value = getEdiValue(fileInfos.FZ) // FZ page 3
    }
    if (rowField.label === 'Nombre de salariés') {
      rowField.value = '' // Non dispo
    }
    if (rowField.label === 'Résultat financier') {
      rowField.value = getEdiValue(fileInfos.GV) // GU page 1
    }
    if (rowField.label === 'Résultat courant') {
      rowField.value = getEdiValue(fileInfos.GW) // GW page 1
    }
    if (rowField.label === 'Résultat exceptionnel') {
      rowField.value = getEdiValue(fileInfos.HI) // HI page 4
    }
    if (rowField.label === 'Impôt sur les bénéfices') {
      rowField.value = getEdiValue(fileInfos.HK) // HK page 4
    }
  })

  if (props.field.value === null) {
    props.field.value = {
      links: [newRow],
    }
  } else {
    props.field.value.links.push(newRow)
  }

  updateRows()
}

function showEdiImportDIalog() {
  const dialog = Dialog.create({
    component: EdiFileImportDialog,
    componentProps: {
      file: props.field.value,
    },
  })
    .onOk(async (uploadedFileInfos) => {
      console.log(uploadedFileInfos)
      // 

      //   props.field.value = {
      //     fileId: uploadedFileInfos.id,
      //     filename: uploadedFileInfos.fileName,
      //   }
      //   emit('update:field', props.node, props.field);
      importEdiFile(uploadedFileInfos)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <h3>
    {{ field.label }}
    <span v-if="field.label === 'Bilans' || field.label === 'Comptes de résultat'" style="margin-left: 30px"><q-btn dense
        label="Importer une liasse fiscale au format EDI" @click="showEdiImportDIalog()" /></span>
  </h3>

  <q-table :columns="columns" :rows="rows" flat bordered row-key="email" class="table">
    <template v-slot:body="props">
      <q-tr v-for="row in props.row">
        <q-td v-for="field in row">
          <StringField v-if="field.fieldModel.type === FieldType.String" :database="database" :node="row" :field="field"
            :show-label="false" @update:field="onFieldChanged" />
          <FormattedTextField v-if="field.fieldModel.type === FieldType.FormattedText" :database="database" :node="row"
            :field="field" :show-label="false" @update:field="onFieldChanged" />
          <FileField v-if="field.fieldModel.type === FieldType.File" :database="database" :node="row" :field="field"
            :show-label="false" @update:field="onFieldChanged" />
          <DateField v-if="field.fieldModel.type === FieldType.Date" :database="database" :node="row" :field="field"
            :show-label="false" @update:field="onFieldChanged" />
          <SelectField v-if="field.fieldModel.type === FieldType.Select" :database="database" :node="row" :field="field"
            :show-label="false" @update:field="onFieldChanged" />
          <ObjectLinkField v-if="field.fieldModel.type === FieldType.ObjectLink" :database="database"
            :environmentDatabase="environmentDatabase" :node="row" :field="field" :show-label="false"
            @update:field="onFieldChanged" />
          <ObjectField v-if="field.fieldModel.type === FieldType.Object" :database="database" :node="row"
            :environmentDatabase="environmentDatabase" :field="field" :show-label="false"
            @update:field="onFieldChanged" />
          <ObjectListField v-if="field.fieldModel.type === FieldType.ObjectList" :database="database"
            :environmentDatabase="environmentDatabase" :node="row" :field="field" :show-label="false"
            @update:field="onFieldChanged" />
          <ProjectLinkField v-if="field.fieldModel.type === FieldType.ProjectLink" :database="database"
            :environmentDatabase="environmentDatabase" :node="row" :field="field" :show-label="false"
            @update:field="onFieldChanged" />
        </q-td>

        <q-td key="action" :props="props">
          <q-btn v-if="props.rowIndex === rows.length - 1" outline rounded class="q-mr-sm" padding="xs" color="primary"
            icon="add" @click="addRow()"></q-btn>
          <q-btn outline rounded color="negative" padding="xs" icon="delete" @click="removeRow(props.rowIndex)"></q-btn>
        </q-td>
      </q-tr>
    </template>
    <template v-slot:no-data="{ message }">
      <div class="full-width row flex-center q-gutter-sm">
        <span>
          <q-btn label="Ajouter un élément" @click="addRow()" />
        </span>
      </div>
    </template>
  </q-table>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
