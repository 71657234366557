import Main from './Main.vue'
import DocumentEditor from './DocumentEditor.vue'
import DocumentGenerator from './DocumentGenerator.vue'
import DocumentsList from './DocumentsList.vue'
import DragAndDrop from './DragAndDrop.vue'
import WebSocket from './WebSocket.vue'
import Pdf from './Pdf.vue'
import ConcatPdf from './ConcatPdf.vue'
import TestApi from './TestApi.vue'
import ProjectEditor from './ProjectEditor.vue'
import Project from './Project.vue'

const routes = [
  {
    name: 'v2/main',
    component: Main,
    path: '/v2/main',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/document_editor',
    component: DocumentEditor,
    path: '/v2/document_editor',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/document_generator',
    component: DocumentGenerator,
    path: '/v2/document_generator',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/documents_list',
    component: DocumentsList,
    path: '/v2/documents_list',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/drag_and_drop',
    component: DragAndDrop,
    path: '/v2/drag_and_drop',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/web_socket',
    component: WebSocket,
    path: '/v2/web_socket',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/pdf',
    component: Pdf,
    path: '/v2/pdf',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/concat_pdf',
    component: ConcatPdf,
    path: '/v2/concat_pdf',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/test_api',
    component: TestApi,
    path: '/v2/test_api',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/project_editor',
    component: ProjectEditor,
    path: '/v2/project_editor',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'v2/project/project_detail',
    component: Project,
    path: '/v2/project/:id',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
