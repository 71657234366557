const mainMenu = {
  menu: {
    sections: [
      {
        name: 'home',
        label: 'Accueil',
        link: 'dashboard/main',
        icon: 'o_home',
        iconSelected: 'home',
        roles: ['user', 'admin', 'finance'],
        items: [],
      },
      {
        name: 'projects',
        label: 'Dossiers',
        link: 'projects/main',
        icon: 'o_layers',
        iconSelected: 'layers',
        roles: ['user'],
        items: [
          // {
          //   label: "Tâches",
          //   link: "projects/tasks",
          //   icon: "task",
          //   roles: ["user"],
          // },
        ],
      },
      {
        label: 'Dossiers permanents',
        link: 'database',
        icon: 'o_folder',
        iconSelected: 'folder',
        roles: ['councel', 'admin', 'user'],
        items: [],
      },
      // {
      //   name: "documents",
      //   label: "Documents",
      //   link: "documents/main",
      //   icon: "folder",
      //   roles: ["user"],
      //   items: [],
      // },
      // {
      //   name: "organization",
      //   label: "Organisation",
      //   link: "organization/main",
      //   icon: "corporate_fare",
      //   roles: ["user"],
      //   items: [
      //     {
      //       label: "Sociétés",
      //       link: "organization/companies",
      //       icon: "business",
      //       roles: ["user"],
      //     },
      //     {
      //       label: "Salariés",
      //       link: "organization/employees",
      //       icon: "badge",
      //       roles: ["user"],
      //     },
      //   ],
      // },
      // {
      //   name: "settings",
      //   label: "Paramètres",
      //   link: "settings/main",
      //   icon: "manage_accounts",
      //   roles: ["user"],
      //   items: [
      //     {
      //       label: "Profil",
      //       link: "settings/profile",
      //       icon: "account_circle",
      //       roles: ["user"],
      //     },
      //     {
      //       label: "Thème",
      //       link: "settings/theme",
      //       icon: "palette",
      //       roles: ["user"],
      //     },
      //   ],
      // },
      // {
      //   name: "administration",
      //   label: "Administration",
      //   link: "administration/main",
      //   icon: "admin_panel_settings",
      //   roles: ["admin", "finance"],
      //   items: [
      //     // {
      //     //   label: "Rôles",
      //     //   link: "administration/roles",
      //     //   icon: "attribution",
      //     //   roles: ["admin"],
      //     // },
      //     {
      //       label: "Utilisateurs",
      //       link: "administration/users",
      //       // icon: "people",
      //       roles: ["admin"],
      //     },
      //     {
      //       label: "Crédits",
      //       link: "administration/credits",
      //       roles: ["admin"],
      //     },
      //     {
      //       label: "Facturation",
      //       link: "administration/billing",
      //       icon: "account_balance",
      //       roles: ["finance"],
      //     },
      //   ],
      // },
      {
        name: 'users',
        label: 'Utilisateurs',
        link: 'administration/users',
        icon: 'o_people',
        iconSelected: 'people',
        roles: ['admin', 'finance'],
        items: [],
      },
      // {
      //   name: "credits",
      //   label: "Crédits",
      //   link: "administration/credits",
      //   icon: "o_payments",
      //   iconSelected: "payments",
      //   roles: ["admin", "finance"],
      //   items: [],
      // },
    ],
  },
}

export { mainMenu }
