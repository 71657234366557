<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'

interface Props {
    field: Field
    showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showLabel: true,
})

function updatingModel() {

}

function onFieldChange() {

}

props.field.value = {
    "line1": "",
    "line2": "",
    "line3": "",
    "line4": "",
    "line5": "",
    "line6": "",
}
</script>

<template>
    <div style="border: 0.5px solid #ccc; padding: 16px;">
        <div v-if="showLabel">
            {{ field.name }}
        </div>
        <q-input v-model="field.value.line1" label="Numéro et rue" @change="onFieldChange"
            @update:model-value="updatingModel" />
        <q-input v-model="field.value.line2" label="Complément" @change="onFieldChange"
            @update:model-value="updatingModel" />
        <q-input v-model="field.value.line3" label="Boîte postale" @change="onFieldChange"
            @update:model-value="updatingModel" />
        <q-input v-model="field.value.line4" label="Code postal" @change="onFieldChange"
            @update:model-value="updatingModel" />
        <q-input v-model="field.value.line5" label="Ville" @change="onFieldChange" @update:model-value="updatingModel" />
        <q-input v-model="field.value.line6" label="Cedex" @change="onFieldChange" @update:model-value="updatingModel" />
    </div>
</template>

<style lang="scss" scoped>
.table {
    margin-top: 10px;
}

h3 {
    font-size: medium;
}
</style>
