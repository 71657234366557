<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  title: string
  buttonConfirmLabel: string
  mode: string
  clientId?: string | undefined | null
  selectedGroup: any
  showPortalSelector?: boolean
  clientName: string
}
const props = withDefaults(defineProps<Props>(), {
  showPortalSelector: false,
  clientName: ''
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const authenticatedUser = useAuthenticatedUser()
const userApi = useApi()
const name: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)

function beforeShow() {
  if (props.mode === 'ADD') {
    name.value = ''
  } else {
    name.value = props.selectedGroup.name
  }
}

async function createItem() {
  console.log("authenticatedUser", authenticatedUser)
  console.log("groupType", groupType.value)
  let clientId
  if (props.showPortalSelector) {
    if (groupType.value.value == 'client') {
      console.log("client group", authenticatedUser.clientId)
      clientId = authenticatedUser.clientId
    } else {
      console.log("environment group")
      clientId = undefined
    }
  } else {
    clientId = props.clientId
  }

  try {
    isLoading.value = true
    const newGroup = await userApi.createPortalGroup(
      {
        name: name.value,
      },
      clientId,
    )
    isLoading.value = false
    Notify.create({
      message: 'Groupe créé',
      type: 'primary',
    })
    emit('ok', newGroup)
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: 'Une erreur est survenue',
      type: 'negative',
    })
  }
}

async function updateItem() {
  try {
    isLoading.value = true
    await userApi.updatePortalGroup(
      props.selectedGroup.id,
      {
        name: name.value,
      },
      props.clientId,
    )
    isLoading.value = false
    Notify.create({
      message: 'Groupe modifié avec succès',
      type: 'primary',
    })
    emit('ok')
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: 'Une erreur est survenue',
      type: 'negative',
    })
  }
}

function onConfirmClick() {
  props.mode === 'ADD' ? createItem() : updateItem()
}
const portalOptions = [
  {
    label: "Client " + props.clientName,
    value: "client",
  },
  {
    label: "MarvellUp",
    value: "MarvellUp",
  }
]

const groupType = ref(portalOptions[0])
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <q-select v-if="props.showPortalSelector" outlined v-model="groupType" :options="portalOptions"
          label="Type de groupe" style="margin-bottom: 16px;" />
        <BaseInput v-model="name" label="Nom" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom du groupe est requis']" />
      </template>
      <template #actions>
        <q-btn flat @click="onConfirmClick" :label="buttonConfirmLabel" :disable="name.length === 0" color="primary" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
