<script lang="ts" setup>
import { ref, inject, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const variables: Ref<any> = inject('variables', ref(null))
const selectedVariable = ref(null)
const stringVariables = variables.value.map((variable) => {
  return variable.name
})
const options: Ref<Array<string>> = ref(stringVariables)

function insertVariable() {
  const variable = variables.value.find((v) => {
    return v.name === selectedVariable.value
  })
  emit('ok', variable)
}

function filterFn(val, update, abort) {
  update(() => {
    const needle = val.toLowerCase()
    options.value = stringVariables.filter((v) => v.toLowerCase().indexOf(needle) > -1)
  })
}

function beforeHide() {
  selectedVariable.value = null
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-hide="beforeHide">
    <BaseDialog
      title="Insérer une variable"
      caption="Veuillez sélectionner la variable que vous souhaitez insérer dans le document."
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <BaseSelect
          class="full-width"
          clearable
          v-model="selectedVariable"
          use-input
          input-debounce="0"
          label="Sélectionnez une variable"
          :options="options"
          style="width: 250px"
          @filter="filterFn"
          behavior="menu"
        >
        </BaseSelect>
      </template>
      <template #actions>
        <q-btn
          flat
          label="Insérer la variable"
          color="primary"
          :disable="!selectedVariable"
          @click="insertVariable"
          v-close-popup
        ></q-btn>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
