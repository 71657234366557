export function removeElementFromParent(node, elementToRemove) {
  // console.log("removeElementFromParent", node, elementToRemove)

  let elementList = undefined
  if (node.type == 'page') {
    elementList = node.fields
  } else if (node.type == 'array') {
    elementList = node.columns
  } else if (node.type == 'columns') {
    elementList = node.columns[0].fields
  }

  if (elementList == undefined) {
    // console.log("elementList is undefined")
    return
  }

  removeElementFromList(elementList, elementToRemove)

  if (node.type == 'columns') {
    elementList = node.columns[1].fields
    removeElementFromList(elementList, elementToRemove)
  }
}

function removeElementFromList(elementList, elementToRemove) {
  // console.log("removeElementFromList", elementList, elementToRemove)
  for (let i = 0; i < elementList.length; i++) {
    const element = elementList[i]
    if (element == elementToRemove) {
      elementList.splice(i, 1)
      return
    }
    removeElementFromParent(element, elementToRemove)
  }
}
