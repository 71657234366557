import Main from './Main.vue'
import EntityEditor from './EntityEditor.vue'

const routes = [
  {
    name: 'database/main',
    component: Main,
    path: '/database/main',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'database/editor',
    component: EntityEditor,
    path: '/database/editor/:entityId',
    props: (route) => ({
      entityId: route.query.entityId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
