<script lang="ts" setup>
import { useDialogPluginComponent, Notify } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  isErrorOnSurvey: boolean
  isChoicesEmpty: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isErrorOnSurvey: false,
  isChoicesEmpty: false,
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Génération du document"
      :show-cancel-button="false"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <span v-if="isErrorOnSurvey && !isChoicesEmpty">
          Attention ! Vous n'avez pas répondu à toutes les questions du questionnaire. Nous allons générer un document
          incomplet.
        </span>
        <span v-if="isErrorOnSurvey && isChoicesEmpty">
          Vous devez répondre à toutes les questions obligatoires du questionnaire pour générer le document.
        </span></template
      >
      <template #actions>
        <q-btn flat v-if="isErrorOnSurvey && !isChoicesEmpty" label="Annuler" color="primary" v-close-popup />
        <q-btn flat v-if="isErrorOnSurvey && !isChoicesEmpty" label="Générer" color="primary" @click="emit('ok')" />
        <q-btn
          flat
          v-if="isChoicesEmpty || (isChoicesEmpty && !isErrorOnSurvey)"
          label="OK"
          color="primary"
          v-close-popup
      /></template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
