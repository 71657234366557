<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { Question } from '@/models/survey/Question'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  rowSelected: any
  rows: Array<any> | undefined
  columns: Array<any>
  question: Question
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const selected = ref([])

function addAnswer() {
  console.log("selected items", selected.value)
  emit('ok', selected.value)
}
</script>
<template>
  <q-dialog ref="dialogRef" full-width>
    <BaseDialog :title="rowSelected.label" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <q-table v-if="question.type === 'array' || question.type === 'multiarray' || question.type === 'multisign'" flat
          bordered row-key="id" :rows="rows" :columns="columns" selection="multiple" v-model:selected="selected">
        </q-table>
        <q-table v-if="question.type === 'object' ||
          question.type === 'unisign' ||
          question.type === 'text' ||
          question.type === 'number'
          " flat bordered row-key="id" :rows="rows" :columns="columns" selection="single" v-model:selected="selected">
        </q-table>
      </template>
      <template #actions><q-btn flat color="primary" label="Ajouter" @click="addAnswer()"></q-btn> </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
