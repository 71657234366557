import { Document } from '@/models/v2/document/Document'
import { last } from 'cypress/types/lodash'

export interface Action {
  do(document: Document, index: number): void
  undo(document: Document, index: number): void
}

export class UndoStack {
  actions: Action[]
  document: Document

  constructor(document: Document) {
    this.actions = []
    this.document = document
  }

  undoLastAction(): Action {
    const lastAction = this.actions.pop()
    lastAction?.undo(this.document, lastAction.position[0])
    return lastAction
  }

  pushAction(action: Action): void {
    this.actions.push(action)
  }
}

export class RedoStack {
  actions: Action[]
  document: Document

  constructor(document: Document) {
    this.actions = []
    this.document = document
  }

  redoLastUndoAction(): Action {
    const lastAction = this.actions.pop()

    lastAction?.do(this.document, lastAction.position[0])
    return lastAction
  }

  pushAction(action: Action): void {
    this.actions.push(action)
  }
}

export class UndoRedo {
  undoStack: UndoStack
  redoStack: RedoStack
  document: Document

  constructor(document: Document) {
    this.document = document
    this.undoStack = new UndoStack(this.document)
    this.redoStack = new RedoStack(this.document)
  }

  do(action: Action) {
    // action.do(this.document);
    this.undoStack.pushAction(action)
  }

  undo() {
    const lastAction = this.undoStack.undoLastAction()
    if (lastAction) {
      this.redoStack.pushAction(lastAction)
    }
  }

  redo() {
    const lastUndoAction = this.redoStack.redoLastUndoAction()
    if (lastUndoAction) {
      this.undoStack.pushAction(lastUndoAction)
    }
  }
}

export class AddParagraphBlock implements Action {
  paragraph: any
  position: number[]

  constructor(paragraph, position: number[]) {
    this.paragraph = paragraph
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.paragraph)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddHeadingBlock implements Action {
  paragraph: any
  position: number[]

  constructor(heading, position: number[]) {
    this.heading = heading
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.heading)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddTableBlock implements Action {
  table: any
  position: number[]

  constructor(table, position: number[]) {
    this.table = table
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.table)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddListBlock implements Action {
  list: any
  position: number[]

  constructor(list, position: number[]) {
    this.list = list
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.list)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddImageBlock implements Action {
  image: any
  position: number[]

  constructor(image, position: number[]) {
    this.image = image
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.image)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddLoopBlock implements Action {
  loop: any
  position: number[]

  constructor(loop, position: number[]) {
    this.loop = loop
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.loop)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddConditionBlock implements Action {
  condition: any
  position: number[]

  constructor(condition, position: number[]) {
    this.condition = condition
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.condition)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}

export class AddAlternativeBlock implements Action {
  alternative: any
  position: number[]

  constructor(alternative, position: number[]) {
    this.alternative = alternative
    this.position = position
  }

  do(document: Document, index: number): void {
    document.sections[0].elements.splice(index, 0, this.alternative)
  }

  undo(document: Document, index: number): void {
    document.sections[0].popElement(index)
  }
}
