<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseFileInput from '@/components/base/BaseFileInput.vue'

interface Props {}

const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const fileToUpload: Ref<Array<File>> = ref([])

function beforeShow() {}

async function uploadFile() {
  let uploadedFileInfos = await userApi.uploadDatabaseFile(fileToUpload.value)

  emit('ok', uploadedFileInfos)
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog
      title="Importer un fichier"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <BaseFileInput
          label="Sélectionnez le fichier à importer."
          v-model="fileToUpload"
          :multiple="false"
          @uploadFile="uploadFile"
          :showImportButton="false"
        ></BaseFileInput>
      </template>
      <template #actions>
        <q-btn color="primary" @click="uploadFile" flat label="Importer" :disable="fileToUpload.length == 0" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
