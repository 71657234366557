<script setup lang="ts">
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { ref } from 'vue'
import { Notify } from 'quasar'
import UserApi from '@/services/api/core/UserApi'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const store = useStore()
store.commit('setPageTitle', 'Liste des documents')

const userApi: UserApi = useApi()

const list = ref([])
const rows = ref([])
const loading = ref(true)
const columns = ref([
  { name: 'name', label: 'Nom', field: 'name', sortable: true, align: 'left' },
  { name: 'project', label: 'Projet', field: 'projectName', sortable: true, align: 'left' },
  { name: 'task', label: 'Tâche', field: 'taskName', sortable: true, align: 'left' },
])

fetch(userApi.api.BASE_URL + 'api/v1/legacy/get_documents')
  .then((response) => {
    response.json().then((data) => {
      list.value = data.results
      loading.value = false
    })
  })
  .catch((error) => {
    console.log(error)
  })

function onRowClick(event, row) {
  console.log('onRowClick', row)
  console.log(`redirect to ${row.id} for row ${row.name}`)
  router.push({
    name: 'v2/document_generator',
    params: { id: row.id },
  })
}
</script>

<template>
  <q-page padding class="page">
    <h5>Liste des documents</h5>
    <BaseTable
      row-key="name"
      :rows="list"
      :is-loading="loading"
      :columns="columns"
      @row-click="onRowClick"
      :enable-add="false"
      :enable-actions="false"
    />
  </q-page>
</template>
