<script lang="ts" setup>
import { Ref, ref, inject, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Notify, Dialog } from 'quasar'
import { format } from 'date-fns'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'
import SignatureTaskProcessDialog from './dialog/SignatureTaskProcessDialog.vue'
import DocumentCard from '@/components/projects/execution/task_actions/document_generation/DocumentCard.vue'
import FileSaver from 'file-saver'
import PdfPreviewDialogWithIframe from '@/components/projects/execution/task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'
import DeleteSignedDocumentDialog from './dialog/DeleteSignedDocumentDialog.vue'

interface Props {
  task: Task
}

const props = withDefaults(defineProps<Props>(), {})
const userApi: UserApi = useApi()
const envelopes: Ref<Array<any>> = ref([])
const isButtonLoading = ref(false)

const project = inject(ProjectKey)
const route = useRoute()

watch(props, (newValue, oldValue) => {
  updateEnvelopes()
})

updateEnvelopes()

async function updateTaskStatus() {
  console.log('updateTaskStatus')
  if (props.task.status == 'not_started') {
    console.log('Set task as started')
    props.task.status = 'started'
    await userApi.putProjectTask(project?.value.id, props.task?.id, {
      status: 'started',
      client: route.params.clientId,
    })

    const new_event = await userApi.createProjectEvent(project?.value.id, {
      type: 1,
      user_project_id: project?.value.id,
      legacy_task_id: props.task?.id,
    })
    // emit("updateEvents", new_event);
  }
}

async function startSignatureProcess() {
  const dialog = Dialog.create({
    component: SignatureTaskProcessDialog,
    componentProps: {
      task: props.task,
    },
  }).onOk(() => {
    updateEnvelopes()
    updateTaskStatus()
    dialog.hide()
  })
}

async function updateEnvelopes() {
  isButtonLoading.value = true
  userApi.getTaskSignatureEnvelopes(props.task.id).then((result) => {
    envelopes.value = result
    console.log("envelopes", envelopes.value)
    for (let i = 0; i < envelopes.value.length; ++i) {
      let envelope = envelopes.value[i]
      envelope.documentName = envelope.documents[0].originalName
      if (envelope.status === 'completed') {
        envelope.sortDate = envelope.completedAt
      } else {
        envelope.sortDate = envelope.myCreatedAt
      }
    }
    envelopes.value.sort((a, b) => {
      return new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime()
    })
    isButtonLoading.value = false
  })
}

function downloadFile(envelope) {
  for (let i = 0; i < envelope.documents.length; ++i) {
    let document = envelope.documents[i]
    if (document.isSigned == true) {
      downloadDocument(document, envelope)
      return
    }
  }
}

async function downloadDocument(document, envelope) {
  try {
    // isLoading.value = true;
    const doc = await userApi.downloadSignedDocument(document.internalId)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    // reader.readAsBinaryString(new Blob([doc]));
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file.docx', {
        type: 'application/octet-stream',
      })
      // setloadingSaveDoc(false);

      var arr = new Uint8Array(reader.result).subarray(0, 4)
      var header = ''
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }

      if (header == '25504446') {
        // PDF
        FileSaver(newFile, envelope.documentName + '.pdf')
      } else {
        // Assume docx
        FileSaver(newFile, envelope.documentName + '.docx')
      }
    }

    // isLoading.value = false;
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    // isLoading.value = false;
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

function formatDate(date_str) {
  const dateFormat = new Date(date_str)
  return format(dateFormat, 'dd/MM/yyyy à HH:mm')
}

async function showPdfPreview(envelope) {
  if (envelope.status !== 'completed') {
    return
  }

  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(envelope.documentName + '.pdf')

  let fileUrl = ""
  for (let i = 0; i < envelope.documents.length; ++i) {
    let document = envelope.documents[i]
    if (document.isSigned == true) {
      fileUrl = BASE_URL + `api/v1/legacy/service_files/${document.internalId}/download?type=preview&filename=${filenameConverted}`
      break
    }
  }

  const dialog = Dialog.create({
    component: PdfPreviewDialogWithIframe,
    componentProps: {
      // file: file.id,
      documentName: envelope.documentName + '.pdf',
      // taskId: props.task.id,
      fileUrl: fileUrl,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showDeleteDocumentModal(envelope) {
  const dialog = Dialog.create({
    component: DeleteSignedDocumentDialog,
    componentProps: {
    },
  })
    .onOk(async () => {
      try {
        await userApi.deleteSignedDocument(envelope.id)
        dialog.hide()
        updateEnvelopes()
        Notify.create({
          message: 'Document supprimé',
          type: 'primary',
        })
      }
      catch (e) {
        dialog.hide()
        Notify.create({
          message: 'Une erreur est survenue lors de la suppression du document',
          type: 'negative',
        })
      }
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div class="row field items-top">
    <div class="field-title field-title task-field-title col-auto">
      <p>Action</p>
    </div>
    <div class="q-pl-md col">
      <q-btn color="primary" size="13px" padding="xs md" label="Lancer le processus de signature"
        @click="startSignatureProcess" :loading="isButtonLoading" :disable="isButtonLoading || project.isReadOnly" />
    </div>
    <div class="full-width">
      <DocumentCard class="q-mt-md" v-for="envelope in envelopes" @click="showPdfPreview(envelope)">
        <template #name>
          <div>
            {{ envelope.documentName }}
          </div>
        </template>
        <template #caption v-if="envelope.status === 'completed'">
          <div>
            Signé le {{ formatDate(envelope.completedAt) }}
          </div>
        </template>
        <template #caption v-if="envelope.status !== 'completed'">
          <div>
            En cours de signature - Processus démarré le
            {{ formatDate(envelope.myCreatedAt) }}
          </div>
        </template>
        <template #actions>
          <div>
            <q-btn-dropdown flat rounded dropdown-icon="more_vert" name="document-options-btn" @click.stop>
              <q-list dense>
                <q-item clickable v-close-popup @click="downloadFile(envelope)" v-if="envelope.status === 'completed'">
                  <q-item-section>
                    <q-item-label>Télécharger</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="showPdfPreview(envelope)" v-if="envelope.status === 'completed'">
                  <q-item-section>
                    <q-item-label> Prévisualiser </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup class="text-red" @click="showDeleteDocumentModal(envelope)">
                  <q-item-section>
                    <q-item-label text-color="negative">Supprimer</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
            <q-tooltip v-if="envelope.status !== 'completed'">Le document sera disponible une fois signé</q-tooltip>
          </div>
        </template>
      </DocumentCard>
    </div>
  </div>
</template>
