<script setup lang="ts"></script>
<template>
  <q-footer class="footer">
    <div class="row justify-between items-center full-height">
      <slot name="content"></slot>
    </div>
  </q-footer>
</template>
<style lang="scss" scoped>
.footer {
  height: 54px;
}
</style>
