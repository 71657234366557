<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  entity: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const newDocumentName: Ref<string> = ref(props.entity.name)
const isLoading: Ref<boolean> = ref(false)

async function renameFile() {
  try {
    isLoading.value = true
    isLoading.value = false

    if (newDocumentName.value != props.entity.name) {
      Notify.create({
        message: 'Element renommé',
        type: 'primary',
      })
      emit('ok', newDocumentName.value)
    } else {
      dialogRef.value?.hide()
    }
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: "Une erreur est survenue lors du renommage de l'élément",
      type: 'negative',
    })
  }
}
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Renommer l'entité" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="newDocumentName" type="text" label="Nom" autofocus autoselect @keyup.enter="renameFile()">
        </BaseInput>
      </template>
      <template #actions>
        <q-btn flat label="Renommer" @click="renameFile()" color="primary" :disable="newDocumentName === ''" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
