<script setup lang="ts">
import { ref } from 'vue'

const outlookCalendarIntegrationEnabled = ref(false)

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const activated = urlParams.get('activated')
if (activated) {
  outlookCalendarIntegrationEnabled.value = true
}

function activateOutlookCalendarIntegration() {
  window.location =
    'http://localhost:8000/api/v1/meetings/calendar_integrations/start_microsoft_calendar_authorization_process'
}

function desactivateOutlookCalendarIntegration() {
  window.location = 'http://localhost:4000/activity/calendar'
}
</script>

<template>
  <div v-if="!outlookCalendarIntegrationEnabled">
    L'intégration Outlook Calendar n'est pas activée &nbsp;&nbsp;
    <q-btn label="Activer" @click="activateOutlookCalendarIntegration" />
  </div>
  <div v-else>
    L'intégration Outlook Calendar est activée &nbsp;&nbsp;
    <q-btn label="Désactiver" @click="desactivateOutlookCalendarIntegration" />
  </div>
</template>

<style lang="scss" scoped></style>
