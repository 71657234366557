import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let tribunal: ObjectType = {
  name: 'Tribunal',
  fields: [
    {
      label: 'Nom',
      type: FieldType.String,
    },
    {
      label: 'Adresse',
      type: FieldType.Object,
      objectTypeName: 'Adresse',
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[0].value
  },
}

export { tribunal }
