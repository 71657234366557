<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { useBrowserLocation } from '@vueuse/core'
import { onMounted, ref } from 'vue'

import { getRoutes } from '../../../models/AuthenticatedUser'

const store = useStore()
const router = useRouter()
const route = useRoute()

const logoUrl = ref('')
const login = ref('')
const firstName = ref('')
const lastName = ref('')
const password = ref('')
const passwordCheck = ref('')

onMounted(() => {
  store.commit('setPageTitle', 'Authentification')
})

const location = useBrowserLocation()
// TODO: Replace with an API call
if (location.value.hostname.includes('bewiz')) {
  logoUrl.value = 'https://www.bewiz.fr/wp-content/uploads/2015/01/bewiz2.png'
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_bewiz.gif'
} else if (location.value.hostname.includes('marvellup')) {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/615b210de1a84a70208dd30e_MarvellUp-03%20(1)-p-500.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvell_up.png'
} else if (location.value.hostname.includes('karila')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_karila.png'
} else {
  logoUrl.value =
    'https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ae6b0c56ec71df6665d5a3_comeon_logofinal-p-500.png'
}

function handleSubmit() {
  router.push({ name: 'base/login' })
}
</script>

<template>
  <q-page flex>
    <div class="q-pa-md page-content q-col-gutter-md centered-form" style="max-width: 450px">
      <div class="row q-col-gutter-sd">
        <q-img :src="logoUrl"></q-img>
      </div>
      <div class="row q-col-gutter-sd">
        <h1 class="col-md-12">Inscription terminée !</h1>
      </div>
      <div class="row">
        <q-btn
          class="col-md-12 submit-button justify-center full-width"
          color="primary"
          label="Se connecter"
          @click="handleSubmit"
        />
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h1 {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
