<script setup lang="ts">
import { Task } from '@/models/projects/Task'
import { TaskTypeEnum } from '@/models/projects/TaskType.enum.ts'
import DocumentGenerationTaskActions from './document_generation/DocumentGenerationTaskActions.vue'
import SignatureTaskActions from './signature/SignatureTaskActions.vue'
import UploadTaskActions from './upload/UploadTaskActions.vue'
import MailSendingTaskActions from './mail_sending/MailSendingTaskActions.vue'

interface Props {
  task: Task
}

const props = defineProps<Props>()

const taskTypeEnumType = TaskTypeEnum
</script>

<template>
  <DocumentGenerationTaskActions v-if="props.task.type === taskTypeEnumType.DocumentGeneration" :task="task" />

  <SignatureTaskActions v-if="props.task.type === taskTypeEnumType.DocumentSignature" :task="task" />

  <UploadTaskActions v-if="props.task.type === taskTypeEnumType.DocumentUpload" :task="task" />

  <MailSendingTaskActions v-if="props.task.type === taskTypeEnumType.MailSending" :task="task" />
</template>

<style lang="scss">
.field-title {
  color: gray;
}

.or {
  color: gray;
}
</style>
