export enum TaskTypeEnum {
  Information = 'information',
  Form = 'form',
  DocumentGeneration = 'document_generation',
  DocumentUpload = 'document_upload',
  DocumentSignature = 'document_signature',
  OnlinePayment = 'online_payment',
  MailSending = 'mail_sending',
  AppointmentMaking = 'appointment_making',
}
