import { Database } from '../Database'
import { NodeType } from '../NodeType'

import { physicalPerson } from '../node_types/PhysicalPerson'
import { entity } from '../node_types/Entity'
import { sas } from '../node_types/SAS'
import { treatment } from '../node_types/Treatment'
import { address } from '../node_types/Address'
import { agRegister } from '../node_types/AgRegister'
import { ag } from '../node_types/Ag'
import { treatmentRegister } from '../node_types/TreatmentRegister'
import { cpam } from '../node_types/CPAM'
import { tribunal } from '../node_types/Tribunal'
import { infractionRegister } from '../node_types/infractionRegister'
import { requestRegister } from '../node_types/requestRegister'
import { subcontractorRegister } from '../node_types/subcontractorRegister'

let demoAgoDatabase: Database = {
  clientId: 'client_id',
  objectTypes: [
    address,
    physicalPerson,
    entity,
    sas,
    treatment,
    agRegister,
    ag,
    treatmentRegister,
    infractionRegister,
    requestRegister,
    subcontractorRegister,
    cpam,
    tribunal,
  ],

  // Nodes
  nodes: [
    // {
    //   id: "c4aa2827-c8cc-40ca-afbb-e212e2998e5d",
    //   label: "Répertoires",
    //   type: NodeType.Folder,
    //   fields: [],
    //   children: [
    //     {
    //       id: "aa3fa4fb-f9e3-4922-b9b5-55b41534a40b",
    //       label: "Répertoires généraux",
    //       type: NodeType.Folder,
    //       fields: [],
    //       children: [
    {
      id: 'b59ab461-1e83-4924-8786-ca12872b7480',
      label: 'Personnes physiques',
      type: NodeType.Folder,
      authorizedObjectTypes: ['Personne physique'],
      fields: [],
      children: [
        // {
        //   id: "7bcb14fd-a4dd-4b69-90f8-66803c24e3ad",
        //   label: "Jean Dupont",
        //   type: NodeType.Object,
        //   objectType: "Personne physique",
        //   fields: [
        //     {
        //       label: "Civilité",
        //       value: "Monsieur",
        //     },
        //     { label: "Prénom", value: "Jean" },
        //     { label: "Nom", value: "Dupont" },
        //     {
        //       label: "Date de naissance",
        //       value: "10/04/1979",
        //     },
        //     {
        //       label: "Ville de naissance",
        //       value: "Lille",
        //     },
        //     {
        //       label: "Nationalité",
        //       value: "Française",
        //     },
        //     {
        //       label: "Adresse",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59000",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Lille",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Profession",
        //       value: "Maçon",
        //     },
        //     {
        //       label: "Numéro de sécurité sociale",
        //       value: "1234",
        //     },
        //   ],
        //   children: [],
        // }, // End Jean Dupont
        // {
        //   id: "787cba4f-639f-48f8-affa-6c341ee73ca8",
        //   label: "Jacques Martin",
        //   type: NodeType.Object,
        //   objectType: "Personne physique",
        //   fields: [
        //     {
        //       label: "Civilité",
        //       value: "Monsieur",
        //     },
        //     {
        //       label: "Prénom",
        //       value: "Jacques",
        //     },
        //     { label: "Nom", value: "Martin" },
        //     {
        //       label: "Date de naissance",
        //       value: "10/04/1979",
        //     },
        //     {
        //       label: "Ville de naissance",
        //       value: "Lille",
        //     },
        //     {
        //       label: "Nationalité",
        //       value: "Française",
        //     },
        //     {
        //       label: "Adresse",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Profession",
        //       value: "Maçon",
        //     },
        //     {
        //       label: "Numéro de sécurité sociale",
        //       value: "1234",
        //     },
        //   ],
        //   children: [],
        // }, // End Jacques Martin
      ],
    }, // End Personnes physiques

    {
      id: '75d2179b-d25a-400b-bf05-41d723f6d0a9',
      label: 'Entreprises et associations',
      type: NodeType.Folder,
      authorizedObjectTypes: ['SAS', 'SASU', 'SAS', 'EURL', 'Association'],
      fields: [],
      children: [
        // {
        //   id: "c8490959-5004-45bb-958d-1cd9e3fe427b",
        //   label: "Come On Law",
        //   type: NodeType.Object,
        //   objectType: "SAS",
        //   fields: [
        //     {
        //       label: "Nom",
        //       value: "Come On Law",
        //     },
        //     {
        //       label: "Forme juridique",
        //       value: "SAS",
        //     },
        //     {
        //       label: "Capital social",
        //       value: "42",
        //     },
        //     {
        //       label: "Date de création",
        //       value: "10/04/2020",
        //     },
        //     {
        //       label: "Adresse du siège social",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Numéro d'immatriculation",
        //       value: "1234",
        //     },
        //     {
        //       label: "Ville d'immatriculation",
        //       value: "Paris",
        //     },
        //     {
        //       label: "Représentant légal",
        //       value: "7bcb14fd-a4dd-4b69-90f8-66803c24e3ad",
        //     },
        //     {
        //       label: "Sociétés filles",
        //       value: {
        //         links: [
        //           {
        //             fields: [
        //               {
        //                 label: "Société fille",
        //                 value: "218b7177-cac9-46af-8d27-2d28e780403b",
        //               },
        //               {
        //                 label: "Nombre de titres détenus dans la fille",
        //                 value: "42",
        //               },
        //               {
        //                 label:
        //                   "Nombre total de titres dans le capital de la fille",
        //                 value: "43",
        //               },
        //               {
        //                 label: "Classe d'actions",
        //                 value: "Classe",
        //               },
        //             ],
        //           },
        //           {
        //             fields: [
        //               {
        //                 label: "Société fille",
        //                 value: "641d4085-a9d1-48e0-b54a-b2de53bf6401",
        //               },
        //               {
        //                 label: "Nombre de titres détenus dans la fille",
        //                 value: "12",
        //               },
        //               {
        //                 label:
        //                   "Nombre total de titres dans le capital de la fille",
        //                 value: "25",
        //               },
        //               {
        //                 label: "Classe d'actions",
        //                 value: "Classe",
        //               },
        //             ],
        //           },
        //         ],
        //       },
        //     }, // End Sociétés filles
        //     {
        //       label: "Commissaires aux comptes",
        //       value: {
        //         links: [
        //           {
        //             fields: [
        //               {
        //                 label: "Commissaire aux comptes",
        //                 value: "62b943c6-bc5f-4432-a238-3237292c62d0",
        //               },
        //               {
        //                 label: "Position",
        //                 value: "Titulaire",
        //               },
        //               {
        //                 label: "Date de désignation",
        //                 value: "10/04/2022",
        //               },
        //             ],
        //           },
        //           {
        //             fields: [
        //               {
        //                 label: "Commissaire aux comptes",
        //                 value: "c727b54c-b605-4b2f-89d9-6e51bcf1e993",
        //               },
        //               {
        //                 label: "Position",
        //                 value: "Suppléant",
        //               },
        //               {
        //                 label: "Date de désignation",
        //                 value: "10/04/2021",
        //               },
        //             ],
        //           },
        //         ],
        //       },
        //     }, // End commissaires aux comptes
        //     {
        //       label: "Membres du conseil stratégique",
        //       value: {
        //         links: [
        //           {
        //             fields: [
        //               {
        //                 label: "Membre",
        //                 value: "7bcb14fd-a4dd-4b69-90f8-66803c24e3ad",
        //               },
        //               {
        //                 label: "Date d'entrée",
        //                 value: "11/05/2021",
        //               },
        //               {
        //                 label: "Date de sortie",
        //                 value: undefined,
        //               },
        //             ],
        //           },
        //         ],
        //       },
        //     }, // End Membres du conseil stratégique
        //   ],
        //   children: [
        //     {
        //       id: "a015aab8-0080-477e-939b-fec559849b3a",
        //       label: "Fiche d'identité",
        //       type: NodeType.FieldLink,
        //       objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //       fieldLink: {
        //         objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //         fields: [
        //           "Nom",
        //           "Forme juridique",
        //           "Capital social",
        //           "Date de création",
        //           "Adresse du siège social",
        //           "Numéro d'immatriculation",
        //           "Ville d'immatriculation",
        //           "Représentant légal",
        //         ],
        //       },
        //       fields: [],
        //       children: [
        //         {
        //           id: "7c232439-5e79-4295-aaeb-e5e80f911b72",
        //           label: "Statuts",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Statuts"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "7c232439-5e79-4295-aaeb-e5e80f911b71",
        //           label: "Commissaires aux comptes",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Commissaires aux comptes"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "e7766024-eec1-4644-8a52-5598df833a0f",
        //           label: "Baux",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Baux"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "ca9fce33-0083-4df1-b083-69783cba8039",
        //           label: "Sociétés filles",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Sociétés filles"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "50410541-5255-4727-9433-138ed8b48b81",
        //           label: "Actionnaires",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Actionnaires"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "2eac86ac-47d1-4ba0-a91e-33d2f8664cb2",
        //           label: "Membres du CSE",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Membres du CSE"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "b05ad22d-f6cf-449d-8f94-1fde9b4b4fb1",
        //           label: "Délégués du personnel",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Délégués du personnel"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //         {
        //           id: "499bef10-2d1c-44e0-a6d5-ce4b6e8e4912",
        //           label: "Membres du conseil stratégique",
        //           type: NodeType.FieldLink,
        //           objectLinkId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //           fieldLink: {
        //             objectId: "c8490959-5004-45bb-958d-1cd9e3fe427b", // SAS Come On Law
        //             fields: ["Membres du conseil stratégique"],
        //           },
        //           fields: [],
        //           children: [],
        //         },
        //       ],
        //     }, // End Fiche d'identité
        //     // {
        //     //   id: "a9429367-df94-46b6-9a3b-cfd731919bd9",
        //     //   label: "Registres",
        //     //   type: NodeType.Folder,
        //     //   fields: [],
        //     //   children: [
        //     //     {
        //     //       id: "162f5845-aeaf-4d14-b175-77be8be328c1",
        //     //       label: "Registres des personnes",
        //     //       type: NodeType.Folder,
        //     //       fields: [],
        //     //       children: [
        //     //         {
        //     //           id: "43ead9a6-732d-4ab3-97f8-629f4aa814f3",
        //     //           label: "Registres de gouvernance",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //         {
        //     //           id: "11ffcf5e-14ca-48da-9e59-c2b658a5fe01",
        //     //           label: "Registres du personnel",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //         {
        //     //           id: "d01cd6ae-b2f2-4e9e-904f-7c9cf848a414",
        //     //           label: "Registres de la santé du personnel",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //         {
        //     //           id: "e810ac33-d438-4b62-8374-c7a262778cb0",
        //     //           label: "Registres des assemblées générales",
        //     //           type: NodeType.Object,
        //     //           objectType: "Registre des assemblées générales",
        //     //           authorizedObjectTypes: ["Assemblée générale"],
        //     //           fields: [],
        //     //           children: [
        //     //             {
        //     //               id: "d5440f8f-05ee-488a-b9f1-7d91ad07e304",
        //     //               label: "Asemblée générale du 10/04/2022",
        //     //               type: NodeType.Object,
        //     //               objectType: "Assemblée générale",
        //     //               fields: [
        //     //                 {
        //     //                   label: "Date",
        //     //                   value: "10/04/2022",
        //     //                 },
        //     //                 {
        //     //                   label: "Projet",
        //     //                   value: "Ag du 10/04/2022",
        //     //                 },
        //     //               ],
        //     //               children: [],
        //     //             },
        //     //           ],
        //     //         },
        //     //         {
        //     //           id: "cc46aa88-a376-4ce6-9f7f-4fa98574f23c",
        //     //           label: "Registres des décisions de l'associé unique",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //         {
        //     //           id: "c62ef562-9c5a-44fd-baa6-6de12fa71184",
        //     //           label: "Registre IT IP",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //         {
        //     //           id: "1d699058-f82a-4f3e-b016-9c620539ea02",
        //     //           label: "Registre des mouvements de titre",
        //     //           type: NodeType.Folder,
        //     //           fields: [],
        //     //           children: [],
        //     //         },
        //     //       ],
        //     //     },
        //     //     {
        //     //       id: "387d97c6-e939-4c24-8d86-3588f0e6caf5",
        //     //       label: "Registres RGPD",
        //     //       type: NodeType.Folder,
        //     //       fields: [],
        //     //       children: [
        //     //         {
        //     //           id: "11bc13d9-3560-4b5a-a98f-0bf06bcded35",
        //     //           label: "Registre des traitements",
        //     //           type: NodeType.Object,
        //     //           objectType: "Registre des traitements",
        //     //           fields: [],
        //     //           children: [
        //     //             {
        //     //               id: "a73b5d44-f980-4e6a-a1db-cad1dcbab3cd",
        //     //               label: "Traitement 1",
        //     //               type: NodeType.Object,
        //     //               objectType: "Traitement",
        //     //               fields: [
        //     //                 {
        //     //                   label: "Intitulé",
        //     //                   value: "Traitement 1",
        //     //                 },
        //     //                 {
        //     //                   label: "Description",
        //     //                   value: "Description du traitement 1",
        //     //                 },
        //     //               ],
        //     //               children: [],
        //     //             },
        //     //             {
        //     //               id: "34f391fb-65c9-4fa8-8f50-71a49787edfc",
        //     //               label: "Traitement 2",
        //     //               type: NodeType.Object,
        //     //               objectType: "Traitement",
        //     //               fields: [
        //     //                 {
        //     //                   label: "Intitulé",
        //     //                   value: "Traitement 2",
        //     //                 },
        //     //                 {
        //     //                   label: "Description",
        //     //                   value: "Description du traitement 2",
        //     //                 },
        //     //               ],
        //     //               children: [],
        //     //             },
        //     //           ],
        //     //         },
        //     //       ],
        //     //     },
        //     //   ],
        //     // }, // End registres
        //   ],
        // }, // End SAS groupe
        // {
        //   id: "218b7177-cac9-46af-8d27-2d28e780403b",
        //   label: "Société fille 1",
        //   type: NodeType.Object,
        //   objectType: "Entité",
        //   fields: [
        //     {
        //       label: "Nom",
        //       value: "Société fille 1",
        //     },
        //     {
        //       label: "Forme juridique",
        //       value: "SAS",
        //     },
        //     {
        //       label: "Capital social",
        //       value: "42",
        //     },
        //     {
        //       label: "Date de création",
        //       value: "10/04/2020",
        //     },
        //     {
        //       label: "Adresse du siège social",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Numéro d'immatriculation",
        //       value: "1234",
        //     },
        //     {
        //       label: "Ville d'immatriculation",
        //       value: "Paris",
        //     },
        //     {
        //       label: "Représentant légal",
        //       value: "787cba4f-639f-48f8-affa-6c341ee73ca8",
        //     },
        //   ],
        //   children: [],
        // }, // End Société fille 1
        // {
        //   id: "641d4085-a9d1-48e0-b54a-b2de53bf6401",
        //   label: "Société fille 2",
        //   type: NodeType.Object,
        //   objectType: "Entité",
        //   fields: [
        //     {
        //       label: "Nom",
        //       value: "Société fille 2",
        //     },
        //     {
        //       label: "Forme juridique",
        //       value: "SAS",
        //     },
        //     {
        //       label: "Capital social",
        //       value: "42",
        //     },
        //     {
        //       label: "Date de création",
        //       value: "10/04/2020",
        //     },
        //     {
        //       label: "Adresse du siège social",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Numéro d'immatriculation",
        //       value: "1234",
        //     },
        //     {
        //       label: "Ville d'immatriculation",
        //       value: "Paris",
        //     },
        //     {
        //       label: "Représentant légal",
        //       value: "787cba4f-639f-48f8-affa-6c341ee73ca8",
        //     },
        //   ],
        //   children: [],
        // }, // End Société fille 2
        // {
        //   id: "62b943c6-bc5f-4432-a238-3237292c62d0",
        //   label: "Commissaire aux comptes 1",
        //   type: NodeType.Object,
        //   objectType: "Entité",
        //   fields: [
        //     {
        //       label: "Nom",
        //       value: "Commissaire aux comptes 1",
        //     },
        //     {
        //       label: "Forme juridique",
        //       value: "SAS",
        //     },
        //     {
        //       label: "Capital social",
        //       value: "42",
        //     },
        //     {
        //       label: "Date de création",
        //       value: "10/04/2020",
        //     },
        //     {
        //       label: "Adresse du siège social",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Numéro d'immatriculation",
        //       value: "1234",
        //     },
        //     {
        //       label: "Ville d'immatriculation",
        //       value: "Paris",
        //     },
        //     {
        //       label: "Représentant légal",
        //       value: "787cba4f-639f-48f8-affa-6c341ee73ca8",
        //     },
        //   ],
        //   children: [],
        // }, // End Commissaire aux comptes 1
        // {
        //   id: "c727b54c-b605-4b2f-89d9-6e51bcf1e993",
        //   label: "Commissaire aux comptes 2",
        //   type: NodeType.Object,
        //   objectType: "Entité",
        //   fields: [
        //     {
        //       label: "Nom",
        //       value: "Commissaire aux comptes 2",
        //     },
        //     {
        //       label: "Forme juridique",
        //       value: "SAS",
        //     },
        //     {
        //       label: "Capital social",
        //       value: "42",
        //     },
        //     {
        //       label: "Date de création",
        //       value: "10/04/2020",
        //     },
        //     {
        //       label: "Adresse du siège social",
        //       value: {
        //         fields: [
        //           {
        //             label: "Numéro et rue",
        //             value: "12 rue de Paris",
        //           },
        //           {
        //             label: "Code postal",
        //             value: "59160",
        //           },
        //           {
        //             label: "Ville",
        //             value: "Capinghem",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       label: "Numéro d'immatriculation",
        //       value: "1234",
        //     },
        //     {
        //       label: "Ville d'immatriculation",
        //       value: "Paris",
        //     },
        //     {
        //       label: "Représentant légal",
        //       value: "7bcb14fd-a4dd-4b69-90f8-66803c24e3ad",
        //     },
        //   ],
        //   children: [],
        // }, // End Commissaire aux comptes 2
      ],
    }, // End Entreprises et associations
    // ],
    // }, // End Répertoires généraux

    //     {
    //       id: "dbd88691-0d87-4537-9036-9359fed0356e",
    //       label: "Répertoires de spécialités",
    //       type: NodeType.Folder,
    //       fields: [],
    //       children: [],
    //     }, // End Répertoires de spécialités
    //   ],
    // }, // End Répertoires

    // {
    //   id: "a10279fd-d2bf-41b2-9c2b-c19b5891788d",
    //   label: "Bibles d'actes",
    //   type: NodeType.Folder,
    //   fields: [],
    //   children: [],
    // }, // End Bibles d'actes

    {
      id: '5cf6dab4-fc62-4888-bb6e-7ab67da24be8',
      label: 'Tribunaux',
      type: NodeType.Folder,
      authorizedObjectTypes: ['Tribunal'],
      fields: [],
      children: [
        {
          id: 'e1aab761-dde0-41a8-9023-6a10567c4643',
          label: 'Tribunal de Paris',
          type: NodeType.Object,
          objectType: 'Tribunal',
          fields: [
            { label: 'Nom', value: 'Tribunal de Paris' },
            {
              label: 'Adresse',
              value: {
                fields: [
                  {
                    label: 'Numéro et rue',
                    value: 'Parvis du Tribunal-de-Paris',
                  },
                  {
                    label: 'Code postal',
                    value: '75017',
                  },
                  {
                    label: 'Ville',
                    value: 'Paris',
                  },
                ],
              },
            },
          ],
          children: [],
        }, // End Tribunal de Paris
      ],
    }, // End tribunaux

    {
      id: '92f601eb-6d5d-4d4a-b430-1fc42f7b0be0',
      label: 'CPAM',
      type: NodeType.Folder,
      authorizedObjectTypes: ['CPAM'],
      fields: [],
      children: [
        {
          id: 'f7b2f95b-1a97-4212-9863-9c897d842c50',
          label: "CPAM d'Annecy",
          type: NodeType.Object,
          objectType: 'CPAM',
          fields: [
            { label: 'Nom', value: "CPAM d'Annecy" },
            {
              label: 'Adresse',
              value: {
                fields: [
                  {
                    label: 'Numéro et rue',
                    value: '2 rue Robert-Schuman',
                  },
                  {
                    label: 'Code postal',
                    value: '74984',
                  },
                  {
                    label: 'Ville',
                    value: 'Annecy',
                  },
                  {
                    label: 'Cedex',
                    value: 'Cedex 9',
                  },
                ],
              },
            },
          ],
          children: [],
        }, // End CPAM 1
      ],
    }, // End CPAM
  ],
}

export { demoAgoDatabase }
