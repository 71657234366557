<script setup lang="ts">
import { onMounted, ref, provide, inject } from 'vue'
import { useStore } from '@/store/store'
import { initOffice } from './OutlookAddInCommon.ts'
import Documents from './Documents.vue'
import SearchResult from './SearchResult.vue'
import ClientProjects from './ClientProjects.vue'
import EnvironmentSwitcher from './OutlookEnvironmentSwitcher.vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

const store = useStore()
const userApi: UserApi = useApi()

store.commit('setPageTitle', 'Office Manager')

const favorites = inject('favorites')

const recentProjects = await userApi.getRecentlyViewedProjects(1, '', 5)
recentProjects.results.forEach((recentProject) => {
  recentProject.objectId = recentProject.id
})

const step = ref(1)
provide('step', step)
const searchQuery = ref('')
provide('query', searchQuery)
const searchInput = ref('')
const project = ref(null)
const importButtonLoading = ref(false)

const client = ref(null)
provide('client', client)
const selectedNode = ref(null)
provide('documentTreeSelectedNode', selectedNode)
const searchResults = ref(null)
provide('searchResults', searchResults)

const documentTreeNodes = ref([
  {
    id: 0,
    type: 1,
    label: 'Racine',
    children: [],
  },
])
provide('documentTreeNodes', documentTreeNodes)

onMounted(() => {
  console.log('onmounted')
  initOffice(() => {
    const item = Office.context.mailbox.item
    console.log('item', item)
  })
})

function searchMatters() {
  console.log('searchMatters')
  searchQuery.value = searchInput.value
}

function changeMatter() {
  step.value = 1
}

function changeProject() {
  step.value = 11
}

function sendAndImportMail() {
  console.log('send mail')
}

async function goToClientFavorite(clientFavorite) {
  console.log('client favorite', clientFavorite)
  const selectedClient = await userApi.getClient(clientFavorite.objectId)
  client.value = selectedClient
  step.value = 11
}

async function goToUserProjectFavorite(userProjectFavorite) {
  console.log('project favorite', userProjectFavorite)
  const selectedProject = await userApi.getProject(userProjectFavorite.objectId)
  client.value = await userApi.getClientForProject(userProjectFavorite.objectId)
  project.value = selectedProject
  documentTreeNodes.value = [
    {
      id: 0,
      type: 1,
      label: 'Racine',
      children: [],
    },
  ]
  step.value = 2
}
</script>

<template>
  <q-page class="page">
    <EnvironmentSwitcher />

    <h1>Edition d'email</h1>

    <div v-if="step == -1">
      <div class="row">
        <div class="col"></div>
        <q-spinner class="col-auto" size="50px" color="primary" />
        <div class="col"></div>
      </div>
    </div>

    <div v-if="step == 1">
      <div style="padding: 8px; font-weight: 500; color: #4068c8">Sélectionnez le dossier à utiliser</div>
      <KeepAlive>
        <q-input v-model="searchInput" outlined dense label="Rechercher un client ou un dossier"
          @keydown.enter.prevent="searchMatters()" class="search-input" />
      </KeepAlive>

      <KeepAlive>
        <div v-if="searchQuery == ''">
          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 16px;">Dossiers récents</div>
          <div v-for="(recentProject, index) in recentProjects.results" style="cursor: pointer"
            @click="goToUserProjectFavorite(recentProject)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ recentProject.name }}</div>
            </div>
          </div>

          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 8px;">Clients favoris</div>
          <div v-for="(clientFavorite, index) in favorites.clients" style="cursor: pointer"
            @click="goToClientFavorite(clientFavorite)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ clientFavorite.name }}</div>
            </div>
          </div>

          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 8px;">Dossiers favoris</div>
          <div v-for="(userProjectFavorite, index) in favorites.userProjects" style="cursor: pointer"
            @click="goToUserProjectFavorite(userProjectFavorite)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ userProjectFavorite.name }}</div>
            </div>
          </div>
        </div>
      </KeepAlive>

      <KeepAlive>
        <SearchResult />
      </KeepAlive>
    </div>

    <div v-if="step == 11">
      <div class="item-header" style="padding: 0px 8px">Client</div>
      <div class="row items-center no-wrap" style="padding: 0px 8px">
        <div class="col-auto">
          {{ client.name }}
        </div>
        <q-space></q-space>
        <q-btn dense class="col-auto" @click="changeMatter" icon="o_edit"></q-btn>
      </div>
      <q-separator></q-separator>
      <div style="margin-top: 8px; padding: 8px; font-weight: 500; color: #4068c8">
        Sélectionnez le dossier de destination
      </div>
      <KeepAlive>
        <ClientProjects />
      </KeepAlive>
    </div>

    <div v-if="step == 2">
      <q-scroll-area style="width: 100%; height: calc(100vh - 50px - 28px)">
        <div style="padding: 0 8px">
          <div class="item-header">Client</div>
          <div class="row items-center no-wrap" style="">
            <div class="col-auto">
              {{ client.name }}
            </div>
            <q-space></q-space>
            <q-btn dense class="col-auto" @click="changeMatter" icon="o_edit"></q-btn>
          </div>
        </div>

        <div class="row-auto" style="padding: 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
          <div class="item-header">Dossier</div>
          <div class="row items-center no-wrap">
            <div class="col-auto">
              {{ project.name }}
            </div>
            <q-space></q-space>
            <q-btn dense class="col-auto" @click="changeProject" icon="o_edit"></q-btn>
          </div>
        </div>
        <q-separator></q-separator>

        <div style="padding: 8px; font-weight: 500; color: #4068c8">
          Sélectionnez les documents à ajouter en pièces jointes
        </div>
        <Documents></Documents>
      </q-scroll-area>

      <!-- <q-footer style=" margin-bottom: 50px;">
                <q-btn style="width: 100%;" @click="sendAndImportMail" label="Envoyer et importer" color="primary"
                    :loading="importButtonLoading"></q-btn>
                </q-footer> -->
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
h1 {
  margin-top: 0 8px 8px 8px;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1rem;
}

.item-header {
  color: grey;
  font-size: 12px;
}

.page {}

h5 {
  margin-bottom: 10px;
}

h4 {
  font-size: 28px;
  margin-left: 10px;
  margin-right: 10px;
}

h5 {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-input {
  margin-left: 10px;
  margin-right: 10px;
}

.page {
  padding: 0px;
}
</style>
