<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Page } from '@/models/survey/Page'

interface Props {
  pages: Page[] | undefined
}
const props = defineProps<Props>()

const route = useRoute()
const progress = ref(0)
const progressLabel = computed(() => (progress.value * 100).toFixed(0) + '%')
const progressLabelPosition = ref('50%')

progress.value = +route.query.page / (props.pages.length + 1)
progressLabelPosition.value = `${(1 - progress.value) * 100}%`
watch(route, (newProgress) => {
  progress.value = +route.query.page / (props.pages.length + 1)
})

watch(progress, (newProgress) => {
  progressLabelPosition.value = `${(1 - newProgress) * 100}%`
})
</script>
<template>
  <q-linear-progress stripe size="20px" rounded color="primary" :value="progress">
    <div class="progress-bar-counter absolute-full flex flex-center text-bold">
      {{ progressLabel }}
    </div>
  </q-linear-progress>
</template>
<style lang="scss" scoped>
.progress-bar-counter {
  color: white;
  right: v-bind(progressLabelPosition);
  transition: 2s;
  font-size: 10px;
}
</style>
