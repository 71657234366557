import { Serializable } from './Serializable'
import { ElementUtils } from './ElementUtils'

export class Element implements Serializable<Element> {
  id: string
  type: string
  elements: Element[]

  constructor() {
    this.id = ''
    this.type = ''
    this.elements = new Array()
  }

  deserialize(input: Object): Element {
    this.id = input.id
    this.type = input.type

    this.elements = new Array()
    input.elements.forEach((element) => {
      const elementObject = ElementUtils.deserializeElement(element)
      this.elements.push(elementObject)
    })

    return this
  }
}
