<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { AlternativeBlock } from '@/models/v2/document/elements/control/block/AlternativeBlock'

interface Props {
  alternative: AlternativeBlock
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const alternativeData: Ref<AlternativeBlock> = ref(props.alternative)
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Alternative" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="alternativeData.alternative.label" label="Nom de l'alternative"> </BaseInput>
      </template>
      <template #actions>
        <q-btn color="primary" label="Modifier" v-close-popup></q-btn>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
