<script async setup lang="ts">
import { ref, Ref, watch, inject, onMounted } from 'vue'
import { QTableProps, Dialog, Notify, useQuasar } from 'quasar'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import { isMobile } from '@/utils/detectMobile'
import AddSubProjectTreeDialog from '@/components/projects/dialog/project_parameters/AddSubProjectsTreeDialog.vue'
import SelectOrCreateClientAndProjectDialog from '@/components/projects/management/SelectOrCreateClientAndProjectDialog.vue'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const userApi: UserApi = useApi()
const router = useRouter()
const $q = useQuasar()

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
    // style: 'max-width: 100px; overflow:hidden; text-overflow: ellipsis',
  },
  {
    name: 'project',
    label: 'Dossier',
    field: 'projectName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'client',
    label: 'Client',
    field: 'clientName',
    sortable: true,
    align: 'left',
  },
]

const rows = ref([])
const isLoading = ref(true)

onMounted(async () => {
  isLoading.value = false
})

// Get all user subprojects
const subprojects = await userApi.getUserSubProjects()
rows.value = subprojects.results

function onAddClick(): void {
  // Open subproject selection dialog
  const dialog = Dialog.create({
    component: AddSubProjectTreeDialog,
    componentProps: {},
  })
    .onOk((subProjectTemplateIds) => {
      // Open client and project selection dialog
      console.log('subProjectTemplateIds', subProjectTemplateIds)
      const dialog2 = Dialog.create({
        component: SelectOrCreateClientAndProjectDialog,
      })
        .onOk(async ({ projectId, clientId }) => {
          $q.loading.show({
            message: "L'opération est en cours de création.\nMerci de patienter quelques instants...",
            boxClass: 'bg-grey-2 text-grey-9',
            spinnerColor: 'primary',
          })
          // Create subproject
          store.state.user.clientId = clientId
          const subProject = await userApi.createSubProjects({
            userProjectId: projectId,
            projectTemplateIds: subProjectTemplateIds.split(','),
          })
          console.log("Created subproject", subProject.id)

          if (store.state.user.portal == 'CLIENT') {
            router.push({
              name: 'projects/project',
              params: { projectId: projectId },
            })
          } else {
            router.push({
              name: 'clients/client/projects/project',
              params: {
                clientId: clientId,
                projectId: projectId,
              },
            })
          }
          $q.loading.hide()
          // dialog2.hide()
        })
        .onCancel(() => {
          dialog2.hide()
        })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function rowClicked(event: any, row: any): Promise<void> {
  store.state.user.clientId = row.clientId
  const client = await userApi.getClient(row.clientId)
  store.state.currentClient = client
  if (store.state.user?.portal == 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: { projectId: row.projectId },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: row.clientId,
        projectId: row.projectId,
      },
    })
  }
}

</script>

<template>
  <BaseTable v-if="!isMobile()" selection="none" :columns="columns" :rows="rows" add-button-label="Créer une opération"
    :enable-add="true" @add-item-click="onAddClick" :enable-actions="false" @on-row-click="rowClicked" :is-loading="isLoading">
  </BaseTable>

  <!-- Mobile
  <BaseTable v-if="isMobile()" grid flat dense :enableActions="false" :enableAdd="true"
    add-button-label="Créer une opération" :columns="columns" :rows="rows" class="table-mobile">
    <template v-slot:item="item">
      <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => rowClicked($event, item.item.row)">
        {{ item.item.row.name }}
        <q-separator />
      </div>
    </template>
  </BaseTable> -->
</template>

<style lang="scss" scoped>
.table-mobile {
  margin-top: 16px;
}
</style>
