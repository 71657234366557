<script setup lang="ts">
import { useStore } from '@/store/store'
import SubscriptionsModulesTable from './SubscriptionsModulesTable.vue'

const store = useStore()
store.commit('setPageTitle', 'Gestion des modules des abonnements')
</script>

<template>
    <q-page padding class="page">
        <h5 style="margin-top: 20px">Modules des abonnements</h5>

        <div style="margin-left: 24px; margin-top: 24px;">
            <SubscriptionsModulesTable></SubscriptionsModulesTable>
        </div>

    </q-page>
</template>
