import Visio from '@/pages/common/visio/Visio.vue'

const routes = [
  {
    name: 'visio',
    component: Visio,
    path: '/visio',
    meta: {
      requiresAuth: true,
      layout: 'public',
    },
  },
]

export { routes }
