<script lang="ts" setup>
import { inject, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import FileSaver from 'file-saver'
import { Notify, Dialog } from 'quasar'
import RenameNodeDialog from './RenameNodeDialog.vue'
import DeleteNodeDialog from './DeleteNodeDialog.vue'
import UnlockNodeDialog from './UnlockNodeDialog.vue'
import NodeLockedDialog from './NodeLockedDialog.vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'

interface Props {
    node: any
}
const props = withDefaults(defineProps<Props>(), {})

const nodes = inject("nodes")
const fileDropZoneRef = ref(null)
const clonedItem = inject("clonedItem")
const noCodeProjectCatalogResponse = inject("noCodeProjectCatalogResponse")
const noCodeProjectCatalog = inject("noCodeProjectCatalog")
const unsavedChanges = inject("unsavedChanges")

function getNode(nodes, nodeId) {
    for (let i = 0; i < nodes.length; ++i) {
        const currentNode = nodes[i]
        if (currentNode.id === nodeId) {
            return currentNode
        }
        if (!currentNode.children) {
            continue
        }
        const childNode = getNode(currentNode.children, nodeId)
        if (childNode !== undefined) {
            return childNode
        }
    }
    return undefined
}

function startDrag(evt, item) {
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('itemId', item.id)
    console.log('dragging', item.id)
}

async function onDrop(evt) {
    console.log("onDrop", evt)
    fileDropZoneRef.value.classList.remove('file-drop-zone-hover')
    const itemId = evt.dataTransfer.getData('itemId')

    if (itemId) {
        // Drag and drop from tree
        const item = getNode(nodes.value, itemId)
        console.log("item->node", item, props.node)

        if (item.id === props.node.id) {
            // Drop on same node
            console.log("drop on same node")
            return
        }

        if (item.parent.id === props.node.parent.id) {
            // Drop on same node
            console.log("drop on same parent node")
            // return
        }

        item.parent.children = item.parent.children.filter((child) => {
            return child.id !== item.id
        })

        item.parent = props.node.parent

        props.node.parent.children.unshift(item)

        unsavedChanges.value = true
    } else {
        // Drop from unclassified list
        console.log("clonedItem", clonedItem.value)
        clonedItem.value = JSON.parse(JSON.stringify(clonedItem.value))
        clonedItem.value.label = clonedItem.value.name
        clonedItem.value.type = 'project'
        clonedItem.value.legacyId = clonedItem.value.id
        clonedItem.value.id = clonedItem.value.id.toString()
        props.node.parent.children.unshift(clonedItem.value)
        parseNodes([clonedItem.value], props.node)
        updateNonClassifiedNoCodeProjects()

        unsavedChanges.value = true
    }
}

function onDragEnter(event) {
    fileDropZoneRef.value.classList.add('file-drop-zone-hover')

    // Needed for onDrop to be fired
    event.preventDefault()
}

function onDragLeave(event) {
    fileDropZoneRef.value.classList.remove('file-drop-zone-hover')
}

function addSearchText(node) {
    let currentNode = node

    if (node.searchText == undefined) {
        node.searchText = ''
    }
    node.searchText = node.searchText + ' ' + node.label

    while (currentNode != undefined) {
        node.searchText = node.searchText + ' ' + currentNode.label
        currentNode = currentNode.parent
    }
}

async function updateNonClassifiedNoCodeProjects() {
    noCodeProjectCatalog.value = noCodeProjectCatalogResponse.response.results
    noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
        return !(project.name.startsWith('Modèle sec') || project.name.startsWith('Test'))
    })

    noCodeProjectCatalog.value = noCodeProjectCatalog.value.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    filterNonClassifiedNoCodeProjects()
}

function filterNonClassifiedNoCodeProjects() {
    const projectIds = getProjectIds(nodes.value[0])
    console.log('projectIds', projectIds)
    noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
        return !projectIds.includes(project.id.toString())
    })
}

function getProjectIds(node) {
    const ids = []
    if (node.children) {
        for (const child of node.children) {
            ids.push(child.id)
            ids.push(...getProjectIds(child))
        }
    }
    return ids
}

function parseNodes(nodes, parent) {
    if (nodes == undefined) {
        return
    }
    nodes.forEach((node) => {
        node.parent = parent
        if (node.type === 'root') {
            node.tickable = false
            node.noTick = true
        } else if (node.type === 'genericModels') {
            node.tickable = false
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 700;'
        } else if (node.type === 'subject') {
            node.tickable = false
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 500;'
        } else if (node.type === 'category') {
            node.tickable = false
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 500;'
        } else if (node.type === 'subCategory') {
            node.tickable = false
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 500;' // font-style: italic;"
        } else if ((node.type === 'project') || (node.type === 'personalProject')) {
            node.tickable = true
            node.noTick = false
            node.icon = 'o_source'
            node.iconColor = 'primary'
            node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #4068c8;'
            node.textStyle = 'color: #4068c8;'
        } else if (node.type === 'basicModels') {
            node.tickable = true
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 500;'
        } else if (node.type === 'basicModel') {
            node.tickable = true
            node.noTick = false
            node.icon = 'o_description'
            node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #22633f;'
            node.textStyle = 'color: #22633f;'
        }
        addSearchText(node)
        parseNodes(node.children, node)
    })
}


function createUUID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
}

function editNode(node) {
    const dialog = Dialog.create({
        component: RenameNodeDialog,
        componentProps: {
            node: node,
        },
    })
        .onOk((newName) => {
            node.label = newName
            console.log("Edited node", node)
            unsavedChanges.value = true
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

function addSection(node) {
    const newNode = {
        id: createUUID(),
        label: 'Nouvelle section',
        type: 'subCategory',
        children: [],
    }

    node.children.unshift(newNode)

    parseNodes(node.children, node)

    unsavedChanges.value = true
}

function removeNode(node) {
    const dialog = Dialog.create({
        title: 'Supprimer',
        message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
        cancel: true,
        persistent: true,
    })
        .onOk(() => {
            node.parent.children = node.parent.children.filter((child) => {
                return child !== node
            })
            updateNonClassifiedNoCodeProjects()
            unsavedChanges.value = true
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

</script>

<template>
    <div style="padding: 0px; overflow: hidden"
        class="row no-wrap justify-start items-start content-start full-width items-center cursor-pointer" draggable="true"
        @dragstart="startDrag($event, node)" @drop="onDrop($event)" @dragover.prevent @dragenter="onDragEnter($event)"
        @dragleave="onDragLeave($event)" ref="fileDropZoneRef">
        <div v-if="node.type === 'root'">&nbsp;</div>
        <div v-if="node.type !== 'root'"
            class="row  no-wrap justify-start items-start content-start full-width items-center node">
            <div class="col-auto">
                <q-icon v-if="node.icon" :name="node.icon" :style="node.iconStyle"></q-icon>
                <span :style="node.textStyle" class="node-label">{{ node.label }}</span>
                <span>(id: {{ node.id }})</span>
                <q-btn v-if="node.type != 'genericModels'" dense icon="o_edit" size="6px" @click.stop="editNode(node)"
                    text-color="grey" class="node-button">
                    <q-tooltip>Renommer</q-tooltip>
                </q-btn>
                <q-btn v-if="node.type != 'project'" icon="o_add" size="6px" dense @click.stop="addSection(node)"
                    text-color="grey" class="node-button">
                    <q-tooltip>Ajouter une section</q-tooltip>
                </q-btn>
                <q-btn v-if="node.type != 'genericModels'" icon="o_clear" size="6px" dense @click.stop="removeNode(node)"
                    text-color="grey" class="node-button">
                    <q-tooltip>Supprimer</q-tooltip>
                </q-btn>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.file-name {
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // border: 1px solid;
    // text-wrap: nowrap;
}

.file-date {
    color: #2c2c2c;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.drop-zone {
    // margin-bottom: 10px;
    // padding: 10px;
}

.drag-el {
    // cursor: grab;
    // margin-bottom: 10px;
    // padding: 5px;
}

.editing-user {
    font-style: italic;
    font-size: 13px;
    color: #431010;
    margin-top: -4px;
}

.previewInNewTabButton {
    color: #cccccc;
}

.previewInNewTabButton:hover {
    color: #777777;
    background-color: #bbbbbb;
}

.file-drop-zone-hover {
    background-color: lightgrey;
}

.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}

.node-label {
    margin-right: 8px;
}

.ul {
    list-style-type: disc;
}

:deep(.q-checkbox__inner) {
    color: rgb(158, 158, 158);

    margin-right: 5px;
    font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
    height: 0px;
    width: 0px;
    overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
    border: 1.3px solid currentColor;
}

.search-input {
    width: 500px;
    margin-left: 0px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.subtitle {
    font-size: 18px;
}

.category-header {
    background-color: #227a68;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // cursor: pointer;
}

.category-subheader {
    background-color: #2a5d97;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    // cursor: pointer;
}

.project-category {
    color: rgb(61, 61, 63);
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
}

.available-project {
    cursor: pointer;
    font-weight: 500;
}

.page-content-header {
    // margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
