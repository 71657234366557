<script setup lang="ts">
import { ref, computed, watch, inject } from 'vue'
import { ConditionBlock } from '@/models/v2/document/elements/control/block/ConditionBlock'
import BlockElementView from '../../../BlockElementView.vue'
import draggable from 'vuedraggable'

interface Props {
  data: ConditionBlock
}

const props = withDefaults(defineProps<Props>(), {})

const variables = inject('variables')

const isHovering = ref(true)

const conditionValue = ref(undefined)

const conditionVariable = getVariableById(props.data.conditions[0].variableId)

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

watch(conditionVariable, () => {
  if (conditionVariable.value == props.data.conditions[0].variableValue) {
    conditionValue.value = true
  } else if (conditionVariable.value == '') {
    conditionValue.value = undefined
  } else {
    conditionValue.value = false
  }
})

const cardClass = computed(() => {
  let classes = 'full-width'
  if (conditionValue.value === undefined) {
    classes += ' block top-left-corner-angle'
  }

  return classes
})

const cardSectionClass = computed(() => {
  let classes = 'q-pa-sm q-pl-md'
  if (conditionValue.value === true) {
    classes += ' no-tab'
  }
  return classes
})
</script>

<template>
  <div class="column items-start">
    <q-badge class="badge-corner" align="top" v-if="conditionValue == undefined">
      <span v-if="data.label !== undefined && data.label !== ''">
        {{ data.label }}
      </span>
      <span v-if="data.label === undefined || data.label === ''"> Condition </span>
    </q-badge>
    <q-card
      flat
      v-bind:bordered="conditionValue === undefined"
      :class="cardClass"
      v-if="conditionValue == undefined || conditionValue == true"
    >
      <q-card-section :class="cardSectionClass">
        <div class="block-view-content">
          <div class="condition-block-view">
            <draggable
              class="drag-area q-gutter-sm"
              tag="div"
              :list="data.elements"
              :group="{ name: 'g1' }"
              item-key="id"
            >
              <template #item="{ element, index }">
                <div>
                  <BlockElementView :element="element"></BlockElementView>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss" scoped>
.no-tab {
  padding: 0px;
}

.element-view {
  padding-left: 5px;
  padding-right: 5px;
}

.top-left-corner-angle {
  border-top-left-radius: 0;
}

.element-hover {
  border-color: #e2cb9d;
}

.badge-corner {
  background-color: #e2cb9d;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}

.block-content {
  border-left-color: #b0e2f2;
  border-left-width: 5px;
}

.drag-area {
  min-height: 30px;
}

.element-view {
  padding-left: 5px;
  padding-right: 5px;
}

.top-left-corner-angle {
  border-top-left-radius: 0;
}

.element-hover {
  border-color: #e2cb9d;
}

.badge-corner {
  background-color: #e2cb9d;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}

.block-content {
  border-left-color: #b0e2f2;
  border-left-width: 5px;
}
</style>
