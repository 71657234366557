import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let physicalPerson: ObjectType = {
  name: 'Personne physique',
  fields: [
    {
      label: 'Civilité',
      type: FieldType.Select,
      selectOptions: ['Monsieur', 'Madame'],
    },
    {
      label: 'Prénom',
      type: FieldType.String,
    },
    {
      label: 'Nom',
      type: FieldType.String,
    },
    {
      label: 'Email',
      type: FieldType.String,
    },
    {
      label: 'Date de naissance',
      type: FieldType.Date,
    },
    {
      label: 'Ville de naissance',
      type: FieldType.String,
    },
    {
      label: 'Nationalité',
      type: FieldType.String,
    },
    {
      label: 'Adresse',
      type: FieldType.Object,
      objectTypeName: 'Adresse',
    },
    {
      label: 'Profession',
      type: FieldType.String,
    },
    {
      label: 'Numéro de sécurité sociale',
      type: FieldType.String,
    },
  ],
  onChange: function (object: Node): void {
    let firstName = ''
    let lastName = ''

    if (object.fields[1].value != undefined && object.fields[1].value != '') {
      firstName = object.fields[1].value
    }
    if (object.fields[2].value != undefined && object.fields[2].value != '') {
      lastName = object.fields[2].value
    }

    if (firstName != '' && lastName != '') {
      object.label = firstName + ' ' + lastName
    }
    if (firstName != '' && lastName == '') {
      object.label = firstName
    }
    if (firstName == '' && lastName != '') {
      object.label = lastName
    }
  },
}

export { physicalPerson }
