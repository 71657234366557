<script async setup lang="ts">
import { ref, onMounted, nextTick, provide } from 'vue'
import { Dialog } from 'quasar'
import { ParagraphBlock } from '@/models/v2/document/elements/content/block/ParagraphBlock'
import InlineElementView from '../../../InlineElementView.vue'
import AddVariableDialog from '../../../dialogs/AddVariableDialog.vue'
// import Cell from './table/Cell.vue'

interface Props {
  data: ParagraphBlock
}

const props = withDefaults(defineProps<Props>(), {})

const isMounted = ref(false)
const hover = ref(false)
const paragraphContentId = props.data.id + '_Paragraph_Content'
let range
let selection

onMounted(() => {
  isMounted.value = true
})

function getInlineElementContainerSelector(element) {
  return "[inline-element-id='" + element.id + "']"
}

function getCaretIndex(element) {
  let position = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()
      // preCaretRange.selectNode(element);
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      position = preCaretRange.toString().length
    }
  }
  return position
}

function node_walk(node, func) {
  var result = func(node)
  for (node = node.firstChild; result !== false && node; node = node.nextSibling) result = node_walk(node, func)
  return result
}

// getCaretPosition: return [start, end] as offsets to elem.textContent that
//   correspond to the selected portion of text
//   (if start == end, caret is at given position and no text is selected)
function getCaretPosition(elem) {
  var sel = window.getSelection()
  var cum_length = [0, 0]

  if (sel.anchorNode == elem) cum_length = [sel.anchorOffset, sel.extentOffset]
  else {
    var nodes_to_find = [sel.anchorNode, sel.extentNode]
    if (!elem.contains(sel.anchorNode) || !elem.contains(sel.extentNode)) return undefined
    else {
      var found = [0, 0]
      var i
      node_walk(elem, function (node) {
        for (i = 0; i < 2; i++) {
          if (node == nodes_to_find[i]) {
            found[i] = true
            if (found[i == 0 ? 1 : 0]) return false // all done
          }
        }

        if (node.textContent && !node.firstChild) {
          for (i = 0; i < 2; i++) {
            if (!found[i]) cum_length[i] += node.textContent.length
          }
        }
      })
      cum_length[0] += sel.anchorOffset
      cum_length[1] += sel.extentOffset
    }
  }
  if (cum_length[0] <= cum_length[1]) return cum_length
  return [cum_length[1], cum_length[0]]
}

function getCaretCoordinates() {
  let x = 0,
    y = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0).cloneRange()
      range.collapse(true)
      const rect = range.getClientRects()[0]
      if (rect) {
        x = rect.left
        y = rect.top
      }
    }
  }
  return { x, y }
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

function insertVariable(event) {
  const variableUuid = createUUID()

  const newVariableContainer = document.createElement('span')
  newVariableContainer.setAttribute('inline-element-id', 'Variable_' + variableUuid)

  range.deleteContents()
  range.insertNode(newVariableContainer)
  //cursor at the last with this
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)

  nextTick(() => {
    props.data.elements.push({
      id: 'Variable_' + variableUuid,
      type: 'InlineVariable',
      variableId: event.id,
      elements: [],
    })
  })
}

function openAddVariableDialog() {
  const dialog = Dialog.create({
    component: AddVariableDialog,
    componentProps: {},
  })
    .onOk((data) => {
      insertVariable(data)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onShowMenu(event) {
  selection = window.getSelection()
  range = selection.getRangeAt(0)
}

function addRow() {
  const length = props.data.content[0].length
  const newRow = []
  for (let i = 0; i < length; i++) {
    newRow.push('')
  }
  props.data.content.push(newRow)
}

function addColumn() {
  props.data.content = props.data.content.map((row) => {
    row.push('')
    return row
  })
}

function updateData(val, index, i) {
  props.data.content[index][i] = val
}
</script>

<template>
  <div class="column items-start" @mouseover="hover = true" @mouseleave="hover = false">
    <q-card flat bordered class="full-width block-content">
      <q-card-sections horizontal class="row items-center justify-start no-wrap q-pl-none q-pt-sm q-pb-sm q-pr-sm">
        <q-icon name="drag_handle" class="handle" :color="hover ? 'black' : 'transparent'"> </q-icon>

        <q-card-section class="q-pa-none">
          <div class="flex">
            <table>
              <tr v-for="(row, index) in data.content">
                <Cell v-for="(element, i) in row" :data="element" @update:data="(val) => updateData(val, index, i)" />
              </tr>
            </table>
            <div class="flex q-pl-sm">
              <q-btn icon="add" flat size="sm" round style="margin: auto" @click="addColumn" />
            </div>
          </div>
          <div class="flex justify-center q-pr-lg">
            <q-btn icon="add" flat size="sm" round class="q-ma-sm" @click="addRow" />
          </div>
          <!-- <span
                :id="paragraphContentId"
                class="q-ma-none full-width paragraph-span"
                contenteditable="true"
                v-html="data.content"
              >
              </span> -->
          <q-menu touch-position context-menu @show="onShowMenu">
            <q-list dense style="min-width: 100px">
              <q-item clickable v-close-popup>
                <q-item-section @click="openAddVariableDialog"> Ajouter une variable </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
          <span v-for="element in data.elements">
            <Teleport :to="getInlineElementContainerSelector(element)" v-if="isMounted">
              <InlineElementView :element="element"></InlineElementView>
            </Teleport>
          </span>
        </q-card-section>
      </q-card-sections>
    </q-card>
  </div>
</template>

<style lang="scss" scoped>
.paragraph-block-view {
  display: block;
  border: 1px solid;
  margin-bottom: 5px;
}

.paragraph-span {
  word-break: break-word;
}

.paragraph-block-content {
  padding-left: 5px;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}

td {
  padding: 5px;
}

tr {
  height: 32px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
