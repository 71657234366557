<script lang="ts" setup>
import { ref } from 'vue'
import { format } from 'date-fns'

import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { FieldType } from '@/models/database/FieldType'
import { ObjectType } from '@/models/database/ObjectType'

interface Props {
  database: Database
  node: Node
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:modelValue', 'update:field'])

function findObjectType(objectTypeName): ObjectType {
  let result = null
  props.database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })
  return result
}

function onFieldChange(e) {
  if (props.node?.type === NodeType.Object) {
    let objectType = findObjectType(props.node?.objectType)
    objectType.onChange(props.node)
    // props.node.label = props.node?.fields[0].value + " " + props.node?.fields[1].value;
  }

  // emit('update:node', props.node);
}

const model = ref()
const proxyDate = ref(format(new Date(), 'dd/MM/yyyy'))

if (props.field.value && props.field.value.includes('-')) {
  model.value = format(new Date(props.field.value), 'dd/MM/yyyy')
  proxyDate.value = format(new Date(props.field.value), 'dd/MM/yyyy')
} else {
  model.value = props.field.value
  proxyDate.value = props.field.value
}

function save() {
  model.value = proxyDate.value
  props.field.value = proxyDate.value
  emit('update:modelValue', model.value)
  emit('update:field', props.node, props.field)
}
</script>

<template>
  <!-- <q-input v-model="field.value" :label="showLabel ? field.label : undefined" @change="onFieldChange" /> -->

  <q-input v-model="model" class="q-pb-none" mask="##/##/####" v-bind="$attrs" @update:model-value="onFieldChange"
    :label="showLabel ? field.label : undefined">
    <template v-slot:prepend>
      <q-icon name="event" class="cursor-pointer"> </q-icon>
    </template>
    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
      <q-date v-model="proxyDate" mask="DD/MM/YYYY">
        <div class="row items-center justify-end">
          <q-btn v-close-popup label="Annuler" color="primary" flat />
          <q-btn v-close-popup label="OK" color="primary" @click="save" flat />
        </div>
      </q-date>
    </q-popup-proxy>
  </q-input>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
