<script setup lang="ts">
import { inject } from 'vue'
import { InlineVariable } from '@/models/v2/document/elements/content/inline/InlineVariable'

interface Props {
  data: InlineVariable
}

const props = withDefaults(defineProps<Props>(), {})

const variables = inject('variables')

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

const variable = getVariableById(props.data.variableId)
</script>

<template>
  <span class="inline-variable bordered" contenteditable="false">{{ variable.name }}</span>
</template>

<style lang="scss" scoped>
.inline-variable {
  border: 1px solid #0000001f;
  border-radius: 5px;
  padding: 1px 3px;
  border-bottom-color: #b0e2f2;
  border-bottom-width: 3px;
}
</style>
