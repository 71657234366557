<script setup lang="ts">
import { ref, Ref, watch, computed, inject, provide } from 'vue'
import { Dialog, Notify } from 'quasar'

import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

import { Database } from '@/models/database/Database'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { ObjectType } from '@/models/database/ObjectType'

import DeleteNodeDialog from './DeleteNodeDialog.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'

import TextField from "./rendered_fields/TextField.vue"
import ChoiceField from "./rendered_fields/ChoiceField.vue"
import MultipleChoiceField from "./rendered_fields/MultipleChoiceField.vue"
import NumberField from "./rendered_fields/NumberField.vue"
import DateField from "./rendered_fields/DateField.vue"
import WysiwygTextField from "./rendered_fields/WysiwygTextField.vue"
import ArrayField from "./rendered_fields/ArrayField.vue"
import FunctionField from "./rendered_fields/FunctionField.vue"
import AddressField from './rendered_fields/AddressField.vue'
import ColumnsField from './rendered_fields/ColumnsField.vue'
import FileField from './rendered_fields/FileField.vue'

interface Props {
  database: Database
  environmentDatabase: Array<Database>
  node: any
}

const props = defineProps<Props>()

const userApi: UserApi = useApi()
const authenticatedUser: AuthenticatedUser = useAuthenticatedUser()

const emit = defineEmits(['update:node', 'remove:node'])

const environmentDatabase = inject('environmentDatabase')
const inEnvironmentDatabase = inject('inEnvironmentDatabase')
const databaseEntities = inject("databaseEntities")

const nodesHashMap = inject('nodesHashMap')

watch(props, () => {
  console.log("database item edit form - new node selected", props.node)
  // updateNode();
  updateFieldLink()
})

const linkedNode = ref()
const linkedNodeFields = ref([])

function updateFieldLink() {
  if (props.node?.type == NodeType.FieldLink) {
    linkedNode.value = findObjectById(props.database.nodes, props.node.objectLinkId)

    linkedNodeFields.value = []
    props.node.fieldLink.fields.forEach((linkedField) => {
      let field = findObjectField(linkedNode.value, linkedField)
      linkedNodeFields.value.push(field)
    })
  }
}

updateFieldLink()

function findObjectById(nodes, objectId) {
  let result = undefined

  nodes.forEach((node) => {
    if (node.id === objectId) {
      result = node
    }
    let childResult = findObjectById(node.children, objectId)
    if (childResult != undefined) {
      result = childResult
    }
    // node.children?.forEach((child) => {

    // })
  })
  return result
}

function findObjectField(object, fieldName) {
  let result = undefined

  object.fields.forEach((objectField) => {
    if (objectField.label == fieldName) {
      result = objectField
    }
  })

  return result
}

function findObjectType(objectTypeId): ObjectType {
  let result = null
  databaseEntities.forEach((objectType) => {
    if (objectType.id === objectTypeId) {
      result = objectType
    }
  })

  return result
}

const rows = ref([])

function removeNode(node) {
  props.database.nodes = props.database.nodes?.filter((n) => n.id != node.id)
  saveDatabase(props.database)

  emit('remove:node')
  // props.database.nodes.forEach((parent) => {
  //   removeNodeFromParent(parent, node);
  // });
}

function getClientForNode(node) {
  const clientDatabase = environmentDatabase.find((db) => db.clientId == node.clientId)

  return clientDatabase.clientName
}

async function saveDatabase(database, clientId) {
  console.log("saveDatabase", database, clientId)
  let saveResult = await userApi.updateDatabase(database, clientId)


  if (inEnvironmentDatabase) {
    // const clientDb = environmentDatabase.find((cdb) => cdb.clientId == authenticatedUser.clientId)
    // clientDb.databaseJson = props.database
  } else {
    const clientDb = environmentDatabase.find((cdb) => cdb.clientId == authenticatedUser.clientId)
    if (clientDb != undefined) {
      clientDb.databaseJson = props.database
    }
  }
}

function showDeleteNodeDialog(node) {
  const dialog = Dialog.create({
    component: DeleteNodeDialog,
    componentProps: {},
  })
    .onOk(() => {
      removeNode(node)
      dialog.hide()
      Notify.create({
        message: 'Elément supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onFieldChanged(node, field) {
  // emit("update:node");
  console.log("onFieldChanged", node, field)
  updateLabel(node)
  saveDatabase(props.database, undefined)
}

function updateLabel(node) {
  console.log("updateLabel", node)
  let objectType = findObjectType(node.objectType)
  if (objectType == undefined) {
    return
  }
  if (objectType.name === 'Personne physique') {
    let firstName = ''
    let lastName = ''

    if (node.fields[1].value != undefined && node.fields[1].value != '') {
      firstName = node.fields[1].value
    }
    if (node.fields[2].value != undefined && node.fields[2].value != '') {
      lastName = node.fields[2].value
    }

    if (firstName != '' && lastName != '') {
      node.label = firstName + ' ' + lastName
    }
    if (firstName != '' && lastName == '') {
      node.label = firstName
    }
    if (firstName == '' && lastName != '') {
      node.label = lastName
    }
  } else {
    let name = ''
    if (node.fields[0].value != undefined && node.fields[0].value) {
      name = node.fields[0].value
    }
    if (name != '') {
      node.label = name
    }
  }
}
</script>

<template>
  <div v-if="node === null" style="padding: 30px">
    <div class="text-h6">Veuillez sélectionner un élément pour l'éditer.</div>
  </div>
  <div style="height: calc(100% - 160px); margin: 16px; ">
    <q-scroll-area v-if="node != undefined" style="width: 100%; height: calc(100vh - 120px); padding-right: 16px;">
      <div v-if="node.type == NodeType.Object" style="padding: 16px">
        <q-card flat bordered>
          <q-card-section>
            <div class="row items-center no-wrap">
              <div class="col">
                <div class="text-h6">
                  {{ node.label }}
                </div>
              </div>

              <q-btn color="primary" label="Actions">
                <q-menu auto-close>
                  <q-card class="card">
                    <q-card-actions vertical align="left">
                      <!-- <ItemActionMenuItem v-if="isMoralPerson()" label="Mettre à jour depuis API Entreprise"
                        @click="showApiEntrepriseDialog()" style="text-align:left;" /> -->
                      <!-- <ItemActionMenuItem label="Copier dans un autre client" @click="showCopyToClientDialog(node)"
                        style="text-align:left;" /> -->
                      <ItemActionMenuItem style="text-align:left;" label="Supprimer"
                        @click="showDeleteNodeDialog(node)" />
                    </q-card-actions>
                  </q-card>
                </q-menu>
              </q-btn>
            </div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <!-- <div v-for="field in node?.fields">
              <StringField v-if="field.fieldModel.type === FieldType.String" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <FormattedTextField v-if="field.fieldModel.type === FieldType.FormattedText" :database="database"
                :node="node" :field="field" @update:field="onFieldChanged" />
              <FileField v-if="field.fieldModel.type === FieldType.File" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <DateField v-if="field.fieldModel.type === FieldType.Date" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <SelectField v-if="field.fieldModel.type === FieldType.Select" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <ObjectLinkField v-if="field.fieldModel.type === FieldType.ObjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectField v-if="field.fieldModel.type === FieldType.Object" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectListField v-if="field.fieldModel.type === FieldType.ObjectList" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ProjectLinkField v-if="field.fieldModel.type === FieldType.ProjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
            </div> -->
          </q-card-section>
        </q-card>
      </div>
      <div v-if="node.type != NodeType.Object && node.fields != undefined" v-for="field in node.fields">
        <TextField v-if="field.type === 'text'" :field="field" @update:field="onFieldChanged" />
        <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" @update:field="onFieldChanged" />
        <NumberField v-if="field.type === 'number'" :field="field" @update:field="onFieldChanged" />
        <DateField v-if="field.type === 'date'" :field="field" @update:field="onFieldChanged" />
        <ChoiceField v-if="field.type === 'choice'" :field="field" @update:field="onFieldChanged" />
        <MultipleChoiceField v-if="field.type === 'multi_choice'" :field="field" @update:field="onFieldChanged" />
        <FileField v-if="field.type === 'file'" :field="field" @update:field="onFieldChanged" />
        <ArrayField v-if="field.type === 'array'" :field="field" @update:field="onFieldChanged" />
        <ColumnsField v-if="field.type === 'columns'" :field="field" @update:field="onFieldChanged" />
        <AddressField v-if="field.type === 'address'" :field="field" @update:field="onFieldChanged" />
        <FunctionField v-if="field.type === 'function'" :field="field" @update:field="onFieldChanged" />
        <div style="height: 16px">&nbsp;</div>
      </div>
    </q-scroll-area>
  </div>
</template>

<style lang="scss" scoped>
:deep(.text-center) {
  text-align: left;
}
</style>
