<script lang="ts" setup>
import { ref, inject, watch, onMounted } from 'vue'

const dropzone = ref()
const dataTransferItems = inject('dataTransferItems')
const elementsToUpload = inject('elementsToUpload')
var holder

onMounted(() => {
  dropzone.value.addEventListener(
    'dragover',
    (event) => {
      // console.log("dragover preventDefault");
      event.preventDefault()
    },
    false,
  )

  dropzone.value.addEventListener(
    'dragenter',
    (event) => {
      // console.log("dragenter preventDefault");
      event.preventDefault()
    },
    false,
  )

  dropzone.value.addEventListener(
    'dragleave',
    (event) => {
      // console.log("dragleave preventDefault");
      event.preventDefault()
    },
    false,
  )

  dropzone.value.addEventListener(
    'drop',
    (event) => {
      event.preventDefault()
      let items = event.dataTransfer.items
      console.log('dataTransferItems', items)
      readFiles(items)
    },
    false,
  )

  // dropzone.value.ondrop = function (e) {
  //   this.className = '';
  //   e.preventDefault();
  //   readfiles(e.dataTransfer.files);
  // }

  holder = document.getElementById('dropzone')

  // holder.ondrop = function (e) {
  //   this.className = '';
  //   e.preventDefault();
  //   readFiles(e.dataTransfer.files);
  // }
})

function readFiles(items) {
  for (let i = 0; i < items.length; i++) {
    let item = items[i].webkitGetAsEntry()
    console.log('dataTransferItem', i, item)
    if (item != null) {
      console.log('found files')
      dataTransferItems.value.push(item)
      if (item) {
        scanFiles(item, undefined)
      }
    } else {
      console.log('other type. bypassing')
    }
  }
}

function scanFiles(item, parent) {
  if (item.isDirectory) {
    elementsToUpload.value.push({
      type: 'directory',
      item: item,
      parent: parent,
    })
  } else {
    elementsToUpload.value.push({
      type: 'file',
      item: item,
      parent: parent,
    })
  }
}

function scanInputSelectedFiles(e) {
  console.log(e)

  const files = e.target.files
  console.log(files)
  for (let i = 0; i < files.length; i++) {
    let file = files[i]
    console.log('parsing file', file)
    let fileEntry = {
      isFile: true,
      file: (resolve, reject) => {
        try {
          resolve(file);
        } catch (error) {
          reject(error);
        }
      }
    }
    dataTransferItems.value.push(fileEntry)
    elementsToUpload.value.push({
      type: 'file',
      item: file,
      parent: null,
    })
  }
}
</script>

<template>
  <div id="dropzone" ref="dropzone">
    <div id="boxtitle">Glissez les fichiers à importer ici</div>
    <div id="filesSelector">
      <input id="filesInput" type="file" multiple @change="scanInputSelectedFiles($event)" label="Ajouter des fichiers" />
    </div>
  </div>

  <div v-if="elementsToUpload.length != 0">
    <div style="font-weight: 700; margin-top: 10px">Fichiers à importer :</div>
    <q-scroll-area style="height: 200px; max-width: 100%" visible>
      <q-list bordered separator>
        <q-item v-for="elementToUpload in elementsToUpload">
          <q-item-section>
            <q-item-label>{{ elementToUpload.item.name }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>

<style lang="scss" scoped>
#dropzone {
  text-align: center;
  width: 95%;
  height: 200px;
  margin: 10px;
  padding: 10px;
  border: 2px dashed blue;
  border-radius: 16px;
}

#boxtitle {
  vertical-align: middle;
  text-align: center;
  color: black;
  font:
    bold 1.5em 'Arial',
    sans-serif;
  width: 100%;
  height: 50%;
  padding-top: 10px;
}

#filesSelector {
  height: 50%;
}
</style>
