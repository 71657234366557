<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

async function ok() {
    dialogRef.value?.hide()
    isLoading.value = false
    emit('ok')
}

function cancel() {
    isLoading.value = false
    emit('hide')
}
</script>
<template>
    <q-dialog ref="dialogRef">
        <BaseDialog title="Utilisateur existant" :is-dialog-loading="isLoading" @on-dialog-cancel="cancel()"
            @hide="onDialogHide()">
            <template #body>
                L'utilisateur existe déjà dans un autre client.<br />
                En continuant, il sera promu en tant qu'utilisateur multi-clients.<br />
                Voulez-vous continuer ?
            </template>
            <template #actions>
                <q-btn flat label="Oui" @click="ok()" color="primary" />
            </template>
        </BaseDialog>
    </q-dialog>
</template>
<style lang="scss" scoped></style>
