import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

import { Alternative } from './Alternative'

export class AlternativeBlockChoice extends Element implements Serializable<AlternativeBlockChoice> {
  label: string

  constructor() {
    super()

    this.label = ''
  }

  deserialize(input: Object): AlternativeBlockChoice {
    super.deserialize(input)

    this.label = input.label

    return this
  }
}
