<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'

interface Props {
  modelValue: string | number | undefined
  autoselect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  autoselect: false,
})

const emit = defineEmits(['update:modelValue'])

const input = ref(null)

onMounted(() => {
  if (props.autoselect) {
    input.value?.select()
  }
})

const value = computed({
  get: () => props.modelValue,
  set: (value: any | undefined) => {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <q-input ref="input" outlined v-bind="$attrs" v-model="value" />
</template>

<style lang="scss" scoped></style>
