import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'
import { Condition } from './Condition'

export class ConditionBlock extends Element implements Serializable<ConditionBlock> {
  label: string
  conditions: Array<Condition>

  constructor() {
    super()

    this.label = ''
    this.conditions = [new Condition()]
  }

  deserialize(input: any): ConditionBlock {
    super.deserialize(input)

    this.label = input.label
    this.conditions = []

    input.conditions.forEach((element) => {
      const condition = new Condition()
      condition.deserialize(element)
      this.conditions.push(condition)
    })

    return this
  }
}
