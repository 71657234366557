<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'
import EntityField from './EntityField.vue'

const unsavedChanges = inject("unsavedChanges")
const selectedPage = inject("selectedPage")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function getCardClass(type) {
    return "drag-cursor"
}

const functionOptions = [
    "Somme",
    "Nombre de lignes",
    "Min",
    "Max",
    "Formule",
]

const arrayOptions = [
    "Actions",
]

const columnOptions = [
    "Nombre",
]

if (props.element.formulaFields == undefined) {
    props.element.formulaFields = []
}

const formulaFields = ref(props.element.formulaFields)



function onClone() {
    console.log("onClone")

}

function onChange() {
    console.log("onChange")

}
</script>


<template>
    <q-select dense v-model="element.function" style="margin-top: 8px" :options="functionOptions">
        <template v-slot:before>
            <span style="font-size: 14px; color: black;">Fonction
                :</span>
        </template>
    </q-select>
    <q-select dense v-model="element.arrayField" style="margin-top: 8px" :options="arrayOptions">
        <template v-slot:before>
            <span style="font-size: 14px; color: black;">Tableau
                :</span>
        </template>
    </q-select>
    <q-select v-if="element.function == 'Somme' || element.function == 'Min' || element.function == 'Max'" dense
        v-model="element.columnField" style="margin-top: 8px" :options="columnOptions">
        <template v-slot:before>
            <span style="font-size: 14px; color: black;">Colonne
                :</span>
        </template>
    </q-select>
    <div v-if="element.function == 'Formule'">
        <span style="font-size: 14px; color: black;">Formule
            :</span>

        <!-- <div style="width: 100%; height: 150px; background-color: white; margin-left: 65px;">
                Aucun élément de formule pour l'instant<br />
                Veuillez glisser un élément de formule
            </div> -->

        <q-list dense class="" style="margin: 8px; margin-left: 65px;">
            <draggable :list="formulaFields" :group="{ name: 'g1', pull: 'clone', put: true }" ghost-class="ghost"
                item-key="id" element="q-gutter-sm" :clone="onClone" @change="onChange">
                <template #item="{ element, index }">
                    <EntityField :element="element"></EntityField>
                </template>
                <template #header>
                    <div v-if="formulaFields.length === 0" style="height: 150px;">
                        Aucun élément pour le moment<br />
                        Glissez un premier élément
                        <div>
                        </div>
                    </div>
                </template>
            </draggable>
        </q-list>

        <!-- <q-input dense outlined v-model="element.formula" v-if="element.function == 'Formule'" style="margin-top: 8px">
                <template v-slot:before>
                    <span style="font-size: 14px; color: black;">Formule
                        :</span>
                </template>
            </q-input> -->
    </div>
</template>