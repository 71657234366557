<script lang="ts" setup>
import { ref, provide, Ref } from 'vue'
import { useRouter } from 'vue-router'

import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

import BaseTable from '@/components/common/Table/BaseTable.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { format } from 'date-fns'
import { tsTypeAliasDeclaration } from '@babel/types'

interface Props {
  data_filter?: string
  clientId?: string
  disable_add?: boolean
  disable_actions?: boolean
  selection?: string
}

const props = withDefaults(defineProps<Props>(), {
  disable_add: false,
  disable_actions: false,
  show_client: false,
  show_project: true,
  selection: 'single',
})

const store = useStore()
const userApi: UserApi = useApi()
const router = useRouter()
const authenticatedUser = useAuthenticatedUser()

const columns = [
  {
    name: 'clientName',
    label: 'Client',
    field: 'clientName',
    sortable: true,
    align: 'left',
    format: (val, row) => row.client.name,
  },
  {
    name: 'projecName',
    label: 'Dossier',
    field: 'projectName',
    sortable: true,
    align: 'left',
    format: (val, row) => row.project.name,
  },
  {
    name: 'taskName',
    label: 'Tâche',
    field: 'name',
    sortable: true,
    align: 'left',
  },
  {
    name: 'taskStatus',
    label: 'Statut',
    field: 'status',
    sortable: true,
    align: 'left',
    format: (val, row) => {
      if (row.status == 'IP') {
        return 'En cours'
      }
      if (row.status == 'NS') {
        return 'Nouveau'
      }
      return row.status
    },
  },
  {
    name: 'taskDeadline',
    label: 'Echéance',
    field: 'deadline',
    sortable: true,
    align: 'left',
    format: (val, row) => {
      if (row.deadline === null) {
        return '-'
      }
      return format(new Date(row.deadline), 'dd/MM/yyyy')
    },
  },
]

const selectedRows = ref([])
provide('projectSelectedRows', selectedRows)
const isTableLoading = ref(false)
const clientId = ref(props.clientId) as Ref<string>

const rows = ref([])

refreshTasks()

async function refreshTasks() {
  isTableLoading.value = true
  const tasks = await userApi.getUserAssignedTasks(authenticatedUser.id)
  rows.value = tasks.results

  isTableLoading.value = false
}

function onRowClick(event, row) {
  if (store.state.user.portal === 'CLIENT') {
    // store.commit("setPageTitle", `Dossier : ${row.name}`);
    router.push({
      name: 'projects/project',
      params: {
        projectId: row.project.id,
      },
      query: {
        taskId: row.legacyId,
      },
    })
  } else {
    // store.commit("setPageTitle", `Dossier : ${row.name}`);
    store.state.user.clientId = row.client.id
    store.state.currentClient = row.client
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: row.client.id,
        projectId: row.project.id,
      },
      query: {
        taskId: row.legacyId,
      },
    })
  }
}
</script>

<template>
  <BaseTable
    :selection="selection"
    :columns="columns"
    :rows="rows"
    :is-loading="isTableLoading"
    :enable-actions="false"
    :enable-add="false"
    @on-row-click="onRowClick"
  >
  </BaseTable>
</template>
