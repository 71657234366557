<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent, Notify, QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseSkeletonTable from '@/components/base/BaseSkeletonTable.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'

interface Props {
  clientId?: string
  selectedGroup: any
}

interface Props { }
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi = useApi()
const clientId = ref(props.clientId)

const userColumns: QTableProps['columns'] = [
  {
    name: 'fullName',
    label: 'Nom',
    field: 'fullName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'username',
    label: 'Utilisateurs',
    field: 'username',
    sortable: true,
    align: 'left',
  },
]

const isLoading = ref(false)
const group = ref()
const availableUsers = ref([])
const selectedAvailableUsers = ref([])

const usersInGroup = ref([])
const selectedUsersInGroup = ref([])

async function refreshData() {
  console.log("refreshData", props.selectedGroup.id, clientId.value)

  isLoading.value = true
  selectedAvailableUsers.value = []
  selectedUsersInGroup.value = []

  const selectedGroupId = props.selectedGroup.id

  const portalGroup = await userApi.getPortalGroup(selectedGroupId, clientId.value)
  group.value = portalGroup
  usersInGroup.value = group.value.users

  const portalUser = await userApi.getPortalUsers(clientId.value)
  availableUsers.value = portalUser.results.filter((user) => {
    let keep = true
    group.value.users.forEach((groupUser) => {
      if (groupUser.id === user.id) {
        keep = false
      }
    })
    return keep
  })

  isLoading.value = false
}

async function addUsersToGroup() {
  const selectedGroupId = props.selectedGroup.id
  const userIds = []
  selectedAvailableUsers.value.forEach((user) => {
    userIds.push(user.id)
  })
  await userApi.addUsersToPortalGroup(selectedGroupId, userIds, clientId.value)

  await refreshData()

  Notify.create({
    message: userIds.length > 1 ? 'Les utilisateurs ont été ajoutés au groupe' : "L'utilisateur a été ajouté au groupe",
    type: 'primary',
  })
}

async function removeUsersFromGroup() {
  const selectedGroupId = props.selectedGroup.id
  const userIds = []
  selectedUsersInGroup.value.forEach((user) => {
    userIds.push(user.id)
  })
  await userApi.removeUsersToPortalGroup(selectedGroupId, userIds, clientId.value)
  await refreshData()

  Notify.create({
    message: userIds.length > 1 ? 'Les utilisateurs ont été retirés du groupe' : "L'utilisateur a été retiré du groupe",
    type: 'primary',
  })
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="refreshData" full-width>
    <BaseDialog title="Utilisateurs du groupe" :show-cancel-button="false" :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="row q-pr-xl q-pl-xl">
          <div class="col">
            <h5 class="q-mt-sm q-mb-md">Utilisateurs disponibles</h5>
            <BaseTable flat bordered :columns="userColumns" :rows="availableUsers" row-key="id" selection="multiple"
              v-model:selected="selectedAvailableUsers" class="user-table" hide-header :loading="isLoading"
              :enableAdd="false" :enableActions="false">
              <template #loading>
                <BaseSkeletonTable v-if="availableUsers.length === 0"></BaseSkeletonTable>
                <q-inner-loading showing color="primary" />
              </template>
            </BaseTable>
          </div>

          <div class="col-1 self-center">
            <div class="button-container">
              <q-btn @click="addUsersToGroup" icon="chevron_right"></q-btn>
              <br />
              <q-btn @click="removeUsersFromGroup" icon="chevron_left"></q-btn>
            </div>
          </div>

          <div class="col">
            <h5 class="q-mt-sm q-mb-md">Utilisateurs ayant accès</h5>
            <BaseTable flat bordered :columns="userColumns" :rows="usersInGroup" row-key="id" selection="multiple"
              v-model:selected="selectedUsersInGroup" hide-header :loading="isLoading" :enableAdd="false"
              :enableActions="false">
              <template #loading>
                <BaseSkeletonTable v-if="usersInGroup.length === 0"></BaseSkeletonTable>
                <q-inner-loading showing color="primary" />
              </template>
            </BaseTable>
          </div>
        </div>
      </template>
      <template #actions>
        <q-btn flat label="Terminer" color="primary" v-close-popup />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
h6 {
  text-align: center;
  display: block;
  font-weight: 700;
}

.button-container {
  text-align: center;
  display: block;
}

.user-table {}
</style>
