<script setup lang="ts">
import { ref, computed } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useDialogPluginComponent, Notify } from 'quasar'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  addTitle: string
  editTitle: string
  addConfirmLabel: string
  editConfirmLabel: string
  selectedRow?: {}
  mode: string
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits, 'editItemClick', 'addItemClick'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi = useApi()

const nameOnCard = ref('')
const cardDigits = ref('')
const cvc = ref('')
const expirationMonth = ref('')
const expirationYear = ref('')
const isLoading = ref(false)

function beforeShow() {
  if (props.mode === 'ADD') {
    nameOnCard.value = ''
    cardDigits.value = ''
    cvc.value = ''
    expirationYear.value = ''
    expirationYear.value = ''
  } else {
    const selectedItem = props.selectedRow[0]
    if (selectedItem === undefined) {
      return
    }
    nameOnCard.value = selectedItem.nameOnCard
    cardDigits.value = selectedItem.cardDigits
    cvc.value = selectedItem.cardCvc
    expirationMonth.value = selectedItem.expirationMonth
    expirationYear.value = selectedItem.expirationYear
  }
}

const title = computed(() => {
  if (props.mode === 'ADD') {
    return props.addTitle
  } else {
    return props.editTitle
  }
})

const confirmLabel = computed(() => {
  if (props.mode === 'ADD') {
    return props.addConfirmLabel
  } else {
    return props.editConfirmLabel
  }
})

async function createPaymentMethod() {
  try {
    isLoading.value = true
    await userApi.createPaymentMethod({
      card_brand: 'VISA',
      card_digits: cardDigits.value,
      card_cvc: cvc.value,
      card_last_4_digits: '4242',
      name_on_card: nameOnCard.value,
      expiration_month: expirationMonth.value,
      expiration_year: expirationYear.value,
      stripe_payment_method_id: '1234',
    })
    emit('ok')
    Notify.create({
      message: 'Moyen de paiement ajouté avec succès',
      type: 'primary',
    })
    isLoading.value = false
  } catch (e) {
    dialogRef.value?.hide()
    Notify.create({
      message: "Une erreur est survenue lors de l'ajout du moyen de paiement",
      type: 'negative',
    })
    isLoading.value = false
  }
}

async function updatePaymentMethod() {
  const selectedItem = props.selectedRow[0]
  await userApi.updatePaymentMethod(selectedItem.id, {
    card_brand: 'VISA',
    card_digits: cardDigits.value,
    card_cvc: cvc.value,
    card_last_4_digits: '4242',
    name_on_card: nameOnCard.value,
    expiration_month: expirationMonth.value,
    expiration_year: expirationYear.value,
    stripe_payment_method_id: '1234',
  })
  emit('editItemClick')
}

function onConfirmClick() {
  if (props.mode === 'ADD') {
    createPaymentMethod()
  } else {
    updatePaymentMethod()
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" :dialog-width="400" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="nameOnCard" label="Nom et prénom" class="q-mb-md"> </BaseInput>
        <BaseInput v-model="cardDigits" label="Numéro de la carte" class="q-mb-md"> </BaseInput>
        <BaseInput v-model="cvc" label="CVC" class="q-mb-md"> </BaseInput>
        <BaseInput v-model="expirationMonth" label="Mois expiration" class="q-mb-md"> </BaseInput>
        <BaseInput v-model="expirationYear" label="Année expiration" class="q-mb-md"> </BaseInput>
      </template>
      <template #actions>
        <q-btn flat :label="confirmLabel" color="primary" v-close-popup @click="onConfirmClick" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
