<script setup lang="ts">
import { ref, computed } from 'vue'
import HeadingBlockView from './elements/content/block/HeadingBlockView.vue'
import ParagraphBlockView from './elements/content/block/ParagraphBlockView.vue'
import ListBlockView from './elements/content/block/ListBlockView.vue'
import TableBlockView from './elements/content/block/TableBlockView.vue'
import ImageBlockView from './elements/content/block/ImageBlockView.vue'
import ConditionBlockView from './elements/control/block/ConditionBlockView.vue'
import AlternativeBlockView from './elements/control/block/AlternativeBlockView.vue'
import AlternativeBlockChoiceView from './elements/control/block/AlternativeBlockChoiceView.vue'
import LoopBlockView from './elements/control/block/LoopBlockView.vue'

interface Props {
  element: Element
}

const props = withDefaults(defineProps<Props>(), {})

const cardClass = computed(() => {
  let classes = 'full-width'
  if (props.element.type === 'ConditionBlock') {
    classes += ' block top-left-corner-angle'
  } else {
    classes += ' block-content'
  }

  return classes
})
</script>

<template>
  <div class="element-view">
    <HeadingBlockView v-if="element.type === 'HeadingBlock'" :data="element"> </HeadingBlockView>
    <ParagraphBlockView v-if="element.type === 'ParagraphBlock'" :data="element"> </ParagraphBlockView>
    <ListBlockView v-if="element.type === 'ListBlock'" :data="element"> </ListBlockView>
    <TableBlockView v-if="element.type === 'TableBlock'" :data="element"> </TableBlockView>
    <ImageBlockView v-if="element.type === 'ImageBlock'" :data="element"> </ImageBlockView>
    <ConditionBlockView v-if="element.type === 'ConditionBlock'" :data="element"> </ConditionBlockView>
    <AlternativeBlockView v-if="element.type === 'AlternativeBlock'" :data="element"> </AlternativeBlockView>
    <AlternativeBlockChoiceView v-if="element.type === 'AlternativeBlockChoice'" :data="element">
    </AlternativeBlockChoiceView>
    <LoopBlockView v-if="element.type === 'LoopBlock'" :data="element"> </LoopBlockView>
  </div>
</template>

<style lang="scss" scoped></style>
