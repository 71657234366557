<script lang="ts" setup>
import { ref, inject, Ref } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const byDefault = ref(false)
</script>
<template>
  <div class="row">
    <BaseCheckbox v-model="byDefault" label="Coché par défaut ?" />
  </div>
</template>
<style lang="scss" scoped></style>
