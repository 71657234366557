import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class ListBlock extends Element implements Serializable<ListBlock> {
  content: string
  showNumbers: boolean

  constructor() {
    super()
    this.content = ''
    this.showNumbers = false
  }

  deserialize(input: Object): ListBlock {
    super.deserialize(input)

    this.content = input.content
    this.showNumbers = input.showNumbers

    return this
  }
}
