import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { useStore } from '@/store/store'

export function useAuthenticatedUser(): AuthenticatedUser {
  const store = useStore()
  return store.state.user
}

export function useAuthenticatedUserHasPermission() {
  const authenticatedUser = useAuthenticatedUser()

  const authenticatedUserHasPermission = function (permission): boolean {
    const globalPermissions = authenticatedUser.permissions.globalPermissions
    return globalPermissions.includes(permission)
  }

  return authenticatedUserHasPermission
}
