<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  participant: {}
}

const props = defineProps<Props>()

const isEmptyRoom = computed(() => {
  return !props.participant
})
</script>

<template>
  <div class="video-col">
    <div class="video-col-content">
      <div class="video-content" v-if="!isEmptyRoom">
        <div class="media-wrapper">
          <div class="empty-video" v-if="!props.participant.video_enabled">
            <div class="container">
              <div class="avatar-xl">
                <span class="avatar-title rounded-circle">{{ props.participant.initial }}</span>
              </div>
            </div>
          </div>
          <template v-show="props.participant.video_enabled">
            <div class="video-element" :id="props.participant.sid">
              <audio autoplay :muted="props.participant.is_local"></audio>
              <video autoplay muted playsinline></video>
            </div>
          </template>
        </div>
        <div class="controls">
          <div class="controls-content">
            <div class="microphone-mute-icon" v-if="!props.participant.audio_enabled">
              <i class="fas fa-microphone-slash"></i>
            </div>
            <div class="name-banner">
              <template v-if="props.participant.is_local"> {{ props.participant.full_name }} (Vous) </template>
              <template v-else>
                {{ props.participant.full_name }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-room-invitation" v-else>
        <div class="container">
          <h3 class="title"><i class="far fa-clock"></i> Dans l'attente d'autres participants.</h3>
        </div>
      </div>
    </div>
  </div>
</template>
