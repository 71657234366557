<script setup lang="ts">
import { usePageTitle } from '@/store/useAppStore'
import { useStore } from '@/store/store'
const store = useStore()

const company = {
  name: 'xx',
}
store.commit('setPageTitle', 'Dossier "' + company.name + '"')
</script>

<template>
  <q-page flex> Entreprise </q-page>
</template>
