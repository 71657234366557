<script setup lang="ts">
import VisioHeader from '@/components/common/visio/VisioHeader.vue'
import VisioVideoLayout from '@/components/common/visio/VisioVideoLayout.vue'
import VisioFooter from '@/components/common/visio/VisioFooter.vue'

const maxParticipants = 2
const participants = [1]
const roomName = 'Rendez-vous'
</script>

<template>
  <q-page flex>
    Video twilio
    <VisioHeader :max-participants="maxParticipants" :participants-count="participants.length" :room-name="roomName" />
    <VisioVideoLayout :participants="participants" />
    <VisioFooter
      @quit-room="quitRoom"
      @open-settings="openSettingsModal"
      @toggle-local-video="toggleLocalVideo"
      @toggle-local-microphone="toggleLocalMicrophone"
    />
  </q-page>
</template>
