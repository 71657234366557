<script setup lang="ts">
import EnvironmentSwitcher from './EnvironmentSwitcher.vue'
import Menu from './Menu.vue'
import { ref } from 'vue'

const fixedVisible = ref(true)
const onHoverVisible = ref(false)
let hoverTimeout

function handleMouseEnter() {
      hoverTimeout = setTimeout(() => {
        onHoverVisible.value = true
      }, 200); // Delay of 200ms
}

function handleMouseLeave() {
      clearTimeout(hoverTimeout);
      onHoverVisible.value = false;
    }
</script>

<template>
  <q-drawer
    side="left"
    behavior="desktop"
    show-if-above
    bordered
    :width="fixedVisible || onHoverVisible ? 256 : 40"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    >
    <EnvironmentSwitcher v-show="fixedVisible || onHoverVisible"/>
    <!-- Button to toggle the drawer -->
    <q-btn dense round absolute size="12px"
      :style="{top: '5px', left: fixedVisible || onHoverVisible ? '220px' : '5px'}"
      color="primary"
      :icon="fixedVisible ? 'chevron_left' : 'chevron_right'"
      @click="fixedVisible = !fixedVisible, onHoverVisible = !onHoverVisible"
    />
    <Menu v-show="fixedVisible || onHoverVisible"></Menu>

  </q-drawer>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

</style>
