<script lang="ts" setup>
import { Dialog, Notify } from 'quasar'
import ObjectUserPermissionsForm from '@/components/common/dialog/ObjectUserPermissionsForm.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
    element: any
    project: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['groupsUpdated'])

const authenticatedUser = useAuthenticatedUser()

function addGroup(event) {
    event.preventDefault()
    console.log('addGroup', props.project)

    const dialog = Dialog.create({
        component: ObjectUserPermissionsForm,
        componentProps: {
            clientId: authenticatedUser.clientId,
            selectedRows: [props.project.value],
            rights: ['view_userproject', 'change_userproject', 'delete_userproject'],
            environment: 'UserProject',
            tab: 'groups'
        },
    })
        .onOk(() => {
            dialog.hide()
            emit('groupsUpdated')
        })
        .onCancel(() => {
            dialog.hide()
            emit('groupsUpdated')
        })
}
</script>

<template>
    <div class="title">
        Groupes
        <q-btn flat dense icon="add" @click="addGroup" />
    </div>
</template>

<style lang="scss" scoped>
.title {
    font-size: 1rem;
    font-weight: bold;
    // margin-bottom: 1rem;
}
</style>
