<script lang="ts" setup>
import { useApi } from '@/store/useAppStore'
import { ref } from 'vue'
const userApi = useApi()

const visible = ref(false)

function refresh() {
  window.location.reload()
}

setInterval(checkUpdatesInCode, 1 * 60 * 1000)
function checkUpdatesInCode() {
  userApi.checkVersion().then((result) => {
    if (result) {
      visible.value = true
    }
  })
  // userApi.logLocalStorage()
}
</script>
<template>
  <q-dialog v-model="visible" seamless position="bottom">
    <q-card style="width: 400px" class="refresh-version">
      <q-card-section class="row items-center no-wrap">
        <div class="column">
          <div class="text-weight-bold">Une nouvelle version de l'app est disponible !</div>
          <div class="text-caption">Veuillez rafraîchir la page pour en profiter.</div>
        </div>
        <q-space />

        <q-btn flat round icon="refresh" @click="refresh" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<style lang="scss" scoped>
.refresh-version {
  border-top: 4px solid;
  border-color: #3d73c5;
}
</style>
