<script setup lang="ts">
import { ref } from 'vue'
import { Dialog } from 'quasar'
import { HeadingBlock } from '@/models/v2/document/elements/content/block/HeadingBlock'
import EditTitleDialog from '@/pages/platform/v2/components/DocumentEditor/dialogs/EditTitleDialog.vue'

interface Props {
  data: HeadingBlock
}

const hover = ref(false)
const props = withDefaults(defineProps<Props>(), {})

function saveSize(s) {
  props.data.size = s
}

function openDialog() {
  const dialog = Dialog.create({
    component: EditTitleDialog,
    componentProps: {
      data: props.data,
    },
  })
    .onOk((data) => {
      saveSize(data)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div class="column items-start" @mouseover="hover = true" @mouseleave="hover = false">
    <q-card flat bordered class="full-width block-content">
      <q-card-section class="row items-center justify-start no-wrap q-pl-none q-pt-sm q-pb-sm q-pr-sm heading-block">
        <q-icon name="drag_handle" class="handle" :color="hover ? 'black' : 'transparent'"></q-icon>
        <q-card-section class="q-pa-none flex justify-between" style="width: 100%">
          <span :class="`text-${data.size} q-ma-none heading-span`" contenteditable="true">
            {{ data.content }}
          </span>
          <div class="settings">
            <q-btn icon="settings" round flat size="sm" @click="openDialog" />
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>
  </div>
</template>

<style lang="scss" scoped>
.handle {
  cursor: grab;
  position: relative;
  padding: 0px 2px;
}

.heading-span {
  word-break: break-word;
}

.settings {
  display: none;
}

.heading-block:hover .settings {
  display: block;
}
</style>
