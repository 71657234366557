<script lang="ts" setup>
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const model = ref()
</script>

<template>
  <!-- <div>
    {{ field.name }}
  </div> -->
  <q-select v-model="field.value" :options="field.choices" :label="showLabel ? field.name : undefined" />
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
