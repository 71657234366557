import Main from './Main.vue'
import Monitoring from './Monitoring.vue'
import Logs from './Logs.vue'

const routes = [
  {
    name: 'infrastructure/main',
    component: Main,
    path: '/infrastructure',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'infrastructure/monitoring',
    component: Monitoring,
    path: '/infrastructure/monitoring',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'infrastructure/logs',
    component: Logs,
    path: '/infrastructure/logs',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
