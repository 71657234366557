<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { Dialog, Notify } from 'quasar'
import { removeElementFromParent } from './utils'
import TextField from './TextField.vue'
import FunctionField from './FunctionField.vue'
import ArrayField from './ArrayField.vue'
import NumberField from './NumberField.vue'
import DateField from './DateField.vue'
import ChoiceField from './ChoiceField.vue'
import MultiChoiceField from './MultiChoiceField.vue'
import WysiwygTextField from './WysiwygTextField.vue'
import FileField from "./FileField.vue"
import ColumnsField from "./ColumnsField.vue"
import AddressField from "./AddressField.vue"
import FunctionOperatorField from "./FunctionOperatorField.vue"

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

const selectedPage = inject("selectedPage")
const unsavedChanges = inject("unsavedChanges")

function getCardClass(type) {
    return "drag-cursor"
}

function removeNode() {
    console.log("removeNode")
    const dialog = Dialog.create({
        title: 'Supprimer',
        message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
        cancel: true,
        persistent: true,
    })
        .onOk(() => {
            removeElementFromParent(selectedPage.value, props.element)
            unsavedChanges.value = true
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

function getFieldTypeName() {
    switch (props.element.type) {
        case "text":
            return "Texte"
        case "wysiwyg_text":
            return "Texte WYSIWYG"
        case "number":
            return "Nombre"
        case "date":
            return "Date"
        case "choice":
            return "Choix"
        case "multi_choice":
            return "Choix multiples"
        case "file":
            return "Fichier"
        case "array":
            return "Tableau"
        case "function":
            return "Fonction"
        case "function_operator":
            return "Opérateur"
        case "columns":
            return "Colonnes"
        case "address":
            return "Adresse"
    }
    return props.element.type
}

function onNameChange() {
    console.log("onNameChange")
    unsavedChanges.value = true
}
</script>


<template>
    <q-card flat bordered :class="getCardClass(element.type)">
        <q-card-section class="q-pa-sm">
            <q-card-section class="q-pa-sm node">
                <div class="row">
                    <div class="col">
                        <span class="input-label">Type : </span><span
                            style="margin-left: 4px;">{{ getFieldTypeName() }}</span>
                    </div>
                    <div class="col-auto"></div>
                    <div class="col">
                        <q-btn icon="o_clear" size="12px" dense @click.stop="removeNode(element)" text-color="grey"
                            class="float-right node-button">
                            <q-tooltip>Supprimer</q-tooltip>
                        </q-btn>
                    </div>
                </div>
                <div>
                    <q-input v-if="element.type != 'columns'" dense v-model="element.name" style="margin-top: 8px"
                        @update:model-value="onNameChange" debounce="500">
                        <template v-slot:before>
                            <span class="input-label" style="font-size: 14px; color: black;">Nom : </span>
                        </template>
                    </q-input>
                    <div v-if="element.type == 'text'">
                        <TextField :element="element"></TextField>
                    </div>

                    <div v-if="element.type == 'wysiwyg_text'">
                        <WysiwygTextField :element="element"></WysiwygTextField>
                    </div>

                    <div v-if="element.type == 'number'">
                        <NumberField :element="element"></NumberField>
                    </div>

                    <div v-if="element.type == 'date'">
                        <DateField :element="element"></DateField>
                    </div>

                    <div v-if="element.type == 'choice'">
                        <ChoiceField :element="element"></ChoiceField>
                    </div>

                    <div v-if="element.type == 'multi_choice'">
                        <MultiChoiceField :element="element"></MultiChoiceField>
                    </div>

                    <div v-if="element.type == 'file'">
                        <FileField :element="element"></FileField>
                    </div>

                    <div v-if="element.type == 'array'">
                        <ArrayField :element="element"></ArrayField>
                    </div>

                    <div v-if="element.type == 'function'">
                        <FunctionField :element="element"></FunctionField>
                    </div>

                    <div v-if="element.type == 'function_operator'">
                        <FunctionOperatorField :element="element"></FunctionOperatorField>
                    </div>

                    <div v-if="element.type == 'columns'">
                        <ColumnsField :element="element"></ColumnsField>
                    </div>

                    <div v-if="element.type == 'address'">
                        <AddressField :element="element"></AddressField>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>

<style lang="scss">
.input-label {
    display: inline-block;
    width: 60px;
    // background-color: red;
}
</style>

<style scoped lang="scss">
.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}
</style>