import Main from './Main.vue'
import Profile from './Profile.vue'
import Theme from './Theme.vue'

const routes = [
  {
    name: 'settings/main',
    component: Main,
    path: '/settings',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'settings/profile',
    component: Profile,
    path: '/settings/profile',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'settings/theme',
    component: Theme,
    path: '/settings/theme',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
