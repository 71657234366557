<script lang="ts" setup>
import { inject, ref, watch, onMounted } from 'vue'
import { Notify, Dialog } from 'quasar'

interface Props {
    node: any
}
const props = withDefaults(defineProps<Props>(), {})

const fileDropZoneRef = ref(null)
const treeRef = inject("treeRef")
const unsavedChanges = inject("unsavedChanges")
</script>

<template>
    <div style="padding: 0px; overflow: hidden"
        class="row no-wrap justify-start items-start content-start full-width items-center" ref="fileDropZoneRef">
        <div v-if="node.type === 'root'">&nbsp;</div>
        <div v-if="node.type !== 'root'"
            class="row  no-wrap justify-start items-start content-start full-width items-center node">
            <div class="col-auto">
                <q-icon v-if="node.icon" :name="node.icon" :style="node.iconStyle"></q-icon>
                <span :style="node.textStyle" class="node-label">{{ node.label }}</span>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.file-name {
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // border: 1px solid;
    // text-wrap: nowrap;
}

.file-date {
    color: #2c2c2c;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.drop-zone {
    // margin-bottom: 10px;
    // padding: 10px;
}

.drag-el {
    // cursor: grab;
    // margin-bottom: 10px;
    // padding: 5px;
}

.editing-user {
    font-style: italic;
    font-size: 13px;
    color: #431010;
    margin-top: -4px;
}

.previewInNewTabButton {
    color: #cccccc;
}

.previewInNewTabButton:hover {
    color: #777777;
    background-color: #bbbbbb;
}

.file-drop-zone-hover {
    background-color: lightgrey;
}

.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}

.node-label {
    margin-right: 8px;
}

.ul {
    list-style-type: disc;
}

:deep(.q-checkbox__inner) {
    color: rgb(158, 158, 158);

    margin-right: 5px;
    font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
    height: 0px;
    width: 0px;
    overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
    border: 1.3px solid currentColor;
}

.search-input {
    width: 500px;
    margin-left: 0px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.subtitle {
    font-size: 18px;
}

.category-header {
    background-color: #227a68;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // cursor: pointer;
}

.category-subheader {
    background-color: #2a5d97;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    // cursor: pointer;
}

.project-category {
    color: rgb(61, 61, 63);
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
}

.available-project {
    cursor: pointer;
    font-weight: 500;
}

.page-content-header {
    // margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
