import { routes as dashboardRoutes } from '../dashboard/routes.js'
import { routes as activityRoutes } from '../activity/routes.js'
import { routes as clientsRoutes } from '../clients/routes.js'
import { routes as settingsRoutes } from '../settings/routes.js'
import { routes as administrationRoutes } from '../administration/routes.js'
import { routes as visioRoutes } from '../visio/routes.js'

import { routes as outlookAddInRoutes } from '../../outlook-add-in/router/routes.js'

let routes = []

routes = routes.concat(dashboardRoutes)
routes = routes.concat(activityRoutes)
routes = routes.concat(clientsRoutes)
routes = routes.concat(settingsRoutes)
routes = routes.concat(administrationRoutes)
routes = routes.concat(visioRoutes)
routes = routes.concat(outlookAddInRoutes)

export { routes }
