<script setup lang="ts">
import { ref, Ref, computed, inject } from 'vue'
import { Dialog } from 'quasar'
import { LoopBlock } from '@/models/v2/document/elements/control/block/LoopBlock'
import BlockElementView from '../../../BlockElementView.vue'
import EditLoopDialog from '../../../dialogs/EditLoopDialog.vue'
import draggable from 'vuedraggable'

interface Props {
  data: LoopBlock
}

const props = withDefaults(defineProps<Props>(), {})

const hover = ref(false)
const variables: Ref<Array<any>> = inject('variables', ref([]))

const cardClass = computed(() => {
  let classes = 'full-width'
  classes += ' block top-left-corner-angle'

  return classes
})

function showEditLoopDialog() {
  const dialog = Dialog.create({
    component: EditLoopDialog,
    componentProps: {
      loop: props.data,
      variables: variables.value,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div class="column items-start" @mouseover="hover = true" @mouseleave="hover = false">
    <q-badge class="badge-corner" align="top" @click="showEditLoopDialog">
      <span class="material-icons"> loop </span>
      <span contenteditable="true" v-if="data.label !== undefined && data.label !== ''">
        {{ data.label }}
      </span>
      <span contenteditable="true" v-if="data.label === undefined || data.label === ''"> Boucle </span>
    </q-badge>
    <q-card flat bordered :class="cardClass">
      <q-card-sections horizontal class="row items-center justify-start no-wrap q-pl-none q-pt-sm q-pb-sm q-pr-sm">
        <q-icon name="drag_handle" class="handle" :color="hover ? 'black' : 'transparent'"> </q-icon>
        <div class="condition-block-view full-width">
          <draggable
            class="drag-area q-gutter-sm"
            tag="div"
            :list="data.elements"
            :group="{ name: 'g1' }"
            item-key="id"
            handle=".handle"
          >
            <template #item="{ element, index }">
              <div>
                <BlockElementView :element="element"></BlockElementView>
              </div>
            </template>
          </draggable>
        </div>
      </q-card-sections>
    </q-card>
  </div>
</template>

<style lang="scss" scoped></style>
