import Main from './Main.vue'
import Users from './Users.vue'
import Roles from './Roles.vue'
import Environments from './Environments.vue'
import ProjectTemplateEditor from './ProjectTemplateEditor.vue'
import DocumentTemplateEditor from './DocumentTemplateEditor.vue'

const routes = [
  {
    name: 'administration/main',
    component: Main,
    path: '/administration',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'administration/users',
    component: Users,
    path: '/administration/users',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'administration/roles',
    component: Roles,
    path: '/administration/roles',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'administration/environments',
    component: Environments,
    path: '/administration/environments',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'administration/project_templates_editor',
    component: ProjectTemplateEditor,
    path: '/administration/project_templates_editor',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'administration/document_templates_editor',
    component: DocumentTemplateEditor,
    path: '/administration/document_templates_editor',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
