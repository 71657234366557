<script lang="ts" setup>
import { ref, Ref, inject, computed } from 'vue'
import { ProjectKey } from '@/models/symbols'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  project: any
  action: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const uploadTasks = []

getUploadTasks(props.project.value)

function getUploadTasks(project) {
  project.subProjects.forEach((subProject) => {
    subProject.sections.forEach((section) => {
      section.tasks.forEach((task) => {
        if (task.type == 'document_upload') {
          uploadTasks.push({
            label: subProject.name + ' - ' + task.name,
            value: task,
          })
        }
      })
    })
  })
}

const destinationProject = ref(null)

async function moveFile() {
  emit('ok', destinationProject)
}

const title = computed(() => {
  if (props.action == 'move') {
    return "Déplacer dans les documents d'un autre dossier"
  } else if (props.action == 'copy') {
    return "Copier dans les documents d'un autre dossier"
  }
})

const actionLabel = computed(() => {
  if (props.action == 'move') {
    return 'Déplacer'
  } else if (props.action == 'copy') {
    return 'Copier'
  }
})

const searchString = ref('')

async function searchProjects() {
  const searchResults = await userApi.search(searchString.value)
  console.log('searchResults', searchResults)
  projects.value = searchResults.projects.results.filter((project) => project.id != props.project.value.id)
  searchExecuted.value = true
}

const projects = ref([])

const searchExecuted = ref(false)

function moveToProject(project) {
  emit('ok', project)
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      :title="title"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
      :isFullContent="true"
    >
      <template #full-content>
        <!-- <span v-if="action == 'move'" style="display: inline-block">Sélectionnez le dossier dans lequel vous voulez
          déplacer les documents
        </span>
        <span v-if="action == 'copy'" style="display: inline-block">Sélectionnez le dossier dans lequel vous voulez
          copier les documents
        </span>
        <br /><br />
        <q-select :options="uploadTasks" v-model="destinationProject"> </q-select> -->
        <div>
          <q-toolbar class="text-primary">
            <q-input
              dense
              outlined
              v-model="searchString"
              label="Nom du dossier"
              style="width: 100%"
              @keydown.enter.prevent="searchProjects()"
            ></q-input>
            &nbsp;&nbsp;&nbsp;
            <q-btn label="Rechercher" @click="searchProjects()" outlined color="primary" :loading="isLoading"></q-btn>
          </q-toolbar>

          <div
            style="margin-top: 20px; margin-bottom: 5px; margin-left: 20px"
            v-if="projects.length === 0 && searchExecuted"
          >
            Aucune dossier ne correspond à la recherche
          </div>
          <div style="margin-top: 20px; margin-bottom: 5px; margin-left: 20px" v-if="projects.length > 0">
            Sélectionnez un dossier
            <!-- pour déplacer les documents -->
          </div>
          <q-scroll-area
            class="company-list-scroll-area"
            v-if="projects.length > 0"
            style="width: 95%; margin-left: 20px; margin-right: 20px"
          >
            <q-list bordered separator v-if="projects.length > 0" style="width: 100%">
              <q-item
                clickable
                v-ripple
                v-for="project in projects"
                @click="moveToProject(project)"
                style="width: 100%"
              >
                <q-item-section style="">
                  <q-item-label>{{ project.name }}</q-item-label>
                  <q-item-label caption>Client : {{ project.client.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </div>
      </template>
      <template #actions>
        <!-- <q-btn flat :label="actionLabel" @click="moveFile" :disable="destinationProject == null" /> -->
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped>
.company-list-scroll-area {
  width: 90%;
  height: 50vh;
}

.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h5 {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
