<script lang="ts" setup>
import { ref, Ref, onMounted } from 'vue'
import { QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const emit = defineEmits(['clientSelected'])
const userApi: UserApi = useApi()

interface Props {
  clientId?: string
}
const props = defineProps<Props>()

const clientsColumns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
    style: 'max-width:200px; overflow:hidden; text-overflow:ellipsis',
  },
  {
    name: 'see',
    label: 'Voir le client',
    field: 'see',
    sortable: true,
    align: 'left',
  },
  {
    name: 'edit',
    label: 'Modifier le client',
    field: 'edit',
    sortable: true,
    align: 'left',
  },
  {
    name: 'delete',
    label: 'Supprimer le client',
    field: 'delete',
    sortable: true,
    align: 'left',
  },
  {
    name: 'seeAllProjects',
    label: 'Voir tous les dossiers',
    field: 'seeAllProjects',
    sortable: true,
    align: 'left',
  },
]
const clientsRows: Ref<Array<any>> = ref([])

const isLoading: Ref<boolean> = ref(false)
const filter: Ref<string> = ref('')

onMounted(async () => {
  try {
    isLoading.value = true
    const clients = await userApi.getClients()
    clients.results = clients.results.filter((client) => client.id == props.clientId)
    clientsRows.value = clients.results.map((client) => {
      return {
        ...client,
        see: false,
        edit: false,
        delete: false,
        seeAllProjects: false,
      }
    })
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
  }
})

function onRowClick(props) {
  emit('clientSelected', props.row)
}
</script>
<template>
  Cliquer sur le nom d'un client pour gérer les droits de ses dossiers
  <q-table
    flat
    bordered
    dense
    virtual-scroll
    title="Clients"
    style="max-height: 80vh"
    row-key="client"
    :loading="isLoading"
    :filter="filter"
    :columns="clientsColumns"
    :rows="clientsRows"
    :rows-per-page-options="[0]"
  >
    <template #top-left>
      <q-input filled color="primary" debounce="300" dense class="filter-input" name="search-bar" v-model="filter">
        <template #prepend>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" @click="onRowClick(props)" class="cursor-pointer">
        <q-td key="name" :props="props">
          {{ props.row.name }}
        </q-td>
        <q-td key="see" :props="props">
          <BaseCheckbox v-model="props.row.see"></BaseCheckbox>
        </q-td>
        <q-td key="edit" :props="props">
          <BaseCheckbox v-model="props.row.edit"></BaseCheckbox>
        </q-td>
        <q-td key="delete" :props="props">
          <BaseCheckbox v-model="props.row.delete"></BaseCheckbox>
        </q-td>
        <q-td key="seeAllProjects" :props="props">
          <BaseCheckbox v-model="props.row.seeAllProjects"></BaseCheckbox>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<style lang="scss" scoped></style>
