<script setup lang="ts">
import { ref, inject, onMounted } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Notify } from 'quasar'
import { createLabels } from '@/components/projects/execution/documents/DocumentsCommon'

const userApi: UserApi = useApi()

const project = inject('project')
const loading = ref(false)
const addingAttachment = ref(false)

const currentDirectoryNode = ref(null)
const documentTreeNodes = inject('documentTreeNodes')
let rootDirectory
const directories = ref([])
const selectedDirectory = ref(rootDirectory)

onMounted(async () => {
  loading.value = true
  const documentTree = await userApi.getUserProjectDocumentTree(project?.value.id)

  createLabels(documentTree.children, undefined)

  documentTreeNodes.value[0].children = documentTree.children

  currentDirectoryNode.value = documentTreeNodes.value[0]

  console.log('documentTreeNodes', documentTreeNodes.value)

  rootDirectory = {
    node: currentDirectoryNode.value,
    label: '/',
  }

  directories.value = [rootDirectory]
  selectedDirectory.value = rootDirectory

  loading.value = false
})

function onDirectoryClick(node) {
  console.log('directory clicked')
  currentDirectoryNode.value = node
  const newDirectory = {
    node: node,
    label: selectedDirectory.value.label == '/' ? '/' + node.label : selectedDirectory.value.label + '/' + node.label,
  }
  directories.value.push(newDirectory)
  selectedDirectory.value = newDirectory
}

function changeDirectory(newDirectory) {
  console.log('change directory', newDirectory)
  currentDirectoryNode.value = newDirectory.node
  while (directories.value[directories.value.length - 1] !== newDirectory) {
    directories.value.pop()
  }
}

async function onFileClick(node) {
  addingAttachment.value = true
  await addAttachment(node)
  addingAttachment.value = false
}

// https://stackoverflow.com/questions/18650168/convert-blob-to-base64
function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result.split(',')[1])
    reader.readAsDataURL(blob)
  })
}

async function downloadFile(node) {
  console.log('download file', node)
  try {
    const doc = await userApi.downloadDocumentTreeFile(project.value.id, node.id)
    const fileAsBase64 = await blobToBase64(new Blob([doc]))
    return fileAsBase64
  } catch (e) {
    console.error(e)
    Notify.create({
      message: "Erreur lors de l'ajout de la pièce jointe",
      type: 'negative',
    })
  }
}

async function addAttachment(node) {
  const mailItem = Office.context.mailbox.item
  console.log('item', mailItem)

  const base64String = await downloadFile(node)

  Office.context.mailbox.item.addFileAttachmentFromBase64Async(
    base64String,
    node.name,
    { asyncContext: null },
    function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.log(asyncResult.error.message)
      } else {
        // Get the ID of the attached file.
        const attachmentID = asyncResult.value
        console.log('ID of added attachment: ' + attachmentID)
      }
    },
  )
}
</script>

<template>
  <div style="margin-bottom: 20px">
    <div v-if="loading">
      <div class="row">
        <div class="col"></div>
        <q-spinner class="col-auto" size="50px" color="primary" />
        <div class="col"></div>
      </div>
    </div>
    <div v-if="!loading && documentTreeNodes.length != 0" style="padding-top: 8px">
      <div
        v-if="documentTreeNodes[0].children.length == 0"
        style="background-color: white; height: 50px; padding-top: 8px"
      >
        <span class="absolute-center-" style="font-size: 14px; padding-left: 50px">Aucun document</span>
      </div>
      <div v-if="documentTreeNodes[0].children.length != 0">
        <q-select
          options-cover
          dense
          outlined
          v-model="selectedDirectory"
          :options="directories"
          option-value="node"
          option-label="label"
          class="directory-select"
          @update:model-value="changeDirectory"
        ></q-select>
        <q-list v-for="node in currentDirectoryNode.children">
          <q-item
            v-if="node.type === 1"
            style="margin-left: 0px; padding-left: 8px"
            :clickable="!addingAttachment"
            @click="onDirectoryClick(node)"
            :disable="addingAttachment"
          >
            <q-icon :name="node.icon" size="22px"></q-icon>
            <span style="margin-left: 8px">
              {{ node.label }}
            </span>
            <q-separator />
          </q-item>
          <q-item
            v-if="node.type === 2"
            style="margin-left: 0px; padding-left: 8px"
            :clickable="!addingAttachment"
            @click="onFileClick(node)"
            :disable="addingAttachment"
          >
            <q-icon :name="node.icon" size="22px"></q-icon>
            <span style="margin-left: 8px">
              {{ node.label }}
            </span>
            <q-separator />
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h5 {
  color: rgb(64, 104, 200);
  margin-top: 0px;
  padding-top: 0px;
  font-size: 20px;
}

.directory-select {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
