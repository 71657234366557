<script setup lang="ts">
import { computed } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'

const authenticatedUser = useAuthenticatedUser()
const router = useRouter()

const store = useStore()
const initials = computed(() => authenticatedUser.initials)
const fullName = computed(() => authenticatedUser.fullName)
const email = computed(() => authenticatedUser.email)

const logout = function () {
  store.state.currentClient = null
  authenticatedUser.clientId = ''
  router.push({ name: 'base/logout' })
}
</script>

<template>
  <q-btn round flat class="" size="14px">
    <q-avatar color="primary" text-color="white" size="28px">{{ initials }}</q-avatar>
    <q-menu>
      <q-card class="card">
        <q-card-section>
          <div class="text-subtitle1">{{ fullName }}</div>
          <div class="text-subtitle2">{{ email }}</div>
        </q-card-section>

        <q-separator />

        <q-card-actions vertical>
          <q-btn color="primary" @click="logout">Se déconnecter</q-btn>
        </q-card-actions>
      </q-card>
    </q-menu>
  </q-btn>
</template>

<style lang="scss" scoped></style>
