<script setup lang="ts">
import { ref } from 'vue'
import VisioAvailabilityTable from '@/components/visio/VisioAvailabilityTable.vue'
import OutLookCalendarIntegration from '@/components/visio/OutLookCalendarIntegration.vue'
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Calendrier')

const modelMultiple = ref([])

const specialityOptions = ['Corporate', 'Social', 'IP-IT-DATA', 'AT/MP']
</script>

<template>
  <q-page padding class="page">
    <!-- <h5>Visios planifiées</h5>
    <PlannedVisiosTable />-->

    <h5>Disponibilités visio</h5>
    <OutLookCalendarIntegration />
    <br />
    <VisioAvailabilityTable />

    <h5>Spécialités</h5>
    <q-select
      outlined
      v-model="modelMultiple"
      multiple
      :options="specialityOptions"
      use-chips
      stack-label
      label="Spécialités"
      class="speciality_select"
    />
  </q-page>
</template>

<style lang="scss" scoped>
.speciality_select {
  max-width: 400px;
}

h5 {
  margin-bottom: 10px;
}
</style>
