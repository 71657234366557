<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ItemActionsMenu from './ItemActionsMenu.vue'
import AddItemButton from './AddItemButton.vue'
import BaseSkeletonTable from '@/components/base/BaseSkeletonTable.vue'

interface Props {
  addTitle?: string
  editTitle?: string
  addConfirmLabel?: string
  editConfirmLabel?: string
  addButtonLabel?: string
  selection?: string
  tableLabel?: string
  columns: Array<any>
  rows: Array<any>
  selectedRows?: Array<any>
  enableAdd?: boolean
  enableActions?: boolean
  isLoading?: boolean
  isFilterInput?: boolean
  itemSlot?: boolean
  customSlots?: boolean
  slotedColumns?: Array<any>
}

const props = withDefaults(defineProps<Props>(), {
  addTitle: 'Ajouter',
  editTitle: 'Editer',
  addButtonLabel: 'Nouveau',
  tableLabel: 'default',
  enableAdd: true,
  enableActions: true,
  isLoading: false,
  isFilterInput: true,
  itemSlot: true,
  customSlots: false,
  slotedColumns: [],
})

const emit = defineEmits(['addItemClick', 'onRowClick', 'selectedRows', 'onRowSelected', 'filterUpdated'])

const filter = ref('')
const actionsDisabled = ref(true)
const qTableSelectedRows = ref([])

watch(qTableSelectedRows, (newSelection, _oldSelection) => {
  emit('onRowSelected', newSelection)
  if (newSelection === undefined || newSelection.length === 0) {
    actionsDisabled.value = true
  } else {
    actionsDisabled.value = false
  }
})

watch(props.rows, (_newRows, _oldRows) => {
  if (props.selectedRows.length == 0) {
    actionsDisabled.value = true
    return
  }
  let selectedRowWasRemoved = true
  props.rows.forEach((row) => {
    if (props.selectedRows[0].id == row.id) {
      selectedRowWasRemoved = false
    }
  })
  if (selectedRowWasRemoved) {
    // Empty selectedRows
    while (props.selectedRows.length > 0) {
      props.selectedRows.pop()
    }
  }
})

function addButtonClick() {
  emit('addItemClick')
}

function onRowClick(event: any, row: any): void {
  emit('onRowClick', event, row)
}

// function filterRows(rows, terms, cols, getCellValue) {
//   const lowerTerms = terms.toLowerCase().trim();
//   if (lowerTerms === "") {
//     return rows;
//   }
//   return rows.filter((row) => {
//     return cols.some((col) => {
//       const val = getCellValue(row, col);
//       if (val === undefined || val === null) {
//         return false;
//       }
//       return val.toString().toLowerCase().indexOf(lowerTerms) !== -1;
//     });
//   });
// }

// https://dev.to/ajscommunications/the-awesome-dynamic-slot-name-in-vue-3-574k
// https://stackoverflow.com/questions/57156543/how-to-declare-a-list-of-slots-with-dynamic-names-and-dynamic-components-in-a-vu
const displayedFieldKeys = computed(() => {
  console.log('props.slotedColumns', props.slotedColumns)
  return props.slotedColumns.map(function (value) {
    console.log('value', value)
    return {
      quasarName: 'body-cell-' + value.name,
      colName: 'body-cell-' + value.name,
    }
  })
})

console.log('displayedFieldKeys', displayedFieldKeys.value)

displayedFieldKeys.value.forEach((key) => {
  console.log('key', key)
})
</script>

<template>
  <q-table row-key="id" v-model:selected="qTableSelectedRows" flat class="base-table" bordered v-bind="$attrs"
    :rows="rows" :columns="columns" :filter="filter" :selection="selection" :loading="isLoading"
    :hide-no-data="isLoading" @row-click="onRowClick">
    <template #top-left>
      <q-input filled debounce="300" color="primary" dense class="filter-input" name="search-bar" v-model="filter"
        @update:model-value="emit('filterUpdated', filter)" v-if="isFilterInput">
        <template #prepend>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
    <template #top-right>
      <ItemActionsMenu v-if="enableActions" :disable="actionsDisabled">
        <slot name="action-menu-items"></slot>
      </ItemActionsMenu>
      <span v-if="enableAdd">
        &nbsp;&nbsp;
        <AddItemButton :label="addButtonLabel" @click="addButtonClick" />
      </span>
    </template>

    <template #loading v-if="isLoading">
      <q-inner-loading showing color="primary" />
      <BaseSkeletonTable></BaseSkeletonTable>
    </template>

    <template v-if="itemSlot" v-slot:item="item">
      <slot name="item" :item="item"></slot>
    </template>

    <template v-for="key in displayedFieldKeys" :key="key.quasarName" v-slot:[key.quasarName]="slotProps">
      <slot :name="key.colName" :item="slotProps"></slot>
    </template>

    <slot name="body"></slot>
    <slot name="add-edit-form"></slot>
  </q-table>
</template>

<style lang="scss" scoped>
.filter-input {
  width: 300px;
}

.filter-input-mobile {
  width: 100%;
}
</style>
