<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

// interface Props {
// }
// const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const searchText: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)

async function search() {
  try {
    emit('ok', searchText.value)
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: "Une erreur est survenue lors du renommage de l'élément",
      type: 'negative',
    })
  }
}
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Recherche"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <BaseInput v-model="searchText" type="text"> </BaseInput>
      </template>
      <template #actions>
        <q-btn flat label="Chercher" @click="search()" color="primary" :disable="searchText === ''" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
