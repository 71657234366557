export function initOffice(callback) {
  //https://stackoverflow.com/questions/33954663/unable-to-use-window-history-replacestate-function-in-mail-addin
  window.backupHistoryFunctions = {}
  window.backupHistoryFunctions.pushState = window.history.pushState
  window.backupHistoryFunctions.replaceState = window.history.replaceState

  let officeApiScript = document.getElementById('officeApiScript')
  if (officeApiScript) {
    console.log('officeApiScript already loaded')
    if (callback) {
      callback()
    }
    return
  }

  officeApiScript = document.createElement('script')
  officeApiScript.setAttribute('src', 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js')
  officeApiScript.setAttribute('id', 'officeApiScript')
  officeApiScript.addEventListener('load', function () {
    console.log('officeApiScript loaded')
    Office.onReady((info) => {
      console.log('OnOfficeReady', info)

      if (callback) {
        callback()
      }

      window.history.pushState = window.backupHistoryFunctions.pushState
      window.history.replaceState = window.backupHistoryFunctions.replaceState
      console.log(window.history.replaceState)

      // Office.context.mailbox.item.internetHeaders.setAsync({
      //   Prefer: 'IdType="ImmutableId"',
      // });
      // if (info.host === Office.HostType.Outlook) {
      //     console.log("outlook");
      //     router.push("/outlook-add-in");
      // }
    })
  })
  document.head.appendChild(officeApiScript)
}
