<script lang="ts" setup>
import { useDialogPluginComponent } from 'quasar'
import { Question } from '@/models/survey/Question'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseDatePicker from '@/components/base/BaseDatePicker.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  question: Question
  form: {}
  value: {}
  isEdit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isEdit: false,
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'addClick', 'editClick'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const booleanSelectValues = [
  {
    label: 'Oui',
    value: true,
  },
  {
    label: 'Non',
    value: false,
  },
]

let title
if (props.isEdit) {
  title = 'Modifier une donnée'
} else {
  title = 'Ajouter une donnée'
}
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog :title="title" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div v-for="option in form" :key="option.id" class="q-mb-md" :id="option.id">
          <BaseInput
            v-if="option.type === 'text' || option.type === 'number'"
            :type="option.type"
            v-model="value[option.id]"
            :label="option.label"
          >
          </BaseInput>
          <div v-if="option.type === 'bool'" class="column">
            <BaseSelect clearable v-model="value[option.id]" :options="booleanSelectValues" emit-value map-options>
            </BaseSelect>
          </div>
          <div v-if="option.type === 'date'">
            <BaseDatePicker v-model="value[option.id]" :label="option.label"> </BaseDatePicker>
          </div>
        </div>
      </template>
      <template #actions>
        <q-btn v-if="!isEdit" flat v-close-popup label="Ajouter" color="primary" @click="emit('ok')" />
        <q-btn v-if="isEdit" flat v-close-popup label="Modifier" color="primary" @click="emit('ok')" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped>
.modal-qestion {
  font-size: 16px;
  font-weight: 400;
}
</style>
