<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { Notify } from 'quasar'
import { useBrowserLocation } from '@vueuse/core'
import { ref, computed } from 'vue'
import { usePageTitle } from '@/store/useAppStore'

import { AuthenticatedUser, getRoutes } from '@/models/core/AuthenticatedUser'
import BackOfficeApi from '@/services/api/BackOfficeApi'
import { isMobile } from '@/utils/detectMobile'

const store = useStore()
const router = useRouter()
const route = useRoute()

const logoUrl = ref('')
const logoStyle = ref('')
const email = ref('')
const showConfirmMessage = ref(false)
const isLoading = ref(false)

usePageTitle('Authentification')

function isOutlookAddIn() {
  if (
    location.value.hostname.includes('cedricdinont.dyndns-home.com') ||
    location.value.hostname.includes('outlook-add-in')
  ) {
    return true
  } else {
    return false
  }
}

const location = useBrowserLocation()
// TODO: Replace with an API call
if (location.value.hostname.includes('bewiz')) {
  logoUrl.value = 'https://www.bewiz.fr/wp-content/uploads/2015/01/bewiz2.png'
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_bewiz.gif'
} else if (location.value.hostname.includes('marvellup')) {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/615b210de1a84a70208dd30e_MarvellUp-03%20(1)-p-500.png";
  // logoUrl.value =
  //   "https://app.staging.comeonlaw.com/static/images/logo_marvell_up.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvellup.png'
  if (isMobile()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    logoStyle.value = 'width: 80%;padding-left: 100px;'
  }
} else if (location.value.hostname.includes('karila')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_karila.png'
} else {
  logoUrl.value =
    "https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ae6b0c56ec71df6665d5a3_comeon_logofinal-p-500.png"
  // logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvellup.png'
  if (isMobile() || isOutlookAddIn()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    // logoStyle.value = 'width: 80%; padding-left: 100px; margin-top: 50px;'  // cabinet d'avocats
    logoStyle.value = 'width: 100%; padding-left: 16px; margin-top: 50px;'    // COL
  }
}
async function handleSubmit() {
  try {
    isLoading.value = true
    const api = new BackOfficeApi()
    api.setStore(store)
    await api.forgotPassword(email.value)
    isLoading.value = false
    showConfirmMessage.value = true
  } catch (error) {
    isLoading.value = false
    Notify.create({
      message: "L'adresse email n'est pas connue",
      type: 'negative',
    })
  }
}

var mainDivClass = computed({
  get: () => {
    if (isMobile()) {
      return 'q-pa-md page-content q-col-gutter-md centered-form-mobile'
    } else {
      return 'q-pa-md page-content q-col-gutter-md centered-form'
    }
  },
})

var titleDivClass = computed({
  get: () => {
    if (isMobile()) {
      return 'col-md-12 title-mobile'
    } else {
      return 'col-md-12 title'
    }
  },
})
</script>

<template>
  <q-page flex>
    <div :class="mainDivClass" style="max-width: 450px">
      <div class="row q-col-gutter-sd" :style="logoStyle">
        <q-img :src="logoUrl"></q-img>
      </div>
      <div class="row q-col-gutter-sd">
        <h1 :class="titleDivClass">Mot de passe oublié</h1>
        <div v-if="showConfirmMessage">
          <span class="text-subtitle1">
            Nous vous avons envoyé un email avec un lien de réinitialisation de mot de passe. <br />
            Veuillez vérifier votre boîte mail.
          </span>
        </div>
      </div>
      <div class="columns">
        <q-input outlined id="email" type="text" label="Adresse email" v-model="email" required autofocus />
        <q-btn class="col-md-12 submit-button justify-center full-width" color="primary"
          label="Réinitialiser le mot de passe" :loading="isLoading" :disable="isLoading" @click="handleSubmit" />
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.centered-form-mobile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.title {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

.title-mobile {
  margin-bottom: 10px;
  margin-top: 20px;
  line-height: 50px;
  text-align: center;
  width: 100%;
}

input {
  width: 400px;
}

.forgot-password {
  color: #3d73c5;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.submit-button {
  margin-top: 20px;
}
</style>
