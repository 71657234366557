<script setup lang="ts">
import PaymentMethodsTable from '@/components/common/administration/credits/PaymentMethodsTable.vue'
import SubscriptionsTable from '@/components/common/administration/credits/SubscriptionsTable.vue'
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Gestion des crédits')
</script>

<template>
  <q-page padding class="page">
    <h5>Moyens de paiement</h5>
    <PaymentMethodsTable />

    <h5>Abonnements</h5>
    <SubscriptionsTable />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}
</style>
