<script setup lang="ts">
import { ref, computed } from 'vue'
import InlineVariableView from './elements/content/inline/InlineVariableView.vue'

interface Props {
  element: Element
}

const props = withDefaults(defineProps<Props>(), {})

const isHovering = ref(true)

const cardClass = computed(() => {
  let classes = 'full-width'
  if (props.element.type === 'ConditionBlock') {
    classes += ' block top-left-corner-angle'
  } else {
    classes += ' block-content'
  }

  return classes
})
</script>

<template>
  <span class="inline-element-view">
    <span class="inline-element-view-content">
      <InlineVariableView class="cursor-pointer" v-if="element.type === 'InlineVariable'" :data="element">
      </InlineVariableView>
    </span>
  </span>
</template>

<style lang="scss" scoped></style>
