import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class InlineVariable extends Element implements Serializable<InlineVariable> {
  variableId: number

  constructor() {
    super()
    this.variableId = 0
  }

  deserialize(input: Object): InlineVariable {
    super.deserialize(input)

    this.variableId = input.variableId

    return this
  }
}
