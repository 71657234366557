<script setup lang="ts">
import { ref, watch, inject, onMounted } from 'vue'
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  icon: string
  link: string
  label: string
  // active?: boolean
  custom?: string | undefined
  expanded?: boolean
  iconSelected?: string
}

const props = withDefaults(defineProps<Props>(), {
  expanded: true,
})

const activeSection = inject('activeSection')
const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()

const title = ref(props.label)
const subtitleVisible = ref(false)
const subtitle = ref('')
const originalTitle = ref(props.label)

function updateVisibleState() {
  if (props.custom == 'client_selector' && store.state.currentClient != undefined) {
    subtitle.value = store.state.currentClient.name
    title.value = store.state.currentClient.name
    subtitleVisible.value = true
  } else {
    title.value = originalTitle.value
    subtitleVisible.value = false
  }
}

watch(store?.state, (_newStore, _oldStore) => {
  updateVisibleState()
})

updateVisibleState()

function quitClientEnvironment(event) {
  if (authenticatedUser) {
    event.preventDefault()
    store.state.currentClient = null
    authenticatedUser.clientId = ''
    router.push({ name: 'clients/main' })
  }
}

function isClientActive() {
  // Highlight clients on client routes
  return route.params.clientId && props.custom == 'client_selector';
}

</script>

<template>
  <div v-if="(custom == 'client_selector' && subtitleVisible) || custom != 'client_selector'">
    <q-item v-if="true" :to="{ name: link }" class="section" @click="activeSection = title"
    :class="{ 'q-router-link--active': isClientActive() }">
      <q-toolbar class="toolbar" :class="{'toolbar-client': custom == 'client_selector'}">
        <q-icon v-if="icon" size="22px" :name="activeSection === title ? iconSelected : icon" class="avatar-base" />
        {{ title }}
        <q-space></q-space>
        <q-btn v-if="subtitleVisible" class="quit-button" color="primary" unelevated size="sm"
          @click="quitClientEnvironment" label="Quitter" />
      </q-toolbar>
    </q-item>

    <q-list dense>
      <slot></slot>
    </q-list>

    <q-separator />
  </div>

  <!-- <div v-if="custom == 'quick_access'">
    <q-list dense>
      <slot></slot>
    </q-list>
  </div> -->
</template>

<style lang="scss" scoped>
.quit-button {
  width: 55px;
  display: inline-block;
  margin-right: 0 px;
  margin-left: 5px;
}

.q-router-link {
  color: #5e5e5e;
}

.section {
  font-weight: 400;
  font-size: 14px;
}

.q-router-link--active {
  font-weight: 500;
  color: #4068c8;
}

.q-item__section--avatar {
  min-width: 10px;
  padding-right: 8px;
  // color: #5e5e5e;
}

.avatar-base {
  min-width: 10px;
  padding-right: 8px;
}

.avatar {
  min-width: 10px;
  padding-right: 8px;
  color: #5e5e5e;
}

.avatarActive {
  min-width: 10px;
  padding-right: 8px;
  color: #4068c8;
}

.subtitle {
  padding-left: 38px;
}

.toolbar {
  padding: 0px;
  margin: 0px;
}

.q-item {
  padding-left: 15px;
  padding-right: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

.q-icon {
  color: #5e5e5e;
}

.q-router-link--active .q-icon {
  color: #4068c8;
}
</style>
