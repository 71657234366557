<script lang="ts" setup>
import { ref, Ref, watch } from 'vue'
import { QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

interface Props {
  client: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['projectSelected'])

const userApi: UserApi = useApi()

const isLoading: Ref<boolean> = ref(false)
const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 100,
})

const projectsColumns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Dossier',
    field: 'name',
    sortable: true,
    align: 'left',
    style: 'max-width:200px; overflow:hidden; text-overflow:ellipsis',
  },
  {
    name: 'see',
    label: 'Voir le dossier',
    field: 'see',
    sortable: true,
    align: 'left',
  },
  {
    name: 'edit',
    label: 'Modifier le dossier',
    field: 'edit',
    sortable: true,
    align: 'left',
  },
  {
    name: 'delete',
    label: 'Supprimer le dossier',
    field: 'delete',
    sortable: true,
    align: 'left',
  },
]
const projectsRows: Ref<Array<any>> = ref([])

const filter: Ref<string> = ref('')

function onRowClick(props) {
  emit('projectSelected', props.row)
}

async function getProjects() {
  try {
    isLoading.value = true
    const { page, rowsPerPage } = pagination.value
    const projects = await userApi.getClientProjects(page, filter.value, rowsPerPage, props.client.id)
    isLoading.value = false
    return projects
  } catch (e) {
    isLoading.value = false
  }
}

watch(props, async () => {
  if (props.client) {
    const projects = await getProjects()
    projectsRows.value = projects.results.map((project) => {
      return {
        ...project,
        see: false,
        edit: false,
        delete: false,
      }
    })
  }
})
</script>
<template>
  Cliquer sur le nom d'un dossier pour gérer les droits sur ses tâches
  <q-table
    title="Dossiers"
    flat
    bordered
    style="max-height: 85vh"
    virtual-scroll
    dense
    row-key="client"
    v-model:pagination="pagination"
    no-data-label="Veuillez cliquer sur un client pour voir les dossiers"
    :loading="isLoading"
    :filter="filter"
    :columns="projectsColumns"
    :rows="projectsRows"
    :rows-per-page-options="[0]"
    @request="getProjects"
    @row-click="onRowClick"
  >
    <template #top-left>
      <q-input filled debounce="300" color="primary" dense class="filter-input" name="search-bar" v-model="filter">
        <template #prepend>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" @click="onRowClick(props)" class="cursor-pointer">
        <q-td key="name" :props="props">
          {{ props.row.name }}
        </q-td>
        <q-td key="see" :props="props">
          <BaseCheckbox v-model="props.row.see"></BaseCheckbox>
        </q-td>
        <q-td key="edit" :props="props">
          <BaseCheckbox v-model="props.row.edit"></BaseCheckbox>
        </q-td>
        <q-td key="delete" :props="props">
          <BaseCheckbox v-model="props.row.delete"></BaseCheckbox>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<style lang="scss" scoped></style>
