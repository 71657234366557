import Main from './Main.vue'
import Tasks from './Tasks.vue'

const routes = [
  {
    name: 'dashboard/main',
    component: Main,
    path: '/home',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'dashboard/tasks',
    component: Tasks,
    path: '/tasks',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
