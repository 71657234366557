<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { AddEditMode } from '@/components/base/AddEditMode.enum'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  mode: AddEditMode
  selectedRows: any
}

const props = withDefaults(defineProps<Props>(), {
  mode: AddEditMode.ADD,
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const name: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)

function beforeShow() {
  if (props.mode === AddEditMode.ADD) {
    name.value = ''
  } else {
    const selectedItem = props.selectedRows
    if (selectedItem === undefined) {
      return
    }
    name.value = selectedItem.name
  }
}

async function createClient() {
  try {
    isLoading.value = true
    await userApi.createClient({
      name: name.value,
    })
    Notify.create({
      message: `Client ${name.value} créé`,
      type: 'primary',
    })
    emit('ok')
    // isLoading.value = false
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: `Une erreur est survenue lors de la création du client`,
      type: 'negative',
    })
  }
}

async function updateClient() {
  try {
    isLoading.value = true
    await userApi.updateClient(props.selectedRows.id, {
      name: name.value,
    })
    Notify.create({
      message: `Le client ${name.value} a été modifié avec succès`,
      type: 'primary',
    })
    emit('ok')
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: `Une erreur est survenue lors de la modification du client`,
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="mode === AddEditMode.ADD ? 'Nouveau client' : 'Modifier client'" :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="name" label="Nom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom du client est requis']" />
      </template>
      <template #actions>
        <q-btn v-if="mode === AddEditMode.ADD" color="primary" @click="createClient" flat label="Ajouter" />
        <q-btn v-if="mode !== AddEditMode.ADD" color="primary" @click="updateClient" flat label="Modifier" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
