import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class HeadingBlock extends Element implements Serializable<HeadingBlock> {
  content: string
  size: string

  constructor() {
    super()
    this.content = ''
    this.size = ''
  }

  deserialize(input: Object): HeadingBlock {
    super.deserialize(input)

    this.content = input.content
    this.size = input.size
    return this
  }
}
