import { Serializable } from '../../../Serializable'

export class Condition implements Serializable<Condition> {
  variableId: string
  operator: string
  variableValue: string

  constructor() {
    this.variableId = ''
    this.operator = ''
    this.variableValue = ''
  }

  deserialize(input: any): Condition {
    this.variableId = input.variableId
    this.operator = input.operator
    this.variableValue = input.variableValue

    return this
  }
}
