import Main from './Main.vue'
import SearchResult from './SearchResult.vue'

const routes = [
  {
    name: 'dashboard/main',
    component: Main,
    path: '/home',
  },
  {
    name: 'dashboard/search',
    component: SearchResult,
    path: '/search',
    props: (route) => ({ query: route.query.q }),
  },
]

export { routes }
