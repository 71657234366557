import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class InlineAlternative extends Element implements Serializable<InlineAlternative> {
  variableId: number
  emptyValueText: string
  variableValues: Array<string>
  values: Array<string>

  constructor() {
    super()

    this.variableId = 0
    this.emptyValueText = ''
    this.variableValues = []
    this.values = []
  }

  deserialize(input: Object): ConditionBlock {
    super.deserialize(input)

    this.variableId = input.variableId
    this.emptyValueText = input.emptyValueText
    this.variableValues = input.variableValues
    this.values = input.values

    return this
  }
}
