<script lang="ts" setup>
import { ref, provide } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'
import TextField from "./rendered_fields/TextField.vue"
import ChoiceField from "./rendered_fields/ChoiceField.vue"
import MultipleChoiceField from "./rendered_fields/MultipleChoiceField.vue"
import NumberField from "./rendered_fields/NumberField.vue"
import DateField from "./rendered_fields/DateField.vue"
import WysiwygTextField from "./rendered_fields/WysiwygTextField.vue"
import ArrayField from "./rendered_fields/ArrayField.vue"
import FunctionField from "./rendered_fields/FunctionField.vue"
import AddressField from './rendered_fields/AddressField.vue'
import ColumnsField from './rendered_fields/ColumnsField.vue'
import FileField from './rendered_fields/FileField.vue'

interface Props {
    page: any
}

const props = withDefaults(defineProps<Props>(), {
})

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const isExecutingAction = ref(false)

console.log("page", props.page)

// const arrayTableRef = ref(null)
// provide("arrayTableRef", arrayTableRef)

const rows = ref([])
provide("rows", rows)
const rowsUpdated = ref(1)
provide("rowsUpdated", rowsUpdated)

console.log("page", props.page)

</script>

<template>
    <q-dialog ref="dialogRef" full-height full-width>
        <BaseDialog title="Prévisualisation de la page" :is-dialog-loading="isExecutingAction"
            @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()" :is-full-content="false" :showCancelButton="false">
            <template #body>
                <div style="height: calc(100% - 160px); margin: 16px 0px 0px 0px; ">
                    <q-scroll-area style="height: calc(100vh - 230px); width: 100%; max-width: 100%; padding-right: 16px;" visible>
                        <div v-for="field in page.fields">
                            <TextField v-if="field.type === 'text'" :field="field" />
                            <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" />
                            <NumberField v-if="field.type === 'number'" :field="field" />
                            <DateField v-if="field.type === 'date'" :field="field" />
                            <ChoiceField v-if="field.type === 'choice'" :field="field" />
                            <MultipleChoiceField v-if="field.type === 'multi_choice'" :field="field" />
                            <FileField v-if="field.type === 'file'" :field="field" />
                            <ArrayField v-if="field.type === 'array'" :field="field" />
                            <ColumnsField v-if="field.type === 'columns'" :field="field" />
                            <AddressField v-if="field.type === 'address'" :field="field" />
                            <FunctionField v-if="field.type === 'function'" :field="field" />
                            <div style="height: 16px">&nbsp;</div>
                        </div>
                    </q-scroll-area>
                </div>
            </template>
            <template #actions>
                <q-btn flat label="OK" @click="emit('ok')" color="false" />
            </template>
        </BaseDialog>
    </q-dialog>
</template>

<style lang="scss" scoped></style>
