import { Element } from './Element'

export class Footer {
  id: string
  elements: Element[]

  constructor() {
    this.id = ''
    this.elements = new Array()
  }
}
