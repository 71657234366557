<script setup lang="ts">
import ClientsTable from '@/components/clients/ClientsTable.vue'
import { useStore } from '@/store/store'
import { isMobile } from '@/utils/detectMobile'

const store = useStore()

if (isMobile()) {
  store.commit('setPageTitle', 'Clients')
} else {
  store.commit('setPageTitle', 'Gestion des clients')
}

function myTweak(offset, height) {
  // console.log("myTweak", offset, height);
  return { minHeight: offset ? `calc(100vh - ${offset}px - 10px)` : '100vh' }
}
</script>

<template>
  <q-page padding class="page" v-if="!isMobile()" :style-fn="myTweak" style="">
    <!-- border: 0px solid transparent; -->
    <!-- <q-scroll-area style="height: 100%; width: 100%;"> -->
    <h5>Clients</h5>
    <ClientsTable />
    <!-- </q-scroll-area> -->
  </q-page>
  <q-page class="page-mobile" v-if="isMobile()">
    <!-- <h5>Clients</h5> -->
    <ClientsTable disable_add disable_actions />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}
</style>
