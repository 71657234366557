function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

let CPAMs = [
  {
    id: createUUID(),
    label: "CPAM de l'Ain",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Ain",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de l'Aisne",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Aisne",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de l'Allier",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Allier",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM des Alpes de Haute Provence',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM des Alpes de Haute Provence',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM des Hautes Alpes',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM des Hautes Alpes',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de d'Ardèche",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Ardèche",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM des Ardennes',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM des Ardennes',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de d'Ariège",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Ariège",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de l'Aube",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Aube",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de l'Aude",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Aude",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM du Var',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM du Var',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM de d'Aveyron",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM de l'Aveyron",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM du Calvados',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM du Calvados',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM de Paris',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM de Paris',
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: "CPAM d'Ile-De-France",
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: "CPAM d'Ile-de-France",
      },
    ],
    children: [],
  },

  {
    id: createUUID(),
    label: 'CPAM du Nord',
    type: 'CPAM',
    fields: [
      {
        label: 'Nom',
        value: 'CPAM du Nord',
      },
    ],
    children: [],
  },
]

export { CPAMs }
