<script async setup lang="ts">
import { useStore } from '@/store/store'
import ProjectsEditor from './ProjectsEditor.vue'

const store = useStore()
store.commit('setPageTitle', 'Gestion des modèles d\'opérations juridiques')

function myTweak(offset) {
  console.log('myTweak', offset)
  return { minHeight: offset ? `calc(100vh - ${offset}px - 40px - 16px)` : '100vh' }
}
</script>

<template>
  <q-page class="page" :style-fn="myTweak">
    <Suspense>
      <ProjectsEditor />
      <template #fallback>
        <q-inner-loading showing color="primary" label="Chargement en cours..." />
      </template>
    </Suspense>
  </q-page>
</template>

<style scoped lang="scss">
.text-h4 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
  cursor: grab;
}
</style>
