import { Serializable } from '../../../Serializable'

export class Alternative implements Serializable<Alternative> {
  label: string
  variableId: string
  operator: string
  variableValue: string

  constructor() {
    this.label = ''
    this.variableId = ''
    this.operator = ''
    this.variableValue = ''
  }

  deserialize(input: Object): Alternative {
    this.label = input.label
    this.variableId = input.variableId
    this.operator = input.operator
    this.variableValue = input.variableValue

    return this
  }
}
