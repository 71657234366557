<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue: string | boolean | null | []
  options: any[]
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (value: any | undefined) => {
    emit('update:modelValue', value)
  },
})

// if (props.modelValue === null) {
//   console.log("Found undefined boolean value. Set to false by default.")
//   value.value = false
// }
</script>

<template>
  <q-select outlined v-model="value" v-bind="$attrs" :options="options" label="Choisissez une option">
    <template #before-options>
      <slot name="before-options"></slot>
    </template>
  </q-select>
</template>

<style lang="scss" scoped></style>
