export async function toggleFavorite(userApi, favorites, type, object) {
  console.log('toggle favorite', favorites, type, object)

  let favoriteList
  let contentTypeId

  if (type == 'client') {
    favoriteList = favorites.value.clients
    contentTypeId = 9
  } else if (type == 'user_project') {
    favoriteList = favorites.value.userProjects
    contentTypeId = 17
  }

  const selectedFavorite = favoriteList.find((favorite) => {
    if (object.id == favorite.objectId) {
      return true
    }
    return false
  })

  if (selectedFavorite) {
    console.log('removing favorite')
    favoriteList = favoriteList.filter((favorite) => favorite.objectId != selectedFavorite.objectId)
    if (type == 'client') {
      favorites.value.clients = favoriteList
    } else if (type == 'user_project') {
      favorites.value.userProjects = favoriteList
    }
    await userApi.unsetFavorite({
      contentTypeId: contentTypeId,
      objectId: object.id,
    })
    return Promise.resolve(false)
  } else {
    console.log('adding favorite')
    favoriteList.push({
      id: 42,
      objectId: object.id,
      name: object.name,
      order: 0,
    })

    favoriteList.sort((a, b) => {
      return a.name < b.name ? -1 : 1
    })

    console.log('favoriteList', favoriteList)
    await userApi.setFavorite({
      contentTypeId: contentTypeId,
      objectId: object.id,
      name: object.name,
      order: 0,
    })
    return Promise.resolve(true)
  }
}
