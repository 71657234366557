<script setup lang="ts">
import { ref, computed } from 'vue'
import { useDialogPluginComponent, QTableProps, Dialog } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import PaymentMethodAddEditForm from './PaymentMethodAddEditForm.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

interface Props {
  addTitle: string
  editTitle: string
  addConfirmLabel: string
  editConfirmLabel: string
  selectedRows: any[]
  mode: string
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()

const monthSubs = ref([])
const yearSubs = ref([])
const selectedSubscription = ref(undefined)
const selectedMonthSubscription = ref(undefined)
const selectedYearSubscription = ref(undefined)
const selectedPaymentMethodForPurchase = ref(undefined)
const paymentMethods = ref([])
const tab = ref('month')

const subscriptionColumns: QTableProps['columns'] = [
  {
    name: 'credits',
    label: 'Crédits dans le pack',
    field: 'credits',
    sortable: true,
    align: 'left',
  },
  {
    name: 'prix',
    label: 'Prix HT',
    field: 'price',
    sortable: true,
    align: 'left',
    format: (val, row) => Number(parseFloat(val)).toFixed(0) + ' €',
  },
]

const title = computed(() => {
  if (props.mode === 'ADD') {
    return props.addTitle
  } else {
    return props.editTitle
  }
})

const confirmLabel = computed(() => {
  if (props.mode === 'ADD') {
    return props.addConfirmLabel
  } else {
    return props.editConfirmLabel
  }
})

function beforeShow() {
  userApi.getPaymentMethods().then((response) => {
    paymentMethods.value = response.results
  })

  userApi.getSubscriptions().then((response) => {
    monthSubs.value = response.results.filter((item) => item.periodicity == 'MONTH')
    yearSubs.value = response.results.filter((item) => item.periodicity == 'YEAR')
  })
  if (props.mode === 'ADD') {
    selectedSubscription.value = undefined
    selectedMonthSubscription.value = undefined
    selectedYearSubscription.value = undefined
    selectedPaymentMethodForPurchase.value = undefined
  } else {
    const selectedItem = props.selectedRows[0]
    if (selectedItem === undefined) {
      return
    }
    selectedSubscription.value = selectedItem.selected_subscription
    selectedMonthSubscription.value = selectedItem.selected_month_subscription
    selectedYearSubscription.value = selectedItem.selected_year_subscription
    selectedPaymentMethodForPurchase.value = selectedItem.selected_payment_method_for_purchase
  }
}

function createItem() {
  let selectedSubscription
  if (tab.value === 'month') {
    selectedSubscription = selectedMonthSubscription.value[0]
  } else {
    selectedSubscription = selectedYearSubscription.value[0]
  }

  userApi
    .purchaseSubscription(selectedSubscription.id, {
      payment_method_id: selectedPaymentMethodForPurchase.value.id,
    })
    .then((result) => {
      emit('ok')
    })
}

function onConfirmClick() {
  if (props.mode === 'ADD') {
    createItem()
  }
}

function showPaymentForm() {
  const dialog = Dialog.create({
    component: PaymentMethodAddEditForm,
    componentProps: {
      mode: 'ADD',
      addTitle: 'Nouveau moyen de paiement',
      editTitle: 'Modifier le moyen de paiement',
      addConfirmLabel: 'Ajouter',
      editConfirmLabel: 'Modifier',
    },
  })
    .onOk(() => {
      userApi.getPaymentMethods().then((response) => {
        paymentMethods.value = response.results
      })
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="month" label="Mensuel" />
          <q-tab name="year" label="Annuel" />
        </q-tabs>

        <q-separator />

        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="month">
            <div class="q-pa-md">
              <q-table
                :rows="monthSubs"
                :columns="subscriptionColumns"
                hide-bottom
                row-key="id"
                selection="single"
                v-model:selected="selectedMonthSubscription"
                flat
                bordered
              />
            </div>
          </q-tab-panel>

          <q-tab-panel name="year">
            <div class="q-pa-md">
              <q-table
                :rows="yearSubs"
                :columns="subscriptionColumns"
                hide-bottom
                row-key="id"
                selection="single"
                flat
                bordered
                v-model:selected="selectedYearSubscription"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
        <q-separator class="q-mt-md q-mb-md"></q-separator>
        <BaseSelect
          v-model="selectedPaymentMethodForPurchase"
          :options="paymentMethods"
          outlined
          option-value="id"
          :option-label="
            (item) =>
              item === null
                ? 'Aucune carte sélectionnée'
                : `${item.nameOnCard}   **** **** **** ${item.cardLast4Digits}   ${item.expiration}`
          "
          label="Moyen de paiement"
        >
          <template #before-options>
            <q-btn
              align="left"
              flat
              icon="add"
              class="full-width q-pt-md q-pb-sm"
              label="Ajouter un moyen de paiement"
              @click="showPaymentForm"
            ></q-btn>
          </template>
        </BaseSelect>
        <PaymentMethodAddEditForm
          add-title="Nouveau moyen de paiement"
          edit-title="Modifier le moyen de paiement"
          add-confirm-label="Ajouter"
          edit-confirm-label="Modifier"
          :selected-row="selectedRows"
          @add-item-click="onAddPaymentClick"
          @edit-item-click="onEditPaymentClick"
        ></PaymentMethodAddEditForm>
      </template>
      <template #actions>
        <q-btn flat :label="confirmLabel" color="primary" v-close-popup @click="onConfirmClick" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
