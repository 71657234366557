<script setup lang="ts">
import { provide, watch, ref } from 'vue'
import { useStore } from '@/store/store'
import { useRoute } from 'vue-router'
import ProjectExecution from './ProjectExecution.vue'

const store = useStore()

store.commit('setPageTitle', 'Dossier')

const route = useRoute()

// These props are set by the router
interface Props {
  documentId?: string | null | undefined
  taskId?: string | null | undefined
  lateralTab?: string | null | undefined
}

const props = withDefaults(defineProps<Props>(), {})

console.log('Project page mobile - documentId: ', props.documentId, ' - taskId: ', props.taskId)

const documentId = ref(props.documentId)
const taskId = ref(props.taskId)
const lateralTab = ref(props.lateralTab)

provide('documentId', props.documentId)
provide('taskId', props.taskId)
provide('lateralTab', props.lateralTab)

watch(
  () => props.documentId,
  () => {
    console.log(
      'Project page - documentId: ',
      props.documentId,
      ' - taskId: ',
      props.taskId,
      '- lateralTab: ',
      props.lateralTab,
    )
    documentId.value = props.documentId
  },
)

watch(
  () => props.taskId,
  () => {
    console.log(
      'Project page - documentId: ',
      props.documentId,
      ' - taskId: ',
      props.taskId,
      '- lateralTab: ',
      props.lateralTab,
    )
    taskId.value = props.taskId
  },
)

watch(
  () => props.lateralTab,
  () => {
    console.log(
      'Project page - documentId: ',
      props.documentId,
      ' - taskId: ',
      props.taskId,
      '- lateralTab: ',
      props.lateralTab,
    )
    lateralTab.value = props.lateralTab
  },
)
</script>

<template>
  <q-page class="full-height">
    <Suspense>
      <template #default>
        <div>
          <ProjectExecution :project-id="route.params.projectId.toString()"> </ProjectExecution>
        </div>
      </template>
      <template #fallback>
        <div class="bg-transparent">
          <div class="row items-center" style="height: 74px">
            <q-skeleton animation="blink" type="text" class="q-ml-md" style="width: 30%; height: 42px" />
            <q-skeleton class="q-ml-sm" type="circle" size="32px"> </q-skeleton>
            <q-skeleton class="q-ml-sm" type="circle" size="32px"> </q-skeleton>
          </div>
          <div class="row items-center q-mb-sm">
            <q-skeleton type="QBtn" class="q-ml-md" style="width: 150px" />
            <q-skeleton type="QBtn" class="q-ml-sm" style="width: 150px" />
            <q-skeleton class="q-ml-sm" type="circle" size="32px"> </q-skeleton>
            <q-skeleton class="q-ml-sm" type="circle" size="32px"> </q-skeleton>
          </div>
          <q-separator />
          <div class="full-width row justify-center items-center" style="height: 70vh">
            <q-spinner size="50px" color="grey" />
          </div>
        </div>
      </template>
    </Suspense>
  </q-page>
</template>

<style lang="scss" scoped>
.page-mobile {
  padding: 0px;
}
</style>
