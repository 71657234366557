<script lang="ts" setup>
import { ref, inject, Ref } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const choices = ref([
  {
    value: '',
  },
  {
    value: '',
  },
])

function addChoice() {
  choices.value.push({ value: '' })
}

function removeChoice(index: number): void {
  choices.value.splice(index, 1)
}
</script>
<template>
  <div v-for="(choice, index) in choices" class="row">
    <BaseInput v-model="choice.value" :label="`Élement ${index + 1}`" dense class="q-my-sm" />
    <div class="flex items-center">
      <q-btn
        :disable="index <= 1"
        outline
        rounded
        color="negative"
        padding="sm"
        icon="delete"
        @click="removeChoice(index)"
        class="q-ml-sm"
      />
    </div>
  </div>
  <q-btn
    color="primary"
    class="q-my-md"
    @click="addChoice"
    outline
    round
    dense
    icon="add"
    style="width: fit-content; margin-left: 85px"
  />
</template>
<style lang="scss" scoped></style>
