<script lang="ts" setup>
import { ref } from 'vue'

interface Props {
    element: any
    treeRef: any
    showPermissions: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['update:view', 'update:edit'])

function onViewUpdate() {
    emit('update:view', props.element.view)
}

function onEditUpdate() {
    emit('update:edit', props.element.edit)
}

const readOptions = [
    { label: "Aucun", value: "none" },
    { label: "Messages personnels", value: "personal" },
    { label: "Tous les messages", value: "all" },
]

const readModel = ref()
</script>

<template>
    <div v-if="!showPermissions" class="title">
        Messages
    </div>

    <div v-if="showPermissions"
        class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node"
        style="border-bottom: 0.5px solid; border-bottom-color: #9e9e9e;">

        <div class="col-shrink title" style="overflow: hidden">
            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                Messages
            </div>
        </div>

        <!-- space filler -->
        <div class="col" style="overflow: hidden"></div>

        <div class="col-auto" style="overflow: hidden">
            <span class="row items-center" style="text-align: center; line-height: 14px">
                <span class="column items-center" style="width: 150px">
                    <span class="col">Envoyer</span>
                    <span class="col">
                        <q-checkbox v-model="element.edit" @update:model-value="onViewUpdate"></q-checkbox>
                    </span>
                </span>

                <span class="column items-center" style="width: 200px;">
                    <span class="col">Lire</span>
                    <span class="col">
                        <!-- <q-checkbox v-model="element.view" @update:model-value="onViewUpdate"></q-checkbox> -->
                        <q-select v-model="readModel" :options="readOptions" outlined dense
                            style="width: 200px; margin-top: 8px; margin-bottom: 8px;">
                        </q-select>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.title {
    font-size: 1rem;
    font-weight: bold;
    // margin-bottom: 1rem;
}
</style>
