<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useBrowserLocation } from '@vueuse/core'

import SubProjectsTable from '@/components/projects/management/SubProjectsTable.vue'
import ProjectsTable from '@/components/projects/management/ProjectsTable.vue'
import TasksTable from '@/components/projects/management/TasksTable.vue'
import ClientsTable from '@/components/clients/ClientsTable.vue'

const store = useStore()

store.commit('setPageTitle', 'Accueil')

const route = useRoute()
const router = useRouter()
const authenticatedUser = useAuthenticatedUser()
const location = useBrowserLocation()

interface Props {
  switchToEnvironment?: string
}

const props = withDefaults(defineProps<Props>(), {
  switchToEnvironment: "",
})

function myTweak(offset) {
  console.log('myTweak', offset)
  return { minHeight: offset ? `calc(100vh - ${offset}px -10px)` : '100vh' }
}

if (props.switchToEnvironment != "") {
  console.log('switchToEnvironment', props.switchToEnvironment)
  // store.commit('setPageTitle', 'Switching environment')
  authenticatedUser.environments.forEach((environment) => {
    if (environment.id == props.switchToEnvironment) {
      authenticatedUser.portal = environment.portalType
      if (environment.portalType == 'CLIENT') {
        authenticatedUser.clientId = environment.client.id
        authenticatedUser.client = environment.client
      } else {
        authenticatedUser.clientId = null
        authenticatedUser.client = null
      }

      authenticatedUser.environment = environment

      store.commit('setUser', authenticatedUser)
      router.push({ name: 'dashboard/main' })
      window.location = location.value.origin
    }
  })
}

</script>

<template>
  <q-page padding class="page" :style-fn="myTweak">

    <h5 style="margin-top: 30px">Mes opérations juridiques</h5>
    <SubProjectsTable />

    <h5 style="margin-top: 30px">Mes dossiers</h5>
    <Suspense>
      <ProjectsTable show_client filter="user" selection="none" />
    </Suspense>

    <h5 style="margin-top: 20px">Mes tâches</h5>
    <Suspense>
      <TasksTable disable_add disable_actions selection="none" />
    </Suspense>

    <h5 style="margin-top: 30px">Mes clients</h5>
    <Suspense>
      <ClientsTable disable_add disable_actions show_client filter="user" selection="none" />
    </Suspense>

  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

h4 {
  font-size: 28px;
}
</style>
