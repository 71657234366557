<script setup lang="ts">
import { ref, computed } from 'vue'
import { Document } from '@/models/v2/document/Document'
import { Section } from '@/models/v2/document/Section'
import { HeadingBlock } from '@/models/v2/document/elements/content/block/HeadingBlock'
import { ParagraphBlock } from '@/models/v2/document/elements/content/block/ParagraphBlock'
import { ListBlock } from '@/models/v2/document/elements/content/block/ListBlock'
import { TableBlock } from '@/models/v2/document/elements/content/block/TableBlock'
import { ConditionBlock } from '@/models/v2/document/elements/control/block/ConditionBlock'
import { AddParagraphBlock } from '@/models/v2/document/elements/Action'
import draggable from 'vuedraggable'

interface Props {
  document: Document
  headings: []
  showTitle: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const buttons = ref([
  {
    id: 'Condition_1',
    type: 'ConditionBlock',
    elements: [],
    name: 'Condition',

    label: 'condition',
    conditions: [{}],
  },
  {
    id: 'Alternative_1',
    type: 'AlternativeBlock',
    elements: [],
    name: 'Alternative',
    alternative: {
      label: 'Alternative',
    },
  },
  {
    id: 'Loop_1',
    type: 'LoopBlock',
    elements: [],
    name: 'Boucle',
  },
  {
    id: 'Header_1',
    type: 'HeadingBlock',
    elements: [],
    content: 'Titre',
    name: 'Titre',
  },
  {
    id: 'Paragraph_1',
    type: 'ParagraphBlock',
    blockType: AddParagraphBlock,
    elements: [],
    content: 'Paragraph 1',
    name: 'Paragraphe',
  },
  {
    id: 'Array_1',
    type: 'TableBlock',
    elements: [],
    content: [
      ['', ''],
      ['', ''],
    ],
    name: 'Tableau',
  },
  {
    id: 'Image_1',
    type: 'ImageBlock',
    elements: [],
    content: 'Image 1',
    name: 'Image',
  },
  {
    id: 'List_1',
    type: 'ListBlock',
    elements: [],
    content: '<li></li>',
    showNumbers: false,
    name: 'Liste',
  },
  {
    id: 'List_2',
    type: 'ListBlock',
    elements: [],
    content: '<li></li>',
    showNumbers: true,
    name: 'Liste numérotée',
  },
])

const disabledBlocks = ref([
  {
    id: 'Image_1',
    type: 'ImageBlock',
    elements: [],
    content: 'Image 1',
    name: 'Image',
  },
  {
    id: 'Page_Break_1',
    type: 'PageBreakBlock',
    elements: [],
    content: 'PageBreak 1',
    name: 'Saut de page',
  },
  {
    id: 'Section_Break_1',
    type: 'SectionBreakBlock',
    elements: [],
    content: 'SectionBreak 1',
    name: 'Saut de section',
  },
])

const inlineButtons = ref([
  {
    id: 'Variable_1',
    type: 'VariableInline',
    elements: [],
    name: 'Variable',
  },
])

const disabledInline = ref([
  {
    id: 'Condition_1',
    type: 'ConditionInline',
    elements: [],
    name: 'Condition',
    condition: {
      label: 'condition',
    },
  },
  {
    id: 'Alternative_1',
    type: 'AlternativeInline',
    elements: [],
    name: 'Alternative',
    alternative: {
      label: 'Alternative',
    },
  },
  {
    id: 'Loop_1',
    type: 'LoopInline',
    elements: [],
    name: 'Boucle',
  },
  {
    id: 'Footnote_1',
    type: 'FootnoteInline',
    name: 'Footnote',
  },
  {
    id: 'Reference_1',
    type: 'ReferenceInline',
    name: 'Référence',
  },
  {
    id: 'HeShe_1',
    type: 'HeSheInline',
    name: 'Il/Elle',
  },
])

function addSectionButtonClick() {
  const section = new Section()
  section.deserialize({
    id: 'Section_2',
    header: {
      id: 'Header_2',
      elements: [],
    },
    footer: {
      id: 'Footer_2',
      elements: [],
    },
    elements: [
      {
        id: 'Heading_2',
        type: 'HeadingBlock',
        content: 'Titre 2',
        elements: [],
      },
      {
        id: 'Paragraph_2',
        type: 'ParagraphBlock',
        content: 'Paragraph 2',
        elements: [],
      },
    ],
  })
  props.document.appendSection(section)
}

function addHeadingBlockButtonClick() {
  const heading = new HeadingBlock()
  heading.deserialize({
    id: 'Heading_X',
    type: 'HeadingBlock',
    content: 'Titre ajouté',
    elements: [],
  })
  props.document.sections[0].appendElement(heading)
}

function addParagraphBlockButtonClick() {
  const paragraph = new ParagraphBlock()
  paragraph.deserialize({
    id: 'Paragraph_X',
    type: 'ParagraphBlock',
    content: 'Paragraphe ajouté',
    elements: [],
  })
  props.document.sections[0].appendElement(paragraph)
}

function addTableBlockButtonClick() {
  const table = new TableBlock()
  table.deserialize({
    id: 'Table_X',
    type: 'TableBlock',
    content: 'Table ajouté',
    elements: [],
  })
  props.document.sections[0].appendElement(table)
}

function addImageBlockButtonClick() {
  const image = new ImageBlock()
  image.deserialize({
    id: 'Image_X',
    type: 'ImageBlock',
    content: 'Image ajouté',
    elements: [],
  })
  props.document.sections[0].appendElement(image)
}

function addListBlockButtonClick() {
  const list = new ListBlock()
  list.deserialize({
    id: 'List_X',
    type: 'ListBlock',
    content: 'List ajouté',
    elements: [],
  })
  props.document.sections[0].appendElement(list)
}

function addConditionBlockButtonClick() {
  const condition = new ConditionBlock()
  condition.deserialize({
    id: 'Condition_X',
    type: 'ConditionBlock',
    label: 'Le salarié est à temps plein',
    conditions: [
      {
        variableId: '1',
        operator: 'equals',
        variableValue: 'Temps plein',
      },
    ],
    elements: [],
  })
  props.document.sections[0].appendElement(condition)
}

function getCardClass(type: string): string {
  if (type === 'ConditionBlock' || type === 'AlternativeBlock' || type === 'LoopBlock') {
    return 'block drag-cursor'
  } else {
    return 'block-content drag-cursor'
  }
}

function getInlineCardClass(type: string): string {
  if (type === 'ConditionInline' || type === 'AlternativeInline' || type === 'LoopInline') {
    return 'inline drag-cursor'
  } else {
    return 'inline-content drag-cursor'
  }
}
</script>

<template>
  <div class="elements-menu">
    <q-scroll-area style="height: 93.6vh; width: 100%; padding-right: 16px" ref="scrollAreaRef">
      <q-list dense class="">
        <q-item-label header>Bloc</q-item-label>
        <draggable
          :list="buttons"
          :group="{ name: 'g1', pull: 'clone', put: false }"
          ghost-class="ghost"
          item-key="name"
          class="q-gutter-sm"
        >
          <template #item="{ element, index }">
            <q-card flat bordered :class="getCardClass(element.type)">
              <q-card-section class="q-pa-sm">
                {{ element.name }}
              </q-card-section>
            </q-card>
          </template>
        </draggable>

        <div class="q-gutter-sm q-pt-sm">
          <q-card v-for="block in disabledBlocks" flat bordered disabled :class="getCardClass(block.type)">
            <q-card-section class="q-pa-sm">{{ block.name }} </q-card-section>
          </q-card>
        </div>
      </q-list>
      <q-list dense class="q-pt-md q-mr-md">
        <q-item-label header>En ligne</q-item-label>
        <draggable
          :list="inlineButtons"
          :group="{ name: 'g1', pull: 'clone', put: false }"
          ghost-class="ghost"
          item-key="name"
          class="q-gutter-sm"
        >
          <template #item="{ element, index }">
            <q-card flat bordered :class="getInlineCardClass(element.type)">
              <q-card-section class="q-pa-sm">{{ element.name }} </q-card-section>
            </q-card>
          </template>
        </draggable>

        <div class="q-gutter-sm q-pt-sm">
          <q-card v-for="inline in disabledInline" flat bordered disabled :class="getInlineCardClass(inline.type)">
            <q-card-section class="q-pa-sm">{{ inline.name }} </q-card-section>
          </q-card>
        </div>
      </q-list>
    </q-scroll-area>
  </div>
  <div class="elements-menu q-pt-md" v-if="showTitle">
    <q-list dense>
      <q-item v-for="heading in headings" clickable :class="`heading ${heading.size}`">
        {{ heading.content }}
      </q-item>
    </q-list>
  </div>
</template>

<style lang="scss" scoped>
.elements-menu {
  width: 200px;
}

.inline-content {
  border-bottom-color: #b0e2f2;
  border-bottom-width: 3px;
}

.inline {
  border-bottom-color: #e2cb9d;
  border-bottom-width: 3px;
}

.drag-cursor {
  cursor: grab;
}

.heading.h1 {
  padding-left: 1px;
}

.heading.h2 {
  padding-left: 5px;
}

.heading.h3 {
  padding-left: 9px;
}

.heading.h4 {
  padding-left: 13px;
}

.heading.h5 {
  padding-left: 17px;
}

.heading.h6 {
  padding-left: 21px;
}
</style>
