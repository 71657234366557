export enum NodeType {
  Folder = 'FOLDER',
  Filter = 'FILTER',
  Object = 'OBJECT',
  FieldLink = 'FIELD_LINK',
  Register = 'REGISTER',
  GdprRegisters = 'GDPR_REGISTERS',
  GdprTreatmentsRegister = 'GDPR_TREATMENT_REGISTER',
  GdprRequestsRegister = 'GDPR_REQUESTS_REGISTER',
  GdprViolationsRegister = 'GDPR_VIOLATIONS_REGISTER',
  GdprSubContractorssRegister = 'GDPR_SUB_CONTRACTORS_REGISTER',
}
