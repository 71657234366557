<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '@/store/store'
// import * as pdfjsLib from "pdfjs-dist/build/pdf";
// import * as worker from 'pdfjs-dist/build/pdf.worker.js';

interface Props {
  pdf?: any
}
const props = withDefaults(defineProps<Props>(), {})

const url = 'https://www.orimi.com/pdf-test.pdf'

const store = useStore()
store.commit('setPageTitle', 'PoC v2 - PDF')

// If absolute URL from the remote server is provided, configure the CORS
// header on that server.
// var url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf';

// Loaded via <script> tag, create shortcut to access PDF.js exports.
// var pdfjsLib = window['pdfjs-dist/build/pdf'];

// The workerSrc property shall be specified.
pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js'

let canvas
let ctx
var pdfDoc = null,
  pageNum = 1,
  pageRendering = false,
  pageNumPending = null,
  scale = 0.8
/**
 * Get page info from document, resize canvas accordingly, and render page.
 * @param num Page number.
 */
function renderPage(num) {
  pageRendering = true
  // Using promise to fetch the page
  pdfDoc.getPage(num).then(function (page) {
    var viewport = page.getViewport({ scale: scale })
    canvas.height = 1000
    canvas.width = 1500

    // Render PDF page into canvas context
    var renderContext = {
      canvasContext: ctx,
      viewport: viewport,
    }
    var renderTask = page.render(renderContext)

    // Wait for rendering to finish
    renderTask.promise.then(function () {
      pageRendering = false
      if (pageNumPending !== null) {
        // New page rendering is pending
        renderPage(pageNumPending)
        pageNumPending = null
      }
    })
  })

  // Update page counters
  document.getElementById('page_num').textContent = num
}

/**
 * If another page rendering in progress, waits until the rendering is
 * finised. Otherwise, executes rendering immediately.
 */
function queueRenderPage(num) {
  if (pageRendering) {
    pageNumPending = num
  } else {
    renderPage(num)
  }
}

/**
 * Displays previous page.
 */
function onPrevPage() {
  if (pageNum <= 1) {
    return
  }
  pageNum--
  queueRenderPage(pageNum)
}

/**
 * Displays next page.
 */
function onNextPage() {
  if (pageNum >= pdfDoc.numPages) {
    return
  }
  pageNum++
  queueRenderPage(pageNum)
}

/**
 * Asynchronously downloads PDF.
 */
pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
  pdfDoc = pdfDoc_
  document.getElementById('page_count').textContent = pdfDoc.numPages

  // Initial/first page rendering
  renderPage(pageNum)
})

onMounted(() => {
  canvas = document.getElementById('the-canvas')
  ctx = canvas.getContext('2d')
  canvas.width = 1000
  canvas.height = 1000

  document.getElementById('prev').addEventListener('click', onPrevPage)
  document.getElementById('next').addEventListener('click', onNextPage)
})
</script>

<template>
  <q-page padding class="page">
    hello world
    <div>
      <button id="prev">Previous</button>
      <button id="next">Next</button>
      &nbsp; &nbsp;
      <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
    </div>

    <canvas id="the-canvas"></canvas>
    <!-- <embed src="/Formulaire_M3.pdf" width="100%" height="1000px;" type="application/pdf"> -->
    <!-- <object data="/Formulaire_M3.pdf" type="application/pdf" width="100%" height="1000px">
        <div>No online PDF viewer installed</div>
    </object> -->
  </q-page>
</template>
<style lang="scss" scoped>
#the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>
