<script lang="ts" setup>
import { ref, inject, Ref, computed } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const objectModelDatabase = ref('Personne physique')
const dataBaseOptions = ['Personne physique', 'Personne morale', 'SAS']

const mappingType = ref('Simple')
const mappingTypeOptions = ['Simple', 'Adresse', 'Liste choix simple', 'Liste choix multiple']

const email = ref('')

const objectModel = ref('')
const objectModelOptions = computed(() => {
  if (objectModelDatabase.value === 'Personne physique') {
    return [
      'Civilité',
      'Prénom',
      'Nom',
      'Email',
      'Date de naissance',
      'Ville de naissance',
      'Nationalité',
      'Adresse',
      'Profession',
      'Numéro de sécurité sociale',
    ]
  } else if (objectModelDatabase.value === 'Personne morale') {
    return ['Dénomination sociale', 'Forme juridique', 'Adresse']
  } else if (objectModelDatabase.value === 'SAS') {
    return [
      'Statuts',
      'Commissaires aux comptes',
      'Baux',
      'Sociétés filles',
      'Actionnaires',
      'Membres du CSE',
      'Délégués du personnel',
      'Membres du conseil stratégique',
    ]
  }
})
const type = ref('Email')
const autoFilledText = ref('')

const name = ref('')
const objectModel2 = ref('')
const type2 = ref('Texte')
const autoFilledText2 = ref('')

const firstName = ref('')
const objectModel3 = ref('')
const type3 = ref('Texte')
const autoFilledText3 = ref('')
</script>
<template>
  <div class="row">
    <BaseSelect v-model="objectModelDatabase" :options="dataBaseOptions" emit-value map-options
      label="Modèle d'objet de la base de donnée" class="col-6 q-my-md q-pr-sm" />

    <BaseSelect v-model="mappingType" :options="mappingTypeOptions" emit-value map-options label="Type de mapping"
      class="col-6 q-my-md q-pl-sm" />
  </div>
  <div class="row justify-between">
    <BaseInput v-model="email" label="Email" dense />
    <BaseSelect v-model="objectModel" :options="objectModelOptions" emit-value map-options label="Modèle d'objet"
      class="col-4 q-pl-sm" dense />
    <BaseInput v-model="type" label="Email" disable dense />
    <BaseInput v-model="autoFilledText" label="Valeur pré-remplie" dense />
  </div>

  <div class="row justify-between">
    <BaseInput v-model="name" label="Nom" dense />
    <BaseSelect v-model="objectModel2" :options="objectModelOptions" emit-value map-options label="Modèle d'objet"
      class="col-4 q-pl-sm" dense />
    <BaseInput v-model="type2" label="Texte" disable dense />
    <BaseInput v-model="autoFilledText2" label="Valeur pré-remplie" dense />
  </div>

  <div class="row justify-between">
    <BaseInput v-model="firstName" label="Prénom" dense />
    <BaseSelect v-model="objectModel3" :options="objectModelOptions" emit-value map-options label="Modèle d'objet"
      class="col-4 q-pl-sm" dense />
    <BaseInput v-model="type3" label="Texte" disable dense />
    <BaseInput v-model="autoFilledText3" label="Valeur pré-remplie" dense />
  </div>
</template>
<style lang="scss" scoped></style>
