<script setup lang="ts">
import { useApi } from '@/store/useAppStore'
import { ref } from 'vue'
import { QTableProps } from 'quasar'
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Plateformes intermédiaires')

const userApi = useApi()

const columns: QTableProps['columns'] = [{ name: 'name', label: 'Nom', field: 'name', sortable: true, align: 'left' }]

const environments = ref([])
const selectedEnvironments = ref([])

userApi.getAllEnvironments().then((response) => {
  environments.value = response.results
})

const getSelectedString = function () {
  return selectedEnvironments.value.length === 0
    ? ''
    : `${selectedEnvironments.value.length} plateforme${
        selectedEnvironments.value.length > 1 ? 's' : ''
      } sélectionné sur ${environments.length}`
}
</script>

<template>
  <q-page padding class="page">
    <div class="q-pa-md">
      <q-table
        :columns="columns"
        row-key="id"
        :rows="environments"
        selection="multiple"
        v-model:selected="selectedEnvironments"
        :selected-rows-label="getSelectedString"
      >
        <template v-slot:top-right>
          <q-btn color="primary" label="Créer une plateforme conseillers" @click="createEnvironment" />
        </template>
      </q-table>
    </div>
  </q-page>
</template>
