<script async setup lang="ts">
import { ref, watch } from 'vue'
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()

const userApi: UserApi = useApi()

interface Props {
  query: string
}

const props = defineProps<Props>()

store.commit('setPageTitle', 'Résultats de recherche')

let results
const clientRows = ref([])
const projectRows = ref([])
const documentRows = ref([])
const loading = ref(false)

// https://medium.com/js-dojo/watch-vue-route-params-query-with-composition-api-97b3c8c402e
watch(
  () => props.query,
  () => {
    updateResults()
  },
)

async function updateResults() {
  loading.value = true
  clientRows.value = []
  projectRows.value = []
  documentRows.value = []
  results = await userApi.search(route.query.q)
  // console.log(results)
  clientRows.value = results.clients.results
  projectRows.value = results.projects.results
  documentRows.value = results.projectDocuments.results
  loading.value = false
}

function formatProjectName(val, row) {
  // if (row.lopMatterId) {
  //     return row.name + " (LOP " + row.lopMatterId + ")";
  // }

  return row.name
}

function formatDocumentProjectName(val, row) {
  // if (row.userProject.lopMatterId) {
  //     return row.userProject.name + " (LOP " + row.userProject.lopMatterId + ")";
  // }

  return row.userProject.name
}

const clientColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
  },
])

const projectColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
    format: formatProjectName,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.client.name,
    sortable: true,
  },
])

const documentColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.userProject.client.name,
    sortable: true,
  },
  {
    name: 'dossier',
    required: false,
    label: 'Dossier',
    align: 'left',
    field: (row) => row.userProject.name,
    sortable: true,
    format: formatDocumentProjectName,
  },
])

function clientRowClicked(event, row) {
  console.log('client', row)
  store.state.currentClient = row
  authenticatedUser.clientId = row.id
  router.push({
    name: 'clients/client/projects',
    params: { clientId: row.id },
  })
}

function projectRowClicked(event, row) {
  console.log('project', row)
  store.state.user.clientId = row.clientId
  store.state.currentClient = row.client
  router.push({
    name: 'clients/client/projects/project',
    params: {
      clientId: row.clientId,
      projectId: row.id,
    },
  })
}

function documentRowClicked(event, row) {
  console.log('project document', row)
  store.state.user.clientId = row.userProject.client.id
  store.state.currentClient = row.userProject.client
  router.push({
    name: 'clients/client/projects/project',
    params: {
      clientId: row.userProject.client.id,
      projectId: row.userProject.id,
    },
    query: {
      documentId: row.id,
      // preview: true
    },
  })
}

updateResults()
</script>

<template>
  <q-page class="page">
    <h5>Clients</h5>
    <q-table
      grid
      flat
      dense
      hide-header
      :rows="clientRows"
      :columns="clientColumns"
      row-key="name"
      @row-click="clientRowClicked"
      no-data-label="Aucun résultat"
      :loading="loading"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => clientRowClicked($event, props.row)">
          {{ props.row.name }}
          <q-separator />
        </div>
      </template>
    </q-table>

    <h5>Dossiers</h5>
    <q-table
      grid
      flat
      dense
      hide-header
      :rows="projectRows"
      :columns="projectColumns"
      row-key="name"
      @row-click="projectRowClicked"
      no-data-label="Aucun résultat"
      :loading="loading"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => projectRowClicked($event, props.row)">
          {{ props.row.name }}
          <br />
          <div style="color: grey">Client : {{ props.row.client.name }}</div>
          <q-separator />
        </div>
      </template>
    </q-table>

    <h5>Documents</h5>
    <q-table
      grid
      flat
      dense
      hide-header
      :rows="documentRows"
      :columns="documentColumns"
      row-key="name"
      @row-click="documentRowClicked"
      no-data-label="Aucun résultat"
      :loading="loading"
    >
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => documentRowClicked($event, props.row)">
          {{ props.row.name }}
          <br />
          <div style="color: grey">Client : {{ props.row.userProject.client.name }}</div>
          <div style="color: grey">Dossier : {{ props.row.userProject.name }}</div>
          <q-separator />
        </div>
      </template>
    </q-table>
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  color: rgb(21, 84, 135);
  margin-top: 16px;
  margin-bottom: 6px;
}

h4 {
  font-size: 28px;
}

.page {
  padding-left: 0px;
  // margin: 0px;
}
</style>
