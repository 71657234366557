import { Serializable } from './Serializable'
import { Header } from './Header'
import { Footer } from './Footer'
import { Element } from './Element'
import { ElementUtils } from './ElementUtils'

export class Section implements Serializable<Section> {
  id: string
  header: Header
  footer: Footer
  elements: Element[]

  constructor() {
    this.id = ''
    this.header = new Header()
    this.footer = new Footer()
    this.elements = new Array()
  }

  deserialize(input: Object): Section {
    this.id = input.id
    this.elements = new Array()
    input.elements.forEach((element) => {
      const elementObject = ElementUtils.deserializeElement(element)
      this.elements.push(elementObject)
    })
    return this
  }

  public appendElement(element: Element) {
    this.elements.push(element)
  }

  public popElement(index: number) {
    this.elements.splice(index, 1)
  }
}
