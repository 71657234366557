<script setup lang="ts">
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { ref, Ref } from 'vue'
import { Notify } from 'quasar'
import UserApi from '@/services/api/core/UserApi'
import BaseFileInput from '@/components/base/BaseFileInput.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import draggable from 'vuedraggable'
import FileSaver from 'file-saver'

const store = useStore()
store.commit('setPageTitle', 'PoC v2 - Concat PDF')

const userApi: UserApi = useApi()
const denominationUniteLegale = ref('')
const siren = ref('')
const papersData = ref('')

function search() {
  const uri = encodeURI(denominationUniteLegale.value)
  const url = `https://api.insee.fr/entreprises/sirene/V3/siret?q=denominationUniteLegale%3A%22${uri}%22`

  fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer d9204e00-f3af-3380-95d2-4b4af2fb7bc7',
    },
  })
    .then((response) => {
      return response.text()
    })
    .then((data) => {
      console.log(data)
    })
}

function search2() {
  // 890202500
  fetch(`https://api.insee.fr/entreprises/sirene/V3/siren/${siren.value}`, {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer d9204e00-f3af-3380-95d2-4b4af2fb7bc7',
    },
  })
    .then((response) => {
      return response.text()
    })
    .then((data) => {
      console.log(data)
    })
}

function search3() {
  // 890202500
  fetch(
    `https://api.pappers.fr/v2/entreprise?siren=${siren.value}&api_token=d6801f18c44c607f57c5f32ce6d031e19253eea972139827`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer d9204e00-f3af-3380-95d2-4b4af2fb7bc7',
      },
    },
  )
    .then((response) => {
      return response.text()
    })
    .then((data) => {
      papersData.value = JSON.parse(data)
    })
}
</script>

<template>
  <q-page padding class="page">
    <div class="q-mt-xl">
      <h3>Test api sirène</h3>
      <div class="">
        <BaseInput v-model="denominationUniteLegale" label="denominationUniteLegale" class="" />
        <q-btn label="rechercher" @click="search" class="q-mt-md" />
      </div>
      <div class="q-mt-md">
        <BaseInput v-model="siren" label="siren" class="" />
        <q-btn label="rechercher" @click="search2" class="q-mt-md" />
      </div>
      <h3>test api papers</h3>
      <div class="q-mt-md">
        890202500
        <BaseInput v-model="siren" label="siren" class="" />
        <q-btn label="rechercher" @click="search3" class="q-mt-md" />
      </div>
      <div class="q-mt-md" v-if="papersData">
        <div v-for="r in papersData.representants">
          <h4>Représentants:</h4>
          <p>Qualité: {{ r.qualite }}</p>
          <p>date_prise_de_poste: {{ r.date_prise_de_poste }}</p>
          <p>nom: {{ r.nom }}</p>
          <p>prenom: {{ r.prenom }}</p>
          <hr />
        </div>
      </div>

      <div class="q-mt-md" v-if="papersData">
        <div v-for="r in papersData.beneficiaires_effectifs">
          <h4>beneficiaires effectifs:</h4>
          <p>type: {{ r.type }}</p>
          <p>nom: {{ r.nom }}</p>
          <p>prenom: {{ r.prenom }}</p>
          <p>pourcentage_parts: {{ r.pourcentage_parts }}</p>
          <p>pourcentage_votes: {{ r.pourcentage_votes }}</p>

          <hr />
        </div>
      </div>

      <div class="q-mt-md" v-if="papersData">
        <div v-for="r in papersData.depots_actes">
          <h4>Dépots actes:</h4>
          <p>date_depot: {{ r.date_depot }}</p>
          <p>nom_fichier_pdf: {{ r.nom_fichier_pdf }}</p>
          <div v-for="acte in r.actes">
            actes: <br />
            type: {{ acte.type }}<br />
            decision: {{ acte.decision }}<br />
            date_acte: {{ acte.date_acte }}<br />
          </div>

          <hr />
        </div>
      </div>

      <div class="q-mt-md" v-if="papersData">
        <div v-for="r in papersData.publications_bodacc">
          <h4>publications bodacc:</h4>
          <p>numero_parution: {{ r.numero_parution }}</p>
          <p>date: {{ r.date }}</p>
          <p>bodacc: {{ r.bodacc }}</p>
          <p>type: {{ r.type }}</p>
          <p>forme_juridique: {{ r.forme_juridique }}</p>
          <p>administration: {{ r.administration }}</p>
          <p>activite: {{ r.activite }}</p>

          <hr />
        </div>
      </div>
    </div>
  </q-page>
</template>
<style lang="scss" scoped></style>
