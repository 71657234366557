<script setup lang="ts">
import { ref, Ref, watch, computed, inject, provide } from 'vue'
import { Dialog, Notify } from 'quasar'

import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

import { Database } from '@/models/database/Database'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { FieldType } from '@/models/database/FieldType'
import { ObjectType } from '@/models/database/ObjectType'

import DeleteNodeDialog from './DeleteNodeDialog.vue'
import CopyNodeToClientDialog from './CopyNodeToClientDialog.vue'

import StringField from './object_fields/StringField.vue'
import FormattedTextField from './object_fields/FormattedTextField.vue'
import FileField from './object_fields/FileField.vue'
import DateField from './object_fields/DateField.vue'
import SelectField from './object_fields/SelectField.vue'
import ObjectField from './object_fields/ObjectField.vue'
import ObjectLinkField from './object_fields/ObjectLinkField.vue'
import ObjectListField from './object_fields/ObjectListField.vue'
import ProjectLinkField from './object_fields/ProjectLinkField.vue'
import ApiEntreriseDialog from './ApiEntrepriseDialog.vue'
import GdprRegistersNode from './nodes/GdprRegistersNode.vue'
import GdprTreatmentsRegisterNode from './nodes/GdprTreatmentRegisterNode.vue'
import GdprSubContractorsRegisterNode from './nodes/GdprSubContractorsRegisterNode.vue'
import GdprRequestsRegisterNode from './nodes/GdprRequestsRegisterNode.vue'
import GdprViolationsRegisterNode from './nodes/GdprViolationsRegisterNode.vue'

import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'

import { sas } from '@/models/database/node_types/SAS'
import { entity } from '@/models/database/node_types/Entity'
import { physicalPerson } from '@/models/database/node_types/PhysicalPerson'

interface Props {
  database: Database
  environmentDatabase: Array<Database>
  node: Ref<Node | null>
}

const props = defineProps<Props>()

const userApi: UserApi = useApi()
const authenticatedUser: AuthenticatedUser = useAuthenticatedUser()

const emit = defineEmits(['update:node', 'remove:node'])

const environmentDatabase = inject('environmentDatabase')
const inEnvironmentDatabase = inject('inEnvironmentDatabase')

const nodesHashMap = inject('nodesHashMap')

// const nodeRef = ref(props.node);

// watch(nodeRef, (node) => {
//     console.log("Node updated", node);
// })

// const node2 = computed({
//     get() {
//         return props.node
//     },
//     set(val) {
//         console.log("Node updated", val);
//         emit('update:node', val);
//     }
// })

watch(props, () => {
  // updateNode();
  updateFieldLink()
})

const linkedNode = ref()
const linkedNodeFields = ref([])

function updateFieldLink() {
  if (props.node?.type == NodeType.FieldLink) {
    linkedNode.value = findObjectById(props.database.nodes, props.node.objectLinkId)

    linkedNodeFields.value = []
    props.node.fieldLink.fields.forEach((linkedField) => {
      let field = findObjectField(linkedNode.value, linkedField)
      linkedNodeFields.value.push(field)
    })
  }
}

updateFieldLink()

function findObjectById(nodes, objectId) {
  let result = undefined

  nodes.forEach((node) => {
    if (node.id === objectId) {
      result = node
    }
    let childResult = findObjectById(node.children, objectId)
    if (childResult != undefined) {
      result = childResult
    }
    // node.children?.forEach((child) => {

    // })
  })
  return result
}

function findObjectField(object, fieldName) {
  let result = undefined

  object.fields.forEach((objectField) => {
    if (objectField.label == fieldName) {
      result = objectField
    }
  })

  return result
}

function findObjectType(objectTypeName): ObjectType {
  if (objectTypeName === 'SAS') {
    return sas
  }

  if (objectTypeName === 'Entité') {
    return entity
  }

  if (objectTypeName === 'Personne physique') {
    return physicalPerson
  }

  let result = null
  props.database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })

  return result
}

function onFieldChange(e) {
  if (props.node?.type === NodeType.Object) {
    let objectType = findObjectType(props.node?.objectType)
    objectType.onChange(props.node)
    // props.node.label = props.node?.fields[0].value + " " + props.node?.fields[1].value;
  }

  // emit('update:node', props.node);
}

const agRegisterColumns = [
  {
    name: 'date',
    required: true,
    label: 'Date',
    align: 'left',
    field: 'date',
    sortable: true,
  },
  {
    name: 'documents',
    align: 'left',
    label: 'Documents',
    field: 'documents',
    sortable: true,
  },
  {
    name: 'projet',
    align: 'left',
    label: 'Projet',
    field: 'projet',
    sortable: true,
  },
]

const rows = ref([])

const agRegisterRows = ref([])

function updateRows() {
  rows.value = []

  if (props.node?.objectType === 'Registre des sous-traitants') {
    const children = [
      {
        fields: [
          {
            name: 'name',
            value: 'Sous-traitant 1',
          },
          {
            name: 'finality',
            value: 'Finalité 1',
          },
        ],
      },
      {
        fields: [
          {
            name: 'name',
            value: 'Sous-traitant 2',
          },
          {
            name: 'finality',
            value: 'Finalité 2',
          },
        ],
      },
    ]

    children.forEach((child) => {
      rows.value.push({
        id: child.id,
        name: child.fields[0].value,
        finality: child.fields[1].value,
      })
    })
  }

  if (props.node?.objectType === 'Registre des violations') {
    const children = [
      {
        fields: [
          {
            name: 'date1',
            value: '01/04/2023',
          },
          {
            name: 'date2',
            value: '05/04/2023',
          },
          {
            name: 'nature',
            value: 'Nature 1',
          },
        ],
      },
      {
        fields: [
          {
            name: 'date1',
            value: '23/05/2023',
          },
          {
            name: 'date2',
            value: '23/05/2023',
          },
          {
            name: 'nature',
            value: 'Nature 2',
          },
        ],
      },
    ]

    children?.forEach((child) => {
      rows.value.push({
        id: child.id,
        date1: child.fields[0].value,
        date2: child.fields[1].value,
        nature: child.fields[2].value,
      })
    })
  }

  if (props.node?.objectType === 'Registre des traitements') {
    const children = [
      {
        fields: [
          {
            name: 'domain',
            value: 'Gestion des ressources humaines',
          },
          {
            name: 'treatment',
            value: "Gestion des formalités d'embauche",
          },
          {
            name: 'date',
            value: '01/04/2023',
          },
          {
            name: 'finality',
            value: 'Finalité 1',
          },
          {
            name: 'document',
            value:
              'http://localhost:4000/clients/3034ab86-fe2a-4068-b49b-c14d6b02fe00/projects/2a97c65d-0582-425c-bb54-f3c596a7a5e7?taskId=86008',
          },
        ],
      },
      {
        fields: [
          {
            name: 'domain',
            value: "Mesures de sécurité spécifiques à l'organisation",
          },
          {
            name: 'treatment',
            value: "Traitement de données spécifique à l'organisation",
          },
          {
            name: 'date',
            value: '02/04/2023',
          },
          {
            name: 'finality',
            value: 'Finalité 2',
          },
          {
            name: 'document',
            value:
              'http://localhost:4000/clients/3034ab86-fe2a-4068-b49b-c14d6b02fe00/projects/2a97c65d-0582-425c-bb54-f3c596a7a5e7?taskId=86006',
          },
        ],
      },
    ]

    children?.forEach((child) => {
      rows.value.push({
        id: child.id,
        domain: child.fields[0].value,
        treatment: child.fields[1].value,
        date: child.fields[2].value,
        finality: child.fields[3].value,
        document: child.fields[4].value,
      })
    })
  }

  return true
}

function onFieldChanged(node, field) {
  // emit("update:node");

  updateLabel(node)

  if (inEnvironmentDatabase == 'true') {
    let nodeClientId = node.clientId
    if (node.clientId === undefined || node.clientId === null) {
      const parentNode = nodesHashMap[node.parentId]
      if (parentNode !== undefined) {
        const grandParentNode = nodesHashMap[parentNode.parentId]
        if (grandParentNode !== undefined) {
          nodeClientId = grandParentNode.clientId
        }
      }
      // nodeClientId = props.database.clientId;
    }

    const clientDatabase = environmentDatabase.find((cc) => cc.clientId == nodeClientId)

    saveDatabase(clientDatabase.databaseJson, nodeClientId)
  } else {
    saveDatabase(props.database, undefined)
  }
}

function isMoralPerson() {
  if (props.node?.objectType === 'SAS' || props.node?.objectType === 'Entité') {
    return true
  }
  return false
}

function updateLabel(node) {
  let objectType = findObjectType(node.objectType)
  if (objectType == undefined) {
    return
  }
  if (objectType.name === 'Personne physique') {
    let firstName = ''
    let lastName = ''

    if (node.fields[1].value != undefined && node.fields[1].value != '') {
      firstName = node.fields[1].value
    }
    if (node.fields[2].value != undefined && node.fields[2].value != '') {
      lastName = node.fields[2].value
    }

    if (firstName != '' && lastName != '') {
      node.label = firstName + ' ' + lastName
    }
    if (firstName != '' && lastName == '') {
      node.label = firstName
    }
    if (firstName == '' && lastName != '') {
      node.label = lastName
    }
  } else {
    let name = ''
    if (node.fields[0].value != undefined && node.fields[0].value) {
      name = node.fields[0].value
    }
    if (name != '') {
      node.label = name
    }
  }
}

// function removeNodeFromParent(parent, node) {
//   node.children.forEach(child => {
//     removeNodeFromParent(child, node);
//   })
// }

function removeNode(node) {
  props.database.nodes[0].children = props.database.nodes[0].children?.filter((n) => n.id != node.id)
  props.database.nodes[1].children = props.database.nodes[1].children?.filter((n) => n.id != node.id)
  saveDatabase(props.database)

  emit('remove:node')
  // props.database.nodes.forEach((parent) => {
  //   removeNodeFromParent(parent, node);
  // });
}

function getClientForNode(node) {
  const clientDatabase = environmentDatabase.find((db) => db.clientId == node.clientId)

  return clientDatabase.clientName
}

async function saveDatabase(database, clientId) {
  let saveResult = await userApi.updateDatabase(database, clientId)

  if (inEnvironmentDatabase) {
    // const clientDb = environmentDatabase.find((cdb) => cdb.clientId == authenticatedUser.clientId)
    // clientDb.databaseJson = props.database
  } else {
    const clientDb = environmentDatabase.find((cdb) => cdb.clientId == authenticatedUser.clientId)
    if (clientDb != undefined) {
      clientDb.databaseJson = props.database
    }
  }
}

function updateFieldsOld(apiInfos) {
  const address = apiInfos['siege']['adresse_ligne_1']
  const ville = apiInfos['siege']['ville']
  const cp = apiInfos['siege']['code_postal']
  // const dateCreation = apiInfos['date_creation_formate'].replace("/", "-");

  const capitalSocial = apiInfos['capital']

  props.node.fields[2].value = capitalSocial
  // props.node.fields[3].value = dateCreation;
  props.node.fields[4].value.fields[0].value = address
  props.node.fields[4].value.fields[3].value = cp
  props.node.fields[4].value.fields[4].value = ville
}

// Fields list
function updateFields(rows) {
  if (rows[0].update) props.node.fields[0].value = rows[0].new_value // Dénom
  if (rows[1].update) props.node.fields[1].value = rows[1].new_value // Forme juridique
  if (rows[2].update) props.node.fields[2].value = rows[2].new_value // Capital social
  if (rows[3].update) props.node.fields[3].value = rows[3].new_value // Date création
  if (rows[4].update) props.node.fields[5].value = rows[4].new_value // Numéro immat
  if (rows[5].update) props.node.fields[6].value = rows[5].new_value // Ville immat
  if (rows[6].update) props.node.fields[4].value.fields[0].value = rows[6].new_value // Adresse / Numéro et rue
  if (rows[7].update) props.node.fields[4].value.fields[3].value = rows[7].new_value // Adresse / Code postal
  if (rows[8].update) props.node.fields[4].value.fields[4].value = rows[8].new_value // Adresse / Ville
}

function showApiEntrepriseDialog() {
  const dialog = Dialog.create({
    component: ApiEntreriseDialog,
    componentProps: {
      node: props.node,
    },
  })
    .onOk((rows) => {
      //   props.field.value = {
      //     fileId: uploadedFileInfos.id,
      //     filename: uploadedFileInfos.fileName,
      //   }
      //   emit('update:field', props.node, props.field);
      // updateFields(JSON.parse(apiInfos));
      updateFields(rows)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showCopyToClientDialog(node) {
  const dialog = Dialog.create({
    component: CopyNodeToClientDialog,
    componentProps: {},
  })
    .onOk(async (destinationClient) => {
      console.log('destinationClient', destinationClient)
      await userApi.databaseCopyNodeToClient(authenticatedUser.clientId, destinationClient.id, node.id, node.parentId)
      dialog.hide()
      Notify.create({
        message: 'Elément copié avec succès',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}


function showDeleteNodeDialog(node) {
  const dialog = Dialog.create({
    component: DeleteNodeDialog,
    componentProps: {},
  })
    .onOk(() => {
      removeNode(node)
      dialog.hide()
      Notify.create({
        message: 'Elément supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div v-if="node === null" style="padding: 30px">
    <div class="text-h6">Veuillez sélectionner un élément pour l'éditer.</div>
  </div>
  <div v-if="node !== null">
    <div v-if="node?.type === NodeType.Folder">
      <q-card flat bordered>
        <q-card-section>
          <div class="text-h6">Dossier</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input v-model="node.label" label="Nom" />
        </q-card-section>
      </q-card>
    </div>

    <div v-if="node?.type === NodeType.GdprRegisters">
      <!-- <Suspense> -->
      <GdprRegistersNode :database="database" :node="node"></GdprRegistersNode>
      <!-- <template #fallback>
          <q-card flat bordered>
            <q-card-section>
              <div class="text-h6">Registres RGPD</div>
            </q-card-section>

            <q-card-section class="q-pt-none">
              <q-inner-loading showing color="primary" label="Chargement en cours..." />
            </q-card-section>
          </q-card>

        </template>
      </Suspense> -->
    </div>

    <div v-if="node?.type === NodeType.GdprTreatmentsRegister">
      <GdprTreatmentsRegisterNode :database="database" :node="node"></GdprTreatmentsRegisterNode>
    </div>

    <div v-if="node?.type === NodeType.GdprSubContractorssRegister">
      <GdprSubContractorsRegisterNode :database="database" :node="node"></GdprSubContractorsRegisterNode>
    </div>

    <div v-if="node?.type === NodeType.GdprViolationsRegister">
      <GdprViolationsRegisterNode :database="database" :node="node"></GdprViolationsRegisterNode>
    </div>

    <div v-if="node?.type === NodeType.GdprRequestsRegister">
      <GdprRequestsRegisterNode :database="database" :node="node"></GdprRequestsRegisterNode>
    </div>

    <div v-if="node?.type === NodeType.Register">
      <q-card flat bordered>
        <q-card-section>
          <div class="text-h6">{{ node.label }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-table> </q-table>
        </q-card-section>
      </q-card>
    </div>

    <div v-if="node?.type === NodeType.Object">
      <div v-if="node.objectType === 'Registre des traitements' && updateRows()">
        <q-card flat bordered>
          <q-card-section>
            <div class="text-h6">Registre des traitements</div>
          </q-card-section>

          <q-table :rows="rows" :columns="columns" row-key="id">
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="domain" :props="props">
                  {{ props.row.domain }}
                </q-td>
                <q-td key="treatment" :props="props">
                  {{ props.row.treatment }}
                </q-td>
                <q-td key="date" :props="props">
                  {{ props.row.date }}
                </q-td>
                <q-td key="finality" :props="props">
                  {{ props.row.finality }}
                </q-td>
                <q-td key="document" :props="props">
                  <a :href="props.row.document">Lien</a>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card>
      </div>
      <div v-else-if="node.objectType === 'Registre des sous-traitants' && updateRows()">
        <q-card flat bordered>
          <q-card-section>
            <div class="text-h6">Registre des sous-traitants</div>
          </q-card-section>

          <q-table :rows="rows" :columns="subcontractorColumns" row-key="id"></q-table>
        </q-card>
      </div>
      <div v-else-if="node.objectType === 'Registre des violations' && updateRows()">
        <q-card flat bordered>
          <q-card-section>
            <div class="text-h6">Registre des violations</div>
          </q-card-section>

          <q-table :rows="rows" :columns="infractionColumns" row-key="id"></q-table>
        </q-card>
      </div>
      <div v-else-if="node.objectType === 'Registres des assemblées générales' && updateRows()">
        <q-card flat bordered>
          <q-card-section>
            <div class="text-h6">Registres des assemblées générales</div>
          </q-card-section>

          <q-table :rows="agRegisterRows" :columns="agRegisterColumns" row-key="id"></q-table>
        </q-card>
      </div>
      <div v-else>
        <q-card flat bordered>
          <q-card-section>
            <div class="row items-center no-wrap">
              <div class="col">
                <div class="text-h6">
                  {{ node.label }}
                  <span v-if="inEnvironmentDatabase"> / {{ getClientForNode(node) }} </span>
                </div>
              </div>

              <q-btn color="primary" label="Actions" :disable="disable">
                <q-menu auto-close>
                  <q-card class="card">
                    <q-card-actions vertical align="left">
                      <ItemActionMenuItem v-if="isMoralPerson()" label="Mettre à jour depuis API Entreprise"
                        @click="showApiEntrepriseDialog()" style="text-align:left;" />
                      <ItemActionMenuItem label="Copier dans un autre client" @click="showCopyToClientDialog(node)"
                        style="text-align:left;" />
                      <ItemActionMenuItem style="text-align:left;" label="Supprimer"
                        @click="showDeleteNodeDialog(node)" />
                    </q-card-actions>
                  </q-card>
                </q-menu>
              </q-btn>
            </div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div v-for="field in node?.fields">
              <StringField v-if="field.fieldModel.type === FieldType.String" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <FormattedTextField v-if="field.fieldModel.type === FieldType.FormattedText" :database="database"
                :node="node" :field="field" @update:field="onFieldChanged" />
              <FileField v-if="field.fieldModel.type === FieldType.File" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <DateField v-if="field.fieldModel.type === FieldType.Date" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <SelectField v-if="field.fieldModel.type === FieldType.Select" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <ObjectLinkField v-if="field.fieldModel.type === FieldType.ObjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectField v-if="field.fieldModel.type === FieldType.Object" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectListField v-if="field.fieldModel.type === FieldType.ObjectList" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ProjectLinkField v-if="field.fieldModel.type === FieldType.ProjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div v-if="node?.type === NodeType.FieldLink">
      <div>
        <q-card flat bordered>
          <q-card-section>
            <div class="text-h6">{{ linkedNode.label }} ({{ linkedNode.objectType }})</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div v-for="field in linkedNodeFields">
              <StringField v-if="field.fieldModel.type === FieldType.String" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <FormattedTextField v-if="field.fieldModel.type === FieldType.FormattedText" :database="database"
                :node="node" :field="field" @update:field="onFieldChanged" />
              <FileField v-if="field.fieldModel.type === FieldType.File" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <DateField v-if="field.fieldModel.type === FieldType.Date" :database="database" :node="node" :field="field"
                @update:field="onFieldChanged" />
              <SelectField v-if="field.fieldModel.type === FieldType.Select" :database="database" :node="node"
                :field="field" @update:field="onFieldChanged" />
              <ObjectLinkField v-if="field.fieldModel.type === FieldType.ObjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectField v-if="field.fieldModel.type === FieldType.Object" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ObjectListField v-if="field.fieldModel.type === FieldType.ObjectList" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
              <ProjectLinkField v-if="field.fieldModel.type === FieldType.ProjectLink" :database="database"
                :environmentDatabase="environmentDatabase" :node="node" :field="field" @update:field="onFieldChanged" />
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.text-center) {
  text-align: left;
}
</style>
