<script setup lang="ts">
import { ref, provide } from 'vue'
import { QTableProps, Dialog } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { format, addMonths, addYears } from 'date-fns'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import SubscriptionAddEditForm from './SubscriptionAddEditForm.vue'
import ObjectUserPermissionsForm from '@/components/common/dialog/ObjectUserPermissionsForm.vue'

interface Props {
  clientId?: string
}
const props = withDefaults(defineProps<Props>(), {})

const userApi = useApi()

const columns: QTableProps['columns'] = [
  {
    name: 'created_at',
    label: 'Date de souscription',
    field: 'createdAt',
    sortable: true,
    align: 'left',
    format: (val, row) => formatDateFromStr(val),
  },
  {
    name: 'periodocity',
    label: 'Périodicité',
    field: (row) => row.subscription.periodicity,
    sortable: true,
    align: 'left',
    format: (val, row) => (val == 'MONTH' ? 'Mensuelle' : 'Annuelle'),
  },
  {
    name: 'credits',
    label: 'Crédits par période',
    field: (row) => row.subscription.credits,
    sortable: true,
    align: 'left',
  },
  {
    name: 'remaining_credits',
    label: 'Crédits restants',
    field: 'remainingCreditsInPeriod',
    sortable: true,
    align: 'left',
  },
  {
    name: 'renew',
    label: 'Renouvellement',
    field: 'createdAt',
    sortable: true,
    align: 'left',
    format: (val, row) => formatDateForRenewPeriod(val, row),
  },
]

const clientId = ref(props.clientId)

const showForm = ref(false)
provide('showForm', showForm)
const mode = ref('ADD')
provide('mode', mode)
const rows = ref([])
provide('rows', rows)

const selectedRows = ref([])

userApi.getUserSubscriptions().then((response) => {
  rows.value = response.results
})

const formatDateFromStr = function (dateStr) {
  const dateFormat = new Date(dateStr)
  return format(dateFormat, 'dd/MM/yyyy')
}

const formatDateForRenewPeriod = function (val, row) {
  let renewDate
  const startDate = new Date(val)
  if (row.subscription.periodicity == 'MONTH') {
    renewDate = addMonths(startDate, 1)
  } else {
    renewDate = addYears(startDate, 1)
  }
  return format(renewDate, 'dd/MM/yyyy')
}

function refreshTableData() {
  userApi.getUserSubscriptions(props.clientId).then((response) => {
    rows.value = response.results
  })
}

function onAddClick() {
  mode.value = 'ADD'
  const dialog = Dialog.create({
    component: SubscriptionAddEditForm,
    componentProps: {
      mode: 'ADD',
      addTitle: 'Souscrire un abonnement',
      editTitle: 'Modifier un abonnement',
      addConfirmLabel: 'Ajouter',
      editConfirmLabel: 'Modifier',
      selectedRows: selectedRows.value,
    },
  })
    .onOk(() => {
      dialog.hide()
      refreshTableData()
    })
    .onCancel(() => {
      dialog.hide()
    })
  showForm.value = true
}

async function onDeleteClick() {
  if (confirm('Voulez-vous vraiment résilier cet abonnement ?')) {
    await userApi.deleteSubscription(selectedRows.value[0].id, props.clientId)
    refreshTableData()
  }
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: ObjectUserPermissionsForm,
    componentProps: {
      clientId: clientId.value,
      selectedRows: selectedRows.value,
      rights: ['view_usersubscription', 'change_usersubscription', 'delete_usersubscription'],
      environment: 'UserSubscription',
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable
    add-button-label="Souscrire un abonnement"
    selection="single"
    :columns="columns"
    :rows="rows"
    @add-item-click="onAddClick"
    @on-row-selected="onRowSelected"
  >
    <template v-slot:action-menu-items>
      <!-- <ItemActionMenuItem label="Modifier" @click="onEditClick" /> -->
      <ItemActionMenuItem label="Résilier" @click="onDeleteClick" />
      <ItemActionMenuItem label="Gérer les droits" @click="onManageRightsClick" />
    </template>
  </BaseTable>
</template>
