<script lang="ts" setup>
import { ref, inject, Ref, computed } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const objectModelDatabase = ref('Personne physique')
const dataBaseOptions = ['Personne physique', 'Personne morale', 'SAS']

const mappingType = ref('Simple')
const mappingTypeOptions = ['Simple', 'Adresse', 'Liste choix simple', 'Liste choix multiple']

const associateRole = ref('')
const objectModelOptions = computed(() => {
  if (objectModelDatabase.value === 'Personne physique') {
    return [
      'Civilité',
      'Prénom',
      'Nom',
      'Date de naissance',
      'Ville de naissance',
      'Nationalité',
      'Adresse',
      'Profession',
      'Numéro de sécurité sociale',
    ]
  } else if (objectModelDatabase.value === 'Personne morale') {
    return ['Dénomitation sociale', 'Forme juridique', 'Adresse']
  } else if (objectModelDatabase.value === 'SAS') {
    return [
      'Statuts',
      'Commissaires aux comptes',
      'Baux',
      'Sociétés filles',
      'Actionnaires',
      'Membres du CSE',
      'Délégués du personnel',
      'Membres du conseil stratégique',
    ]
  }
})

const type = ['Texte', 'Nombre', 'Oui / non', 'Date', 'Choix', 'Choix multiple']

const rows = ref([
  {
    element: '',
    objectModel: '',
    type: '',
    autoFilledText: '',
  },
])

function addRow() {
  rows.value.push({
    element: '',
    objectModel: '',
    type: '',
    autoFilledText: '',
  })
}

function removeRow(index: number): void {
  rows.value.splice(index, 1)
}
</script>
<template>
  <div class="row">
    <BaseSelect
      v-model="objectModelDatabase"
      :options="dataBaseOptions"
      emit-value
      map-options
      label="Modèle d'objet de la base de donnée"
      class="col-4 q-my-md q-pr-sm"
      dense
    />

    <BaseSelect
      v-model="mappingType"
      :options="mappingTypeOptions"
      emit-value
      map-options
      label="Type de mapping"
      class="col-4 q-my-md q-px-sm"
      dense
    />

    <BaseInput v-model="associateRole" label="Rôle associé" class="col-4 q-my-md q-pl-sm" dense />
  </div>
  <div v-for="(row, index) in rows">
    <div class="row q-my-sm">
      <BaseInput v-model="row.element" :label="`Élement ${index + 1}`" class="col-3" dense />
      <BaseSelect
        v-model="row.objectModel"
        :options="objectModelOptions"
        emit-value
        map-options
        label="Modèle d'objet"
        class="col-3 q-px-sm"
        dense
      />
      <BaseSelect v-model="row.type" :options="type" emit-value map-options label="Type" class="col-2 q-px-sm" dense />
      <BaseInput v-model="row.autoFilledText" label="Valeur pré-remplie" class="col-3" dense />
      <div class="flex items-center" style="margin: auto">
        <q-btn
          :disable="index === 0"
          outline
          rounded
          color="negative"
          padding="sm"
          icon="delete"
          @click="removeRow(index)"
          class="col-auto"
        />
      </div>
    </div>
  </div>
  <q-btn
    color="primary"
    class="q-my-md"
    @click="addRow"
    outline
    round
    dense
    icon="add"
    style="width: fit-content; margin: auto"
  />
</template>
<style lang="scss" scoped></style>
