import Main from './Main.vue'
import DocumentTemplates from './DocumentTemplates.vue'
import Projects from './Projects.vue'

const routes = [
  {
    name: 'contents/main',
    component: Main,
    path: '/contents/main',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'contents/document_templates',
    component: DocumentTemplates,
    path: '/contents/document_templates',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'contents/projects',
    component: Projects,
    path: '/contents/projects',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
