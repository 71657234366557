<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'

const unsavedChanges = inject("unsavedChanges")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})
</script>


<template></template>