// import Main from "./Main.vue";
import Main from '../../environment/clients/Main.vue'
import Client from './Client.vue'
// import ClientProjects from "./ClientProjects.vue";
import ClientProjects from '../../environment/clients/ClientProjects.vue'
import Project from './Project.vue'

const routes = [
  {
    name: 'clients/main',
    component: Main,
    path: '/clients',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client',
    component: Client,
    path: '/clients/:clientId',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects',
    component: ClientProjects,
    path: '/clients/:clientId/projects',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects/project',
    component: Project,
    path: '/clients/:clientId/projects/:projectId',
    props: (route) => ({
      documentId: route.query.documentId,
      taskId: route.query.taskId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
