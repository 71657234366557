<script setup lang="ts">
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Non trouvé')
</script>

<template>
  <q-page flex> Not found </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h1 {
  margin-bottom: 10px;
  margin-top: 150px;
  line-height: 50px;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
