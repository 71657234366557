<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'

const store = useStore()
store.commit('setPageTitle', 'Exécution de dossier')

const router = useRouter()
const route = useRoute()

router.push({
  name: 'projects/project',
  params: { projectId: route.params.projectId },
  query: route.query,
})
</script>

<template>
  <q-page flex> Redirection </q-page>
</template>
