<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify, Dialog } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import PromoteUserDialog from './PromoteUserDialog.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  title: string
  buttonConfirmLabel: string
  mode: string
  clientId?: string | undefined
  selectedUser?: any,
  showPortalSelector?: boolean
  clientName: string
}
const props = withDefaults(defineProps<Props>(), {
  showPortalSelector: false,
  clientName: ''
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi = useApi()

const email = ref('')
const emailTemp = ref('')
const password = ref('')
const firstName = ref('')
const lastName = ref('')

const clientId = ref(props.clientId)
const authenticatedUser = useAuthenticatedUser()

const isLoading: Ref<boolean> = ref(false)

const creationModeOptions = [
  {
    label: "Envoi d'une invitation par mail",
    value: 'INVITATION',
  },
  {
    label: "Création d'un mot de passe",
    value: 'PASSWORD',
  },
]

const creationMode = ref(creationModeOptions[0])

function beforeShow() {
  if (props.mode == 'ADD') {
    email.value = ''
    password.value = ''
    firstName.value = ''
    lastName.value = ''
  } else {
    if (props.selectedUser === undefined) {
      return
    }
    email.value = props.selectedUser.email
    password.value = props.selectedUser.password
    firstName.value = props.selectedUser.firstName
    lastName.value = props.selectedUser.lastName
  }
}

async function createUser() {
  isLoading.value = true
  emailTemp.value = email.value

  const existingUser = await userApi.getUserByEmail(email.value)
  console.log("existingUser", existingUser)

  if (existingUser.status == "error") {
    console.log("user does not exist")
    // return
  } else {
    console.log("user already exist")
    if (existingUser.environments[0].portalType == "CLIENT") {
      console.log("client user")

      const dialog = Dialog.create({
        component: PromoteUserDialog,
        componentProps: {
        },
      })
        .onOk(async () => {
          await userApi.promoteClientUserToEnvironmentUser(existingUser.id, clientId.value)
          await userApi.addUsersToPortalGroup("8ea55182-c7b4-46cc-9ec9-e5fe9196f7c5", [existingUser.id])
          Notify.create({
            message: 'Utilisateur ajouté au client',
            type: 'primary',
          })
          dialog.hide()
          dialogRef.value?.hide()
        })
        .onCancel(() => {
          Notify.create({
            message: 'Création de l\'utilisateur annulée',
            type: 'negative',
          })
          dialog.hide()
          dialogRef.value?.hide()
        })


      return
    } else {
      console.log("environment user")
      await userApi.addEnvironmentToUser(existingUser.id, {
        environmentId: authenticatedUser.environment.id
      })
      await userApi.sendEnvironmentInvitation(existingUser.id, {
        environmentId: authenticatedUser.environment.id
      })
      Notify.create({
        message: 'Utilisateur créé',
        type: 'primary',
      })
      isLoading.value = false
      emit('ok')
      return
    }

  }

  console.log("authenticatedUser", authenticatedUser)
  console.log("userType", userType.value)
  let finalClientId
  if (props.showPortalSelector) {
    if (userType.value.value == 'client') {
      console.log("client user", authenticatedUser.clientId)
      finalClientId = authenticatedUser.clientId
    } else {
      console.log("environment user")
      finalClientId = undefined
    }
  } else {
    finalClientId = props.clientId
  }

  userApi
    .createPortalUser(
      {
        username: email.value,
        password: password.value,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
      },
      finalClientId,
    )
    .then((result) => {
      if (creationMode.value.value === 'INVITATION') {
        userApi
          .invitePortalUser(
            {
              email: result.email,
              firstName: result.firstName,
              lastName: result.lastName,
            },
            clientId.value,
          )
          .then((result) => {
            isLoading.value = false
            emit('ok', result)
          })
      } else {
        isLoading.value = false
        emit('ok', result)
      }
      dialogRef.value?.hide()
    })
    .catch((error) => {
      if (error.response.data.includes('duplicate key value violates unique constraint')) {
        Notify.create({
          message: `Un utilisateur avec l'email ${emailTemp.value} existe déjà.`,
          type: 'negative',
        })
      }
    })
}

function updateUser() {
  isLoading.value = true
  const selectedItem = props.selectedUser
  userApi
    .updatePortalUser(
      selectedItem.id,
      {
        username: email.value,
        password: password.value,
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
      },
      clientId.value,
    )
    .then((result) => {
      emit('ok')
      isLoading.value = false
    })
}

function onConfirmClick() {
  if (props.mode === 'ADD') {
    createUser()
  } else {
    updateUser()
  }
}

const portalOptions = [
  {
    label: "Client " + props.clientName,
    value: "client",
  },
  {
    label: "MarvellUp",
    value: "MarvellUp",
  }
]

const userType = ref(portalOptions[0])
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" :dialog-width="500" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <q-select v-if="props.showPortalSelector" outlined v-model="userType" :options="portalOptions"
          label="Type d'utilisateur" style="margin-bottom: 16px;" />
        <BaseInput v-model="email" label="Email *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom de l\'utilisateur est requis']">
        </BaseInput>
        <BaseInput v-model="firstName" label="Prénom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le prénom est requis']">
        </BaseInput>
        <BaseInput v-model="lastName" label="Nom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom est requis']">
        </BaseInput>
        <q-select outlined v-model="creationMode" :options="creationModeOptions" label="Mode de création" />
        <BaseInput v-if="creationMode.value == 'PASSWORD'" v-model="password" class="q-mt-md" name="pass"
          type="password" label="Mot de passe *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le mot de passe pour le nouvel utilisateur est requis']">
        </BaseInput>
      </template>
      <template #actions>
        <q-btn flat :label="buttonConfirmLabel" @click="onConfirmClick" color="primary" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
