import { Database } from '@/models/database/Database'
import { useStore } from '@/store/store'

export function useDatabase(): Database {
  const store = useStore()
  return store.state.database
}

export function useAtDatabase(): Database {
  const store = useStore()
  return store.state.atDatabase
}

export function useAgoDatabase(): Database {
  const store = useStore()
  return store.state.agoDatabase
}
