<script setup lang="ts">
import { ref } from 'vue'
import { useApi } from '@/store/useAppStore'
import { QTableProps, Dialog, Notify } from 'quasar'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import UserAddEditForm from './UserAddEditForm.vue'
import UserEditForm from './UserEditForm.vue'
import UserModulesForm from './UserModulesForm.vue'
import UserPermissionsForm from './UserPermissionsForm.vue'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  clientId?: string
}
const props = defineProps<Props>()
const userApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const columns: QTableProps['columns'] = [
  {
    name: 'username',
    label: 'Identifiant',
    field: 'username',
    sortable: true,
    align: 'left',
  },
  {
    name: 'first_name',
    label: 'Prénom',
    field: 'firstName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'last_name',
    label: 'Nom',
    field: 'lastName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'email',
    label: 'Email',
    field: 'email',
    sortable: true,
    align: 'left',
  },
  {
    name: 'subscriptions',
    label: 'Modules',
    field: 'modules',
    sortable: false,
    align: 'left',
  }
]

const clientId = ref(props.clientId)
const isUserTableLoading = ref(false)
const rows = ref([])
const selectedRows = ref([])

isUserTableLoading.value = true
userApi.getPortalUsers(clientId.value).then((response) => {
  rows.value = response.results
  updateModules()
  isUserTableLoading.value = false
})

async function refreshTableData() {
  isUserTableLoading.value = true
  const response = await userApi.getPortalUsers(clientId.value)
  rows.value = response.results
  updateModules()
  isUserTableLoading.value = false
}

function updateModules() {
  rows.value.forEach((row) => {

    row.subscriptionModules = row.subscriptionModules.filter((subscriptionModule) => {
      console.log("filter environment", subscriptionModule, authenticatedUser.environment, subscriptionModule.environmentSubscriptionModule.environmentId == authenticatedUser.environment.id)
      if (subscriptionModule.environmentSubscriptionModule.environmentId == authenticatedUser.environment.id) {
        return true
      } else {
        return false
      }
    }).sort((a: any, b: any) => {
      return a.environmentSubscriptionModule.subscriptionModule.name.localeCompare(b.environmentSubscriptionModule.subscriptionModule.name)
    })

    if (row.subscriptionModules.length > 1) {
      row.subscriptionModules = row.subscriptionModules.filter((subscriptionModule) => {
        console.log(subscriptionModule)
        if (subscriptionModule.environmentSubscriptionModule.subscriptionModule.name == 'Invité') {
          return false
        } else {
          return true
        }
      })
    }
  })
  rows.value.forEach((row) => {
    row.modules = row.subscriptionModules.map((subscriptionModule) => subscriptionModule.environmentSubscriptionModule.subscriptionModule.name).join(', ')
    if (row.modules == '') {
      row.modules = 'Invité'
    }
  })

}

function onAddClick() {
  const dialog = Dialog.create({
    component: UserAddEditForm,
    componentProps: {
      title: 'Nouvel utilisateur',
      buttonConfirmLabel: 'Ajouter',
      mode: 'ADD',
      clientId: props.clientId,
    },
  })
    .onOk(async () => {
      await refreshTableData()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditClick() {
  const dialog = Dialog.create({
    component: UserEditForm,
    componentProps: {
      title: 'Modifier un utilisateur',
      buttonConfirmLabel: 'Modifier',
      mode: 'EDIT',
      clientId: props.clientId,
      selectedUser: selectedRows.value[0],
    },
  })
    .onOk(async () => {
      await refreshTableData()
      dialog.hide()
      Notify.create({
        message: `Utilisateur modifié avec succès`,
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageSubscriptionModulesClick() {
  console.log("manage subscription modules")
  const dialog = Dialog.create({
    component: UserModulesForm,
    componentProps: {
      title: 'Modifier un utilisateur',
      buttonConfirmLabel: 'Modifier',
      mode: 'EDIT',
      clientId: props.clientId,
      selectedUser: selectedRows.value[0],
    },
  })
    .onOk(async () => {
      await refreshTableData()
      dialog.hide()
      Notify.create({
        message: `Utilisateur modifié avec succès`,
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onDeleteClick() {
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message:
        "Voulez-vous vraiment supprimer l'utilisateur " +
        selectedRows.value[0].fullName +
        ' (' +
        selectedRows.value[0].email +
        ') ?',
    },
  })
    .onOk(async () => {
      await userApi.deletePortalUser(selectedRows.value[0].id, clientId.value)
      selectedRows.value = []
      await refreshTableData()

      dialog.hide()
      Notify.create({
        message: 'Utilisateur supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: UserPermissionsForm,
    componentProps: {
      clientId: props.clientId,
      user: selectedRows.value[0],
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable add-button-label="Ajouter un utilisateur" selection="single" :columns="columns" :rows="rows"
    :is-loading="isUserTableLoading" @add-item-click="onAddClick" @on-row-selected="onRowSelected">
    <!-- :enable-add="authenticatedUser.hasPermission('add_environment_users')"  -->
    <template v-slot:action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Gérer les modules" @click="onManageSubscriptionModulesClick" />
      <!-- :disable="!authenticatedUser.hasPermission('change_environment_users')" -->
      <!-- <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" /> -->
      <!-- :disable="!authenticatedUser.hasPermission('delete_environment_users')" -->
      <ItemActionMenuItem label="Gérer les droits globaux" @click="onManageRightsClick" />
      <!--:disable="!authenticatedUser.hasPermission('change_environment_users')"  -->
    </template>
  </BaseTable>
</template>
