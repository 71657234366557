<script lang="ts" setup>
import { ref, inject, Ref, computed } from 'vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'

const publipostable = ref(false)
const dataBaseOptions = ['Personne physique', 'Personne morale', 'SAS']

const mappingTypeOptions = ['Simple', 'Adresse', 'Liste choix simple', 'Liste choix multiple']

const type = ['Texte', 'Nombre', 'Oui / non', 'Date', 'Choix', 'Choix multiple']

function addRow(index) {
  tabs.value[index].rows.push({
    element: '',
    objectModel: '',
    type: '',
    autoFilledText: '',
  })
}

function removeRow(tabIndex: number, index: number): void {
  tabs.value[tabIndex].rows.splice(index, 1)
}

const tabs = ref([])

function addObject() {
  tabs.value.push({
    name: '',
    objectModelDatabase: 'Personne physique',
    mappingType: 'Simple',
    associateRole: '',
    objectModelOptions: [
      'Civilité',
      'Prénom',
      'Nom',
      'Date de naissance',
      'Ville de naissance',
      'Nationalité',
      'Adresse',
      'Profession',
      'Numéro de sécurité sociale',
    ],
    rows: [
      {
        element: '',
        objectModel: '',
        type: '',
        autoFilledText: '',
      },
    ],
  })
}

function onObjectModelDatabaseChange(tabIndex, newValue) {
  if (newValue === 'Personne physique') {
    tabs.value[tabIndex].objectModelOptions = [
      'Civilité',
      'Prénom',
      'Nom',
      'Date de naissance',
      'Ville de naissance',
      'Nationalité',
      'Adresse',
      'Profession',
      'Numéro de sécurité sociale',
    ]
  } else if (newValue === 'Personne morale') {
    tabs.value[tabIndex].objectModelOptions = ['Dénomitation sociale', 'Forme juridique', 'Adresse']
  } else if (newValue === 'SAS') {
    tabs.value[tabIndex].objectModelOptions = [
      'Statuts',
      'Commissaires aux comptes',
      'Baux',
      'Sociétés filles',
      'Actionnaires',
      'Membres du CSE',
      'Délégués du personnel',
      'Membres du conseil stratégique',
    ]
  }
}
</script>
<template>
  <BaseCheckbox v-model="publipostable" label="Variable de publipostable" />
  <div v-for="(tab, tabIndex) in tabs">
    <BaseInput v-model="tab.name" label="Nom de l'object" dense class="q-mt-md" />
    <div class="row">
      <BaseSelect
        v-model="tab.objectModelDatabase"
        :options="dataBaseOptions"
        @update:model-value="onObjectModelDatabaseChange(tabIndex, tab.objectModelDatabase)"
        emit-value
        map-options
        label="Modèle d'objet de la base de donnée"
        class="col-4 q-my-md q-pr-sm"
        dense
      />

      <BaseSelect
        v-model="tab.mappingType"
        :options="mappingTypeOptions"
        emit-value
        map-options
        label="Type de mapping"
        class="col-4 q-my-md q-px-sm"
        dense
      />

      <BaseInput v-model="tab.associateRole" label="Rôle associé" class="col-4 q-my-md q-pl-sm" dense />
    </div>
    <div v-for="(row, index) in tab.rows">
      <div class="row q-my-sm">
        <BaseInput v-model="row.element" :label="`Élement ${index + 1}`" class="col-3" dense />
        <BaseSelect
          v-model="row.objectModel"
          :options="tab.objectModelOptions"
          emit-value
          map-options
          label="Modèle d'objet"
          class="col-3 q-px-sm"
          dense
        />
        <BaseSelect
          v-model="row.type"
          :options="type"
          emit-value
          map-options
          label="Type"
          class="col-2 q-px-sm"
          dense
        />
        <BaseInput v-model="row.autoFilledText" label="Valeur pré-remplie" class="col-3" dense />
        <div class="flex items-center" style="margin: auto">
          <q-btn
            :disable="index === 0"
            outline
            rounded
            color="negative"
            padding="sm"
            icon="delete"
            @click="removeRow(tabIndex, index)"
            class="col-auto"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center q-mb-sm">
      <q-btn color="primary" class="q-my-md" @click="addRow(tabIndex)" outline round dense icon="add" />
    </div>
    <q-separator />
  </div>
  <q-btn label="Ajouter un dossier" @click="addObject" color="primary" outline>
    <!-- <q-popup-edit v-model="newObject" auto-save v-slot="scope">
    <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
  </q-popup-edit> -->
  </q-btn>
</template>
<style lang="scss" scoped></style>
