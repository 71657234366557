<script setup lang="ts">
import { ref, Ref, inject, onMounted } from 'vue'
import { useDialogPluginComponent, Notify, useQuasar, Dialog } from 'quasar'
import { ProjectKey } from '@/models/symbols'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import ConfirmReimportLopMatterDialog from '@/pages/common/projects/ConfirmReimportLopMatterDialog.vue'

interface Props {
  project: any
}

const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const $q = useQuasar()

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const step = ref(undefined)
const searchString = ref('')
const matters = ref([])
const rows = ref([])
const searchExecuted = ref(false)
const isLoading: Ref<boolean> = ref(false)
const login = ref('')
const password = ref('')
const isPwd = ref(true)

onMounted(async () => {
  isLoading.value = true
  const lopCredentialsStatus = await userApi.getUserLopCredentialsStatus(authenticatedUser.id)
  if (lopCredentialsStatus.status == 'invalid') {
    step.value = 'GetLopCredentials'
  } else {
    step.value = 'ImportMatter'
  }
  isLoading.value = false
})

async function searchMatters() {
  isLoading.value = true
  const result = await userApi.lopSearchMatter(searchString.value)
  matters.value = result.dto
  searchExecuted.value = true
  isLoading.value = false
}


async function importMatter(matter) {
  if (!matter.alreadyImported) {
    realImportMatter(matter)
    return
  }

  const dialog = Dialog.create({
    component: ConfirmReimportLopMatterDialog,
    componentProps: {
    },
  })
    .onOk(() => {
      dialog.hide()
      realImportMatter(matter)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function realImportMatter(matter) {
  // $q.loading.show({
  //     message:
  //         "Nous importons les documents depuis LOP.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
  //     boxClass: "bg-grey-2 text-grey-9",
  //     spinnerColor: "primary",
  // });

  // const result = await userApi.documentTreeImportLopMatter(props.project.value.id, matter.id)

  $q.loading.hide()
  emit('ok', matter)
}

async function connectToLop() {
  isLoading.value = true
  const response = await userApi.setUserLopCredentials(authenticatedUser.id, login.value, password.value)
  if (response.code === 200) {
    isLoading.value = false
    step.value = 'ImportMatter'
  } else {
    Notify.create({
      message: "L'identifiant et/ou le mot de passe LOP sont incorrects",
      type: 'negative',
    })
    isLoading.value = false
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Importer un dossier LOP" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()" :isFullContent="true">
      <template #full-content>
        <div v-if="step == 'GetLopCredentials'">
          <div class="q-pa-md q-col-gutter-md centered-form" style="max-width: 450px">
            <div class="row q-col-gutter-sd">
              <h5 class="col-md-12">Veuillez renseigner vos identifiants LOP</h5>
            </div>
            <div class="columns">
              <q-input outlined class="q-mb-lg" id="login" type="text" label="Identifiant" v-model="login" required
                autofocus :disable="isLoading" />
              <q-input outlined id="password" :type="isPwd ? 'password' : 'text'" label="Mot de passe" v-model="password"
                required bottom-slots :disable="isLoading">
                <template #append>
                  <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                    @click="isPwd = !isPwd" />
                </template>
              </q-input>
            </div>
            <div class="row">
              <q-btn class="col-md-12 submit-button justify-center full-width" color="primary" label="Se connecter à LOP"
                @click="connectToLop" :loading="isLoading" :disable="isLoading" />
            </div>
          </div>
        </div>

        <div v-if="step == 'ImportMatter'">
          <q-toolbar class="text-primary">
            <q-input dense outlined v-model="searchString" label="Dossier LOP" style="width: 100%"
              @keydown.enter.prevent="searchMatters()"></q-input>
            &nbsp;&nbsp;&nbsp;
            <q-btn label="Rechercher" @click="searchMatters()" outlined color="primary" :loading="isLoading"></q-btn>
          </q-toolbar>

          <div style="margin-top: 20px; margin-bottom: 5px; margin-left: 20px"
            v-if="matters.length === 0 && searchExecuted">
            Aucune dossier ne correspond à la recherche
          </div>
          <div style="margin-top: 20px; margin-bottom: 5px; margin-left: 20px" v-if="matters.length > 0">
            Cliquer sur un dossier pour le choisir
          </div>
          <q-scroll-area class="company-list-scroll-area" v-if="matters.length > 0"
            style="width: 95%; margin-left: 20px; margin-right: 20px">
            <q-list bordered separator v-if="matters.length > 0" style="width: 100%">
              <q-item clickable v-ripple v-for="matter in matters" @click="importMatter(matter)" style="width: 100%">
                <q-item-section style="">
                  <q-item-label>{{ matter.title }}</q-item-label>
                  <q-item-label caption>Numéro de dossier LOP : {{ matter.id }}</q-item-label>
                  <q-item-label v-if="matter.alreadyImported" caption style="color: red;">Dossier déjà
                    importé</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </div>
      </template>
      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.company-list-scroll-area {
  width: 90%;
  height: 50vh;
}

.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h5 {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
