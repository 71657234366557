export enum FieldType {
  String = 'STRING',
  FormattedText = 'FORMATTED_TEXT',
  Date = 'DATE',
  Select = 'SELECT',
  Object = 'OBJECT',
  ObjectLink = 'OBJECT_LINK',
  ObjectList = 'OBJECT_LIST',
  File = 'FILE',
  ProjectLink = 'PROJECT_LINK',
}
