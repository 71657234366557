<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import { ProjectKey } from '@/models/symbols'
import TaskStatusChangedItem from './TaskStatusChangedItem.vue'
import AssignedUserChanged from './AssignedUserChanged.vue'
import DeadlineChanged from './DeadlineChanged.vue'
import ChatMessage from './ChatMessage.vue'

interface Props {
  event: any
}

const props = withDefaults(defineProps<Props>(), {})

function getEventLabel(event) {
  if (event.type == 1) {
    return 'Etat de tâche modifié'
  }
  if (event.type == 2) {
    return 'Responsable de tâche modifié'
  }
  if (event.type == 3) {
    return 'Echéance de tâche modifiée'
  }
  if (event.type == 4) {
    return 'Nouveau document : ' + event.documentTreeNode.name
  }
  if (event.type == 5) {
    return 'Nouveaux documents'
  }
  if (event.type == 6) {
    return 'Document modifié : ' + event.documentTreeNode.name
  }
}
</script>

<template>
  <q-item clickable v-ripple>
    <q-item-section>
      <q-checkbox :label="getEventLabel(event)" v-model="event.send"></q-checkbox>
    </q-item-section>
  </q-item>
</template>

<style lang="scss" scoped></style>
