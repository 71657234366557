<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'

const unsavedChanges = inject("unsavedChanges")
const selectedPage = inject("selectedPage")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function getCardClass(type) {
    return "drag-cursor"
}

const text = ref(props.element.name)

watch(text, () => {
    console.log("text changed", text.value)
    props.element.name = text.value
})

const choices = ref(["+", "-", "*", "/"])
const v = ref("+")

if (props.element.value == undefined) {
    props.element.value = {
        "operator": "+",
        "operand1": "",
        "operand2": "",
    }
}

function removeNode() {
    console.log("removeNode")
    const dialog = Dialog.create({
        title: 'Supprimer',
        message: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
        cancel: true,
        persistent: true,
    })
        .onOk(() => {
            selectedPage.value.fields.splice(selectedPage.value.fields.indexOf(props.element), 1)
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

</script>


<template>
    <q-select v-model="element.value.operator" :options="choices">
        <template v-slot:before>
            <span style="font-size: 14px; color: black;">Opérateur
                :</span>
        </template>
    </q-select>
    <div>
        <q-input dense v-model="element.value.operand1" style="margin-top: 8px">
            <template v-slot:before>
                <span style="font-size: 14px; color: black;">Opérande 1
                    :</span>
            </template>
        </q-input>
    </div>
    <div>
        <q-input dense v-model="element.value.operand2" style="margin-top: 8px">
            <template v-slot:before>
                <span style="font-size: 14px; color: black;">Opérande 2
                    :</span>
            </template>
        </q-input>
    </div>
</template>

<style scoped lang="scss">
.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}
</style>