<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { Question } from '@/models/survey/Question'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDatePicker from '@/components/base/BaseDatePicker.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

interface Props {
  question: Question
  isEdit: boolean
  form?: any
}
const props = withDefaults(defineProps<Props>(), {
  form: {},
})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const options: Ref<Array<any>> = ref([])
const optionSelected: Ref<string> = ref('')
const fields: Ref<any> = ref({})
const editForm: any = JSON.parse(JSON.stringify(props.form))
const arrayForm: any = JSON.parse(JSON.stringify(props.question.options))

const booleanSelectValues = [
  {
    label: 'Oui',
    value: true,
  },
  {
    label: 'Non',
    value: false,
  },
]

function onOk() {
  fields.value.fields.forEach((field) => {
    if (field.value === undefined) {
      field.value = ''
    }
  })
  emit('ok', fields.value)
}

function optionChanged() {
  fields.value = JSON.parse(
    JSON.stringify(
      arrayForm.find((option, key) => {
        return option.type === optionSelected.value
      }),
    ),
  )
}

function onHide() {
  fields.value = {}
}

function onShow() {
  if (props.isEdit) {
    optionSelected.value = JSON.parse(JSON.stringify(editForm.type))
    fields.value = JSON.parse(JSON.stringify(editForm))
  } else {
    arrayForm.map((option) => {
      options.value.push(option.type)
    })
    optionSelected.value = options.value[0]
    fields.value = JSON.parse(
      JSON.stringify(
        arrayForm.find((option, key) => {
          return option.type === optionSelected.value
        }),
      ),
    )
  }
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="onShow()" @hide="onHide()">
    <BaseDialog :title="question.label" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <BaseSelect
          class="q-mb-md"
          id="option-selector"
          v-model="optionSelected"
          :options="options"
          :readonly="isEdit"
          @update:model-value="optionChanged"
        />
        <div v-for="field in fields.fields" :key="field.id" :id="field.id">
          <BaseInput
            v-model="field.value"
            class="q-mb-md"
            v-if="field.type === 'text' || field.type === 'number'"
            :type="field.type"
            :label="field.label"
          />
          <div class="q-mb-md" v-if="field.type === 'bool'">
            <BaseSelect
              clearable
              v-model="field.value"
              :options="booleanSelectValues"
              :label="field.label"
              emit-value
              map-options
            />
          </div>
          <div class="q-mb-md" v-if="field.type === 'date'">
            <span class="modal-question">{{ field.label }}</span>
            <BaseDatePicker v-model="field.value" />
          </div>
        </div>
      </template>
      <template #actions>
        <q-btn v-if="!isEdit" flat v-close-popup label="Ajouter" color="primary" @click="onOk" />
        <q-btn v-if="isEdit" flat v-close-popup label="Modifier" color="primary" @click="onOk" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
