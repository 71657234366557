<script lang="ts" setup>
import { ref, Ref, watch } from 'vue'
import { useDialogPluginComponent, QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'

interface Props {
  task: Task
  selectedFile: UploadedFile
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()


const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]

const rows: Ref<Array<Client>> = ref([])

async function refreshClients() {
  const clients = await userApi.getClients()
  rows.value = clients.results
}

refreshClients()

const selectedItems = ref([])

function rowClicked(row) {
  console.log('rowClicked')
}

function onRowSelected(row) {
  console.log('rowSelected', row, selectedItems)
  if (row[0]) {
    selectedItems.value = row[0]
  } else {
    selectedItems.value = []
  }

}

function moveProject() {
  console.log('moveProject')
  emit("ok", selectedItems.value)
}

function onSelectedItems(items) {
  console.log('onSelectedItems', items)
  selectedItems.value = items
}

watch(selectedItems, (newSelection, _oldSelection) => {
  console.log('selectedItems', newSelection, selectedItems.value, selectedItems.value.length)
})

const pagination = ref({
  rowsPerPage: 5,
})

async function copyNode() {
  emit('ok', selectedItems.value)
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Copier dans un autre client" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <h4>
          Sélectionnez le client de destination
        </h4>
        <BaseTable selection="single" :columns="columns" :rows="rows" :selected-rows="selectedItems" :enable-add="false"
          @on-row-selected="onRowSelected" @on-row-click="rowClicked" :enable-actions="false" :pagination="pagination">
          <template #action-menu-items>
          </template>
        </BaseTable>
      </template>
      <template #actions>
        <q-btn flat color="primary" @click="copyNode()" label="Copier" :disable="selectedItems.length === 0" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped>
h4 {
  font-size: 16px;

}
</style>
