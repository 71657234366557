<script async setup lang="ts">
import { ref, watch, inject } from 'vue'
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

const userApi: UserApi = useApi()

const query = inject('query')
const step = inject('step')
const project = inject('project')
const client = inject('client')

const results = inject('searchResults')
const clientRows = ref([])
const projectRows = ref([])
const documentTreeNodes = inject('documentTreeNodes')
const loading = ref(false)
const searched = ref(false)

// https://medium.com/js-dojo/watch-vue-route-params-query-with-composition-api-97b3c8c402e
watch(query, () => {
  console.log('query changed', query)
  updateResults()
})

if (results.value != null) {
  clientRows.value = results.value.clients.results
  projectRows.value = results.value.projects.results
}

async function updateResults() {
  searched.value = false
  loading.value = true
  clientRows.value = []
  projectRows.value = []
  results.value = await userApi.searchProjects(query.value)
  // console.log(results)
  clientRows.value = results.value.clients.results
  projectRows.value = results.value.projects.results
  loading.value = false
  searched.value = true
}

function formatProjectName(val, row) {
  return row.name
}

const clientColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
  },
])

const projectColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
    format: formatProjectName,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.client.name,
    sortable: true,
  },
])

function clientRowClicked(event, row) {
  console.log('client', row)
  client.value = row
  step.value = 11
}

function projectRowClicked(event, row) {
  console.log('project', row)
  client.value = row.client
  project.value = row
  documentTreeNodes.value = [
    {
      id: 0,
      type: 1,
      label: 'Racine',
      children: [],
    },
  ]
  step.value = 2
}
</script>

<template>
  <div v-if="results == null">
    <div v-if="loading">
      <q-inner-loading showing color="primary" />
    </div>
  </div>
  <div v-if="results != null && query != ''">
    <h5>Clients</h5>
    <q-table grid flat dense hide-header :rows="clientRows" :columns="clientColumns" row-key="name"
      @row-click="clientRowClicked" no-data-label="Aucun résultat" :loading="loading">
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4 cursor-pointer"
          @click="($event) => clientRowClicked($event, props.row)">
          {{ props.row.name }}
          <q-separator />
        </div>
      </template>
    </q-table>

    <h5>Dossiers</h5>
    <q-table grid flat dense hide-header :rows="projectRows" :columns="projectColumns" row-key="name"
      @row-click="projectRowClicked" no-data-label="Aucun résultat" :loading="loading" style="">
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4 cursor-pointer"
          @click="($event) => projectRowClicked($event, props.row)">
          {{ props.row.name }}
          <br />
          <div style="color: grey">Client : {{ props.row.client.name }}</div>
          <q-separator />
        </div>
      </template>
    </q-table>
  </div>
</template>

<style lang="scss" scoped>
h5 {
  color: rgb(21, 84, 135);
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 18px;
}

h4 {
  font-size: 28px;
}

.page {
  padding-left: 0px;
  // margin: 0px;
}
</style>
