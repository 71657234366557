<script setup lang="ts">
import { ref, inject, watch, provide, onMounted } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useStore } from '@/store/store'
import { useQuasar } from 'quasar'
import { createLabels } from '@/components/projects/execution/documents/DocumentsCommon'

const $q = useQuasar()

const store = useStore()
const userApi: UserApi = useApi()

const project = inject('project')
const step = inject('step')

const selectedNode = inject('documentTreeSelectedNode')

const currentDirectoryNode = ref(null)
const documentTreeNodes = inject('documentTreeNodes')

const loading = ref(false)
const ticked = ref([])
provide('ticked', ticked)

function removeFiles(nodes, parent) {
  for (let i = 0; i < nodes.length; ) {
    const node = nodes[i]
    if (node.type === 2) {
      const index = parent.children.indexOf(node)
      parent.children.splice(index, 1)
    } else {
      ++i
    }
    removeFiles(node.children, node)
  }
}

onMounted(async () => {
  if (documentTreeNodes.value[0].children.length > 0) {
    return
  }

  loading.value = true
  const documentTree = await userApi.getUserProjectDocumentTreeDirectories(project?.value.id)

  createLabels(documentTree.children)

  documentTreeNodes.value[0].children = documentTree.children

  currentDirectoryNode.value = documentTreeNodes.value[0]

  removeFiles(documentTreeNodes.value[0].children, documentTreeNodes.value[0])

  console.log('documentTreeNodes', documentTreeNodes.value)
  loading.value = false
})

function onDirectorySelected() {
  step.value = 3
}
</script>

<template>
  <div style="">
    <div v-if="loading">
      <div class="row">
        <div class="col"></div>
        <q-spinner class="col-auto" size="50px" color="primary" />
        <div class="col"></div>
      </div>
    </div>
    <q-tree
      v-if="!loading"
      ref="treeRef"
      node-key="id"
      :nodes="documentTreeNodes"
      style="width: 100%; margin-left: 8px"
      default-expand-all
      v-model:selected="selectedNode"
      @update:selected="onDirectorySelected"
      selected-color="primary"
    >
    </q-tree>
  </div>
</template>

<style lang="scss" scoped>
h5 {
  color: rgb(64, 104, 200);
  margin-top: 0px;
  padding-top: 0px;
  font-size: 20px;
}

.directory-select {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
