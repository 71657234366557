<script async setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify, QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'


const store = useStore()
store.commit('setPageTitleComponent', "Liste des droits pour le module d'abonnement")

interface Props {
    subscriptionModuleId: any
}

const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()

console.log("route.params.subscriptionModuleId", route.params.subscriptionModuleId)
console.log("props.subscriptionModuleId", props.subscriptionModuleId)
const subscription = ref(await userApi.getSubscriptionModule(route.params.subscriptionModuleId))
console.log("subscription", subscription.value)


const unsavedChanges = ref(false)
provide("unsavedChanges", unsavedChanges)

const columns: QTableProps['columns'] = [
    {
        name: 'group',
        label: 'Groupe',
        field: 'groupName',
        sortable: true,
        align: 'left',
    },
    {
        name: 'name',
        label: 'Droit',
        field: 'name',
        sortable: true,
        align: 'left',
    },
    {
        name: 'value',
        label: 'Autorisé ?',
        field: 'value',
        sortable: true,
        align: 'center',
    },
]

const rows = ref([])

function updateRows() {
    rows.value = [
        {
            id: 'edit_project_parameters',
            groupName: 'Dossiers',
            name: 'Modifier les paramètres du dossier',
            value: subscription.value.permissionsJson.includes('edit_project_parameters'),
        },
        {
            id: 'edit_project_permissions',
            groupName: 'Dossiers',
            name: 'Modifier les permissions du dossier',
            value: subscription.value.permissionsJson.includes('edit_project_permissions'),
        },
        {
            id: 'import_from_lop',
            groupName: 'Dossiers',
            name: 'Importer depuis LOP',
            value: subscription.value.permissionsJson.includes('import_from_lop'),
        },
        {
            id: 'view_all_documents',
            groupName: 'Dossiers - Documents',
            name: 'Voir tous les documents',
            value: subscription.value.permissionsJson.includes('view_all_documents'),
        },
        {
            id: 'view_directory',
            groupName: 'Dossiers - Documents',
            name: 'Voir un dossier',
            value: subscription.value.permissionsJson.includes('view_directory'),
        },
        {
            id: 'create_directory',
            groupName: 'Dossiers - Documents',
            name: 'Créer un dossier',
            value: subscription.value.permissionsJson.includes('create_directory'),
        },
        {
            id: 'view_file',
            groupName: 'Dossiers - Documents',
            name: 'Voir un fichier',
            value: subscription.value.permissionsJson.includes('view_file'),
        },
        {
            id: 'upload_file',
            groupName: 'Dossiers - Documents',
            name: 'Téléverser un fichier',
            value: subscription.value.permissionsJson.includes('upload_file'),
        },
        {
            id: 'edit_sub_project_parameters',
            groupName: 'Dossiers - Opérations juridiques',
            name: 'Modifier les paramètres du projet juridique',
            value: subscription.value.permissionsJson.includes('edit_sub_project_parameters'),
        },
        {
            id: 'view_all_project',
            groupName: 'Dossiers - Opérations juridiques',
            name: 'Voir tout le projet',
            value: subscription.value.permissionsJson.includes('view_all_project'),
        },
        {
            id: 'start_signature_process',
            groupName: 'Dossiers - Opérations juridiques',
            name: 'Lancer un processus de signature',
            value: subscription.value.permissionsJson.includes('start_signature_process'),
        },
        {
            id: 'create_user',
            groupName: 'Utilisateurs et grpoupes',
            name: 'Créer un utilisateur',
            value: subscription.value.permissionsJson.includes('create_user'),
        },
        {
            id: 'create_group',
            groupName: 'Utilisateurs et grpoupes',
            name: 'Créer un groupe',
            value: subscription.value.permissionsJson.includes('create_group'),
        },
    ]
}

updateRows()

// https://www.sanwebe.com/2013/02/confirmation-dialog-on-leaving-page-javascript#:~:text=If%20you%20want%20to%20show,leave%20or%20reload%20the%20page.
window.onbeforeunload = function (e) {
    console.log('onbeforeunload', unsavedChanges.value)
    return unsavedChanges.value ? true : undefined
}

onBeforeRouteLeave(async (to, from) => {
    if (unsavedChanges.value == false) {
        return true
    }

    const answer = window.confirm('Les modifications que vous avez apportées ne seront pas enregistrées si vous quittez cette page.')
    if (!answer) {
        return false
    }
})

async function save() {
    console.log("save")
    subscription.value.permissionsJson = rows.value.filter(row => row.value).map(row => row.id)
    await userApi.updateSubscriptionModule(subscription.value)
    unsavedChanges.value = false
}

function onPermissionChange() {
    console.log("onPermissionChange")
    unsavedChanges.value = true
}
</script>

<template>
    <Teleport to="#pageTitleComponent">
        <div class="row items-center no-wrap">
            <div>
                Liste des droits pour le module d'abonnement {{ subscription.name }}
            </div>

            <div class="col-auto">
                <q-btn dense label="Enregistrer" color="primary" @click="save"
                    style="margin-left: 8px; padding-left: 8px; padding-right: 8px;" :disable="!unsavedChanges"></q-btn>
            </div>
        </div>
    </Teleport>
    <q-page padding class="page">
        <h5>Liste des droits</h5>
        <q-table :rows="rows" :columns="columns" row-key="id">
            <template v-slot:body-cell-value="props">
                <q-td :props="props">
                    <div>
                        <q-checkbox v-model="props.row.value" @click="onPermissionChange" />
                    </div>
                </q-td>
            </template>
        </q-table>
    </q-page>
</template>

<style lang="scss" scoped></style>
