<script setup lang="ts">
import { ref, Ref, provide, onMounted, onBeforeUnmount } from 'vue'
import { Dialog } from 'quasar'
import { Node } from '@/models/database/Node'
import { Question } from '@/models/survey/Question'
import { Survey } from '@/models/survey/Survey'
import ObjectSelectionForm from '@/components/common/database/ObjectSelectionForm.vue'
import SurveyQuestionInput from '../survey/SurveyQuestionInput.vue'

interface Props {
  question: Question
  survey: Survey
  isSummary?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isSummary: false,
})

const descriptionOverflowing = ref(false)
const showFullDescription = ref(false)
const descriptionText = ref(null)

function checkOverflow() {
  if (descriptionText.value && showFullDescription.value===false) {
    descriptionText.value.style.overflow = 'visible'; // Temporarily allow overflow to calculate
    descriptionOverflowing.value = descriptionText.value.scrollHeight > descriptionText.value.clientHeight
    descriptionText.value.style.overflow = ''; // Reset overflow style
  }
}

// Set up ResizeObserver to dynamically check overflow in the question description
const resizeObserver = ref(null);
const setupResizeObserver = () => {
  if (descriptionText.value) {
    resizeObserver.value = new ResizeObserver(() => {
      checkOverflow();
    });
    resizeObserver.value.observe(descriptionText.value);
  }
};

onMounted(() => {
  checkOverflow()
  setupResizeObserver()
});

onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect(); // Clean up observer
  }
});

const databaseObject: Ref<Array<Node | null>> = ref([])
provide('databaseObject', databaseObject)
const databaseForInsert = ref(undefined)
provide('databaseForInsert', databaseForInsert)

const databaseChildObject: Ref<Array<any>> = ref([])
provide('databaseChildObject', databaseChildObject)

function isObjectTypePresentInDatabase(): boolean {
  if (
    props.question.databaseObjectModelFieldsMapping !== null &&
    props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName !== ''
  ) {
    return true
  } else {
    return false
  }
}

function showDatabaseObjectSelectionForm() {
  const dialog = Dialog.create({
    component: ObjectSelectionForm,
    componentProps: {
      question: props.question,
      databaseForInsert: databaseForInsert,
    },
  })
    .onOk((data) => {
      console.log("databaseobject selected", data)
      if (data.isChild) {
        console.log("databaseobject selected is child")
        databaseChildObject.value = data.data

        if (props.question.role !== '') {
          props.survey.pages.forEach((page) => {
            page.questions.forEach((question) => {
              if (question.role === props.question.role && question != props.question) {
                console.log("Need to update answer for question", question)
                // updateAnswerFromDatabase(question)
              }
            })
          })
        }

      } else {
        databaseObject.value = data
        updateAnswerFromDatabase(props.question)
        if (props.question.role !== '') {
          props.survey.pages.forEach((page) => {
            page.questions.forEach((question) => {
              if (question.role === props.question.role && question != props.question) {
                updateAnswerFromDatabase(question)
              }
            })
          })
        }
      }
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function updateAnswerFromDatabase(question) {
  console.log("updating role related question", question)
  if (question.type === 'text') {
    let objectFieldName = question.databaseObjectModelFieldsMapping[0].databaseObjectModelFieldName
    let objectField = getField(databaseObject.value, objectFieldName)

    if (objectFieldName === 'Adresse du siège social') {
      let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
      if (objectField.value.fields[1].value !== undefined) {
        address += ' ' + objectField.value.fields[1].value
      }
      if (objectField.value.fields[2].value !== undefined) {
        address += ' ' + objectField.value.fields[2].value
      }
      if (objectField.value.fields[3].value !== undefined) {
        address += ' ' + objectField.value.fields[3].value
      }
      if (objectField.value.fields[4].value !== undefined) {
        address += ' ' + objectField.value.fields[4].value
      }
      if (objectField.value.fields[5].value !== undefined) {
        address += ' ' + objectField.value.fields[5].value
      }
      question.answer.value = address
    } else {
      question.answer.value = objectField.value
    }
  }
  if (question.type === 'number') {
    let objectFeildName = question.databaseObjectModelFieldsMapping[0].databaseObjectModelFieldName
    let objectField = getField(databaseObject.value, objectFeildName)
    question.answer.value = objectField.value
  }
  if (question.type === 'object') {
    databaseObject?.value.fields?.forEach((field) => {
      if (question.label === field.label) {
        question.answer.value = field.value
      }
      if (question.label === 'Dénomination sociale' && field.label === 'Nom') {
        question.answer.value = field.value
      }
      if (question.label === 'Forme sociale' && field.label === 'Forme juridique') {
        question.answer.value = field.value
      }
      if (question.label === 'Forme sociale ' && field.label === 'Forme juridique') {
        question.answer.value = field.value
      }
      if (
        question.label === 'Veuillez renseigner les informations suivantes relatives au capital social' &&
        field.label === 'Capital social'
      ) {
        // question.answer.value = field.value;
        question.options[0].answer.value = field.value
      }
      if (question.label === 'Adresse de siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        question.answer.value = field.value
      }
      if (question.label === 'Numéro de SIREN' && field.label === "Numéro d'immatriculation") {
        question.answer.value = field.value
      }
      if (question.label === "Ville d'immatriculation au RCS" && field.label === "Ville d'immatriculation") {
        question.answer.value = field.value
      }
      if (
        (question.label === 'Adresse' && field.label === 'Adresse') ||
        (question.label === 'Adresse du siège social' && field.label === 'Adresse du siège social') ||
        (question.label === 'Siège social' && field.label === 'Adresse du siège social')
      ) {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        if (objectField.value.fields[3].value !== undefined) {
          address += ' ' + objectField.value.fields[3].value
        }
        if (objectField.value.fields[4].value !== undefined) {
          address += ' ' + objectField.value.fields[4].value
        }
        if (objectField.value.fields[5].value !== undefined) {
          address += ' ' + objectField.value.fields[5].value
        }
        question.answer.value = address
      }

      if (field.label === 'Adresse/Numéro et rue') {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        question.answer.value = address
      }
    })
  } // question type object

  if (question.type === 'multiarray') {
    console.log('question type: multiarray', question, databaseObject.value)
    // databaseObject?.value.forEach((object) => {
    //   object.fields?.forEach((field) => {
    //     console.log(field)
    //     if (
    //       (question.label === 'Adresse' && field.label === 'Adresse') ||
    //       (question.label === 'Adresse du siège social' && field.label === 'Adresse du siège social') ||
    //       (question.label === 'Siège social' && field.label === 'Adresse du siège social')
    //     ) {
    //       let objectField = field
    //       let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
    //       if (objectField.value.fields[1].value !== undefined) {
    //         address += ' ' + objectField.value.fields[1].value
    //       }
    //       if (objectField.value.fields[2].value !== undefined) {
    //         address += ' ' + objectField.value.fields[2].value
    //       }
    //       if (objectField.value.fields[3].value !== undefined) {
    //         address += ' ' + objectField.value.fields[3].value
    //       }
    //       if (objectField.value.fields[4].value !== undefined) {
    //         address += ' ' + objectField.value.fields[4].value
    //       }
    //       if (objectField.value.fields[5].value !== undefined) {
    //         address += ' ' + objectField.value.fields[5].value
    //       }
    //       question.answer.value = address
    //     }
    //   })
    // })
  }
}

function getField(dBObject, fieldLabel: string): any {
  let result = null
  dBObject.fields.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field
    }
  })
  return result
}

function parseText(text: string) {
  return text.replaceAll('\n', '<br />')
}

function hasCompositeLink(question) {
  console.log("hasCompositeLink", question, props.survey)
  if (props.survey.isCompositeProject == true) {
    return false
  }

  if (question.hasOwnProperty('compositeLink') == false) {
    return false
  }

  if (question.compositeLink !== null && question.compositeLink !== "") {
    return true
  }
  return false
}
</script>
<template>
  <div class="column q-mb-md">
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <div class="text-subtitle1 text-weight-medium">
          <span v-html="parseText(question.label)"></span>
          <span v-if="isObjectTypePresentInDatabase() && !isSummary && !hasCompositeLink(question)">
            &nbsp;&nbsp;
            <q-btn @click="showDatabaseObjectSelectionForm" label="Insérer depuis les dossiers permanents" size="12px" outline>
            </q-btn>
          </span>
        </div>
        <div v-if="hasCompositeLink(question)">Réponse héritée de l'opération parente</div>

        <div v-if="question.description && !isSummary" class="description-container q-mt-sm q-pa-sm row items-center no-wrap">
          <div>
            <q-icon name="info" size="md" class="info q-mr-sm"></q-icon>
          </div>
          <div>
            <div ref="descriptionText" v-html="parseText(question.description)"
              :class="{
                'description-full': showFullDescription,
                'description-reduced': !showFullDescription,
                'gradient-overlay': descriptionOverflowing && !showFullDescription
              }"
              @resize:deep="checkOverflow"
            ></div>
            <div v-if="descriptionOverflowing" class="expand-btn-container">
              <q-btn @click="showFullDescription = !showFullDescription" flat dense round
              :style="{ marginTop: showFullDescription ? '0.5em' : '-0.5em' }"
              :icon="showFullDescription ? 'expand_less' : 'expand_more'"/>
            </div>
          </div>
        </div>

      </q-card-section>
      <q-separator inset />
      <q-card-section>
        <SurveyQuestionInput :question="question" v-if="!isSummary" :complete-question="question" :survey="survey">
        </SurveyQuestionInput>
      </q-card-section>
    </q-card>
  </div>
  <!-- <ObjectSelectionForm :question="question"></ObjectSelectionForm> -->
</template>
<style lang="scss" scoped>
:deep(p) {
  margin-bottom: 0px; /* adjust the value to your desired spacing */
}

.description-container {
  border: solid;
  background-color: #eeeeee;
  border-color: #cfd8dc;
  border-width: 0px 8px;
  border-radius: 4px;

  .info {
    color: rgba(64, 104, 200, 0.48);
  }
}

.description-full {
  max-height: none;
}
.description-reduced {
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  position: relative;
  max-height: 8.5em;
}
.gradient-overlay::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #eeeeee);
  pointer-events: none; /* Allows clicks to pass through to text */

}
.expand-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
