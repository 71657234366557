<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'

import DatabaseLayout from '@/components/common/database/DatabaseLayout.vue'

const store = useStore()
store.commit('setPageTitle', 'Dossiers permanents')

const router = useRouter()

const clientId = store.state.currentClient
</script>

<template>
  <q-page padding class="page">
    <DatabaseLayout />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

.page {
  padding: 0px;
}
</style>
