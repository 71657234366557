<script async setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import draggable from 'vuedraggable'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import SubProjects from './SubProjects.vue'
import { removeAccents } from '@/utils/stringUtils'
import { Notify } from 'quasar'

const store = useStore()
store.commit('setPageTitle', 'Gestion des modèles d\'opérations juridiques')

const userApi: UserApi = useApi()

const projectCatalogResponse = await userApi.getProjectCatalog()
const noCodeProjectCatalogResponse = await userApi.getNoCodeProjectCatalog()
provide("noCodeProjectCatalogResponse", noCodeProjectCatalogResponse)


const projectCatalog = ref(projectCatalogResponse.results)
const noCodeProjectCatalog = ref(noCodeProjectCatalogResponse.response.results)

watch(noCodeProjectCatalog, () => {
    console.log("noCodeProjectCatalog updated", noCodeProjectCatalog.value)
    searchProjects()
})

provide("noCodeProjectCatalog", noCodeProjectCatalog)
const clonedItem = ref(null)
provide("clonedItem", clonedItem)

console.log(projectCatalog)
console.log(noCodeProjectCatalog)

noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
    return !(project.name.startsWith("Modèle sec") || project.name.startsWith("Test"))
})

noCodeProjectCatalog.value = noCodeProjectCatalog.value.sort((a, b) => {
    return a.name.localeCompare(b.name)
})

const noCodeProjectCatalogFiltered = ref(noCodeProjectCatalog.value)

const nodes = ref([])
provide("nodes", nodes)

const splitterModel2 = ref(30)

const thumbStyle = {
    right: '1px',
    borderRadius: '5px',
    width: '5px',
    color: 'rgb(71, 71, 71)',
    opacity: '0.2',
    zIndex: 10,

}

function onClone(item) {
    console.log('onClone', item)
    clonedItem.value = item
}

// https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key
function removeParentReferences(nodes) {
    const newNodes = []
    nodes.forEach((node) => {
        let { parent: _, ...newNode } = node
        if (node.children) {
            newNode.children = removeParentReferences(node.children)
        }
        newNodes.push(newNode)
    })
    return newNodes
}

async function save() {
    console.log('save')

    console.log("nodes", nodes.value)

    const newNodes = removeParentReferences(nodes.value)

    console.log("newNodes", newNodes)
    console.log("nodes", nodes.value)

    await userApi.updatePlatformProjectCatalog(newNodes)

    unsavedChanges.value = false
    Notify.create({
        message: `Catalogue d\'opérations enregistré`,
        type: 'primary',
    })
}


function searchProjects() {
    console.log('searchProjects', searchText.value)

    noCodeProjectCatalogFiltered.value = noCodeProjectCatalog.value

    if (searchText.value !== '') {
        // treeRef.value.collapseAll();
        noCodeProjectCatalogFiltered.value = noCodeProjectCatalog.value.filter((project) => {
            const nodeNameLowerCase = removeAccents(project.name.toLowerCase())
            const filterLowerCase = removeAccents(searchText.value.toLowerCase())

            const filterWords = filterLowerCase.split(' ')
            const foundAllWords = filterWords.every((filterWord) => {
                return removeAccents(nodeNameLowerCase).includes(filterWord)
            })

            return foundAllWords
        })
    }
}

const searchText = ref('')

function resetSearch() {
    searchText.value = ''
    searchProjects()
}

const unsavedChanges = ref(false)
provide("unsavedChanges", unsavedChanges)

// https://www.sanwebe.com/2013/02/confirmation-dialog-on-leaving-page-javascript#:~:text=If%20you%20want%20to%20show,leave%20or%20reload%20the%20page.
window.onbeforeunload = function (e) {
    console.log('onbeforeunload', unsavedChanges.value)
    return unsavedChanges.value ? true : undefined
}

onBeforeRouteLeave(async (to, from) => {
    if (unsavedChanges.value == false) {
        return true
    }

    const answer = window.confirm('Les modifications que vous avez apportées ne seront pas enregistrées si vous quittez cette page.')
    if (!answer) {
        return false
    }
})

</script>

<template>
    <q-splitter v-model="splitterModel2" style="height: calc(100vh - 60px)">
        <template v-slot:before>
            <div style="margin-right: 16px; margin-top: 16px;">
                <div class="text-h4">Opérations No-code non classées</div>
                <div style="margin-top: 8px; margin-bottom: 8px">
                    Glissez les opérations dans la colonne de droite pour les classer<br />
                    &nbsp;
                </div>
                <div>
                    <q-input dense outlined v-model="searchText" @keydown.enter.prevent="searchProjects"
                        class="search-input" @update:model-value="searchProjects" bg-color="white">
                        <template v-slot:prepend>
                            <q-icon v-if="searchText === ''" name="search" size="" />
                            <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
                        </template>
                    </q-input>
                    <q-scroll-area style="width: 100%; height: calc(100vh - 220px); margin-top: 8px;"
                        :thumb-style="thumbStyle">
                        <q-list bordered separator>
                            <draggable :list="noCodeProjectCatalogFiltered"
                                :group="{ name: 'g1', pull: 'clone', put: false }" ghost-class="ghost" item-key="id"
                                class="q-gutter-sm drag-cursor" :clone="onClone">
                                <template #item="{ element, index }">
                                    <q-item style="background-color: white;">
                                        <q-item-section>
                                            <q-item-label>{{ element.name }}</q-item-label>
                                            <div style="color: grey;">
                                                Id: {{ element.id }}
                                            </div>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </draggable>
                        </q-list>
                    </q-scroll-area>
                </div>
            </div>
        </template>

        <template v-slot:after>
            <div style="margin-left: 16px; margin-top: 16px; margin-right: 16px;">
                <div class="text-h4">
                    Catalogue d'opérations Come On Law
                    <q-btn dense label="Enregistrer" color="primary" @click="save" :disable="!unsavedChanges"
                        style="margin-left: 8px; padding-left: 8px; padding-right: 8px;"></q-btn>
                </div>
                <div style="margin-top: 8px; margin-bottom: 8px">
                    Survolez les éléments pour voir les actions possibles
                    <br />
                    Glissez les éléments pour les réorganiser
                </div>
                <div>
                    <div style="margin-top: 8px;">
                        <SubProjects></SubProjects>
                    </div>
                </div>
            </div>
        </template>
    </q-splitter>
</template>

<style scoped lang="scss">
.text-h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
    cursor: grab;
}

.search-input {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-top: 10px;
}
</style>
