<script setup lang="ts">
import { ref } from 'vue'
import { Document } from '@/models/v2/document/Document'
import SectionView from './SectionView.vue'

interface Props {
  document: Document
}

const props = withDefaults(defineProps<Props>(), {})

const splitterModel = ref(75)
</script>

<template>
  <div class="document-view col full-height q-gutter-sm">
    <q-scroll-area style="height: 93vh; width: 100%; padding-right: 16px" ref="scrollAreaRef">
      <span class="text-h5">Nom du document : {{ document.name }}</span>
      <SectionView v-for="section in document.sections" :section="section"> </SectionView>
    </q-scroll-area>
  </div>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
.block-element-header {
  display: block;
  width: 200px;
  border: 1px solid;
  padding-left: 5px;
}

.content-block-element-header {
  background-color: #b0e2f2;
}

.control-block-element-header {
  background-color: #e2cb9d;
}

.inline-element-header {
  display: block;
  width: 200px;
  border: 1px solid;
}

.content-inline-element-header {
  background-color: #b0e2f2;
}

.control-inline-element-header {
  background-color: #e2cb9d;
}

.drag-area {
  min-height: 30px;
}

.element-view {
  padding-left: 0px;
  padding-right: 5px;
}

.top-left-corner-angle {
  border-top-left-radius: 0;
}

.element-hover {
  border-color: #e2cb9d;
}

.badge-corner {
  background-color: #e2cb9d;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}

.block-content {
  border-left-color: #b0e2f2;
  border-left-width: 5px;
}

[contenteditable='true']:active,
[contenteditable='true']:focus {
  border: 1px solid #0000001f;
  outline: none;
}

[contenteditable='true']:hover {
  border: 1px solid #0000001f;
  outline: none;
}

[contenteditable='true'] {
  border: 1px solid transparent;
}
</style>
