<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  node: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const newDocumentName: Ref<string> = ref(props.node.name)
const isLoading: Ref<boolean> = ref(false)

async function unlockNode() {
  try {
    isLoading.value = true
    isLoading.value = false
    Notify.create({
      message: 'Fichier déverrouillé',
      type: 'primary',
    })
    emit('ok', newDocumentName.value)
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: "Une erreur est survenue lors du déverrouillage de l'élément",
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Êtes-vous sûr de vouloir déverrouiller ce fichier ?"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        Attention : L'éditeur actuel pourra perdre les modifications en cours.<br /><br />
        Il est préférable de demander à l'éditeur actuel de sauvegarder son travail et de fermer le fichier avant de le
        déverrouiller.
      </template>
      <template #actions>
        <q-btn flat label="Déverrouiller" @click="unlockNode()" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
