<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import RenameEnvironmentDialog from './RenameEnvironmentDialog.vue'
import AddEnvironmentForm from './AddEnvironmentForm.vue'

interface Props {
}
const props = withDefaults(defineProps<Props>(), {
})

const store = useStore()
const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()

let columns = [
  {
    name: 'environment_name',
    label: 'Nom',
    field: 'name',
    align: 'left',
  },
]

const selectedItems = ref([])
const selectedRows = ref([])
const isTableLoading = ref(false)

const environments = await userApi.getAllEnvironments()

const rows = ref(environments.results)

const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 100,
})

const rowsPerPageOptions = [5, 10, 20, 50]

function onAddClick() {
  const dialog = Dialog.create({
    component: AddEnvironmentForm,
    componentProps: {
    },
  })
    .onOk(async (name) => {
      await userApi.createEnvironment(name)
      const environments = await userApi.getAllEnvironments()
      rows.value = environments.results
      dialog.hide()
      Notify.create({
        message: `Marque blanche ${name} créée`,
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditClick() {

  const dialog = Dialog.create({
    component: RenameEnvironmentDialog,
    componentProps: {
      node: selectedRows.value[0],
    },
  })
    .onOk((newName) => {
      selectedRows.value[0].name = newName
      userApi.renameEnvironment(selectedRows.value[0].pkid, newName)
      dialog.hide()
      Notify.create({
        message: `Marque blanche renommée`,
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditDocumentModelCatalogClick() {
  router.push({
    name: 'environments/environment/document_catalog',
    params: {
      environmentId: selectedRows.value[0].id,
      environmentPkid: selectedRows.value[0].pkid
    },
  })
}

function onEditProjectCatalogClick() {
  router.push({
    name: 'environments/environment/project_catalog',
    params: { environmentId: selectedRows.value[0].pkid },
  })
}

function onConfigureSubscriptions() {
  router.push({
    name: 'environments/environment/subscription_modules',
    params: {
      environmentId: selectedRows.value[0].id,
      environmentPkid: selectedRows.value[0].pkid
    },
  })
}

function onConfigureDatabase() {
  Notify.create({
    message: `Cette action sera bientôt disponible`,
    type: 'negative',
  })
}

function onDeleteClick() {
  Notify.create({
    message: `Cette action sera bientôt disponible`,
    type: 'negative',
  })
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable add-button-label="Créer une marque blanche" table-label="project" name="project-table"
    v-model:pagination="pagination" selection="single" :selected-rows="selectedItems" :columns="columns" :rows="rows"
    :is-loading="isTableLoading" :enable-actions="true" :enable-add="true" :rows-per-page-options="rowsPerPageOptions"
    @add-item-click="onAddClick" class="table" @on-row-selected="onRowSelected">
    <template #action-menu-items>
      <ItemActionMenuItem label="Configurer le catalogue d'opérations" @click="onEditProjectCatalogClick" />
      <ItemActionMenuItem label="Configurer le catalogue de modèles unitiaires"
        @click="onEditDocumentModelCatalogClick" />
      <ItemActionMenuItem label="Configurer les abonnements" @click="onConfigureSubscriptions" />
      <ItemActionMenuItem label="Configurer les dossiers permanents" @click="onConfigureDatabase" />
      <ItemActionMenuItem label="Renommer" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
    </template>
  </BaseTable>
</template>

<style lang="scss" scoped>
.table-mobile {
  margin-top: 16px;
}

.previewInNewTabButton {
  color: #cccccc;
  visibility: hidden;
}

.previewInNewTabButton:hover {
  color: #777777;
  background-color: #bbbbbb;
}

.table tr:hover .previewInNewTabButton {
  visibility: visible;
}
</style>
