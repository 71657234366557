<script lang="ts" setup>
import { Dialog, Notify } from 'quasar'
import ObjectUserPermissionsForm from '@/components/common/dialog/ObjectUserPermissionsForm.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
    element: any
    project: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['usersUpdated'])

const authenticatedUser = useAuthenticatedUser()

function addUser(event) {
    event.preventDefault()
    console.log('addUser', props.project)

    const dialog = Dialog.create({
        component: ObjectUserPermissionsForm,
        componentProps: {
            clientId: authenticatedUser.clientId,
            selectedRows: [props.project.value],
            rights: ['view_userproject', 'change_userproject', 'delete_userproject'],
            environment: 'UserProject',
        },
    })
        .onOk(() => {
            dialog.hide()
            emit('usersUpdated')
        })
        .onCancel(() => {
            dialog.hide()
            emit('usersUpdated')
        })
}
</script>

<template>
    <div class="title">
        Utilisateurs
        <q-btn flat dense icon="add" @click="addUser" />
    </div>
</template>

<style lang="scss" scoped>
.title {
    font-size: 1rem;
    font-weight: bold;
    // margin-bottom: 1rem;
}
</style>
