<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'

const unsavedChanges = inject("unsavedChanges")

interface Props {
    element: any
}

const isLoading = ref(false)

const props = withDefaults(defineProps<Props>(), {
})

console.log("FILE FIELD")
</script>

<template></template>