<script lang="ts" setup>
import { inject, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import FileSaver from 'file-saver'
import { Notify, Dialog } from 'quasar'
import RenameFileVersionDialog from './RenameFileVersionDialog.vue'
import DeleteNodeDialog from './DeleteNodeDialog.vue'
import UnlockNodeDialog from './UnlockNodeDialog.vue'
import NodeLockedDialog from './NodeLockedDialog.vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'

import PdfPreviewDialogWithIframe from '@/components/projects/execution/task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'
import MoveDocumentToUploadTaskDialog from './MoveDocumentToUploadTaskDialog.vue'
import { refreshFiles } from './DocumentsCommon'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { format } from 'date-fns'

interface Props {
  element: any
}
const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()
const route = useRoute()

const project = inject(ProjectKey)
const documentTreeNodes = inject('documentTreeNodes')

const menuRef = ref(null)

// if (route.query.documentId && route.query.preview) {
//   console.log("have a document to preview", route.query.documentId);
// }

onMounted(() => {
  console.log('FileVersionNode - onMounted')
  props.element.anchorElement = menuRef.value?.$el
})

async function renameFileVersion(node, newName) {
  await userApi.renameDocumentTreeNodeVersion(project?.value.id, node.id, node.version.id, newName)
  documentTreeNodes.value = await refreshFiles(userApi, project)
}

function showRenameVersionDialog(node) {
  const dialog = Dialog.create({
    component: RenameFileVersionDialog,
    componentProps: {
      node: node,
    },
  })
    .onOk((newName) => {
      node.name = newName
      renameFileVersion(node, newName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function deleteNode(node) {
  await userApi.deleteDocumentTreeNode(project?.value.id, node.id)

  // console.log("resetting selected node")
  // documentTreeSelectedNode.value = null;

  documentTreeNodes.value = await refreshFiles(userApi, project)
}

function showDeleteFileModal(node) {
  const dialog = Dialog.create({
    component: DeleteNodeDialog,
    componentProps: {
      node: node,
    },
  })
    .onOk(() => {
      deleteNode(node)

      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

const authenticatedUser = useAuthenticatedUser()

// https://developer.mozilla.org/fr/docs/Web/API/btoa
// https://developer.mozilla.org/fr/docs/Glossary/Base64

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)))
}

/* Décoder un tableau d'octets depuis une chaîne en base64 */

function b64ToUint6(nChr) {
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
      ? nChr - 71
      : nChr > 47 && nChr < 58
        ? nChr + 4
        : nChr === 43
          ? 62
          : nChr === 47
            ? 63
            : 0
}

function base64DecToArr(sBase64, nBlocksSize) {
  // Seulement nécessaire si la chaîne en base64 contient
  // des espaces tel qu'un retour de chariot.
  const sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, '')

  const nInLen = sB64Enc.length
  const nOutLen = nBlocksSize ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize : (nInLen * 3 + 1) >> 2
  const taBytes = new Uint8Array(nOutLen)
  let nMod3
  let nMod4
  let nUint24 = 0
  let nOutIdx = 0
  for (let nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4))
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      nMod3 = 0
      while (nMod3 < 3 && nOutIdx < nOutLen) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255
        nMod3++
        nOutIdx++
      }
      nUint24 = 0
    }
  }
  return taBytes
}

/* Chaîne en base64 encodée vers un tableau */
function uint6ToB64(nUint6) {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
      ? nUint6 + 71
      : nUint6 < 62
        ? nUint6 - 4
        : nUint6 === 62
          ? 43
          : nUint6 === 63
            ? 47
            : 65
}

function base64EncArr(aBytes) {
  let nMod3 = 2
  let sB64Enc = ''
  const nLen = aBytes.length
  let nUint24 = 0
  for (let nIdx = 0; nIdx < nLen; nIdx++) {
    nMod3 = nIdx % 3
    // Décommenter le test qui suit afin de découper la
    // chaîne base64 en plusieurs lignes de 80 caractères
    //if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
    //  sB64Enc += "\r\n";
    //}
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24)
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCodePoint(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63),
      )
      nUint24 = 0
    }
  }
  return sB64Enc.substring(0, sB64Enc.length - 2 + nMod3) + (nMod3 === 2 ? '' : nMod3 === 1 ? '=' : '==')
}

/* Tableau UTF-8 vers une chaîne JS et vice versa */
function UTF8ArrToStr(aBytes) {
  let sView = ''
  let nPart
  const nLen = aBytes.length
  for (let nIdx = 0; nIdx < nLen; nIdx++) {
    nPart = aBytes[nIdx]
    sView += String.fromCodePoint(
      nPart > 251 && nPart < 254 && nIdx + 5 < nLen /* six octets */
        ? /* (nPart - 252 << 30) n'est pas possible pour ECMAScript donc, on utilise un contournement : */
        (nPart - 252) * 1073741824 +
        ((aBytes[++nIdx] - 128) << 24) +
        ((aBytes[++nIdx] - 128) << 18) +
        ((aBytes[++nIdx] - 128) << 12) +
        ((aBytes[++nIdx] - 128) << 6) +
        aBytes[++nIdx] -
        128
        : nPart > 247 && nPart < 252 && nIdx + 4 < nLen /* cinq octets */
          ? ((nPart - 248) << 24) +
          ((aBytes[++nIdx] - 128) << 18) +
          ((aBytes[++nIdx] - 128) << 12) +
          ((aBytes[++nIdx] - 128) << 6) +
          aBytes[++nIdx] -
          128
          : nPart > 239 && nPart < 248 && nIdx + 3 < nLen /* quatre octets */
            ? ((nPart - 240) << 18) + ((aBytes[++nIdx] - 128) << 12) + ((aBytes[++nIdx] - 128) << 6) + aBytes[++nIdx] - 128
            : nPart > 223 && nPart < 240 && nIdx + 2 < nLen /* trois octets */
              ? ((nPart - 224) << 12) + ((aBytes[++nIdx] - 128) << 6) + aBytes[++nIdx] - 128
              : nPart > 191 && nPart < 224 && nIdx + 1 < nLen /* deux octets */
                ? ((nPart - 192) << 6) + aBytes[++nIdx] - 128
                : /* nPart < 127 ? */ /* un octet */
                nPart,
    )
  }
  return sView
}

function strToUTF8Arr(sDOMStr) {
  let aBytes
  let nChr
  const nStrLen = sDOMStr.length
  let nArrLen = 0

  /* correspondance… */
  for (let nMapIdx = 0; nMapIdx < nStrLen; nMapIdx++) {
    nChr = sDOMStr.codePointAt(nMapIdx)
    if (nChr >= 0x10000) {
      nMapIdx++
    }
    nArrLen += nChr < 0x80 ? 1 : nChr < 0x800 ? 2 : nChr < 0x10000 ? 3 : nChr < 0x200000 ? 4 : nChr < 0x4000000 ? 5 : 6
  }
  aBytes = new Uint8Array(nArrLen)

  /* transposition… */
  let nIdx = 0
  let nChrIdx = 0
  while (nIdx < nArrLen) {
    nChr = sDOMStr.codePointAt(nChrIdx)
    if (nChr < 128) {
      /* un octet */
      aBytes[nIdx++] = nChr
    } else if (nChr < 0x800) {
      /* deux octets */
      aBytes[nIdx++] = 192 + (nChr >>> 6)
      aBytes[nIdx++] = 128 + (nChr & 63)
    } else if (nChr < 0x10000) {
      /* trois octets */
      aBytes[nIdx++] = 224 + (nChr >>> 12)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
    } else if (nChr < 0x200000) {
      /* quatre octets */
      aBytes[nIdx++] = 240 + (nChr >>> 18)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    } else if (nChr < 0x4000000) {
      /* cinq octets */
      aBytes[nIdx++] = 248 + (nChr >>> 24)
      aBytes[nIdx++] = 128 + ((nChr >>> 18) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    } /* if (nChr <= 0x7fffffff) */ else {
      /* six octets */
      aBytes[nIdx++] = 252 + (nChr >>> 30)
      aBytes[nIdx++] = 128 + ((nChr >>> 24) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 18) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 12) & 63)
      aBytes[nIdx++] = 128 + ((nChr >>> 6) & 63)
      aBytes[nIdx++] = 128 + (nChr & 63)
      nChrIdx++
    }
    nChrIdx++
  }
  return aBytes
}

async function checkIfCanEditInEditor(element, editor) {
  const treeNode = await userApi.getDocumentTreeNode(project?.value.id, element.parent.id)
  element.editingUser = treeNode.editingUser

  if (!element.editingUser) {
    // Notify.create({
    //   message: "Ouverture de l'éditeur en cours...",
    //   type: "primary",
    // });

    editFile(element, editor)

    return
  }

  const dialog = Dialog.create({
    component: NodeLockedDialog,
    componentProps: {
      node: element,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function editFile(element, editor) {
  let command = ''
  if (editor === 'Word') {
    command = 'launch_word'
  }
  if (editor === 'Excel') {
    command = 'launch_excel'
  }
  if (editor === 'PowerPoint') {
    command = 'launch_powerpoint'
  }

  console.log('editing', element)

  const baseUrl =
    'https://api.staging.comeonlaw.com/' +
    userApi.api.getCurrentClientBaseUrl() +
    '/projects/' +
    project?.value.id +
    '/document_tree/nodes/' +
    element.parent.id

  const downloadUrl = '/versions/' + element.version.id + '/download_file/fake_filename?responseType=blob'
  const uploadUrl = '/update_file'
  const lockUrl = '/lock'
  const unlockUrl = '/unlock'
  const fileInfoUrl = '/file_info'

  const deepLinkData = {
    command: 'launch_word',
    user: {
      id: authenticatedUser.id,
      email: authenticatedUser.email,
      firstName: authenticatedUser.firstName,
      lastName: authenticatedUser.lastName,
      fullName: authenticatedUser.fullname,
      accessToken: authenticatedUser.accessToken,
    },
    file: {
      name: element.name,
      type: 'project_document',
      id: element.id,
      baseUrl: baseUrl,
      download: {
        method: 'GET',
        url: downloadUrl,
      },
      upload: {
        method: 'POST',
        url: uploadUrl,
      },
      lock: {
        method: 'POST',
        url: lockUrl,
      },
      unlock: {
        method: 'POST',
        url: unlockUrl,
      },
      info: {
        method: 'GET',
        url: fileInfoUrl,
      },
    },
  }

  console.log(deepLinkData)

  // const base64Encoded = utf8_to_b64(JSON.stringify(deepLinkData));
  const deepLinkDataUTF8 = strToUTF8Arr(JSON.stringify(deepLinkData))
  const base64Encoded = base64EncArr(deepLinkDataUTF8)

  const deepLinkUrl = 'office-manager://files/open?data=' + encodeURIComponent(base64Encoded)

  console.log(deepLinkUrl)
  console.log(deepLinkUrl.length)

  window.location = deepLinkUrl
}

async function downloadFile(node) {
  console.log('downloading file version', node)
  try {
    const doc = await userApi.downloadDocumentTreeFileVersion(project?.value.id, node.parent.id, node.version.id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, node.name)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

async function showPdfPreview(node) {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(node.name)

  let fileUrl =
    BASE_URL +
    userApi.getCurrentClientBaseUrl() +
    `/projects/${project.value.id}/document_tree/nodes/${node.parent.id}/versions/${node.version.id}/download_pdf_preview/${filenameConverted}?type=preview`
  const dialog = Dialog.create({
    component: PdfPreviewDialogWithIframe,
    componentProps: {
      documentName: node.name,
      fileUrl: fileUrl,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showPdfPreviewInNewTab(node) {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(node.name)

  let fileUrl =
    BASE_URL +
    userApi.getCurrentClientBaseUrl() +
    `/projects/${project.value.id}/document_tree/nodes/${node.parent.id}/versions/${node.version.id}/download_pdf_preview/${filenameConverted}?type=preview`
  window.open(fileUrl, '_blank').focus()
}

function showMoveFileToTaskDialog() {
  const dialog = Dialog.create({
    component: MoveDocumentToUploadTaskDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk(async (destinationTask) => {
      dialog.hide()
      await userApi.moveDocumentTreeDocumentToUploadTask(
        project?.value.id,
        props.element.id,
        destinationTask.value.value.id,
      )

      Notify.create({
        message: 'Document déplacé',
        type: 'primary',
      })

      // refreshFiles(userApi, project, documentTreeNodes);
      window.history.go()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function isPreviewableFile(file) {
  if (file.name.endsWith('.pdf') || file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
    return true
  } else {
    return false
  }
}

function startDrag(evt, item) {
  evt.dataTransfer.dropEffect = 'move'
  evt.dataTransfer.effectAllowed = 'move'
  evt.dataTransfer.setData('itemId', item.id)
}

function canMoveTo(node, destination) {
  let currentNode = destination

  while (currentNode !== undefined) {
    if (node.id === currentNode.id) {
      return false
    }
    currentNode = currentNode.parent
  }

  return true
}

function getNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    const childNode = getNode(currentNode.children, nodeId)
    if (childNode !== undefined) {
      return childNode
    }
  }
  return undefined
}

async function onDrop(evt) {
  const itemId = evt.dataTransfer.getData('itemId')
  const item = getNode(documentTreeNodes.value, itemId)

  if (!canMoveTo(item, props.element)) {
    return
  }

  await userApi.moveDocumentTreeNode(project?.value.id, itemId, props.element.parentId)

  documentTreeNodes.value = await refreshFiles(userApi, project)
}

function isWordFile(file) {
  if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
    return true
  } else {
    return false
  }
}

function isExcelFile(file) {
  if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
    return true
  } else {
    return false
  }
}

function isPowerPointFile(file) {
  if (file.name.endsWith('.pptx') || file.name.endsWith('.ppt')) {
    return true
  } else {
    return false
  }
}

function onMenuClick(event) {
  console.log('on menu click', event)
  event.preventDefault()
}

async function showUnlockDialog(node) {
  const dialog = Dialog.create({
    component: UnlockNodeDialog,
    componentProps: {
      node: node,
    },
  })
    .onOk(async (newName) => {
      await userApi.unlockDocumentTreeNode(project?.value.id, node.id)
      documentTreeNodes.value = await refreshFiles(userApi, project)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function formatDate(date) {
  return format(new Date(date), 'dd/MM/yyyy à HH:mm:ss')
}

function onDoublelick(event) {
  console.log('double click', event)
  if (isPreviewableFile(props.element)) {
    showPdfPreview(props.element)
  }
}

function onAuxClick(event) {
  console.log('aux click', event)
  if (event.button == 1) {
    console.log('middle click')
    if (isPreviewableFile(props.element)) {
      showPdfPreviewInNewTab(props.element)
    }
  }

  if (event.button == 2) {
    console.log('right click')
    onContextMenu(event)
  }
}

const contextualMenuRef = ref(null)

function onContextMenu(event) {
  console.log('context menu', documentTreeSelectedNode.value, props.element)
  event.preventDefault()
  documentTreeSelectedNode.value = props.element.id
  contextualMenuRef.value.show(event)
}


</script>

<template>
  <div class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node"
    draggable="true" @dragstart="startDrag($event, element)" @drop="onDrop($event)" @dragover.prevent @dragenter.prevent
    @dblclick="onDoublelick" @auxclick="onAuxClick" @contextmenu="onContextMenu" ref="nodeRef">
    <q-menu touch-position context-menu ref="contextualMenuRef" @before-show="onContextMenuBeforeShow"
      @show="onContextMenuShow" @hide="onContextMenuHide">
      <q-list dense>
        <!-- <q-item v-if="element.editingUser" clickable v-close-popup @click="showUnlockDialog(element)">
                            <q-item-section>
                                <q-item-label>Déverrouiller le fichier</q-item-label>
                            </q-item-section>
                        </q-item> -->
        <q-item clickable v-close-popup @click="showRenameVersionDialog(element)">
          <q-item-section>
            <q-item-label>Nommer la version</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup v-if="isWordFile(element)" @click="checkIfCanEditInEditor(element, 'Word')">
          <q-item-section>
            <q-item-label>Editer dans Word</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup v-if="isExcelFile(element)" @click="checkIfCanEditInEditor(element, 'Excel')">
          <q-item-section>
            <q-item-label>Editer dans Excel</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup v-if="isPowerPointFile(element)"
          @click="checkIfCanEditInEditor(element, 'PowerPoint')">
          <q-item-section>
            <q-item-label>Editer dans PowerPoint</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="downloadFile(element)">
          <q-item-section>
            <q-item-label>Télécharger</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup v-if="isPreviewableFile(element)" @click="showPdfPreview(element)" class="row">
          <q-item-section>
            <q-item-label>Prévisualiser</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn @click="showPdfPreviewInNewTab(element)" dense flat round icon="open_in_new"
              class="previewInNewTabButton" @click.stop></q-btn>
          </q-item-section>
        </q-item>

        <!-- <q-item clickable v-close-popup @click="showMoveFileToTaskDialog(element)">
                            <q-item-section>
                                <q-item-label>Déplacer dans une tâche</q-item-label>
                            </q-item-section>
                        </q-item> -->
        <!-- <q-item clickable v-close-popup @click="showDeleteFileModal(element)">
                            <q-item-section>
                                <q-item-label text-color="negative">Supprimer</q-item-label>
                            </q-item-section>
                        </q-item> -->
      </q-list>
    </q-menu>

    <!-- <q-item clickable v-ripple dense style="padding: 0px"> -->
    <!-- <span class="justify-center">
                <q-icon :name="element.icon || 'description'" size="28px" class="q-mr-sm" color="primary" />
            </span> -->
    <span class="col-auto" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
      Version {{ element.version.name }} par
      {{ element.version.user.fullName }}
      du {{ formatDate(element.version.createdAt) }}
      <!-- <span v-if="element.editingUser" class="editing-user">En cours d'édition par
                    {{ element.editingUser.fullName }}</span> -->
    </span>

    <!-- space filler -->
    <div class="col" style="overflow: hidden"></div>

    <span class="col-auto" style="overflow: hidden">
      <q-btn-dropdown flat rounded dropdown-icon="more_vert" style="padding-left: 8px; padding-right: 8px" clickable
        @click.stop="onMenuClick" ref="menuRef">
        <q-list dense>
          <!-- <q-item v-if="element.editingUser" clickable v-close-popup @click="showUnlockDialog(element)">
                            <q-item-section>
                                <q-item-label>Déverrouiller le fichier</q-item-label>
                            </q-item-section>
                        </q-item> -->
          <q-item clickable v-close-popup @click="showRenameVersionDialog(element)">
            <q-item-section>
              <q-item-label>Nommer la version</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup v-if="isWordFile(element)" @click="checkIfCanEditInEditor(element, 'Word')">
            <q-item-section>
              <q-item-label>Editer dans Word</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup v-if="isExcelFile(element)" @click="checkIfCanEditInEditor(element, 'Excel')">
            <q-item-section>
              <q-item-label>Editer dans Excel</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup v-if="isPowerPointFile(element)"
            @click="checkIfCanEditInEditor(element, 'PowerPoint')">
            <q-item-section>
              <q-item-label>Editer dans PowerPoint</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="downloadFile(element)">
            <q-item-section>
              <q-item-label>Télécharger</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup v-if="isPreviewableFile(element)" @click="showPdfPreview(element)"
            class="row">
            <q-item-section>
              <q-item-label>Prévisualiser</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn @click="showPdfPreviewInNewTab(element)" dense flat round icon="open_in_new"
                class="previewInNewTabButton" @click.stop></q-btn>
            </q-item-section>
          </q-item>

          <!-- <q-item clickable v-close-popup @click="showMoveFileToTaskDialog(element)">
                            <q-item-section>
                                <q-item-label>Déplacer dans une tâche</q-item-label>
                            </q-item-section>
                        </q-item> -->
          <!-- <q-item clickable v-close-popup @click="showDeleteFileModal(element)">
                            <q-item-section>
                                <q-item-label text-color="negative">Supprimer</q-item-label>
                            </q-item-section>
                        </q-item> -->
        </q-list>
      </q-btn-dropdown>
    </span>
    <!-- </q-item> -->
  </div>
</template>

<style lang="scss" scoped>
.file-name {
  font-size: 14px;
  cursor: pointer;
}

.file-date {
  color: #2c2c2c;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.drop-zone {
  // margin-bottom: 10px;
  // padding: 10px;
}

.drag-el {
  // cursor: grab;
  // margin-bottom: 10px;
  // padding: 5px;
}

.editing-user {
  font-style: italic;
  color: #431010;
}

.previewInNewTabButton {
  color: #cccccc;
}

.previewInNewTabButton:hover {
  color: #777777;
  background-color: #bbbbbb;
}
</style>
