<script setup lang="ts">
import { ref, Ref, inject } from 'vue'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { Notify } from 'quasar'
import UserApi from '@/services/api/core/UserApi'
import BaseFileInput from '@/components/base/BaseFileInput.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import draggable from 'vuedraggable'
import FileSaver from 'file-saver'

const store = useStore()
store.commit('setPageTitle', 'PoC v2 - Concat PDF')
const emit = defineEmits(['stepBack'])

const userApi: UserApi = useApi()
const filesToUpload: Ref<Array<File>> = inject('filesToUpload', ref([]))

const addBates = ref(false)
const prefix = ref('')
const sufix = ref('')
const position = ref('En haut à gauche')

// async function uploadFiles() {
//   if (filesToUpload.value) {
//     try {
//       const response = await userApi.uploadPdfToMerge(filesToUpload.value, {
//         prefix: prefix.value,
//         sufix: sufix.value,
//         position: position.value,
//       })

//       const reader = new FileReader()
//       reader.readAsArrayBuffer(new Blob([response]))
//       reader.onload = function () {
//         const newFile = new File([new Blob([reader.result])], 'merged.pdf', {
//           type: 'application/octet-stream',
//         })

//         FileSaver(newFile, 'merged.pdf')
//       }

//       Notify.create({
//         message: 'Fusion effectuée avec succès',
//         type: 'primary',
//       })
//       filesToUpload.value = []
//     } catch (e) {
//       Notify.create({
//         message: 'La fusion des PDFs a échouée',
//         type: 'negative',
//       })
//     }
//   }
// }

function checkFileType(files): void {
  return files.filter((file) => file.type === 'application/pdf')
}

function batesChanged(): void {
  prefix.value = ''
  sufix.value = ''
  position.value = ''
}
</script>

<template>
  <BaseFileInput
    v-model="filesToUpload"
    label="Sélectionnez des annexes à fusionner."
    rejected-message="Un ou plusieurs fichiers ne respectent pas les contraintes (PDF et 100Mo max.)"
    hint-message="PDF et 100Mo max."
    :multiple="true"
    :filter="checkFileType"
    :show-import-button="false"
  />
  <q-card flat bordered class="q-mt-xl" v-if="filesToUpload?.length">
    <q-card-section class="q-px-none q-pb-none q-pt-sm">
      <q-list>
        <q-item-label header>Annexes</q-item-label>
        <draggable :list="filesToUpload" ghost-class="ghost" handle=".handle">
          <template #item="{ element, index }">
            <q-item clickable v-ripple>
              <q-item-section avatar class="handle">
                <q-icon color="black" name="drag_indicator" />
              </q-item-section>

              <q-item-section>
                <div class="row full-width justify-start items-center q-gutter-sm">
                  <span>{{ element.name }}</span>
                </div>
              </q-item-section>
            </q-item>
          </template>
        </draggable>
      </q-list>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section>
      <BaseCheckbox v-model="addBates" @update:model-value="batesChanged" label="Ajouter une numérotation Bates" />
      <div class="row q-mt-md">
        <BaseInput :disable="!addBates" v-model="prefix" dense label="Préfixe" class="col-4 q-pr-md" />
        <BaseInput :disable="!addBates" v-model="sufix" dense label="Suffixe" class="col-4 q-pr-md" />
        <BaseSelect
          :disable="!addBates"
          v-model="position"
          :options="['En haut à gauche', 'En haut à droite', 'En bas à gauche', 'En bas à droite']"
          dense
          emit-value
          map-options
          label="Position"
          class="col-4"
        />
      </div>
    </q-card-section>
  </q-card>
  <div class="row items-baseline float-right">
    <!-- <q-btn
        v-if="filesToUpload?.length"
        label="Fusionner les PDF"
        color="primary"
        class="q-mt-md float-right"
        @click="uploadFiles"
      ></q-btn> -->
  </div>
</template>
<style lang="scss" scoped></style>
