<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { HeadingBlock } from '@/models/v2/document/elements/content/block/HeadingBlock'
import InlineElementView from '../../../InlineElementView.vue'

interface Props {
  data: HeadingBlock
}

const props = withDefaults(defineProps<Props>(), {})

const isMounted = ref(false)

function getInlineElementContainerSelector(element) {
  return "[inline-element-id='" + element.id + "']"
}

onMounted(() => {
  isMounted.value = true
})
</script>

<template>
  <div class="text-h6 q-ma-none full-width heading" contenteditable="false" v-html="data.content"></div>
  <span v-for="element in data.elements">
    <Teleport :to="getInlineElementContainerSelector(element)" v-if="isMounted">
      <InlineElementView :element="element"></InlineElementView>
    </Teleport>
  </span>
</template>

<style lang="scss" scoped>
.heading {
  padding-top: 16px;
}
</style>
