<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import BaseCheckBox from '@/components/base/BaseCheckbox.vue'
import { format } from 'date-fns'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  task: Task
}
const props = withDefaults(defineProps<Props>(), {})
const userApi: UserApi = useApi()
const documents: Ref<Array<any>> = ref([])
const documentsSelected: Ref<Array<any>> = ref([])
const isDialogLoading: Ref<boolean> = ref(false)
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

async function beforeShow() {
  try {
    isLoading.value = true
    documents.value = await userApi.getDocumentsSignatureProcess(props.task.id)
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
    console.error(e)
  }
}

async function signDocuments() {
  try {
    isDialogLoading.value = true
    await userApi.startSignatureProcess(props.task.id, documentsSelected.value)
    Notify.create({
      message: 'Un email de confirmation a été envoyé aux signataires',
      type: 'primary',
    })
    isDialogLoading.value = false
    emit('ok')
  } catch (e) {
    dialogRef.value?.hide()
    Notify.create({
      message: 'La signature a échouée',
      type: 'negative',
    })
    isDialogLoading.value = false
  }
}

function beforeHide() {
  documentsSelected.value = []
}

function selectAllDocument() {
  if (documentsSelected.value.length === documents.value.length) {
    documentsSelected.value = []
  } else {
    documentsSelected.value = [...documents.value]
  }
}

function getButtonLabel(): string {
  return documentsSelected.value.length === documents.value.length ? 'Annuler la sélection' : 'Tout sélectionner'
}
</script>

<template>
  <q-dialog ref="dialogRef" @show="beforeShow()" @hide="beforeHide()">
    <BaseDialog
      title="Signature"
      :is-dialog-loading="isDialogLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <q-list bordered padding>
          <q-item-label header class="row justify-between items-center">
            <span> Sélectionnez les documents que vous souhaitez signer. </span>
            <q-btn outline color="primary" :label="getButtonLabel()" @click="selectAllDocument"></q-btn>
          </q-item-label>
          <div v-if="!isLoading">
            <q-item v-for="document in documents">
              <q-item-section side top>
                <BaseCheckBox v-model="documentsSelected" :val="document"> </BaseCheckBox>
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ document.name }}</q-item-label>
                <q-item-label caption>
                  <span>
                    Dernière génération :
                    {{ format(new Date(document.lastGeneration), 'dd/MM/yyyy HH:mm') }}
                  </span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>
          <div v-if="isLoading">
            <q-item v-for="n in 3">
              <q-item-section side top>
                <BaseCheckBox v-model="documentsSelected" color="grey" disabled></BaseCheckBox>
              </q-item-section>

              <q-item-section>
                <q-item-label> <q-skeleton width="55%"></q-skeleton></q-item-label>
                <q-item-label caption>
                  <q-skeleton width="195px"></q-skeleton>
                </q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </template>
      <template #actions>
        <q-btn flat label="Signer" color="primary" :disable="documentsSelected.length === 0" @click="signDocuments" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
