<script setup lang="ts">
import { inject } from 'vue'
import { Dialog } from 'quasar'
import draggable from 'vuedraggable'
import VariableDetailsDialog from './dialogs/VariableDetailsDialog.vue'

const variables = inject('variables')

function showVariableDetails(variable) {
  const dialog = Dialog.create({
    component: VariableDetailsDialog,
    componentProps: {},
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div class="variables-list">
    <q-scroll-area style="height: 93vh; width: 100%" ref="scrollAreaRef">
      <div class="elements-menu">
        <q-list dense>
          <q-item-label header>Variables</q-item-label>
          <draggable
            :list="variables"
            :group="{ name: 'g1', pull: 'clone', put: false }"
            ghost-class="ghost"
            item-key="name"
          >
            <template #item="{ element, index }">
              <q-item clickable v-ripple @click="showVariableDetails(element)">
                {{ element.name }}
              </q-item>
            </template>
          </draggable>
        </q-list>
      </div>
    </q-scroll-area>
  </div>
</template>

<style lang="scss" scoped>
.variables-list {
  width: 250px;
}
</style>
