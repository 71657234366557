<script async setup lang="ts">
import { ref, computed, watch, nextTick, inject, provide } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { removeAccents } from '@/utils/stringUtils'
import DocumentSectionNode from './DocumentSectionNode.vue'

const store = useStore()
store.commit('setPageTitleComponent', 'Modèles secs visibles dans la marque blanche')

const userApi: UserApi = useApi()
const route = useRoute()

const expandedNodes = ref([])
const searchText = ref('')
const treeFilter = ref('')
const treeRef = ref(null)
provide("treeRef", treeRef)

const nodes = ref([])

const environment = await userApi.getEnvironment(route.params.environmentPkid)
const environmentDocumentCatalogResponse = await userApi.getEnvironmentDocumentCatalog(route.params.environmentPkid)

const ticked = ref(environmentDocumentCatalogResponse.catalogJson)

nodes.value = [
    {
        id: 'root',
        label: 'root',
        type: 'root',
        children: [
            {
                id: "Catégories",
                label: "Catégories",
                type: "genericModels",
                children: [
                    {
                        id: "ATMP",
                        label: "ATMP (aucun modèle pour l'instant)",
                        type: "category",
                    },
                    // {
                    //     id: "Commandement de payer valant saisie immobilière",
                    //     label: "Commandement de payer valant saisie immobilière",
                    //     type: "category",
                    // },
                    {
                        id: "Commercial",
                        label: "Commercial",
                        type: "category",
                    },
                    {
                        id: "Corporate",
                        label: "Corporate",
                        type: "category",
                    },
                    {
                        id: "Déontologie de l'avocat",
                        label: "Déontologie de l'avocat",
                        type: "category",
                    },
                    {
                        id: "Procédure civile",
                        label: "Procédure civile",
                        type: "category",
                    },
                    {
                        id: "Procédure pénale",
                        label: "Procédure pénale",
                        type: "category",
                    },
                    {
                        id: "Social",
                        label: "Social",
                        type: "category",
                    },
                    {
                        id: "Voies d'exécution",
                        label: "Voies d'exécution",
                        type: "category",
                    },
                ]
            }
        ]
    },

]

const unsavedChanges = ref(false)
provide("unsavedChanges", unsavedChanges)

console.log("nodes", nodes.value)

function addSearchText(node) {
    let currentNode = node

    if (node.searchText == undefined) {
        node.searchText = ''
    }
    node.searchText = node.searchText + ' ' + node.label

    while (currentNode != undefined) {
        node.searchText = node.searchText + ' ' + currentNode.label
        currentNode = currentNode.parent
    }
}

function parseNodes(nodes, parent) {
    if (nodes == undefined) {
        return
    }
    nodes.forEach((node) => {
        node.parent = parent
        if (node.type === 'root') {
            node.tickable = false
            node.noTick = true
        } else if (node.type === 'genericModels') {
            node.tickable = false
            node.noTick = true
            node.textStyle = 'font-size: 16px; font-weight: 700;'
        } else if (node.type === 'category') {
            node.tickable = true
            node.noTick = false
            node.textStyle = 'font-size: 16px; font-weight: 500;'
        }
        addSearchText(node)
        parseNodes(node.children, node)
    })
}

parseNodes(nodes.value, undefined)

function filterNodes(nodes, parent) {
    if (nodes == undefined) {
        return
    }

    const childrenToRemove = []

    nodes.forEach((node) => {
        if (node.type == 'basicModels' || node.type == 'basicModel') {
            childrenToRemove.push(node)
        }

        filterNodes(node.children, node)
    })

    if (parent != undefined) {
        console.log('childrenToRemove', childrenToRemove)
        parent.children = nodes.filter((node) => {
            return !childrenToRemove.includes(node)
        })
    }
}

filterNodes(nodes.value, undefined)

function removeEmptySections(nodes, parent) {
    if (nodes == undefined) {
        return
    }

    nodes.forEach((node) => {
        removeEmptySections(node.children, node)
    })

    console.log(parent, nodes.length)
    if (parent && parent.children.length == 0) {
        if (parent.parent) {
            parent.parent.children = parent.parent.children.filter((node) => {
                return node !== parent
            })
        }
    }
}

removeEmptySections(nodes.value, undefined)

function searchProjects() {
    console.log('searchProjects', searchText.value)

    if (searchText.value === '') {
        // treeRef.value.collapseAll();
        expandedNodes.value = ['root']
        treeRef.value.expandAll()
    } else {
        treeRef.value.expandAll()
    }

    treeFilter.value = searchText.value
}

function resetSearch() {
    searchText.value = ''
    searchProjects()
}

function applyTreeFilter(node, filter) {
    console.log("applyTreeFilter", node, filter, searchText.value, showSelectedProjects.value, showUnselectedProjects.value)

    if (node.type === 'project') {
        if (!showSelectedProjects.value && ticked.value.includes(node.id)) {
            console.log("filtering node", node, showSelectedProjects.value)
            return false
        }

        if (!showUnselectedProjects.value && !ticked.value.includes(node.id)) {
            console.log("filtering node", node, showSelectedProjects.value)
            return false
        }
    }

    if (searchText.value == '') {
        return true
    }

    const nodeNameLowerCase = removeAccents(node.searchText.toLowerCase())
    const filterLowerCase = removeAccents(searchText.value.toLowerCase())

    const filterWords = filterLowerCase.split(' ')
    const foundAllWords = filterWords.every((filterWord) => {
        return removeAccents(nodeNameLowerCase).includes(filterWord)
    })

    if (foundAllWords && filter !== '') {
        // expandToNode2(documentTreeNodes, node.id, expandedNodes);
    }

    console.log("foundAllWords", foundAllWords)
    return foundAllWords
}

const scrollAreaOffset = ref(0)

const scrollAreaStyle = computed(() => {
    console.log('computed', scrollAreaOffset.value)
    let width = '100%'
    if (treeRef.value != null) {
        console.log('treeRef', treeRef.value.$el, treeRef.value.$el.clientWidth)
        width = '100%'
    }
    return {
        width: width,
        height: 'calc(100vh - 140px - ' + scrollAreaOffset.value + 'px)',
        paddingRight: '5px',
        backgroundColor: 'white',
        marginRight: '8px',
    }
})

const thumbStyle = {
    right: '1px',
    borderRadius: '5px',
    width: '5px',
    color: 'rgb(71, 71, 71)',
    opacity: '0.2',
    zIndex: 10,
}

function myTweak(offset) {
    console.log('myTweak', offset)
    return { minHeight: offset ? `calc(100vh - ${offset}px - 40px - 16px)` : '100vh' }
}

async function saveCatalog() {
    await userApi.updateEnvironmentDocumentCatalog(environment.pkid, ticked.value)
    Notify.create({
        message: `Catalogue de modèles secs enregistré avec succès`,
        type: 'primary',
    })
    unsavedChanges.value = false
}

watch(ticked, (newValue, oldValue) => {
    console.log('ticked', newValue, oldValue)
    unsavedChanges.value = true
})

// https://www.sanwebe.com/2013/02/confirmation-dialog-on-leaving-page-javascript#:~:text=If%20you%20want%20to%20show,leave%20or%20reload%20the%20page.
window.onbeforeunload = function (e) {
    console.log('onbeforeunload', unsavedChanges.value)
    return unsavedChanges.value ? true : undefined
}

onBeforeRouteLeave(async (to, from) => {
    if (unsavedChanges.value == false) {
        return true
    }

    const answer = window.confirm('Les modifications que vous avez apportées ne seront pas enregistrées si vous quittez cette page.')
    if (!answer) {
        return false
    }
})

const showSelectedProjects = ref(true)
const showUnselectedProjects = ref(true)

function toggleShowSelectedProjects() {
    showSelectedProjects.value = !showSelectedProjects.value
}

function toggleShowUnselectedProjects() {
    showUnselectedProjects.value = !showUnselectedProjects.value
}

const showSelectedProjectsColor = computed(() => {
    if (showSelectedProjects.value) {
        return 'primary'
    } else {
        return 'grey-5'
    }
})

const showUnselectedProjectsColor = computed(() => {
    if (showUnselectedProjects.value) {
        return 'primary'
    } else {
        return 'grey-5'
    }
})

watch(showSelectedProjects, () => {
    treeFilter.value = Math.random().toString()
})

watch(showUnselectedProjectsColor, () => {
    treeFilter.value = Math.random().toString()
})

treeFilter.value = Math.random().toString()
</script>

<template>
    <Teleport to="#pageTitleComponent">
        <div class="row items-center no-wrap">
            <div>
                Modèles visibles dans la marque blanche {{ environment.name }}
            </div>

            <div class="col-auto">
                <q-btn dense label="Enregistrer" color="primary" @click="saveCatalog"
                    style="margin-left: 8px; padding-left: 8px; padding-right: 8px;" :disable="!unsavedChanges"></q-btn>
            </div>
        </div>
    </Teleport>
    <q-page class="page" :style-fn="myTweak">
        <Suspense>
            <div style="margin-right: 16px;">
                <div style="margin-top: 8px; margin-bottom: 8px">
                    Cochez les catégories de modèles secs qui doivent être visibles dans la marque blanche
                </div>

                <!-- <q-input dense outlined v-model="searchText" @keydown.enter.prevent="searchProjects" class="search-input"
                    @update:model-value="searchProjects" bg-color="white">
                    <template v-slot:before>
                        <q-btn dense icon="o_check_box" round :color="showSelectedProjectsColor"
                            @click="toggleShowSelectedProjects">
                            <q-tooltip>Voir les catégories cochées</q-tooltip>
                        </q-btn>
                        <q-btn dense round icon="o_check_box_outline_blank" :color="showUnselectedProjectsColor"
                            @click="toggleShowUnselectedProjects" style="margin-left: 8px; margin-right: 8px;">
                            <q-tooltip>Voir les catégories non cochées</q-tooltip>
                        </q-btn>
                    </template>
                    <template v-slot:prepend>
                        <q-icon v-if="searchText === ''" name="search" size="" />
                        <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
                    </template>
                </q-input> -->
                <q-scroll-area :style="scrollAreaStyle" :thumb-style="thumbStyle">
                    <q-tree dense :nodes="nodes" node-key="id" v-model:expanded="expandedNodes" default-expand-all
                        :filter="treeFilter" :filter-method="applyTreeFilter" ref="treeRef" tick-strategy="strict"
                        no-results-label="Aucune opération juridique trouvée" v-model:ticked="ticked">
                        <template v-slot:default-header="prop">
                            <DocumentSectionNode v-if="prop.node.type !== 'project'" :node="prop.node">
                            </DocumentSectionNode>
                        </template>
                    </q-tree>
                </q-scroll-area>
            </div>
            <template #fallback>
                <q-inner-loading showing color="primary" label="Chargement en cours..." />
            </template>
        </Suspense>
    </q-page>
</template>

<style lang="scss" scoped>
.text-h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
    cursor: grab;
}

.search-input {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-top: 10px;
}

.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}

.node-label {
    margin-right: 8px;
}

.ul {
    list-style-type: disc;
}

:deep(.q-checkbox__inner) {
    color: rgb(158, 158, 158);

    margin-right: 5px;
    font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
    height: 0px;
    width: 0px;
    overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
    border: 1.3px solid currentColor;
}

.search-input {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.subtitle {
    font-size: 18px;
}

.category-header {
    background-color: #227a68;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // cursor: pointer;
}

.category-subheader {
    background-color: #2a5d97;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    // cursor: pointer;
}

.project-category {
    color: rgb(61, 61, 63);
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
}

.available-project {
    cursor: pointer;
    font-weight: 500;
}

.page-content-header {
    // margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

