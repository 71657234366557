<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'
import EntityNode from './EntityNode.vue'
import PageNode from './PageNode.vue'
import BetweenNodeDropZone from './BetweenNodeDropZone.vue'
import { getNode } from '@/components/projects/execution/documents/DocumentsCommon'

const store = useStore()

interface Props {
    entity: any
}

const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()

const selectedPage = inject("selectedPage")

const selectedNode = ref(null)

const nodes = ref([props.entity])
provide("nodes", nodes)

console.log('pagesEditor entity', props.entity)

function getNode(nodes, nodeId) {
    if (nodes === undefined) {
        return undefined
    }
    for (let i = 0; i < nodes.length; ++i) {
        const currentNode = nodes[i]
        if (currentNode.id === nodeId) {
            return currentNode
        }
        const childNode = getNode(currentNode.children, nodeId)
        if (childNode !== undefined) {
            return childNode
        }
    }
    return undefined
}

watch(selectedNode, () => {
    console.log("selectedNode", selectedNode.value, nodes.value)
    const node = getNode(nodes.value[0], selectedNode.value)
    selectedPage.value = node
})


const thumbStyle = {
    right: '1px',
    borderRadius: '5px',
    width: '5px',
    color: 'rgb(71, 71, 71)',
    opacity: '0.2',
    zIndex: 10,
}

const pagesTreeRef = ref(null)
provide("pagesTreeRef", pagesTreeRef)
</script>

<template>
    <div class="q-pa-md">
        <div class="text-h4">Structure</div>
        <q-tree ref="pagesTreeRef" :nodes="entity" node-key="id" :default-expand-all="true" v-model:selected="selectedNode"
            selected-color="primary">
            <template v-slot:default-header="prop">
                <EntityNode v-if="prop.node.type === 'entity'" :node="prop.node"></EntityNode>
                <PageNode v-if="prop.node.type === 'page'" :node="prop.node"></PageNode>
            </template>
            <template v-slot:default-body="prop">
                <BetweenNodeDropZone :node="prop.node"></BetweenNodeDropZone>
            </template>
        </q-tree>
    </div>
</template>

<style lang="scss" scoped>
.text-h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
    cursor: grab;
}

:deep(.q-tree__node-header) {
    padding-left: 3px; // TODO: replace by a left move of the selection area
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
}
</style>
