<script lang="ts" setup>
import { ref, Ref, watch } from 'vue'
import { format } from 'date-fns'
import { Dialog } from 'quasar'
import { Task } from '@/models/projects/Task'
import { Mail } from '@/models/core/Mail'
import { MailEvent } from '@/models/core/MailEvent'
import { MailStatusEnum } from '@/models/core/MailStatus.enum'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import DocumentCard from '@/components/projects/execution/task_actions/document_generation/DocumentCard.vue'
import MailSendingTaskProcessDialog from './dialog/MailSendingTaskProcessDialog.vue'
import MailSendingTaskEventsDialog from './dialog/MailSendingTaskEventsDialog.vue'
import MailBetaDialog from './dialog/MailBetaDialog.vue'

interface Props {
  task: Task
}
const props = defineProps<Props>()

const userApi: UserApi = useApi()
const mails: Ref<Array<Mail>> = ref([])
const isButtonLoading = ref(false)

getMailList()

function showMailSendingDialog() {
  const dialog = Dialog.create({
    component: MailSendingTaskProcessDialog,
    componentProps: {
      task: props.task,
    },
  })
    .onOk(() => {
      dialog.hide()
      getMailList()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function getMailList() {
  isButtonLoading.value = true
  userApi.getTaskMails(props.task.id).then((result) => {
    mails.value = result
    isButtonLoading.value = false
  })
}

function getStatus(mailStatus: MailEvent[]) {
  let status = ''
  if (mailStatus.length > 0) {
    if (mailStatus[0].status === MailStatusEnum.Created) {
      status = 'Créé'
    }
    if (mailStatus[0].status === MailStatusEnum.Accepted) {
      status = 'Impression'
    }
    if (mailStatus[0].status === MailStatusEnum.FilingProof) {
      status = 'Preuve de dépôt'
    }
    if (mailStatus[0].status === MailStatusEnum.Sent) {
      status = 'Envoyé'
    }
    if (mailStatus[0].status === MailStatusEnum.Error) {
      status = 'Erreur'
    }
    if (mailStatus[0].status === MailStatusEnum.InTransit) {
      status = 'En transit'
    }
    if (mailStatus[0].status === MailStatusEnum.WaitingToBeWithdrawn) {
      status = 'En attente de réception'
    }
    if (mailStatus[0].status === MailStatusEnum.Distributed) {
      status = 'Distribué'
    }
    if (mailStatus[0].status === MailStatusEnum.ReturnedToSender) {
      status = "Retour à l'envoyeur"
    }
    if (mailStatus[0].status === MailStatusEnum.DeliveryProof) {
      status = 'Preuve de dépôt'
    }
    if (mailStatus[0].status === MailStatusEnum.WrongAddress) {
      status = 'Mauvaise adresse'
    }
    if (mailStatus[0].status === MailStatusEnum.Canceled) {
      status = 'Annulé'
    }
    if (mailStatus[0].status === MailStatusEnum.NoStatus) {
      status = 'Aucun statut'
    }
  }

  return status
}

function showEvents(mail: Mail) {
  const dialog = Dialog.create({
    component: MailSendingTaskEventsDialog,
    componentProps: {
      mail: mail,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

watch(props, (newValue, oldValue) => {
  getMailList()
})
</script>

<template>
  <div class="row field items-top">
    <div class="field-title task-field-title col-auto">
      <p>Action</p>
    </div>
    <div class="q-pl-md col">
      <q-btn
        color="primary"
        size="13px"
        padding="xs md"
        label="Procéder à l'envoi du courrier"
        @click="showMailSendingDialog"
        :loading="isButtonLoading"
        :disable="isButtonLoading"
      />
    </div>
    <div class="full-width q-mt-md" v-if="mails.length > 0">
      <DocumentCard v-for="mail in mails" icon="mail_outline">
        <template #name>
          Date d'envoi :
          {{ format(new Date(mail.createdAt), 'dd/MM/yyyy - HH:mm:ss') }}
        </template>
        <template #caption>{{ getStatus(mail.events) }}</template>
        <template #actions>
          <q-btn icon="info_outline" flat rounded @click="showEvents(mail)"> </q-btn>
        </template>
      </DocumentCard>
    </div>
  </div>
  <!-- <MailBetaDialog></MailBetaDialog> -->
</template>
