<script setup lang="ts">
import { ref, computed } from 'vue'
import InlineVariableView from './elements/content/inline/InlineVariableView.vue'
import InlineAlternativeView from './elements/control/inline/InlineAlternativeView.vue'
import InlineCounterView from './elements/content/inline/InlineCounterView.vue'

interface Props {
  element: Element
}

const props = withDefaults(defineProps<Props>(), {})

const isHovering = ref(true)

const cardClass = computed(() => {
  let classes = 'full-width'
  if (props.element.type === 'ConditionBlock') {
    classes += ' block top-left-corner-angle'
  } else {
    classes += ' block-content'
  }

  return classes
})
</script>

<template>
  <span class="">
    <span class="">
      <InlineVariableView v-if="element.type === 'InlineVariable'" :data="element"> </InlineVariableView>
      <InlineCounterView v-if="element.type === 'InlineCounter'" :data="element"> </InlineCounterView>
      <InlineAlternativeView v-if="element.type === 'InlineAlternative'" :data="element"> </InlineAlternativeView>
    </span>
  </span>
</template>

<style lang="scss" scoped>
.element-view {
}

.top-left-corner-angle {
  border-top-left-radius: 0;
}

.element-hover {
  border-color: #e2cb9d;
}

.badge-corner {
  background-color: #e2cb9d;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}

.block-content {
  border-left-color: #b0e2f2;
  border-left-width: 5px;
}
</style>
