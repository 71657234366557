<script setup lang="ts">
import { ref } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog } from 'quasar'
import SearchDialog from '@/pages/environment-mobile/dashboard/SearchDialog.vue'
// import ApplicationBar from "./header/ApplicationBar.vue";
// import NavigationDrawer from "./drawer/NavigationDrawer.vue";
// import Main from "./main/Main.vue";
import PageTitle from './PageTitle.vue'

const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const store = useStore()

const leftDrawerOpen = ref(false)

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value
}

const logout = function () {
  store.state.currentClient = null
  store.state.user = null
  authenticatedUser.clientId = ''
  router.push({ name: 'base/logout' })
}

function showSearchDialog() {
  const dialog = Dialog.create({
    component: SearchDialog,
    componentProps: {},
  })
    .onOk((searchText) => {
      dialog.hide()
      router.push({
        name: 'dashboard/search',
        query: { q: searchText },
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function goToDashboard() {
  router.push({
    name: 'dashboard/main',
  })
}

function goToClients() {
  router.push({
    name: 'clients/main',
  })
}
</script>

<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-btn flat dense round icon="menu" aria-label="Menu" @click="toggleLeftDrawer" />

        <q-toolbar-title>
          <PageTitle></PageTitle>
        </q-toolbar-title>

        <q-icon name="search" size="sm" @click="showSearchDialog" />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered>
      <q-list>
        <q-item-label header> Marvell Up </q-item-label>

        <q-item clickable>
          <!-- <q-item-section avatar>
                        <q-icon name="list" />
                    </q-item-section> -->

          <q-item-section @click="goToDashboard">
            <q-item-label>Accueil</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable>
          <!-- <q-item-section avatar>
                        <q-icon name="list" />
                    </q-item-section> -->

          <q-item-section @click="goToClients">
            <q-item-label>Clients</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable @click="logout">
          <!-- <q-item-section avatar>
                        <q-icon name="list" />
                    </q-item-section> -->

          <q-item-section>
            <q-item-label>Déconnecter</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
aside {
  position: fixed !important;
}

.menu-sidebar {
  overflow-x: hidden !important;
}

h5 {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-input {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
