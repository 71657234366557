<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import ItemActionMenuItem from '../../common/Table/ItemActionMenuItem.vue'
import EditProjectDialog from './EditProjectDialog.vue'
import MoveProjectDialog from './MoveProjectDialog.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ObjectUserPermissionsForm from '../../common/dialog/ObjectUserPermissionsForm.vue'
import SetProjectPasswordDialog from '../../common/dialog/SetProjectPasswordDialog.vue'
import ConfirmRemoveProjectPasswordDialog from '../dialog/ConfirmRemoveProjectPasswordDialog.vue'
import UuidEncoder from 'uuid-encoder'
import { isMobile } from '@/utils/detectMobile'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'
import { toggleFavorite } from '@/utils/favoritesUtils'
import CreateProjectDialog from '@/pages/common/projects/CreateProjectDialog.vue'
import SelectOrCreateClient from '@/components/projects/management/SelectOrCreateClient.vue'

interface Props {
  data_filter?: string
  clientId?: string
  filter?: string
  show_client?: boolean
  selection?: string
  last_viewed_projects?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  show_client: false,
  selection: 'single',
  last_viewed_projects: false,
})

const store = useStore()
const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()

const favorites = inject('favorites')

const filter = props.filter
const tFilter = ref('')

console.log('init projects table', props.last_viewed_projects)

let columns

if (props.show_client) {
  columns = [
    {
      name: 'client_name',
      label: 'Client',
      field: 'client_name',
      align: 'left',
      style: 'width: 550px',
      format: (val, row) => row.client.name,
    },
    {
      name: 'name',
      label: 'Nom du dossier',
      field: 'name',
      align: 'left',
      format: formatProjectName,
    },
  ]
} else {
  columns = [
    {
      name: 'favorite',
      label: '',
      field: 'favorite',
      align: 'center',
      style: 'width: 20px; padding-left: 0px; padding-right: 0px;',
    },
    {
      name: 'name',
      label: 'Nom du dossier',
      field: 'name',
      align: 'left',
      // format: formatProjectName,
    },
  ]
}

function formatProjectName(val, row) {
  // if (row.lopMatterId) {
  //   return row.name + " (LOP " + row.lopMatterId + ")";
  // }

  return row.name
}

const selectedRows = ref([])
const isTableLoading = ref(false)
const clientId: Ref<string | undefined> = ref(props.clientId)

const showForm = ref(false)
provide('showForm', showForm)
const mode = ref('ADD')
provide('mode', mode)

const rows = ref([])

const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 100,
})

const rowsPerPageOptions = [5, 10, 20, 50]

onMounted(() => {
  onRequest({
    pagination: pagination.value,
    filter: null,
  })
})

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
  console.log("new selected row", selectedRows.value[0])
}

async function refreshProjects() {
  const { page, rowsPerPage } = pagination.value
  isTableLoading.value = true
  let response

  if (props.last_viewed_projects) {
    response = await userApi.getRecentlyViewedProjects(page, tFilter.value, rowsPerPage)
  } else {
    if (filter === 'user') {
      response = await userApi.getAllUserProjects(page, tFilter.value, rowsPerPage)
    } else {
      response = await userApi.getProjects(page, tFilter.value, rowsPerPage)
    }
  }

  rows.value = response.results
  setFavorites()
  isTableLoading.value = false
}

function setFavorites() {
  console.log('ProjectsTable - setFavorites', rows.value, favorites.value)
  rows.value.forEach((row) => {
    row.favorite = favorites.value.userProjects.some((favorite) => {
      return favorite.objectId === row.id
    })
  })
}

watch(favorites.value, () => {
  console.log('ProjectsTable - favorites changed')
  setFavorites()
})

function onAddClick() {
  // Chose client if filter is 'user'
  if (filter == 'user') {
    const dialog = Dialog.create({
      component: SelectOrCreateClient,
    })
      .onOk((clientId) => {
        const dialog = Dialog.create({
          component: CreateProjectDialog,
          componentProps: {},
        })
          .onOk(async () => {
            dialog.hide()
          })
          .onCancel(() => {
            dialog.hide()
          })
      })
      .onCancel(() => {
        dialog.hide()
      })
  }
  // Create project from inside a client
  else if (store.state.user.portal === 'CLIENT') {
    router.push({ name: 'projects/create' })
  } else {
    const dialog = Dialog.create({
      component: CreateProjectDialog,
      componentProps: {},
    })
      .onOk(async () => {
        dialog.hide()
      })
      .onCancel(() => {
        dialog.hide()
      })
  }
}

function onEditClick() {
  mode.value = 'EDIT'
  const dialog = Dialog.create({
    component: EditProjectDialog,
    componentProps: {
      selectedProject: selectedRows.value[0],
      mode: mode.value,
    },
  })
    .onOk(async (newName) => {
      await refreshProjects()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onMoveClick() {
  const dialog = Dialog.create({
    component: MoveProjectDialog,
    componentProps: {
      selectedProject: selectedRows.value[0],
    },
  })
    .onOk(async (newClient) => {
      console.log("newClient", newClient)
      await userApi.moveProjectToClient(selectedRows.value[0].id, newClient.id)
      await refreshProjects()
      Notify.create({
        message: 'Dossier déplacé avec succès',
        type: 'primary',
      })
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onDeleteClick() {
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message: 'Voulez-vous vraiment supprimer le dossier ' + selectedRows.value[0].name + ' ?',
    },
  })
    .onOk(async () => {
      await userApi.deleteProject(selectedRows.value[0].id)
      selectedRows.value = []
      await refreshProjects()

      dialog.hide()
      Notify.create({
        message: 'Dossier supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function onRequest(requestProperties) {
  const { page, rowsPerPage, sortBy, descending } = requestProperties.pagination
  const tableFilter = requestProperties.filter

  isTableLoading.value = true
  let response: any

  console.log("props.last_viewed_projects", props.last_viewed_projects)
  if (props.last_viewed_projects) {
    response = await userApi.getRecentlyViewedProjects(page, tFilter.value, rowsPerPage)
  } else {
    if (filter === 'user') {
      response = await userApi.getAllUserProjects(page, tableFilter, rowsPerPage)
    } else {
      response = await userApi.getProjects(page, tableFilter, rowsPerPage)
    }
  }

  rows.value = response.results
  setFavorites()

  pagination.value.rowsNumber = response.total
  pagination.value.page = page
  pagination.value.rowsPerPage = rowsPerPage
  pagination.value.sortBy = sortBy
  pagination.value.descending = descending

  isTableLoading.value = false
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: ObjectUserPermissionsForm,
    componentProps: {
      clientId: clientId.value,
      selectedRows: selectedRows.value,
      rights: ['view_userproject', 'change_userproject', 'delete_userproject'],
      environment: 'UserProject',
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function setPassword() {
  const dialog = Dialog.create({
    component: SetProjectPasswordDialog,
    componentProps: {
      userProject: selectedRows.value[0],
    },
  })
    .onOk(() => {

      selectedRows.value[0].adminUserId = authenticatedUser.id
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function removePassword() {
  const dialog = Dialog.create({
    component: ConfirmRemoveProjectPasswordDialog,
    componentProps: {
      userProject: selectedRows.value[0],
    },
  })
    .onOk(async () => {
      await userApi.removeProjectPassword(selectedRows.value[0].id)
      Notify.create({
        message: `Mot de passe du dossier supprimé avec succès`,
        type: 'primary',
      })
      selectedRows.value[0].adminUserId = null
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onRowClick(event, row) {
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        // projectId: encoder.encode(row.id),
        projectId: row.id,
      },
    })
  } else {
    store.state.user.clientId = row.client.id
    store.state.currentClient = row.client
    router.push({
      name: 'clients/client/projects/project',
      params: {
        // clientId: encoder.encode(store.state.user.clientId),
        // projectId: encoder.encode(row.id),
        clientId: store.state.user.clientId,
        projectId: row.id,
      },
    })
  }
}

function openProjectInNewTab(row) {
  if (store.state.user.portal === 'CLIENT') {
    const routeData = router.resolve({
      name: 'projects/project',
      params: {
        // projectId: encoder.encode(row.id),
        projectId: row.id,
      },
    })
    window.open(routeData.href, '_blank')
  } else {
    // store.state.user.clientId = row.client.id
    // store.state.currentClient = row.client
    const routeData = router.resolve({
      name: 'clients/client/projects/project',
      params: {
        // clientId: encoder.encode(store.state.user.clientId),
        // projectId: encoder.encode(row.id),
        clientId: store.state.user.clientId,
        projectId: row.id,
      },
    })
    window.open(routeData.href, '_blank')
  }
}

function filterUpdated(emit) {
  tFilter.value = emit
}

async function onToggleFavorite(event, row) {
  console.log('toggleFavorite', event, row)
  event.preventDefault()
  event.stopPropagation()

  toggleFavorite(userApi, favorites, 'user_project', row)
  row.favorite = !row.favorite
}

const slotedColumns = ref([
  {
    name: 'favorite',
  },
  {
    name: 'name',
  },
])



watch(store.state, () => {
  console.log('ProjectsTable - currentClient changed')
  refreshProjects()
})

function userCanSetPassword() {
  return true //authenticatedUser.id == '92925e4b-c6e7-4649-8f77-256ac1d22662' || authenticatedUser.id == 'ecddc029-8293-46df-a392-e77225510451'
}
</script>

<template>
  <BaseTable v-if="!isMobile()" add-button-label="Créer un dossier" table-label="project" name="project-table"
    v-model:pagination="pagination" :selection="selection" :columns="columns" :rows="rows" :is-loading="isTableLoading"
    :enable-actions="filter !== 'user'" enable-add :rows-per-page-options="rowsPerPageOptions"
    @add-item-click="onAddClick" @on-row-click="onRowClick" @on-row-selected="onRowSelected" @request="onRequest"
    @filter-updated="filterUpdated" :slotedComlumsn="slotedColumns" :slotedColumns="slotedColumns" class="table">
    <template #action-menu-items>
      <ItemActionMenuItem label="Renommer" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
      <ItemActionMenuItem label="Déplacer dans un autre client" @click="onMoveClick" />
      <ItemActionMenuItem label="Gérer les droits" @click="onManageRightsClick" />
      <ItemActionMenuItem v-if="!selectedRows[0].adminUserId && userCanSetPassword()" label="Protéger par mot de passe"
        @click="setPassword" />
      <ItemActionMenuItem
        v-if="selectedRows[0].adminUserId && selectedRows[0].adminUserId == authenticatedUser.id && userCanSetPassword()"
        label="Supprimer le mot de passe" @click="removePassword" />
    </template>

    <template v-slot:body-cell-favorite="props">
      <q-td :props="props.item">
        <q-btn v-if="props.item.row.favorite != true" round flat color="grey" icon="o_star_border" size="md"
          @click="onToggleFavorite($event, props.item.row)">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
        <q-btn v-if="props.item.row.favorite == true" round flat style="color: #e3a93b" icon="o_star" size="md"
          @click="onToggleFavorite($event, props.item.row)">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:body-cell-name="props">
      <q-td :props="props.item" class="table-row">
        {{ props.item.row.name }}
        <q-btn @click="openProjectInNewTab(props.item.row)" dense flat round icon="open_in_new"
          class="previewInNewTabButton" style="margin-left: 10px;" @click.stop></q-btn>
      </q-td>
    </template>
  </BaseTable>

  <BaseTable v-if="isMobile()" dense flat grid :enableActions="false" :enableAdd="false" table-label="project"
    name="project-table" v-model:pagination="pagination" :columns="columns" :rows="rows" :is-loading="isTableLoading"
    :rows-per-page-options="rowsPerPageOptions" @request="onRequest" @filter-updated="filterUpdated"
    class="table-mobile">

    <template v-slot:item="item" :item-slot="true">
      <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => onRowClick($event, item.item.row)">
        {{ item.item.row.name }}
        <q-separator />
      </div>
    </template>
  </BaseTable>

</template>

<style lang="scss" scoped>
.table-mobile {
  margin-top: 16px;
}

.previewInNewTabButton {
  color: #cccccc;
  visibility: hidden;
}

.previewInNewTabButton:hover {
  color: #777777;
  background-color: #bbbbbb;
}

.table tr:hover .previewInNewTabButton {
  visibility: visible;
}
</style>
