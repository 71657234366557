<script setup lang="ts">
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { watch, ref, inject, computed } from 'vue'
import { format, parse, compareAsc } from 'date-fns'
import { ProjectKey } from '@/models/symbols'
import { QPopupProxy } from 'quasar'

interface Props {
  task: Task | undefined
  borderless?: boolean
  isDisable?: boolean
  isReadonly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  borderless: false,
  isDisable: false,
})

const emit = defineEmits(['updateEvents'])

const userApi = useApi()
const model = ref('')
const proxyDate = ref(format(new Date(), 'dd/MM/yyyy'))
const qDateProxy = ref(QPopupProxy)
const isDeadlinePassed = ref(2)

const isLoading = ref(false)
const project = inject(ProjectKey)

const deadlineColor = computed(() => {
  if (isDeadlinePassed.value === -1) {
    return ''
  }
  if (isDeadlinePassed.value === 0) {
    return 'primary'
  }
  if (isDeadlinePassed.value === 1) {
    return 'warning'
  }
})

if (props.task?.deadline) {
  model.value = format(new Date(props.task.deadline), 'dd/MM/yyyy')
  proxyDate.value = format(new Date(props.task.deadline), 'dd/MM/yyyy')
  isDeadlinePassed.value = compareAsc(
    new Date().setHours(0, 0, 0, 0),
    new Date(props.task.deadline).setHours(0, 0, 0, 0),
  )
}

async function save() {
  model.value = proxyDate.value
  isLoading.value = true
  await userApi.putProjectTask(project?.value.id, props.task.id, {
    deadline: parse(model.value, 'dd/MM/yyyy', new Date()),
  })
  props.task.deadline = parse(model.value, 'dd/MM/yyyy', new Date())

  const new_event = await userApi.createProjectEvent(project?.value.id, {
    type: 3,
    user_project_id: project?.value.id,
    legacy_task_id: props.task?.id,
  })
  emit('updateEvents', new_event)

  qDateProxy.value.hide()
  isLoading.value = false
}

watch(props, () => {
  if (props.task?.deadline) {
    model.value = format(new Date(props.task.deadline), 'dd/MM/yyyy')
    isDeadlinePassed.value = compareAsc(
      new Date().setHours(0, 0, 0, 0),
      new Date(props.task.deadline).setHours(0, 0, 0, 0),
    )
  }
})
</script>
<template>
  <q-chip outline color="grey-5" style="margin-left: 0px">
    <q-input
      dense
      rounded
      :loading="isLoading"
      :disable="isLoading || isDisable"
      :rounded="!borderless"
      :readonly="isReadonly"
      :outlined="!borderless"
      :borderless="borderless"
      v-model="model"
      placeholder="Échéance"
      :color="deadlineColor"
      class="q-pb-none deadline-select"
    >
      <template #append>
        <q-icon name="event" class="cursor-pointer" :color="deadlineColor"> </q-icon>
      </template>
      <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
        <q-date v-model="proxyDate" mask="DD/MM/YYYY" :disable="isLoading">
          <div class="row items-center justify-end">
            <q-btn label="Annuler" color="primary" flat v-close-popup />
            <q-btn label="OK" color="primary" flat @click="save" v-close-popup />
          </div>
        </q-date>
      </q-popup-proxy>
    </q-input>
  </q-chip>
</template>

<style lang="scss" scoped>
.deadline-select {
  font-size: 13px;
}
</style>
