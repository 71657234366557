<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useBrowserLocation } from '@vueuse/core'
import { useStore } from '@/store/store'
import { ref } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

// interface Props {
// }
// const props = withDefaults(defineProps<Props>(), {});

const router = useRouter()

const emit = defineEmits(['createProject'])

let environment = 'marvell_up'
// const login = localStorage.getItem("login");
// if (login.startsWith("bewiz")) {
//   environment = "bewiz";
// }
const authenticatedUser = useAuthenticatedUser()

if (authenticatedUser.email.includes('reseau')) {
  environment = 'reseau-ama'
}

const location = useBrowserLocation()

if (location?.value?.hostname?.includes('bewiz')) {
  environment = 'bewiz'
}

if (location?.value?.hostname?.includes('karila')) {
  environment = 'karila'
}

if (location?.value?.hostname?.includes('ayming')) {
  environment = 'ayming'
}

if (location?.value?.hostname?.includes('cercali')) {
  environment = 'cercali'
}

if (location?.value?.hostname?.includes('chicheattaliavocats')) {
  environment = 'chicheattaliavocats'
}

const onSubcategoryClick = function (categoryId, subcategoryId) {
  router.push({ name: 'projects/create/select_template' })
}

const createProject = function (projectTemplateId) {
  val.value[projectTemplateId] = !val.value[projectTemplateId]

  // router.push({
  //   // name: "projects/create/project_template_detail",
  //   name: "projects/create/name_project",
  //   params: { projectTemplateId: projectTemplateId },
  // });
}

const val = ref(Array(3000).fill(false))

function createProjectNew() {
  const projectsTemplateIds = []
  for (let i = 0; i < 3000; ++i) {
    if (val.value[i]) {
      projectsTemplateIds.push(i)
    }
  }

  if (projectsTemplateIds.length === 0) {
    projectsTemplateIds.push(0)
  }
  const projectsTemplateIdsString = projectsTemplateIds.join(',')
  emit('createProject', projectsTemplateIdsString)
}
</script>

<template>
  <div padding>
    <!-- RESEAU AMA  -->
    <div v-if="environment == 'reseau-ama'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-12 col-md-12" style="margin-bottom: 20px; font-weight: 700; font-size: 18px">
          <span style="padding-top: 10px">Sélectionnez les opérations à inclure :</span>
          <!-- <span style="margin-left: 90px">
                    <q-btn
                      @click="createProjectNew()"
                      color="primary"
                      class="q-mt-md"
                      label="Valider"
                    ></q-btn>
                  </span> -->
        </div>
      </div>

      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Corporate</div>
          <div class="category-subheader">SAS</div>
          <ul>
            <li class="project-category">Comptes annuels</li>
            <ul>
              <li @click="createProject(888)" class="available-project">
                <q-checkbox size="xs" v-model="val[888]" />Approbation des comptes annuels
              </li>
            </ul>

            <li class="project-category">Gouvernance</li>
            <ul>
              <li @click="createProject(1335)" class="available-project">
                <q-checkbox size="xs" v-model="val[1335]" />Changement de dirigeant
              </li>
              <!-- <li @click="createProject(646)" class="available-project">Nomination / Changement de dirigeant de SAS</li>                 -->
            </ul>
          </ul>

          <div class="category-subheader">SARL</div>
          <ul>
            <li @click="createProject(1381)" class="available-project">
              <q-checkbox size="xs" v-model="val[1381]" />Changement de gérant
            </li>
            <li @click="createProject(2127)" class="available-project">
              <q-checkbox size="xs" v-model="val[2127]" />Liquidation
            </li>
            <li @click="createProject(2236)" class="available-project">
              <q-checkbox size="xs" v-model="val[2236]" />Dissolution anticipée de société
            </li>
            <li @click="createProject(2401)" class="available-project">
              <q-checkbox size="xs" v-model="val[2401]" />Cession de parts sociales
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Commercial</div>
          <ul>
            <li @click="createProject(2444)" class="available-project">
              <q-checkbox size="xs" v-model="val[2444]" />Ouverture d'un établissement secondaire
            </li>
            <li @click="createProject(2445)" class="available-project">
              <q-checkbox size="xs" v-model="val[2445]" />Transfert d'établissement principal
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-center">
        <div class="col-xs-8 col-sm-8 col-md-8" style="margin-top: 20px">
          <q-btn @click="createProjectNew()" color="primary" class="q-mt-md" label="Valider"></q-btn>
        </div>
      </div>
    </div>

    <!-- MARVELLUP / CHICHEATTALIAVOCATS  -->

    <div v-if="environment == 'marvell_up' || environment == 'chicheattaliavocats'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-12 col-md-12" style="margin-bottom: 20px; font-weight: 700; font-size: 18px">
          <span style="padding-top: 10px">Sélectionnez les opérations à inclure :</span>
          <span style="margin-left: 60px">
            <q-btn @click="createProjectNew()" color="primary" class="q-mt-md" label="Valider"></q-btn>
          </span>
        </div>
      </div>

      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Corporate</div>
          <div class="category-subheader">SAS</div>
          <ul>
            <li class="project-category">Création</li>
            <ul>
              <li @click="createProject(578)" class="available-project">
                <q-checkbox size="xs" v-model="val[578]" />Création de la société
              </li>
              <li @click="createProject(1340)" class="available-project">
                <q-checkbox size="xs" v-model="val[1340]" />Création d'une holding
              </li>
              <li @click="createProject(847)" class="available-project">
                <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
              </li>
            </ul>
            <li class="project-category">Gouvernance</li>
            <ul>
              <li @click="createProject(1335)" class="available-project">
                <q-checkbox size="xs" v-model="val[1335]" />Changement de dirigeant
              </li>
              <!-- <li @click="createProject(646)" class="available-project">Nomination / Changement de dirigeant de SAS</li>                 -->
            </ul>
            <li class="project-category">Modifications statutaires</li>
            <ul>
              <li @click="createProject(1325)" class="available-project">
                <q-checkbox size="xs" v-model="val[1325]" />Changement d'objet social
              </li>
              <li @click="createProject(1307)" class="available-project">
                <q-checkbox size="xs" v-model="val[1307]" />Changement de dénomination sociale
              </li>
              <li @click="createProject(1402)" class="available-project">
                <q-checkbox size="xs" v-model="val[1402]" />Modification de la date de clôture de l'exercice social
              </li>
              <li @click="createProject(1353)" class="available-project">
                <q-checkbox size="xs" v-model="val[1353]" />Prorogation de la durée de la société
              </li>
              <li @click="createProject(1327)" class="available-project">
                <q-checkbox size="xs" v-model="val[1327]" />Transfert du siège social
              </li>
              <!-- <li @click="createProject(636)" class="available-project">Modifications des statuts</li>                             -->
            </ul>
            <li class="project-category">Opérations sur le capital social</li>
            <ul>
              <li @click="createProject(683)" class="available-project">
                <q-checkbox size="xs" v-model="val[683]" />Réduction de capital
              </li>
              <li @click="createProject(684)" class="available-project">
                <q-checkbox size="xs" v-model="val[684]" />Augmentation de capital
              </li>
              <li @click="createProject(1485)" class="available-project">
                <q-checkbox size="xs" v-model="val[1485]" />Cession d'actions
              </li>
              <li @click="createProject(1684)" class="available-project">
                <q-checkbox size="xs" v-model="val[1684]" />Levée de fonds simplifiée
              </li>
            </ul>

            <ul></ul>
            <li class="project-category">Pacte d'actionnaires</li>
            <ul>
              <li @click="createProject(796)" class="available-project">
                <q-checkbox size="xs" v-model="val[796]" />Pacte d'actionnaires
              </li>
            </ul>

            <li class="project-category">Comptes annuels</li>
            <ul>
              <li @click="createProject(888)" class="available-project">
                <q-checkbox size="xs" v-model="val[888]" />Approbation des comptes annuels
              </li>
            </ul>

            <li class="project-category">Compte courant d'associé</li>
            <ul>
              <li @click="createProject(570)" class="available-project">
                <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé
              </li>
            </ul>

            <li class="project-category">Opérations sur titres</li>
            <ul>
              <li @click="createProject(2561)" class="available-project">
                <q-checkbox size="xs" v-model="val[2561]" />Convention de portage d'actions
              </li>
              <li @click="createProject(1496)" class="available-project">
                <q-checkbox size="xs" v-model="val[1496]" />Opérations - Portage d'actions
              </li>
            </ul>

            <li class="project-category">Transformations</li>
            <ul>
              <li>Aucune opération disponible actuellement</li>
            </ul>

            <!-- <li>&nbsp;<br /></li> -->
          </ul>

          <div class="category-subheader">SASU</div>
          <ul>
            <li @click="createProject(1295)" class="available-project">
              <q-checkbox size="xs" v-model="val[1295]" />Création de la société
            </li>
            <li @click="createProject(570)" class="available-project">
              <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé toutes sociétés
            </li>
            <li @click="createProject(847)" class="available-project">
              <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
            </li>
            <li @click="createProject(688)" class="available-project">
              <q-checkbox size="xs" v-model="val[688]" />Augmentation de capital
            </li>
            <li @click="createProject(689)" class="available-project">
              <q-checkbox size="xs" v-model="val[689]" />Réduction de capital
            </li>
            <li @click="createProject(1218)" class="available-project">
              <q-checkbox size="xs" v-model="val[1218]" />AGO d'approbation des comptes annuels
            </li>
          </ul>

          <div class="category-subheader">SARL</div>
          <ul>
            <li @click="createProject(633)" class="available-project">
              <q-checkbox size="xs" v-model="val[633]" />Création de la société
            </li>
            <li @click="createProject(570)" class="available-project">
              <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé toutes sociétés
            </li>
            <li @click="createProject(847)" class="available-project">
              <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
            </li>

            <!-- <li class="project-category">Transformations</li>
                      <ul> -->
            <li @click="createProject(815)" class="available-project">
              <q-checkbox size="xs" v-model="val[815]" />Transformation SARL en SAS
            </li>
            <!-- </ul>                             -->
            <li @click="createProject(1381)" class="available-project">
              <q-checkbox size="xs" v-model="val[1381]" />Changement de gérant
            </li>
            <li @click="createProject(964)" class="available-project">
              <q-checkbox size="xs" v-model="val[964]" />Augmentation de capital en numéraire par création de nouvelles
              parts
            </li>
            <li @click="createProject(1419)" class="available-project">
              <q-checkbox size="xs" v-model="val[1419]" />Changement d'objet social
            </li>
            <li @click="createProject(1418)" class="available-project">
              <q-checkbox size="xs" v-model="val[1418]" />Changement de dénomination sociale
            </li>
            <li @click="createProject(1432)" class="available-project">
              <q-checkbox size="xs" v-model="val[1432]" />Modification de la date de cloture de l'exercice social
            </li>
            <li @click="createProject(1427)" class="available-project">
              <q-checkbox size="xs" v-model="val[1427]" />Prorogation de la durée de la société
            </li>
            <li @click="createProject(1415)" class="available-project">
              <q-checkbox size="xs" v-model="val[1415]" />Transfert du siège social
            </li>
            <li @click="createProject(690)" class="available-project">
              <q-checkbox size="xs" v-model="val[690]" />Augmentation de capital
            </li>
            <li @click="createProject(696)" class="available-project">
              <q-checkbox size="xs" v-model="val[696]" />Réduction de capital
            </li>
            <li @click="createProject(762)" class="available-project">
              <q-checkbox size="xs" v-model="val[762]" />Nomination d'un gérant
            </li>
            <li @click="createProject(781)" class="available-project">
              <q-checkbox size="xs" v-model="val[781]" />Remplacement d'un gérant
            </li>
            <li @click="createProject(1147)" class="available-project">
              <q-checkbox size="xs" v-model="val[1147]" />AGO d'approbation des comptes annuels
            </li>
            <li @click="createProject(2127)" class="available-project">
              <q-checkbox size="xs" v-model="val[2127]" />Liquidation
            </li>
            <li @click="createProject(2236)" class="available-project">
              <q-checkbox size="xs" v-model="val[2236]" />Dissolution anticipée de société
            </li>
            <li @click="createProject(2401)" class="available-project">
              <q-checkbox size="xs" v-model="val[2401]" />Cession de parts sociales
            </li>
          </ul>

          <div class="category-subheader">EURL</div>
          <ul>
            <li @click="createProject(1291)" class="available-project">
              <q-checkbox size="xs" v-model="val[1291]" />Création de la société
            </li>
            <li @click="createProject(570)" class="available-project">
              <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé toutes sociétés
            </li>
            <li @click="createProject(847)" class="available-project">
              <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
            </li>

            <li @click="createProject(1229)" class="available-project">
              <q-checkbox size="xs" v-model="val[1229]" />AGO d'approbation des comptes annuels
            </li>
          </ul>

          <div class="category-subheader">SCI</div>
          <ul>
            <li @click="createProject(669)" class="available-project">
              <q-checkbox size="xs" v-model="val[669]" />Création de la société
            </li>
            <li @click="createProject(570)" class="available-project">
              <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé toutes sociétés
            </li>
            <li @click="createProject(847)" class="available-project">
              <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
            </li>

            <li @click="createProject(1181)" class="available-project">
              <q-checkbox size="xs" v-model="val[1181]" />AGO d'approbation des comptes annuels
            </li>
          </ul>

          <div class="category-subheader">Fonds de dotation</div>
          <ul>
            <li @click="createProject(570)" class="available-project">
              <q-checkbox size="xs" v-model="val[570]" />Convention de compte courant d'associé toutes sociétés
            </li>
            <li @click="createProject(847)" class="available-project">
              <q-checkbox size="xs" v-model="val[847]" />Contrat de domiciliation
            </li>
            <li @click="createProject(1017)" class="available-project">
              <q-checkbox size="xs" v-model="val[1017]" />Fonds de dotation
            </li>
          </ul>

          <!-- <li @click="createProject()" class="available-project"></li>  -->
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Social</div>
          <ul>
            <li @click="createProject(620)" class="available-project">
              <q-checkbox size="xs" v-model="val[620]" />CDI
            </li>
            <li @click="createProject(621)" class="available-project">
              <q-checkbox size="xs" v-model="val[621]" />CDD
            </li>
            <li @click="createProject(723)" class="available-project">
              <q-checkbox size="xs" v-model="val[723]" />Rupture conventionnelle
            </li>
            <li @click="createProject(748)" class="available-project">
              <q-checkbox size="xs" v-model="val[748]" />Charte télétravail
            </li>
            <li @click="createProject(749)" class="available-project">
              <q-checkbox size="xs" v-model="val[749]" />Forfait jours
            </li>
            <li @click="createProject(650)" class="available-project">
              <q-checkbox size="xs" v-model="val[650]" />Règlement intérieur
            </li>
            <li @click="createProject(585)" class="available-project">
              <q-checkbox size="xs" v-model="val[585]" />Lettre renouvellement période d'essai
            </li>
            <li @click="createProject(589)" class="available-project">
              <q-checkbox size="xs" v-model="val[589]" />Lettre de rupture de période d'essai
            </li>
            <li @click="createProject(619)" class="available-project">
              <q-checkbox size="xs" v-model="val[619]" />Offre de contrat de travail à durée indéterminée (CDI)
            </li>
            <li @click="createProject(1213)" class="available-project">
              <q-checkbox size="xs" v-model="val[1213]" />Offre de contrat de travail à durée déterminée (CDD)
            </li>
            <li @click="createProject(756)" class="available-project">
              <q-checkbox size="xs" v-model="val[756]" />Charte de droit à la déconnexion
            </li>
            <li @click="createProject(1163)" class="available-project">
              <q-checkbox size="xs" v-model="val[1163]" />Accord de participation
            </li>
            <li @click="createProject(1188)" class="available-project">
              <q-checkbox size="xs" v-model="val[1188]" />Accord d'intéressement
            </li>
            <li>Entretien professionnel</li>
            <li>Licenciement pour motif personnel ou économique</li>
          </ul>

          <div class="category-header">IP-IT-Data</div>
          <ul>
            <li @click="createProject(1067)" class="available-project">
              <q-checkbox size="xs" v-model="val[1067]" />RGPD
            </li>
            <li @click="createProject(652)" class="available-project">
              <q-checkbox size="xs" v-model="val[652]" />CGU pour SaaS
            </li>
            <li @click="createProject(1176)" class="available-project">
              <q-checkbox size="xs" v-model="val[1176]" />CGV pour SaaS
            </li>
            <li @click="createProject(717)" class="available-project">
              <q-checkbox size="xs" v-model="val[717]" />Cession de marque
            </li>
            <li @click="createProject(718)" class="available-project">
              <q-checkbox size="xs" v-model="val[718]" />Cession des droits de propriété intellectuelle d'un fondateur
            </li>
            <li @click="createProject(719)" class="available-project">
              <q-checkbox size="xs" v-model="val[719]" />Cession des droits de propriété intellectuelle d’un salarié
            </li>
            <li @click="createProject(721)" class="available-project">
              <q-checkbox size="xs" v-model="val[721]" />Cession des droits de propriété intellectuelle d'un tiers
            </li>
            <li @click="createProject(722)" class="available-project">
              <q-checkbox size="xs" v-model="val[722]" />Mentions légales
            </li>
            <li>Dépôt de marque ou logo</li>
            <li>CGV</li>
            <li>Charte de confidentialité</li>
          </ul>

          <div class="category-header">Commercial</div>
          <ul>
            <li @click="createProject(629)" class="available-project">
              <q-checkbox size="xs" v-model="val[629]" />Accord de confidentialité bilatéral
            </li>
            <li @click="createProject(630)" class="available-project">
              <q-checkbox size="xs" v-model="val[630]" />Accord de confidentialité unilatéral
            </li>
            <li @click="createProject(1473)" class="available-project">
              <q-checkbox size="xs" v-model="val[1473]" />Injonction de payer
            </li>
            <li @click="createProject(2444)" class="available-project">
              <q-checkbox size="xs" v-model="val[2444]" />Ouverture d'un établissement secondaire
            </li>
            <li @click="createProject(2445)" class="available-project">
              <q-checkbox size="xs" v-model="val[2445]" />Transfert d'établissement principal
            </li>
          </ul>

          <div class="category-header">AT/MP</div>
          <ul>
            <li @click="createProject(1344)" class="available-project">
              <q-checkbox size="xs" v-model="val[1344]" />Audit de tarification AT/MP
            </li>
            <li @click="createProject(863)" class="available-project">
              <q-checkbox size="xs" v-model="val[863]" />Accident du travail
            </li>
            <!-- <li @click="createProject(813)" class="available-project">Maladies professionnelles</li>
                    <li
                      @click="createProject(814)"
                      class="available-project"
                    >Incapacité Permanente Partielle du salarié</li>-->

            <li @click="createProject(1032)" class="available-project">
              <q-checkbox size="xs" v-model="val[1032]" />Maladies professionnelles
            </li>
            <li @click="createProject(1016)" class="available-project">
              <q-checkbox size="xs" v-model="val[1016]" />Incapacité Permanente Partielle du salarié
            </li>
            <li @click="createProject(1001)" class="available-project">
              <q-checkbox size="xs" v-model="val[1001]" />Faute inexcusable
            </li>
          </ul>

          <div class="category-header">Sûreté</div>
          <ul>
            <li @click="createProject(2717)" class="available-project">
              <q-checkbox size="xs" v-model="val[2717]" />Garantie à la première demande
            </li>
            <li @click="createProject(2710)" class="available-project">
              <q-checkbox size="xs" v-model="val[2710]" />Bordereau de renouvellement d'un privilège de nantissement
            </li>
            <li @click="createProject(2718)" class="available-project">
              <q-checkbox size="xs" v-model="val[2718]" />Protocole d'accord transactionnel
            </li>
            <li @click="createProject(2616)" class="available-project">
              <q-checkbox size="xs" v-model="val[2616]" />Requête afin de délivrance d'une seconde copie exécutoire
            </li>
            <li @click="createProject(2611)" class="available-project">
              <q-checkbox size="xs" v-model="val[2611]" />Requête afin de saisie des rémunérations
            </li>
            <li @click="createProject(2619)" class="available-project">
              <q-checkbox size="xs" v-model="val[2619]" />Requête aux fins d'inscription d'hypothèque judiciaire
              provisoire
            </li>
          </ul>

          <div class="category-header">Procédures collectives</div>
          <ul>
            <li @click="createProject(2551)" class="available-project">
              <q-checkbox size="xs" v-model="val[2551]" />Requête aux fins de désignation d'un contrôleur
            </li>
          </ul>

          <div v-if="environment == 'marvell_up'" class="col-xs-12 col-sm-6 col-md-4">
            <div class="category-header">Interne</div>
            <ul>
              <li @click="createProject(2221)" class="available-project">
                <q-checkbox size="xs" v-model="val[2221]" />Harawell
              </li>
              <li @click="createProject(654)" class="available-project">
                <q-checkbox size="xs" v-model="val[654]" />Contrat contributeur ComeOnLaw
              </li>
              <li @click="createProject(592)" class="available-project">
                <q-checkbox size="xs" v-model="val[592]" />Tests développement
              </li>
              <li @click="createProject(922)" class="available-project">
                <q-checkbox size="xs" v-model="val[922]" />Tests Pierre-Nicolas
              </li>
              <li @click="createProject(1934)" class="available-project">
                <q-checkbox size="xs" v-model="val[1934]" />Test pré-loading
              </li>
              <li @click="createProject(1935)" class="available-project">
                <q-checkbox size="xs" v-model="val[1935]" />Test mise à jour modèle de document
              </li>
            </ul>
          </div>
        </div>
        <!--<div class="col-xs-12 col-sm-6 col-md-4">

                </div>
                <div class="col-xs-12 col-sm-6 col-md-4">

                </div>
                <div class="col-xs-12 col-sm-6 col-md-4">

                </div>
                <div class="col-xs-12 col-sm-6 col-md-4">

            </div> -->
      </div>

      <div class="row justify-end">
        <div class="col-xs-8 col-sm-8 col-md-8" style="margin-top: 20px">
          <q-btn @click="createProjectNew()" color="primary" class="q-mt-md" label="Valider"></q-btn>
        </div>
      </div>
    </div>

    <!-- CERCALI  -->

    <div v-if="environment == 'cercali'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Corporate</div>
          <div class="category-subheader">SAS</div>
          <ul>
            <li class="project-category">Création (Béta)</li>
            <ul>
              <li @click="createProject(578)" class="available-project">Création de la société</li>
              <li @click="createProject(1340)" class="available-project">Création d'une holding</li>
              <li @click="createProject(847)" class="available-project">Contrat de domiciliation</li>
            </ul>
            <li class="project-category">Gouvernance (Béta)</li>
            <ul>
              <li @click="createProject(1335)" class="available-project">Changement de dirigeant</li>
            </ul>
            <li class="project-category">Modifications statutaires (Béta)</li>
            <ul>
              <li @click="createProject(1325)" class="available-project">Changement d'objet social</li>
              <li @click="createProject(1307)" class="available-project">Changement de dénomination sociale</li>
              <li @click="createProject(1402)" class="available-project">
                Modification de la date de clôture de l'exercice social
              </li>
              <li @click="createProject(1353)" class="available-project">Prorogation de la durée de la société</li>
              <li @click="createProject(1327)" class="available-project">Transfert du siège social</li>
            </ul>
            <li class="project-category">Opérations sur le capital social (Béta)</li>
            <ul>
              <li @click="createProject(1684)" class="available-project">Levée de fonds seed et pré-seed</li>
              <li @click="createProject(683)" class="available-project">Réduction de capital</li>
              <li @click="createProject(684)" class="available-project">Augmentation de capital</li>
              <li @click="createProject(1485)" class="available-project">Cession d'actions</li>
            </ul>

            <ul></ul>
            <li class="project-category">Pacte d'actionnaires (Béta)</li>
            <ul>
              <li @click="createProject(796)" class="available-project">Pacte d'actionnaires</li>
            </ul>

            <!-- <li class="project-category">Comptes annuels</li>
                      <ul>
                        <li @click="createProject(888)" class="available-project">Approbation des comptes annuels</li>
                      </ul>          -->

            <li class="project-category">Compte courant d'associé (Béta)</li>
            <ul>
              <li @click="createProject(570)" class="available-project">Convention de compte courant d'associé</li>
            </ul>
          </ul>
        </div>
      </div>
    </div>

    <!-- BEWIZ  -->

    <div v-if="environment == 'bewiz'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Corporate</div>
          <ul>
            <li @click="createProject(578)" class="available-project">Création de SAS / SASU</li>
            <li>Création de SARL / EURL</li>
            <li>Transfert de siège social</li>
            <li>Changement de dénomination ou objet social</li>
            <li>Nomination / Changement de dirigeant</li>
            <li>Approbation des comptes annuels</li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Social</div>
          <ul>
            <li>CDI</li>
            <li>CDD</li>
            <li @click="createProject(585)" class="available-project">Renouvellement de période d'essai</li>
            <li>Rupture de période d'essai</li>
            <li>Rupture conventionnelle</li>
            <li>Charte télétravail</li>
            <li>Forfait jours</li>
            <li>Règlement intérieur</li>
            <li>Entretien professionnel</li>
            <li>Licenciement pour motif personnel ou économique</li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">IP-IT-Data</div>
          <ul>
            <li>Dépôt de marque ou logo</li>
            <li>Cession de marque</li>
            <li>Cession de droits de propriété intellectuelle</li>
            <li>CGU</li>
            <li>CGV</li>
            <li>Mentions légales</li>
            <li>Charte de confidentialité</li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Interne</div>
          <ul>
            <li @click="createProject(654)" class="available-project">Contrat contributeur ComeOnLaw</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- KARILA  -->

    <div v-if="environment == 'karila'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Corporate</div>
          <ul>
            <li @click="createProject(669)" class="available-project">Création de SCI</li>
          </ul>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">Interne</div>
          <ul>
            <li @click="createProject(654)" class="available-project">Contrat contributeur ComeOnLaw</li>
            <li @click="createProject(592)" class="available-project">Tests</li>
          </ul>
        </div>
      </div>
    </div>

    <!-- AYMING  -->

    <div v-if="environment == 'ayming'">
      <div class="row q-gutter-lg">
        <div class="col-xs-12 col-sm-6 col-md-4">
          <div class="category-header">AT/MP</div>
          <ul>
            <!-- <li @click="" class="">Audit de tarification AT/MP</li>  -->
            <li @click="createProject(863)" class="available-project">Accident du travail</li>
            <li @click="createProject(1032)" class="available-project">Maladie professionnelle</li>
            <li>Incapacité Permanente Partielle du salarié</li>
            <li>Faute inexcusable</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.subtitle {
  font-size: 18px;
}

.category-header {
  background-color: #227a68;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  // cursor: pointer;
}

.category-subheader {
  background-color: #2a5d97;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  // cursor: pointer;
}

.project-category {
  color: rgb(61, 61, 63);
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}

ul {
  list-style-type: none;
}

.available-project {
  cursor: pointer;
  font-weight: 500;
}

.page-content-header {
  font-size: 18px;
  font-weight: 700;
}
</style>
