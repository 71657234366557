<script lang="ts" setup>
import { ref, watch } from 'vue'
import { QTableProps } from 'quasar'

import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { FieldType } from '@/models/database/FieldType'

import BaseInput from '@/components/base/BaseInput.vue'

interface Props {
  database: Database
  node: Node
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

let selectOptions = []

updateSelectOptions()

watch(props, () => {
  updateSelectOptions()
})

function updateSelectOptions() {
  selectOptions = props.field.fieldModel.selectOptions.map((option) => {
    return option
  })
}

function onFieldChange(e) {
  emit('update:field', props.node, props.field)
}
</script>

<template>
  <q-select
    :options="selectOptions"
    v-model="field.value"
    :label="showLabel ? field.label : undefined"
    style="margin-top: 10px"
    @update:model-value="onFieldChange"
  />
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
