<script setup lang="ts">
import { ref, computed } from 'vue'
import { Dialog } from 'quasar'
import { AlternativeBlock } from '@/models/v2/document/elements/control/block/AlternativeBlock'
import BlockElementView from '../../../BlockElementView.vue'
import EditAlternativeDialog from '../../../dialogs/EditAlternativeDialog.vue'
import draggable from 'vuedraggable'

interface Props {
  data: AlternativeBlock
}

const props = withDefaults(defineProps<Props>(), {})

const isHovering = ref(true)
const hover = ref(false)

const cardClass = computed(() => {
  let classes = 'full-width'
  classes += ' block top-left-corner-angle'

  return classes
})

function showEditAlternativeDialog() {
  const dialog = Dialog.create({
    component: EditAlternativeDialog,
    componentProps: {
      alternative: props.data,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
</script>

<template>
  <div class="column items-start" @mouseover="hover = true" @mouseleave="hover = false">
    <q-badge class="badge-corner" align="top" v-if="isHovering" @click="showEditAlternativeDialog">
      <span class="material-icons-outlined"> alt_route </span>
      <span contenteditable="true" v-if="data.alternative.label !== undefined && data.alternative.label !== ''">
        {{ data.alternative.label }}
      </span>
      <span contenteditable="true" v-if="data.alternative.label === undefined || data.alternative.label === ''">
        Alternative
      </span>
    </q-badge>
    <q-card flat bordered :class="cardClass">
      <q-card-sections horizontal class="row items-center justify-start no-wrap q-pl-none q-pt-sm q-pb-sm q-pr-sm">
        <q-icon name="drag_handle" class="handle" :color="hover ? 'black' : 'transparent'"> </q-icon>
        <div class="condition-block-view full-width">
          <draggable
            class="drag-area q-gutter-sm"
            tag="div"
            :list="data.elements"
            :group="{ name: 'g1' }"
            item-key="id"
          >
            <template #item="{ element, index }">
              <div>
                <BlockElementView :element="element"></BlockElementView>
              </div>
            </template>
          </draggable>
        </div>
      </q-card-sections>
    </q-card>
  </div>
</template>

<style lang="scss" scoped>
.drag-area {
  min-height: 30px;
}
</style>
