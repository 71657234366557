<script setup lang="ts">
import { useRouter } from 'vue-router'

import { useStore } from '@/store/store'

import ProjectsTable from '@/components/projects/management/ProjectsTable.vue'

const store = useStore()
store.commit('setPageTitle', 'Gestion des dossiers')

const router = useRouter()
</script>

<template>
  <q-page padding class="page">
    <h5>Dossiers</h5>
    <ProjectsTable />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}
</style>
