import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let sas: ObjectType = {
  name: 'SAS',
  parent: 'Entité',
  fields: [
    {
      type: FieldType.ObjectList,
      label: 'Statuts',
      linkFields: [
        {
          label: 'Date initiale de création',
          type: FieldType.Date,
        },
        {
          label: 'Date de mise à jour',
          type: FieldType.Date,
        },
        {
          label: 'Document des statuts',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Extraits Kbis',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Tribunal de commerce',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'RIB - IBAN',
      linkFields: [
        {
          label: 'Banque',
          type: FieldType.String,
        },
        {
          label: 'Intitulé compte',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Commissaires aux comptes',
      linkFields: [
        {
          label: 'Commissaire aux comptes',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: 'Position',
          type: FieldType.Select,
          selectOptions: ['Titulaire', 'Suppléant'],
        },
        {
          label: 'Date de début du mandat',
          type: FieldType.Date,
        },
        {
          label: 'Date de fin du mandat',
          type: FieldType.Date,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Baux',
      linkFields: [
        {
          label: 'Entreprise',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: 'Adresse du bail',
          type: FieldType.String,
          objectTypeName: 'Adresse',
        },
        {
          label: 'Date de signature',
          type: FieldType.Date,
        },
        {
          label: 'Terme du bail',
          type: FieldType.Date,
        },
        {
          label: 'Représentant du bailleur',
          type: FieldType.String,
          objectTypeName: 'Personne physique',
        },
        {
          label: 'Acte de bail',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Sociétés filles',
      linkFields: [
        {
          label: 'Société fille',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Entité', 'SAS'],
        },
        {
          label: 'Nombre de titres détenus dans la fille',
          type: FieldType.String,
        },
        {
          label: 'Nombre total de titres dans le capital de la fille',
          type: FieldType.String,
        },
        {
          label: "Classe d'actions",
          type: FieldType.String,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Actionnaires',
      linkFields: [
        {
          label: 'Actionnaire',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: "Date d'entrée au capital",
          type: FieldType.Date,
        },
        {
          label: 'Date de sortie du capital',
          type: FieldType.Date,
        },
        {
          label: "Compte individuel d'actionnaire",
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: "Pacte d'actionnaires",
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Registre des mouvements de titres',
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Assemblées générales',
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Ordre du jour',
          type: FieldType.FormattedText,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: "Conseils d'administration",
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Ordre du jour',
          type: FieldType.FormattedText,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Conseils de surveillance',
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Ordre du jour',
          type: FieldType.FormattedText,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Table de capitalisation',
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Bilans',
      linkFields: [
        {
          label: "Date de début d'exercice",
          type: FieldType.Date,
        },
        {
          label: "Date de fin d'exercice",
          type: FieldType.Date,
        },

        {
          label: "Chiffre d'affaires",
          type: FieldType.String,
        },
        {
          label: "Produit d'exploitation",
          type: FieldType.String,
        },
        {
          label: "Charges d'exploitation",
          type: FieldType.String,
        },
        {
          label: "Résultat d'exploitation",
          type: FieldType.String,
        },

        {
          label: 'Total bilan',
          type: FieldType.String,
        },
        {
          label: 'Bénéfices',
          type: FieldType.String,
        },
        {
          label: 'Pertes',
          type: FieldType.String,
        },
        {
          label: 'Actif net immobilisé',
          type: FieldType.String,
        },
        {
          label: 'Actif circulant',
          type: FieldType.String,
        },
        {
          label: 'Capitaux propres',
          type: FieldType.String,
        },
        {
          label: 'Emprunts et dettes',
          type: FieldType.String,
        },

        {
          label: 'Traitements et salaires',
          type: FieldType.String,
        },
        {
          label: 'Charges sociales',
          type: FieldType.String,
        },
        {
          label: 'Nombre de salariés',
          type: FieldType.String,
        },

        {
          label: 'Résultat financier',
          type: FieldType.String,
        },
        {
          label: 'Résultat courant',
          type: FieldType.String,
        },
        {
          label: 'Résultat exceptionnel',
          type: FieldType.String,
        },
        {
          label: 'Impôt sur les bénéfices',
          type: FieldType.String,
        },

        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Comptes de résultat',
      linkFields: [
        {
          label: "Date de début d'exercice",
          type: FieldType.Date,
        },
        {
          label: "Date de fin d'exercice",
          type: FieldType.Date,
        },

        {
          label: "Chiffre d'affaires",
          type: FieldType.String,
        },
        {
          label: "Produit d'exploitation",
          type: FieldType.String,
        },
        {
          label: "Charges d'exploitation",
          type: FieldType.String,
        },
        {
          label: "Résultat d'exploitation",
          type: FieldType.String,
        },

        {
          label: 'Total bilan',
          type: FieldType.String,
        },
        {
          label: 'Bénéfices',
          type: FieldType.String,
        },
        {
          label: 'Pertes',
          type: FieldType.String,
        },
        {
          label: 'Actif net immobilisé',
          type: FieldType.String,
        },
        {
          label: 'Actif circulant',
          type: FieldType.String,
        },
        {
          label: 'Capitaux propres',
          type: FieldType.String,
        },
        {
          label: 'Emprunts et dettes',
          type: FieldType.String,
        },

        {
          label: 'Traitements et salaires',
          type: FieldType.String,
        },
        {
          label: 'Charges sociales',
          type: FieldType.String,
        },
        {
          label: 'Nombre de salariés',
          type: FieldType.String,
        },

        {
          label: 'Résultat financier',
          type: FieldType.String,
        },
        {
          label: 'Résultat courant',
          type: FieldType.String,
        },
        {
          label: 'Résultat exceptionnel',
          type: FieldType.String,
        },
        {
          label: 'Impôt sur les bénéfices',
          type: FieldType.String,
        },

        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Opérations juridiques',
      linkFields: [
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Intitulé',
          type: FieldType.String,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
        {
          label: 'Projet',
          type: FieldType.ProjectLink,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Membres du CSE',
      linkFields: [
        {
          label: 'Membre',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique'],
        },
        {
          label: "Date d'entrée dans le CSE",
          type: FieldType.Date,
        },
        {
          label: 'Date de sortie du CSE',
          type: FieldType.Date,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Délégués du personnel',
      linkFields: [
        {
          label: 'Membre',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique'],
        },
        {
          label: 'Date de prise de fonction de délégué du personnel',
          type: FieldType.Date,
        },
        {
          label: 'Date de cessation des fonctions de délégué du personnel',
          type: FieldType.Date,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Membres du conseil stratégique',
      linkFields: [
        {
          label: 'Membre',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: "Date d'entrée",
          type: FieldType.Date,
        },
        {
          label: 'Date de sortie',
          type: FieldType.Date,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: "Contrats d'attribution",
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Catégorie',
          type: FieldType.Select,
          selectOptions: ['AGA', 'ABSA', 'BSPCE', 'BSA', 'ORA', 'OCA'],
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Contrats généraux',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Conventions de compte courant',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: "Conventions d'honoraires",
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Délégations de pouvoirs',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Registre des mouvements de titres',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'ODM et CERFAs',
      linkFields: [
        {
          label: 'Cédant',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: 'Cessionnaire',
          type: FieldType.ObjectLink,
          authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Nombre de titres',
          type: FieldType.String,
        },
        {
          label: 'Catégorie de titre',
          type: FieldType.String,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Contrats mandataires',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Notes de travail',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Notes',
          type: FieldType.FormattedText,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Règlement intérieur',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Lettres confidentielles',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Suivi des mandats',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Date du terme',
          type: FieldType.Date,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },

    {
      type: FieldType.ObjectList,
      label: 'Organigrammes',
      linkFields: [
        {
          label: 'Nom',
          type: FieldType.String,
        },
        {
          label: 'Date',
          type: FieldType.Date,
        },
        {
          label: 'Commentaire',
          type: FieldType.FormattedText,
        },
        {
          label: 'Document',
          type: FieldType.File,
        },
      ],
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[0].value
  },

  children: [],
}

export { sas }
