<script setup lang="ts">
import { inject } from 'vue'
import { InlineVariable } from '@/models/v2/document/elements/content/inline/InlineVariable'

interface Props {
  data: InlineVariable
}

const props = withDefaults(defineProps<Props>(), {})

const variables = inject('variables')

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

const variable = getVariableById(props.data.variableId)
</script>

<template>
  <span v-if="variable.value === null || variable.value === ''" class="variable-name">{{ variable.name }}</span>
  <span v-if="variable.value !== null && variable.value !== ''" class="variable-value">{{ variable.value }}</span>
</template>

<style lang="scss" scoped>
.variable-name {
  background-color: yellow;
}

.variable-value {
}
</style>
