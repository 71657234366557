<script lang="ts" setup>
import { ref } from 'vue'
import FileSaver from 'file-saver'

import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { Notify, Dialog, useQuasar } from 'quasar'
import FileUploadDialog from './FileUploadDialog.vue'
import DeleteFileDialog from './DeleteFileDialog.vue'
import PdfPreviewDialogWithIframe from '@/components/projects/execution/task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'

interface Props {
  database: Database
  node: Node
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

const $q = useQuasar()

const userApi: UserApi = useApi()

const isLoading = ref(false)

// props.field.value = {
//   fileId: "183abdc0-3668-4f6e-9d99-e29e1734bdbe",
//   filename: "fichier.rtf",
// }

async function downloadFile() {
  console.log("props.field.value", props.field.value)
  try {
    const doc = await userApi.downloadDatabaseFile(props.field.value.file_id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, props.field.value.filename)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

function openFileHelper(newWindow, file) {
  var blob = new Blob([file], { type: `application/pdf` })
  newWindow.location = URL.createObjectURL(blob)
}

async function previewFile() {
  $q.loading.show({
    message: 'Veuillez patienter pendant la génération de la prévisualisation PDF...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })
  try {
    const file = await userApi.downloadDatabaseFile(props.field.value.file_id)

    //   var url = URL.createObjectURL(doc);
    //   var a = document.createElement("a");
    //   document.body.appendChild(a);
    //   a.style = "display: none";
    //   a.href = url;
    //   a.download = 'preview' + '.pdf';
    //   a.target = '_blank';
    // a.click();

    let newWindow = window.open('')
    if (newWindow.document.readyState === 'complete') {
      openFileHelper(newWindow, file)
    } else {
      newWindow.onload = () => {
        openFileHelper(newWindow, file)
      }
    }
    $q.loading.hide()
  } catch (e) {
    $q.loading.hide()
    console.error(e)
    Notify.create({
      message: 'Erreur lors de la prévisualisation du document',
      type: 'negative',
    })
  }
}

async function showPdfPreview() {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(props.field.value.filename)
  let fileUrl =
    BASE_URL +
    userApi.getCurrentClientBaseUrl() +
    `/database/files/${props.field.value.file_id}/download/${filenameConverted}?type=preview`
  const dialog = Dialog.create({
    component: PdfPreviewDialogWithIframe,
    componentProps: {
      // file: file,
      documentName: props.field.value.filename,
      // taskId: props.task.id,
      fileUrl: fileUrl,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showUploadFileDialog() {
  const dialog = Dialog.create({
    component: FileUploadDialog,
    componentProps: {
      file: props.field.value,
    },
  })
    .onOk((uploadedFileInfos) => {
      props.field.value = {
        file_id: uploadedFileInfos.id,
        filename: uploadedFileInfos.fileName,
      }
      emit('update:field', props.node, props.field)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showDeleteFileModal() {
  const dialog = Dialog.create({
    component: DeleteFileDialog,
    componentProps: {},
  })
    .onOk(() => {
      deleteFile()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function deleteFile() {
  props.field.value = undefined
}
</script>

<template>
  <!-- <q-file v-model="field.value" :label="showLabel ? field.label : undefined" @change="onFieldChange" /> -->
  <!-- <q-input v-model="field.value" :label="showLabel ? field.label : undefined" @change="onFieldChange" /> -->
  <span v-if="field.value == undefined || field.value.filename == ''">Aucun fichier</span>
  <span v-if="field.value != undefined && field.value.filename != ''" @click="showPdfPreview()" clickable
    style="cursor: pointer">{{ field.value.filename }}</span>
  <q-btn-dropdown :disable="isLoading" flat rounded dropdown-icon="more_vert">
    <q-list>
      <q-item clickable v-close-popup @click="showUploadFileDialog()">
        <q-item-section>
          <q-item-label>Importer un fichier</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup :disable="field.value == undefined || field.value.filename == ''"
        @click="downloadFile()">
        <q-item-section>
          <q-item-label>Télécharger</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup :disable="field.value == undefined || field.value.filename == ''"
        @click="showPdfPreview()">
        <q-item-section>
          <q-item-label>Prévisualiser</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup :disable="field.value == undefined || field.value.filename == ''"
        @click="showDeleteFileModal()">
        <q-item-section>
          <q-item-label text-color="negative">Supprimer</q-item-label>
        </q-item-section>
      </q-item>
      <!-- <q-item clickable v-close-popup class="text-red" :disable="project.isReadOnly">
                    <q-item-section>
                      <q-item-label
                        text-color="negative"
                        @click="showDeleteFileModal(file)"
                        >Supprimer</q-item-label
                      >
                    </q-item-section>
                  </q-item> -->
    </q-list>
  </q-btn-dropdown>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
