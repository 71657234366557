import { defineStore } from 'pinia'

export const useVariablesStore = defineStore('variables', {
  state: () => {
    return {
      variables: [
        {
          id: 1,
          name: 'variable 1',
          label: 'variable 1',
          value: null,
        },
        {
          id: 2,
          name: 'variable 2',
          label: 'variable 2',
          value: null,
        },
        {
          id: 3,
          name: 'variable 3',
          label: 'variable 3',
          value: null,
        },
        {
          id: 4,
          name: 'variable 4',
          label: 'variable 4',
          value: null,
        },
      ],
    }
  },
  getters: {
    getVariableById(state) {
      return (variableId) => state.variables.find((variable) => variable.id === variableId)
    },
  },
  actions: {
    setVariableValue(variableId, value) {
      const variable = this.getVariableById(variableId)
      variable.value = value
    },
  },
})
