import { useStore } from '@/store/store'
import { MaybeRef } from '@vueuse/shared'

export function usePageTitle(
  newPageTitle: MaybeRef<string | null | undefined> = null,
  newPageSubtitle: MaybeRef<string | null | undefined> = null,
) {
  const store = useStore()
  if (newPageTitle !== '') {
    store.commit('setPageTitle', newPageTitle)
  }

  store.commit('setPageSubtitle', newPageSubtitle)
  return {
    pageTitle: store.state.pageTitle,
    pageSubtitle: store.state.pageSubtitle,
  }
}
