<script lang="ts" setup>
import { ref, Ref } from 'vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

const dependantQuestions: Ref<Array<any>> = ref([
  {
    variable: '',
    value: '',
  },
])
const variables: Ref<Array<any>> = ref(['variable 1', 'variable 2', 'variable 3'])
const values: Ref<Array<any>> = ref(['Oui', 'Non'])

function addRow(): void {
  dependantQuestions.value.push({
    variable: '',
    value: '',
  })
}

function removeRow(index: number): void {
  dependantQuestions.value.splice(index, 1)
}
</script>
<template>
  <div v-for="(question, index) in dependantQuestions" :key="question" class="row justify-between items-center q-mb-sm">
    <div class="col row q-gutter-sm q-mr-sm">
      <BaseSelect
        dense
        class="col"
        clearable
        emit-value
        v-model="question.variable"
        :options="variables"
        :label="`Question dépendante ${index + 1}`"
      >
      </BaseSelect>
      <BaseSelect
        dense
        class="col"
        clearable
        emit-value
        v-model="question.value"
        :options="values"
        :label="`Valeur de la question dépendante ${index + 1}`"
      >
      </BaseSelect>
    </div>
    <div class="col-auto q-gutter-sm">
      <q-btn
        :disable="index !== dependantQuestions.length - 1"
        outline
        rounded
        padding="sm"
        color="primary"
        icon="add"
        @click="addRow()"
      >
      </q-btn>
      <q-btn
        :disable="index === 0"
        outline
        rounded
        color="negative"
        padding="sm"
        icon="delete"
        @click="removeRow(index)"
      ></q-btn>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
