<script setup lang="ts">
import { ref } from 'vue'
import { QTableProps, Dialog } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import PaymentMethodAddEditForm from './PaymentMethodAddEditForm.vue'
import ObjectUserPermissionsForm from '@/components/common/dialog/ObjectUserPermissionsForm.vue'

interface Props {
  clientId?: string
}
const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits(['onCardSelected'])
const userApi = useApi()

const columns: QTableProps['columns'] = [
  {
    name: 'name_on_card',
    label: 'Nom',
    field: 'nameOnCard',
    sortable: true,
    align: 'left',
  },
  {
    name: 'digits',
    label: 'Numéro',
    field: 'cardLast4Digits',
    sortable: true,
    align: 'left',
    format: (val, row) => `xxxx xxxx xxxx ${val}`,
  },
  {
    name: 'expiration',
    label: 'Expiration',
    field: 'expiration',
    sortable: true,
    align: 'left',
  },
]

const clientId = ref(props.clientId)
const selectedRows = ref([])

const rows = ref([])

userApi.getPaymentMethods().then((response) => {
  rows.value = response.results
})

function refreshPaymentMethods() {
  userApi.getPaymentMethods().then((response) => {
    rows.value = response.results
  })
}

function onAddClick() {
  const dialog = Dialog.create({
    component: PaymentMethodAddEditForm,
    componentProps: {
      mode: 'ADD',
      addTitle: 'Nouveau moyen de paiement',
      editTitle: 'Modifier le moyen de paiement',
      addConfirmLabel: 'Ajouter',
      editConfirmLabel: 'Modifier',
      selectedRow: selectedRows.value,
    },
  })
    .onOk(() => {
      refreshPaymentMethods()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditClick() {
  const dialog = Dialog.create({
    component: PaymentMethodAddEditForm,
    componentProps: {
      mode: 'EDIT',
      addTitle: 'Nouveau moyen de paiement',
      editTitle: 'Modifier le moyen de paiement',
      addConfirmLabel: 'Ajouter',
      editConfirmLabel: 'Modifier',
      selectedRow: selectedRows.value,
    },
  })
    .onOk(() => {
      refreshPaymentMethods()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function onDeleteClick() {
  if (confirm('Voulez-vous vraiment supprimer ce moyen de paiement ?')) {
    await userApi.deletePaymentMethod(selectedRows.value[0].id)
    refreshPaymentMethods()
  }
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: ObjectUserPermissionsForm,
    componentProps: {
      clientId: clientId.value,
      selectedRows: selectedRows.value,
      rights: ['view_userpaymentmethod', 'change_userpaymentmethod', 'delete_userpaymentmethod'],
      environment: 'UserPaymentMethod',
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
  emit('onCardSelected', selectedRows.value[0])
}
</script>

<template>
  <BaseTable
    add-button-label="Ajouter un moyen de paiement"
    selection="single"
    :columns="columns"
    :rows="rows"
    :isFilterInput="false"
    @add-item-click="onAddClick"
    @on-row-selected="onRowSelected"
  >
    <template v-slot:action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
      <ItemActionMenuItem label="Gérer les droits" @click="onManageRightsClick" />
    </template>
  </BaseTable>
</template>
