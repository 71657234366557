<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

localStorage.clear()

console.log('logout')

router.push({ name: 'base/login' })
</script>

<template>
  <q-page flex> Logout </q-page>
</template>
