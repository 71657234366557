<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useDialogPluginComponent, useQuasar, Dialog } from 'quasar'
import { useStore } from '@/store/store'
import CreateProjectFromLOPDialog from './CreateProjectFromLOPDialog.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

const store = useStore()

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const router = useRouter()
const $q = useQuasar()
const userApi = store.state.api.getUserApi()
const isLoading = ref(false)

const projectName = ref('')

const lopProject = ref(undefined)

onBeforeUnmount(() => {
  $q.loading.hide()
})

const showLoading = function () {
  $q.loading.show({
    message: "Nous préparons votre dossier.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })
}

async function importMatter(project, matter) {
  $q.loading.show({
    message: "Nous importons les documents depuis LOP.\nMerci de patienter, cela peut prendre jusqu'à 1 minute.",
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
  })

  const result = await userApi.documentTreeImportLopMatter(project.id, matter.id)

  $q.loading.hide()
}

async function createProject() {
  showLoading()

  const project = await userApi.createProject({
    name: projectName.value,
    productId: '307fdeb7-a804-4b8c-8c0f-3ea8f4ab6ea1',
    projectTemplateIds: [],
  })

  $q.loading.hide()

  if (lopProject.value !== undefined) {
    console.log('importing lop matter', project, lopProject.value)
    await importMatter(project, lopProject.value)
  }

  if (store.state.user.portal == 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: { projectId: project.id },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: { clientId: store.state.user.clientId, projectId: project.id },
    })
  }
}

function showImportFromLOPDialog() {
  const dialog = Dialog.create({
    component: CreateProjectFromLOPDialog,
    componentProps: {
      project: undefined,
    },
  })
    .onOk((infos) => {
      dialog.hide()
      console.log('infos', infos)
      projectName.value = infos.title
      lopProject.value = infos
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function allowLopImport() {
  return true
  if (store.state.user.portal == 'ENVIRONMENT' && store.state.user.environment.id == '2c29fa44-fb99-4b0d-affd-b1800b379efa') {
    return true
  }
  return false
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Créer un dossier" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()" :isFullContent="true" :showCancelButton="false">

      <template #full-content>
        <div class="q-pa-md page-content q-col-gutter-md" style="max-width: 1000px; margin-left: 50px">
          <q-form :autofocus="true" @submit="createProject">
            <p>Quel nom voulez-vous donner au dossier ?</p>

            <div v-if="allowLopImport()" class="row">
              <div class="col">
                <q-input autofocus class="col-md-9" filled v-model="projectName" label="Nom du nouveau dossier *"
                  lazy-rules :rules="[(val) => (val && val.length > 0) || 'Le nom du dossier est requis']" />
              </div>
              <div class="col">
                <a href="#" @click="showImportFromLOPDialog()" class="import-from-lop-button-link">Importer depuis LOP</a>
              </div>
            </div>

            <div v-if="!allowLopImport()" class="row" style="margin-right: 50px;">
              <div class="col">
                <q-input autofocus class="col-md-12" filled v-model="projectName" label="Nom du nouveau dossier *"
                  lazy-rules :rules="[(val) => (val && val.length > 0) || 'Le nom du dossier est requis']" />
              </div>
            </div>

            <div v-if="lopProject !== undefined" class="row" style="margin-top: 0px">
              Dossier Lop : {{ lopProject.id }}
            </div>
          </q-form>
        </div>
      </template>

      <template #actions>
        <q-btn @click="createProject" color="primary" label="Créer le dossier" :disable="projectName == ''"></q-btn>
      </template>

    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
p {
  font-size: 16px;
}

ul {
  list-style-type: none;
}

li {
  cursor: pointer;
}

.import-from-lop-button-link {
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 20px;
  line-height: 4em;
}
</style>
