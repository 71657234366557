<script setup lang="ts">
import { onMounted, ref, provide, watch, inject } from 'vue'
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'
import { initOffice } from './OutlookAddInCommon.ts'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import SearchResult from './SearchResult.vue'
import Directories from './Directories.vue'
import ClientProjects from './ClientProjects.vue'
import { getNode } from '@/components/projects/execution/documents/DocumentsCommon'
import { Notify } from 'quasar'
import EnvironmentSwitcher from './OutlookEnvironmentSwitcher.vue'

const store = useStore()
const router = useRouter()
const userApi: UserApi = useApi()

store.commit('setPageTitle', 'Office Manager')

const outlookItem = ref(null)
const itemInfos = ref(null)
const importButtonLoading = ref(false)

const favorites = inject('favorites')

onMounted(() => {
  console.log('onmounted')
  initOffice(() => {
    const item = Office.context.mailbox.item
    console.log('item', item)
    update(item)
  })
})

async function update(item) {
  outlookItem.value = item
  documentName.value = item.subject

  attachments.value = item.attachments.map((attachment) => {
    return {
      id: attachment.id,
      name: attachment.name,
      import: true,
    }
  })

  itemInfos.value = await userApi.getOutlookMessageImmportInfo(outlookItem.value.itemId)
  console.log('itemInfos', itemInfos.value)
  if (itemInfos.value.status == 'error') {
    step.value = 1
  } else {
    step.value = 0
  }
}

function getSoapEnvelope(request) {
  // Wrap an Exchange Web Services request in a SOAP envelope.
  var result =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
    '               xmlns:xsd="http://www.w3.org/2001/XMLSchema"' +
    '               xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"' +
    '               xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">' +
    '  <soap:Header>' +
    '    <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />' +
    '  </soap:Header>' +
    '  <soap:Body>' +
    request +
    '  </soap:Body>' +
    '</soap:Envelope>'

  return result
}

function GetItem() {
  var results =
    '  <GetItem xmlns="http://schemas.microsoft.com/exchange/services/2006/messages">' +
    '    <ItemShape>' +
    '      <t:BaseShape>IdOnly</t:BaseShape>' +
    '      <t:IncludeMimeContent>true</t:IncludeMimeContent>' +
    '      <AdditionalProperties xmlns="http://schemas.microsoft.com/exchange/services/2006/types">' +
    '        <FieldURI FieldURI="item:Subject" />' +
    '      </AdditionalProperties>' +
    '    </ItemShape>' +
    '    <ItemIds>' +
    '      <t:ItemId Id="' +
    Office.context.mailbox.item.itemId +
    '" />' +
    '    </ItemIds>' +
    '  </GetItem>'

  return results
}

function base64toBlob(base64Data, contentType) {
  contentType = contentType || ''
  var sliceSize = 1024
  var byteCharacters = atob(base64Data)
  var bytesLength = byteCharacters.length
  var slicesCount = Math.ceil(bytesLength / sliceSize)
  var byteArrays = new Array(slicesCount)

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize
    var end = Math.min(begin + sliceSize, bytesLength)

    var bytes = new Array(end - begin)
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

async function uploadEmlFile(text) {
  var downloadblob = base64toBlob(text)
  // downloadblob.name = filename;
  console.log('downloadblob', downloadblob)

  var parentId = undefined
  if (importDirectory.value.id != 0) {
    parentId = importDirectory.value.id
  }

  let newFile = await userApi.uploadDocumentTreeEml(
    client.value.id,
    project.value.id,
    [downloadblob],
    [documentName.value + '.eml'],
    parentId,
    outlookItem.value.itemId,
    (progress) => {
      console.log('progress', progress)
    },
  )
}

async function uploadAttachmentFile(attachment, text) {
  var downloadblob = base64toBlob(text)
  // downloadblob.name = filename;
  console.log('downloadblob', downloadblob)

  var parentId = undefined
  if (importDirectory.value.id != 0) {
    parentId = importDirectory.value.id
  }

  let newFile = await userApi.uploadDocumentTreeEml(
    client.value.id,
    project.value.id,
    [downloadblob],
    [attachment.name],
    parentId,
    undefined,
    (progress) => {
      console.log('progress', progress)
    },
  )
}

function addMasterCategory() {
  const masterCategoriesToAdd = [
    {
      displayName: 'Importé dans MarvellUp',
      color: Office.MailboxEnums.CategoryColor.Preset12,
    },
  ]

  Office.context.mailbox.masterCategories.addAsync(masterCategoriesToAdd, function (asyncResult) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      console.log('Successfully added categories to master list')
    } else {
      console.log('masterCategories.addAsync call failed with error: ' + asyncResult.error.message)
    }
  })
}

function importMail() {
  importButtonLoading.value = true
  const item = Office.context.mailbox.item
  console.log('item', item)

  Office.context.mailbox.getCallbackTokenAsync({ isRest: false }, async function (result) {
    try {
      console.log('token result', result.value, Office.context.mailbox.ewsUrl, outlookItem.value.itemId)

      var parentId = undefined
      if (importDirectory.value.id != 0) {
        parentId = importDirectory.value.id
      }

      console.log("clientId", client.value.id)

      let newFile = await userApi.retrieveDocumentTreeEml(
        client.value.id,
        project.value.id,
        parentId,
        result.value,
        Office.context.mailbox.ewsUrl,
        outlookItem.value.itemId,
        documentName.value + '.eml',
      )

      console.log('uploaded')

      const categoriesToAdd = ['Importé dans MarvellUp']

      addMasterCategory()

      Office.context.mailbox.item.categories.addAsync(categoriesToAdd, function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log('Successfully added categories')
        } else {
          console.log('categories.addAsync call failed with error: ' + asyncResult.error.message)
        }
      })

      console.log('checking attachments')
      if (importAttachments.value) {
        console.log('importing attachments')
        attachments.value.forEach((attachment) => {
          console.log('attachment', attachment)
          if (attachment.import == false) {
            return
          }
          Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, (content) => {
            console.log('attachment content', content)
            uploadAttachmentFile(attachment, content.value.content)
          })
        })
      }

      itemInfos.value = await userApi.getOutlookMessageImmportInfo(outlookItem.value.itemId)
      importButtonLoading.value = false
      if (itemInfos.value.status == 'error') {
        // step.value = 1;
      } else {
        step.value = 0
      }
    } catch (e) {
      console.log(e)
      importButtonLoading.value = false
      Notify.create({
        message: "Une erreur est survenue lors de l'import du mail",
        color: 'negative',
        icon: 'report_problem',
      })
    }
  })
}

const recentProjects = await userApi.getRecentlyViewedProjects(1, '', 5)
recentProjects.results.forEach((recentProject) => {
  recentProject.objectId = recentProject.id
})

const step = ref(-1)
const importAttachments = ref(true)
const documentName = ref('')
const attachments = ref([])
const searchQuery = ref('')
provide('query', searchQuery)
const searchInput = ref('')
const client = ref(null)
provide('client', client)
const project = ref(null)
const importDirectory = ref(null)
provide('step', step)
const selectedNode = ref(null)
provide('documentTreeSelectedNode', selectedNode)
const searchResults = ref(null)
provide('searchResults', searchResults)

const documentTreeNodes = ref([
  {
    id: 0,
    type: 1,
    label: 'Racine',
    children: [],
  },
])
provide('documentTreeNodes', documentTreeNodes)

function changeMatter() {
  step.value = 1
}

function changeProject() {
  step.value = 11
}

function changeDirectory() {
  console.log('importDirectory', importDirectory)
  importDirectory.value = null

  step.value = 2
}

function searchMatters() {
  console.log('searchMatters')
  searchQuery.value = searchInput.value
}

watch(selectedNode, () => {
  console.log('selectedNode', selectedNode)
  if (selectedNode.value != null) {
    importDirectory.value = getNode(documentTreeNodes.value, selectedNode.value)
    selectedNode.value = null
  }
})

async function goToClientFavorite(clientFavorite) {
  console.log('client favorite', clientFavorite)
  const selectedClient = await userApi.getClient(clientFavorite.objectId)
  client.value = selectedClient
  step.value = 11
}

async function goToUserProjectFavorite(userProjectFavorite) {
  console.log('project favorite', userProjectFavorite)
  const selectedProject = await userApi.getProject(userProjectFavorite.objectId)
  client.value = await userApi.getClientForProject(userProjectFavorite.objectId)
  project.value = selectedProject
  documentTreeNodes.value = [
    {
      id: 0,
      type: 1,
      label: 'Racine',
      children: [],
    },
  ]
  step.value = 2
}
</script>

<template>
  <q-page class="page">
    <EnvironmentSwitcher />

    <h1>Import d'email</h1>

    <div v-if="step == -1">
      <div class="row">
        <div class="col"></div>
        <q-spinner class="col-auto" size="50px" color="primary" />
        <div class="col"></div>
      </div>
    </div>

    <div v-if="step == 0">
      <div style="margin-top: 16px; padding: 8px">Ce message est importé dans le dossier :</div>
      <div style="padding: 0px 8px">
        {{ itemInfos?.userProject?.name }}
      </div>
      <KeepAlive> </KeepAlive>
      <q-btn style="width: 100%; margin-top: 16px" @click="step = 1" label="Importer dans un autre dossier"
        color="primary"></q-btn>
    </div>

    <div v-if="step == 1">
      <div style="padding: 8px; font-weight: 500; color: #4068c8">Sélectionnez le dossier de destination</div>
      <KeepAlive>
        <q-input v-model="searchInput" outlined dense label="Rechercher un client ou un dossier"
          @keydown.enter.prevent="searchMatters()" class="search-input" />
      </KeepAlive>

      <KeepAlive>
        <div v-if="searchQuery == ''">
          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 16px;">Dossiers récents</div>
          <div v-for="(recentProject, index) in recentProjects.results" style="cursor: pointer"
            @click="goToUserProjectFavorite(recentProject)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ recentProject.name }}</div>
            </div>
          </div>

          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 8px;">Clients favoris</div>
          <!-- <q-item-label
            style="font-weight: 500; margin-top: -9px; margin-bottom: 4px; font-style: italic; color: #5469d4; margin-left: 16px; margin-top: 8px;">Clients</q-item-label> -->
          <div v-for="(clientFavorite, index) in favorites.clients" style="cursor: pointer"
            @click="goToClientFavorite(clientFavorite)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ clientFavorite.name }}</div>
            </div>
          </div>

          <div style="padding: 8px; font-weight: 500; color: #4068c8; margin-top: 8px;">Dossiers favoris</div>
          <!-- <q-item-label
            style="font-weight: 500; margin-top: -9px; margin-bottom: 4px; font-style: italic; color: #5469d4; margin-left: 16px; margin-top: 8px;">Dossiers</q-item-label> -->
          <div v-for="(userProjectFavorite, index) in favorites.userProjects" style="cursor: pointer"
            @click="goToUserProjectFavorite(userProjectFavorite)">
            <div class="row no-wrap quick_access_item">
              <div class="col-auto" style="margin-left: 8px; margin-bottom: 8px;">
                {{ userProjectFavorite.name }}</div>
            </div>
          </div>
        </div>
      </KeepAlive>
      <KeepAlive>
        <SearchResult />
      </KeepAlive>
    </div>

    <div v-if="step == 11">
      <div class="item-header" style="padding: 0px 8px">Client</div>
      <div class="row items-center no-wrap" style="padding: 0px 8px">
        <div class="col-auto">
          {{ client.name }}
        </div>
        <q-space></q-space>
        <q-btn dense class="col-auto" @click="changeMatter" icon="o_edit"></q-btn>
      </div>
      <q-separator></q-separator>
      <div style="margin-top: 8px; padding: 8px; font-weight: 500; color: #4068c8">
        Sélectionnez le dossier de destination
      </div>
      <KeepAlive>
        <ClientProjects />
      </KeepAlive>
    </div>

    <div v-if="step == 2">
      <div class="row-auto" style="padding: 0px 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
        <div class="item-header">Client</div>
        <div class="row items-center no-wrap">
          <div class="col-auto">
            {{ client.name }}
          </div>
          <q-space></q-space>
          <q-btn dense class="col-auto" @click="changeMatter" icon="o_edit"></q-btn>
        </div>
      </div>
      <div class="row-auto" style="padding: 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
        <div class="item-header">Dossier</div>
        <div class="row items-center no-wrap">
          <div class="col-auto">
            {{ project.name }}
          </div>
          <q-space></q-space>
          <q-btn dense class="col-auto" @click="changeProject" icon="o_edit"></q-btn>
        </div>
      </div>

      <q-separator></q-separator>

      <div style="padding: 8px; font-weight: 500; color: #4068c8">Sélectionnez le répertoire de destination</div>
      <KeepAlive>
        <Directories></Directories>
      </KeepAlive>
    </div>

    <div v-if="step == 3" class="column no-wrap" style="height: calc(100vh - 50px - 28px)">
      <q-scroll-area style="width: 100%; height: calc(100vh - 50px - 28px)">
        <div class="row-auto" style="padding: 0px 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
          <div class="item-header">Client</div>
          <div class="row items-center no-wrap">
            <div class="col-auto">
              {{ client.name }}
            </div>
            <q-space></q-space>
            <q-btn dense class="col-auto" @click="changeMatter" icon="o_edit"></q-btn>
          </div>
        </div>
        <div class="row-auto" style="padding: 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
          <div class="item-header">Dossier</div>
          <div class="row items-center no-wrap">
            <div class="col-auto">
              {{ project.name }}
            </div>
            <q-space></q-space>
            <q-btn dense class="col-auto" @click="changeProject" icon="o_edit"></q-btn>
          </div>
        </div>
        <div class="row-auto" style="padding: 8px; width: calc(100vw - 16px); max-width: calc(100vw - 16px)">
          <div class="item-header">Répertoire</div>
          <div class="row items-center no-wrap">
            <div class="col-auto">
              {{ importDirectory.label }}
            </div>
            <q-space></q-space>
            <q-btn dense class="col-auto" @click="changeDirectory" icon="o_edit"></q-btn>
          </div>
        </div>
        <q-separator style=""></q-separator>
        <div style="padding: 8px; font-weight: 500; color: #4068c8">Configurez l'import</div>
        <div class="row-auto">
          <q-input dense outlined v-model="documentName" label="Nom du document" style="padding: 8px" />
        </div>

        <q-separator></q-separator>

        <div class="row-auto">
          <q-toggle v-if="attachments.length > 0" v-model="importAttachments" label="Importer les pièces jointes" />
          <div v-if="attachments.length == 0" style="padding: 8px">Aucun fichier attaché à importer</div>
          <div v-if="importAttachments && attachments.length > 0" style="padding-right: 8px; padding-left: 8px">
            Pièces jointes à importer :
            <q-list dense separator style="padding: 8px 0px; width: calc(100vw - 16px)">
              <div v-for="attachment in attachments">
                <div class="row no-wrap items-center">
                  <q-checkbox class="col-auto" style="padding-left: 0px" dense v-model="attachment.import"></q-checkbox>
                  <div class="col"
                    style="margin-left: 4px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                    {{ attachment.name }}
                    <q-tooltip>
                      {{ attachment.name }}
                    </q-tooltip>
                  </div>
                  <!-- <q-space></q-space>
                                    <q-btn dense class="col-auto" @click="changeDirectory" icon="o_edit"></q-btn> -->
                </div>
              </div>
            </q-list>
          </div>
        </div>
      </q-scroll-area>

      <q-footer style="margin-bottom: 50px">
        <q-btn style="width: 100%" @click="importMail" label="Importer" color="primary"
          :loading="importButtonLoading"></q-btn>
      </q-footer>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
h1 {
  margin-top: 0 8px 8px 8px;
  padding: 0 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1rem;
}

.item-header {
  color: grey;
  font-size: 12px;
}

.page {}

h5 {
  margin-bottom: 10px;
}

h4 {
  font-size: 28px;
  margin-left: 10px;
  margin-right: 10px;
}

h5 {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-input {
  margin-left: 10px;
  margin-right: 10px;
}

.page {
  padding: 0px;
}
</style>
