<script setup lang="ts">
import { ref } from 'vue'
import { QTableProps, Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import GroupAddEditForm from './GroupAddEditForm.vue'
import GroupUsersForm from './GroupUsersForm.vue'
import GroupPermissionsForm from './GroupPermissionsForm.vue'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'

interface Props {
  clientId?: string
}
const props = defineProps<Props>()
const userApi = useApi()

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]
const isGroupTableLoading = ref(false)
const selectedRows = ref([])
const rows = ref([])

isGroupTableLoading.value = true
userApi.getPortalGroups(props.clientId).then((result) => {
  rows.value = result
  isGroupTableLoading.value = false
})

async function refreshGroups() {
  isGroupTableLoading.value = true
  const result = await userApi.getPortalGroups(props.clientId)
  rows.value = result
  isGroupTableLoading.value = false
}

function onAddClick() {
  const dialog = Dialog.create({
    component: GroupAddEditForm,
    componentProps: {
      title: 'Nouveau groupe',
      buttonConfirmLabel: 'Ajouter',
      mode: 'ADD',
      clientId: props.clientId,
      selectedGroup: selectedRows.value[0],
    },
  })
    .onOk(async () => {
      await refreshGroups()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onEditClick() {
  const dialog = Dialog.create({
    component: GroupAddEditForm,
    componentProps: {
      title: 'Modifier le groupe',
      buttonConfirmLabel: 'Modifier',
      mode: 'EDIT',
      clientId: props.clientId,
      selectedGroup: selectedRows.value[0],
    },
  })
    .onOk(async () => {
      await refreshGroups()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function onDeleteClick() {
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message: 'Voulez-vous vraiment supprimer le groupe ' + selectedRows.value[0].name + ' ?',
    },
  })
    .onOk(async () => {
      await userApi.deletePortalGroup(selectedRows.value[0].id, props.clientId)
      selectedRows.value = []
      await refreshGroups()

      dialog.hide()
      Notify.create({
        message: 'Groupe supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: GroupPermissionsForm,
    componentProps: {
      clientId: props.clientId,
      group: selectedRows.value[0],
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageUsersClick() {
  console.log("onManageUsersClick2", selectedRows.value[0], props.clientId)
  const dialog = Dialog.create({
    component: GroupUsersForm,
    componentProps: {
      clientId: selectedRows.value[0].clientId,
      selectedGroup: selectedRows.value[0],
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}
function onRowsSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable add-button-label="Ajouter un groupe" selection="single" :columns="columns" :rows="rows"
    :is-loading="isGroupTableLoading" @on-row-selected="onRowsSelected" @add-item-click="onAddClick">
    <template v-slot:action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
      <ItemActionMenuItem label="Associer des utilisateurs" @click="onManageUsersClick" />
      <ItemActionMenuItem label="Gérer les droits globaux" @click="onManageRightsClick" />
    </template>
  </BaseTable>
</template>
