import Main from './Main.vue'
import EnvironmentProjectCatalog from './project_catalog/EnvironmentProjectCatalog.vue'
import EnvironmentDocumentCatalog from './document_catalog/EnvironmentDocumentCatalog.vue'
import EnvironmentSubscriptionModules from './subscription_modules/EnvironmentSubscriptionModules.vue'

const routes = [
  {
    name: 'environments/main',
    component: Main,
    path: '/environments/main',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'environments/environment/project_catalog',
    component: EnvironmentProjectCatalog,
    path: '/environments/:environmentId/project_catalog',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'environments/environment/document_catalog',
    component: EnvironmentDocumentCatalog,
    path: '/environments/:environmentId/:environmentPkid/document_catalog',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'environments/environment/subscription_modules',
    component: EnvironmentSubscriptionModules,
    path: '/environments/:environmentId/:environmentPkid/subscription_modules',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
