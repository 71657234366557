import Main from './Main.vue'
import Profile from './Profile.vue'
import Theme from './Theme.vue'

const routes = [
  { name: 'settings/main', component: Main, path: '/settings' },
  { name: 'settings/profile', component: Profile, path: '/settings/profile' },
  { name: 'settings/theme', component: Theme, path: '/settings/theme' },
]

export { routes }
