<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ParagraphBlock } from '@/models/v2/document/elements/content/block/ParagraphBlock'
import InlineElementView from '../../../InlineElementView.vue'

interface Props {
  data: ParagraphBlock
}

const props = withDefaults(defineProps<Props>(), {})

const isMounted = ref(false)

function getInlineElementContainerSelector(element) {
  return "[inline-element-id='" + element.id + "']"
}

onMounted(() => {
  isMounted.value = true
})
</script>

<template>
  <q-menu touch-position context-menu>
    <q-list dense style="min-width: 100px">
      <q-item clickable v-close-popup>
        <q-item-section>Ajouter un commentaire</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
  <span class="q-ma-none full-width" contenteditable="false" v-html="data.content"> </span>
  <span v-for="element in data.elements">
    <Teleport :to="getInlineElementContainerSelector(element)" v-if="isMounted">
      <InlineElementView :element="element"></InlineElementView>
    </Teleport>
  </span>
</template>

<style lang="scss" scoped>
.paragraph-block-view {
  display: block;
  border: 1px solid;
  margin-bottom: 5px;
}

.paragraph-block-content {
  padding-left: 5px;
}

.block {
  border-left-color: #e2cb9d;
  border-left-width: 5px;
}
</style>
