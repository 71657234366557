<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'
import RenameChoiceDialog from './RenameChoiceDialog.vue'

const unsavedChanges = inject("unsavedChanges")
const selectedPage = inject("selectedPage")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function getCardClass(type) {
    return "drag-cursor"
}


function onChange() {
    console.log("onChange")
    unsavedChanges.value = true
}

function onClone() {
    console.log("onClone")
}

function createUUID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
}

function addItem() {
    console.log("addItem")
    props.element.choices.push({
        id: createUUID(),
        label: "Nouveau choix " + (props.element.choices.length + 1),
        value: "Nouveau choix " + (props.element.choices.length + 1)
    })
    unsavedChanges.value = true
}

function editNode(choice) {
    const dialog = Dialog.create({
        component: RenameChoiceDialog,
        componentProps: {
            node: choice,
        },
    })
        .onOk((newName) => {
            choice.label = newName
            choice.value = newName
            unsavedChanges.value = true
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

function removeNode(choice) {
    props.element.choices = props.element.choices.filter((item) => {
        return item.id !== choice.id
    })
    unsavedChanges.value = true
}
</script>


<template>
    <div style="margin-top: 16px; margin-bottom: 16px;">
        Choix possibles :
    </div>
    <q-list dense class="" style="margin-left: 68px;">
        <draggable :list="element.choices" :group="{ name: 'choices', pull: 'clone', put: false }" ghost-class="ghost"
            item-key="id" class="q-gutter-sm" :clone="onClone" @change="onChange">
            <template #item="{ element, index }">
                <div style="" class="choice">
                    <span>
                        {{ element.label }}
                    </span>
                    <q-btn dense icon="o_edit" size="12px" @click.stop="editNode(element)" text-color="grey"
                        class="choice-button">
                        <q-tooltip>Renommer</q-tooltip>
                    </q-btn>
                    <q-btn icon="o_clear" size="12px" dense @click.stop="removeNode(element)" text-color="grey"
                        class="choice-button">
                        <q-tooltip>Supprimer</q-tooltip>
                    </q-btn>
                </div>
            </template>
            <template #footer>
                <div style="">
                    <q-btn dense color="primary" label="Ajouter un choix" style="padding-left: 8px; padding-right: 8px;"
                        @click="addItem"></q-btn>
                </div>
            </template>
        </draggable>
    </q-list>
</template>

<style scoped lang="scss">
.choice-button {
    visibility: hidden;
}

.choice:hover .choice-button {
    visibility: visible;
}
</style>