import Main from './Main.vue'
import Tasks from './Tasks.vue'
import Project from '../../common/projects/Project.vue'
import CreateProject from '../../common/projects/CreateProject.vue'
import ProjectRedirect from './ProjectRedirect.vue'
import Survey from '../../common/survey/Survey.vue'
import SurveySummary from '../../common/survey/SurveySummary.vue'

const routes = [
  {
    name: 'projects/main',
    component: Main,
    path: '/projects',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/project',
    component: Project,
    path: '/projects/:projectId',
    props: (route) => ({
      documentId: route.query.documentId,
      taskId: route.query.taskId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects/project',
    component: ProjectRedirect,
    path: '/clients/:clientId/projects/:projectId',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/create',
    component: CreateProject,
    path: '/projects/create',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/tasks',
    component: Tasks,
    path: '/projects/tasks',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/tasks/documents/survey',
    component: Survey,
    path: '/projects/:projectId/tasks/:taskId/documents/:documentId/survey',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: '/documents/survey',
    component: Survey,
    path: '/documents/:documentId/survey',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/tasks/documents/survey/summary',
    component: SurveySummary,
    path: '/projects/:projectId/tasks/:taskId/documents/:documentId/survey/summary',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'documents/survey/summary',
    component: SurveySummary,
    path: '/documents/:documentId/survey/summary',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
