const mainMenu = {
  menu: {
    sections: [
      {
        name: 'home',
        label: 'Accueil',
        link: 'dashboard/main',
        icon: 'o_home',
        iconSelected: 'home',
        roles: ['councel', 'admin', 'finance', 'user'],
        items: [],
      },
      // {
      //   name: "calendar",
      //   label: "Calendrier",
      //   link: "activity/calendar",
      //   icon: "o_event",
      //   iconSelected: "event",
      //   roles: ["admin", "finance"],
      //   items: [],
      // },
      {
        name: 'clients',
        label: 'Clients',
        link: 'clients/main',
        icon: 'o_contact_page',
        iconSelected: 'contact_page',
        roles: ['councel', 'admin', 'user'],
        items: [],
      },
      {
        name: 'current_client',
        label: 'Client',
        link: 'clients/client/projects',
        icon: 'o_contact_page',
        iconSelected: 'contact_page',
        roles: ['councel', 'admin', 'user'],
        custom: 'client_selector',
        items: [
          {
            label: 'Dossiers',
            link: 'clients/client/projects',
            roles: ['councel', 'admin', 'user'],
            custom: 'need_client',
          },
          {
            label: 'Dossiers permanents client',
            link: 'clients/client/database',
            roles: ['councel', 'admin', 'user'],
            custom: 'need_client',
          },
          {
            label: 'Utilisateurs client',
            link: 'clients/client/users',
            roles: ['councel', 'admin', 'user'],
            custom: 'need_client',
          },
        ],
      },
      // {
      //   name: 'environment_database',
      //   label: 'Dossiers permanents cabinet',
      //   link: 'administration/database',
      //   icon: 'o_folder',
      //   iconSelected: 'folder',
      //   roles: ['admin', 'finance'],
      //   items: [],
      // },
      {
        name: 'users',
        label: 'Utilisateurs {{ENVIRONMENT_NAME}}',
        link: 'administration/users',
        icon: 'o_people',
        iconSelected: 'people',
        roles: ['admin', 'finance'],
        items: [],
      },
      {
        name: 'favorites',
        label: 'Accès rapide',
        link: 'none',
        icon: 'o_star_border',
        iconSelected: 'o_star',
        roles: ['admin', 'finance'],
        custom: 'quick_access',
        items: [
          {
            label: 'Derniers dossiers consultés',
            link: 'quick_access',
            roles: ['councel', 'admin', 'user'],
            custom: 'recent_projects_quick_access',
          },
          {
            label: 'Clients favoris',
            link: 'quick_access',
            roles: ['councel', 'admin', 'user'],
            custom: 'clients_quick_access',
          },
          {
            label: 'Dossiers favoris',
            link: 'quick_access',
            roles: ['councel', 'admin', 'user'],
            custom: 'user_projects_quick_access',
          },
        ],
      },
      // {
      //   name: "credits",
      //   label: "Crédits",
      //   link: "administration/credits",
      //   icon: "o_payments",
      //   iconSelected: "payments",
      //   roles: ["admin", "finance"],
      //   items: [],
      // },
      // {
      //   name: "products",
      //   label: "Catalogue de produits",
      //   link: "administration/products",
      //   icon: "o_inventory_2",
      //   iconSelected: "inventory_2",
      //   roles: ["councel", "admin", "finance"],
      //   items: [],
      // },
    ],
  },
}

export { mainMenu }
