<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { ConditionBlock } from '@/models/v2/document/elements/control/block/ConditionBlock'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import DependantVariable from './DependantVariable.vue'

interface Props {
  condition: ConditionBlock
}
const props = withDefaults(defineProps<Props>(), {})
const conditionData: Ref<ConditionBlock> = ref(props.condition)
const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Condition" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="conditionData.label" label="Nom de la condition"> </BaseInput>
        <q-separator> </q-separator>
        <DependantVariable></DependantVariable>
      </template>
      <template #actions>
        <q-btn color="primary" label="Modifier" v-close-popup></q-btn>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
