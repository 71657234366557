<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { FieldType } from '@/models/database/FieldType'

import StringField from './StringField.vue'
import FormattedTextField from './FormattedTextField.vue'
import DateField from './DateField.vue'
import SelectField from './SelectField.vue'
import ObjectField from './ObjectField.vue'
import ObjectLinkField from './ObjectLinkField.vue'
import ObjectListField from './ObjectListField.vue'
import ProjectLinkField from './ProjectLinkField.vue'

interface Props {
  database: Database
  environmentDatabase: Array<Database>
  node: Node
  field: Field
}

const props = defineProps<Props>()

const emit = defineEmits(['update:field'])

function onFieldChange() {
  emit('update:field', props.node, props.field)
}
</script>

<template>
  <q-card flat bordered class="object-field">
    <q-card-section>
      <div class="3">{{ field.label }}</div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <div v-for="subField in field?.value.fields">
        <StringField
          v-if="subField.fieldModel.type === FieldType.String"
          :database="database"
          :node="field.value"
          @change="onFieldChange"
          :field="subField"
        />
        <FormattedTextField
          v-if="subField.fieldModel.type === FieldType.FormattedText"
          :database="database"
          :node="field.value"
          @change="onFieldChange"
          :field="subField"
        />
        <DateField
          v-if="subField.fieldModel.type === FieldType.Date"
          :database="database"
          :node="field.value"
          @change="onFieldChange"
          :field="subField"
        />
        <SelectField
          v-if="subField.fieldModel.type === FieldType.Select"
          :database="database"
          :node="field.value"
          @change="onFieldChange"
          :field="subField"
        />
        <ObjectField
          v-if="subField.fieldModel.type === FieldType.Object"
          :database="database"
          :node="field.value"
          :environmentDatabase="environmentDatabase"
          @change="onFieldChange"
          :field="subField"
        />
        <ObjectLinkField
          v-if="subField.fieldModel.type === FieldType.ObjectLink"
          :database="database"
          :environmentDatabase="environmentDatabase"
          @change="onFieldChange"
          :node="field.value"
          :field="subField"
        />
        <ObjectListField
          v-if="subField.fieldModel.type === FieldType.ObjectList"
          :database="database"
          :environmentDatabase="environmentDatabase"
          @change="onFieldChange"
          :node="field.value"
          :field="subField"
        />
        <ProjectLinkField
          v-if="subField.fieldModel.type === FieldType.ProjectLink"
          :database="database"
          :environmentDatabase="environmentDatabase"
          @change="onFieldChange"
          :node="field.value"
          :field="subField"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>
.object-field {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
