<script setup lang="ts">
import { Document } from '@/models/v2/document/Document'
import { ref, Ref, provide, watch } from 'vue'
import SectionView from './SectionView.vue'
import { format } from 'date-fns'
import BaseInput from '@/components/base/BaseInput.vue'

interface Props {
  document: Document
}

const props = withDefaults(defineProps<Props>(), {})

const splitterModel = ref(300)

const comments = ref([])
const commentContent = ref('')
comments.value = [
  {
    id: 1,
    username: 'Cédric Dinont',
    text: "A quelle date penses-tu qu'on puisse faire démarrer son contrat ?",
    createdAt: new Date(),
    avatarId: 1,
  },
  {
    id: 2,
    username: 'Jean-Louis Lasseri',
    text: 'Pour le 15/10/2022.',
    createdAt: new Date(),
    avatarId: 4,
  },
]

function postComment() {
  comments.value.push({
    id: comments.value.length + 1,
    username: 'Jane Doe',
    text: commentContent.value,
    createdAt: new Date(),
    avatarId: 3,
  })
  commentContent.value = ''
}

function answer(username) {
  commentContent.value = `@${username} `
}
</script>

<template>
  <q-splitter v-model="splitterModel" reverse unit="px" style="height: 100%">
    <template v-slot:before>
      <q-scroll-area style="height: 93.6vh; width: 100%; padding-right: 16px" ref="scrollAreaRef">
        <div class="document-view col full-height q-gutter-sm">
          <SectionView v-for="section in document.sections" :section="section"> </SectionView>
        </div>
      </q-scroll-area>
    </template>
    <template v-slot:after>
      <q-scroll-area style="height: 93.6vh; width: 100%; padding-right: 16px" ref="scrollAreaRef">
        <div class="comments">
          <q-card class="my-card" flat bordered style="margin: 10px" v-for="comment in comments">
            <q-item>
              <q-item-section avatar>
                <q-avatar>
                  <img :src="`https://cdn.quasar.dev/img/avatar${comment.avatarId}.jpg`" />
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ comment.username }}</q-item-label>
                <q-item-label caption>
                  {{ format(new Date(comment.createdAt), 'dd/MM/yyyy HH:mm') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-separator />
            <q-card-section horizontal>
              <q-card-section style="padding-bottom: 5px; width: 100%">
                {{ comment.text }}
                <q-separator style="margin-top: 5px" />
                <q-btn size="sm" style="float: right; margin-top: 5px" @click="answer(comment.username)"
                  >répondre</q-btn
                >
              </q-card-section>
            </q-card-section>
          </q-card>

          <q-card class="my-card" flat style="margin: 10px">
            <BaseInput dense v-model="commentContent" label="Ajouter un commentaire" />
            <q-btn dense style="margin-top: 10px; margin-bottom: 10px" @click="postComment">Publier</q-btn>
          </q-card>
        </div>
      </q-scroll-area>
    </template>
  </q-splitter>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
.block-element-header {
  display: block;
  width: 200px;
  border: 1px solid;
  padding-left: 5px;
}

.content-block-element-header {
  background-color: #b0e2f2;
}

.control-block-element-header {
  background-color: #e2cb9d;
}

.inline-element-header {
  display: block;
  width: 200px;
  border: 1px solid;
}

.content-inline-element-header {
  background-color: #b0e2f2;
}

.control-inline-element-header {
  background-color: #e2cb9d;
}

.document-view {
  padding-right: 10px;
  width: 100%;
}

.comments {
}
</style>
