<script setup lang="ts">
import { useStore } from '@/store/store'
import EntitiesTable from './EntitiesTable.vue'

const store = useStore()
store.commit('setPageTitle', 'Gestion des dossiers permanents')
</script>

<template>
  <q-page padding class="page">
    <h5 style="margin-top: 20px">Entités des dossiers permanents</h5>

    <div style="margin-left: 24px; margin-top: 24px;">
      <EntitiesTable></EntitiesTable>
    </div>

  </q-page>
</template>
