<script lang="ts" setup>
import { ref, inject } from 'vue'
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import TextField from "./TextField.vue"
import NumberField from "./NumberField.vue"
import ChoiceField from "./ChoiceField.vue"
import MultipleChoice from "./MultipleChoiceField.vue"
import DateField from "./DateField.vue"
import WysiwygTextField from "./WysiwygTextField.vue"
import FunctionField from "./FunctionField.vue"

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const columns = [
  {
    name: "actions",
    label: "actions",
    field: "actions",
    align: 'left',
    sortable: false,
  }
]

console.log(props.field)

props.field.columns.forEach((column: any) => {
  console.log(column)
  columns.push({
    name: column.name,
    label: column.name,
    field: column.name,
    align: 'left',
    sortable: true,
  })
})

const rows = ref([])
const rowsUpdated = inject("rowsUpdated")
// const rows = ref<any[]>([])

function addRow() {
  console.log("rows before add", rows.value)
  const row = {
    id: createUUID(),
    fields: []
  }
  props.field.columns.forEach((column: any) => {
    row.fields.push({
      name: column.name,
      type: column.type,
      value: ""
    })
  })
  rows.value.push(row)
  rowsUpdated.value = rowsUpdated.value + 1
  console.log("rows after add", rows.value)
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

function removeRow(rowIndex) {
  console.log("rows before remove", rows.value)
  rows.value.splice(rowIndex, 1)
  rowsUpdated.value = rowsUpdated.value + 1
  console.log("rows after remove", rows.value)
}

</script>

<template>
  <q-table :columns="columns" :rows="rows" flat bordered>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="actions" :props="props">
          <q-btn v-if="props.rowIndex === rows.length - 1" outline rounded class="q-mr-sm" padding="xs" color="primary"
            icon="add" @click="addRow()"></q-btn>
          <q-btn outline rounded color="negative" padding="xs" icon="delete" @click="removeRow(props.rowIndex)"></q-btn>
        </q-td>
        <q-td :props="props" :key="field.name" v-for="field in props.row.fields">
          <TextField v-if="field.type === 'text'" :field="field" :showLabel="false" />
          <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" :showLabel="false" />
          <NumberField v-if="field.type === 'number'" :field="field" :showLabel="false" />
          <DateField v-if="field.type === 'date'" :field="field" :showLabel="false" />
          <ChoiceField v-if="field.type === 'choice'" :field="field" :showLabel="false" />
          <MultipleChoice v-if="field.type === 'multi_choice'" :field="field" :showLabel="false" />
          <FunctionField v-if="field.type === 'function'" :field="field" :row="props.row" :showLabel="false" />
        </q-td>
      </q-tr>
    </template>
    <!-- <template #top v-if="rows.length">
      <q-btn icon="add" color="primary" class="q-mr-md" style="margin: auto" @click="addRow()">Ajouter
        une donnée</q-btn>
    </template> -->
    <template #no-data>
      <div class="row justify-center full-width">
        <q-btn icon="add" color="primary" @click="addRow()">Ajouter une donnée</q-btn>
      </div>
    </template>
  </q-table>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
