<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue'

const error = ref<Error>(null)

onErrorCaptured((e) => {
  error.value = e
  return true
})
</script>

<template>
  <div v-if="error">{{ error }}</div>
  <Suspense v-else>
    <template #default>
      <div>
        <slot></slot>
      </div>
    </template>
    <template #fallback>
      <div>Chargement...</div>
    </template>
  </Suspense>
</template>
