import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

import { Alternative } from './Alternative'

export class AlternativeBlock extends Element implements Serializable<AlternativeBlock> {
  alternative: Alternative

  constructor() {
    super()

    this.condition = new Alternative()
  }

  deserialize(input: Object): AlternativeBlock {
    super.deserialize(input)

    this.alternative = new Alternative()
    this.alternative.deserialize(input)

    return this
  }
}
