<script lang="ts" setup>
import { ref } from 'vue'
import { Task } from '@/models/projects/Task'
import { useApi } from '@/store/useAppStore'
import { useDialogPluginComponent, Notify } from 'quasar'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'

interface Props {
  task: Task
}
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()

const name = ref('')
const adress = ref('')
const city = ref('')
const postalCode = ref('')
const country = ref('France')
const file = ref(null)
const isLoading = ref(false)

async function sendMail() {
  try {
    isLoading.value = true
    const mail = {
      name: name.value,
      addressLine1: adress.value,
      addressCity: city.value,
      addressPostalcode: postalCode.value,
      addressCountry: country.value,
    }
    await userApi.sendMail(props.task.id, mail, file.value)
    emit('ok')
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
    console.error('oups', e)
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Envoi de courrier recommandé"
      caption="Veuillez compléter les informations du destinataire."
      :is-dialog-loading="isLoading"
      :is-full-content="true"
      :show-actions="false"
      loading-message="Veuillez patienter, nous envoyons votre courrier..."
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #full-content>
        <q-form @submit="sendMail">
          <q-card-section class="column q-gutter-md">
            <BaseInput
              v-model="name"
              label="Destinataire"
              hint="Nom et prénom ou raison sociale"
              :rules="[(val) => (val && val.length > 0) || 'Veuillez indiquer le nom du destinataire']"
            >
            </BaseInput>
            <BaseInput
              v-model="adress"
              label="Adresse"
              hint="Numéro et nom de rue"
              :rules="[(val) => (val && val.length > 0) || 'Veuillez indiquer l\'adresse du destinataire']"
            >
            </BaseInput>
            <BaseInput
              v-model="city"
              label="Ville"
              :rules="[(val) => (val && val.length > 0) || 'Veuillez indiquer la ville du destinataire']"
            >
            </BaseInput>
            <BaseInput
              v-model="postalCode"
              label="Code postal"
              :rules="[(val) => (val && val.length > 0) || 'Veuillez indiquer le code postal du destinataire']"
            >
            </BaseInput>
            <BaseInput
              v-model="country"
              label="Pays"
              :rules="[(val) => (val && val.length > 0) || 'Veuillez indiquer le pays du destinataire']"
            >
            </BaseInput>
            <q-file outlined v-model="file" label="Sélectionnez le document PDF" accept=".pdf" />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Annuler" color="primary" v-close-popup />
            <q-btn flat type="Submit" label="Envoyer" color="primary" />
          </q-card-actions>
        </q-form>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
