<script setup lang="ts">
import { ref, Ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { TaskTypeEnum } from '@/models/projects/TaskType.enum.ts'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import Editor from '@tinymce/tinymce-vue'
import draggable from 'vuedraggable'
import BaseDialog from '@/components/base/BaseDialog.vue'

const router = useRouter()

const store = useStore()
store.commit('setPageTitle', 'Éditeur de projet')
const sections: Ref<Array<any>> = ref([])
const selectedTask = ref(null)
const splitterModel = ref(50)
const horizontalSplitter = ref(80)
const userApi: UserApi = useApi()
const showDialog = ref(false)
const selectedIssues = ref([])
const issues = ref([])

const { id } = router.currentRoute.value.params

fetch(userApi.api.BASE_URL + `api/v1/legacy/projects_models/${id}`).then((response) => {
  response.json().then((data) => {
    sections.value = data.sections
    sections.value.forEach((section) => {
      section['elementType'] = 'section'
      section['elements'] = []
      section.sections.forEach((subSection) => {
        subSection['elementType'] = 'section'
        subSection['elements'] = []
        subSection.tasks.forEach((task) => {
          subSection.elements.push({
            ...task,
            elementType: 'task',
          })
        })
      })
      section.tasks.forEach((task) => {
        if (section.elements === undefined) {
          section.value['elements'] = []
        }
        task.issues = []
        if (!task.description) {
          const issue = {
            type: 'warning',
            content: 'Projet sans description',
          }
          task.issues.push(issue)
          issues.value.push({
            type: 'warning',
            content: `${task.order + 1} - ${task.name} - ${issue.content}`,
          })
        }
        if (task.type === 'document_signature' && !task.documentName) {
          const issue = {
            type: 'error',
            content: 'Pas de document attaché à une tâche de signature',
          }
          task.issues.push(issue)
          issues.value.push({
            type: 'error',
            content: `${task.order + 1} - ${task.name} - ${issue.content}`,
          })
        }
        if (task.type === 'document_generation' && !task.documentName) {
          const issue = {
            type: 'error',
            content: 'Pas de document attaché à la tâche de génération',
          }
          task.issues.push(issue)
          issues.value.push({
            type: 'error',
            content: `${task.order + 1} - ${task.name} - ${issue.content}`,
          })
        }
        let issueType
        for (let i = 0; i < task.issues.length; i++) {
          const issue = task.issues[i]
          if (issue.type === 'error') {
            issueType = 'error'
            continue
          } else if (issue.type === 'warning') {
            issueType = 'warning'
            continue
          }
        }

        section.elements.push({
          ...task,
          elementType: 'task',
          issueType,
        })
      })
    })
  })
})

function selectTask(task) {
  selectedTask.value = task
}

function getIcon(task): string {
  let icon: string = 'task'
  switch (task.type) {
    case TaskTypeEnum.Information:
      icon = 'info_outline'
      break
    case TaskTypeEnum.Form:
      icon = 'list_alt'
      break
    case TaskTypeEnum.DocumentGeneration:
      icon = 'o_description'
      break
    case TaskTypeEnum.DocumentUpload:
      icon = 'upload_file'
      break
    case TaskTypeEnum.DocumentSignature:
      icon = 'drive_file_rename_outline'
      break
    case TaskTypeEnum.OnlinePayment:
      icon = 'payment'
      break
    case TaskTypeEnum.MailSending:
      icon = 'mail_outline'
      break
    case TaskTypeEnum.AppointmentMaking:
      icon = 'date_range'
      break

    default:
      break
  }
  return icon
}

function openDialog(task) {
  showDialog.value = true
  task = toRaw(task)
  selectedIssues.value = toRaw(task.issues)
}
</script>
<template>
  <q-page padding class="page">
    <q-splitter v-model="horizontalSplitter" horizontal>
      <template v-slot:before>
        <h5>Modèles de projets</h5>
        <q-splitter v-model="splitterModel" style="height: 50vh">
          <template v-slot:before style="margin: 10px">
            <q-list bordered class="q-ml-none q-ma-sm">
              <draggable :list="sections" ghost-class="ghost" :group="{ name: 'g2' }">
                <template #item="{ element, index }">
                  <q-expansion-item
                    switch-toggle-side
                    expand-icon-toggle
                    expand-separator
                    default-opened
                    clickable
                    ripple
                  >
                    <template #header>
                      <q-item-section>
                        <span> {{ index + 1 }} - {{ element.name }} </span>
                      </q-item-section>
                    </template>
                    <draggable
                      class="q-gutter-sm q-pa-sm"
                      :list="element.elements"
                      ghost-class="ghost"
                      :group="{ name: 'g2' }"
                    >
                      <template #item="{ element, index }">
                        <q-card flat bordered>
                          <q-expansion-item
                            switch-toggle-side
                            expand-icon-toggle
                            expand-separator
                            :label="`${index + 1} - ${element.name}`"
                            v-if="element.elementType === 'section'"
                          >
                            <draggable
                              class="drag-area q-gutter-sm q-pa-sm"
                              :list="element.elements"
                              ghost-class="ghost"
                              :group="{ name: 'g2' }"
                            >
                              <template #item="{ element, index3 }">
                                <q-card flat bordered class="q-mb-sm" @click="selectTask(element)">
                                  <q-list separator>
                                    <q-item clickable v-ripple>
                                      <q-item-section avatar>
                                        <q-icon color="primary" :name="getIcon(element)" />
                                      </q-item-section>
                                      <q-item-section>
                                        {{ element.name }}
                                      </q-item-section>
                                    </q-item>
                                  </q-list>
                                </q-card>
                              </template>
                            </draggable>
                          </q-expansion-item>
                          <div v-if="element.elementType === 'task'">
                            <q-list separator @click="selectTask(element)">
                              <q-item clickable v-ripple>
                                <q-item-section avatar>
                                  <q-icon color="primary" :name="getIcon(element)" />
                                </q-item-section>
                                <q-item-section> {{ index + 1 }} - {{ element.name }} </q-item-section>
                                <q-item-section avatar v-if="element.issueType">
                                  <q-icon
                                    :color="element.issueType === 'warning' ? 'warning' : 'negative'"
                                    name="info_outline"
                                    @click="openDialog(element)"
                                  />
                                </q-item-section>
                              </q-item>
                            </q-list>
                          </div>
                        </q-card>
                      </template>
                    </draggable>
                  </q-expansion-item>
                </template>
              </draggable>
            </q-list>
          </template>

          <template v-slot:after>
            <div v-if="selectedTask" class="q-pa-sm">
              <q-card flat bordered>
                <q-card-section>
                  <BaseInput v-model="selectedTask.name" label="Nom de la tâche"> </BaseInput>
                  <BaseCheckbox v-model="selectedTask.optional" label="Tâche facultatif"> </BaseCheckbox>
                  <editor
                    api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44"
                    :init="{
                      menubar: false,
                      plugins: 'lists advlist link searchreplace paste wordcount',
                      toolbar:
                        'undo redo | bold italic underline | align | \
                                                          bullist numlist | removeformat',
                    }"
                    v-model="selectedTask.description"
                  />
                </q-card-section>
                <q-card-actions align="right">
                  <q-btn color="white" outline text-color="black" style="margin: 10px"> Supprimer </q-btn>

                  <q-btn color="primary" style="margin: 10px"> Enregistrer modification </q-btn>
                </q-card-actions>
              </q-card>
            </div>
            <div v-if="!selectedTask" class="q-pa-sm">
              <div class="column items-center content-center">
                <q-icon name="help_outline" size="xl" color="grey"></q-icon>
                <span> Veuillez sélectionner un élement pour afficher son détail </span>
              </div>
            </div>
          </template>
        </q-splitter>
      </template>
      <template v-slot:after>
        <q-list>
          <h5>Erreurs:</h5>
          <q-item v-for="issue in issues" clickable :class="issue.type">
            <q-item-section>{{ issue.content }}</q-item-section>
          </q-item>
        </q-list>
      </template>
    </q-splitter>
  </q-page>
  <!-- <BaseDialog
        :show-dialog="showDialog"
        @before-hide="showDialog = false"
        :showCancelButton="false"
      >
        <template #title>Erreurs:</template>
        <template #body>
          <q-list>
            <q-item v-for="issue in selectedIssues" :class="issue.type">
              <q-item-section>{{ issue.content }}</q-item-section>
            </q-item>
          </q-list>
        </template>
      </BaseDialog> -->
</template>

<style lang="scss" scoped>
.card-section {
  cursor: pointer;
}

.card-section:hover {
  background-color: #e1e3e5;
}

.error {
  border-left: 5px solid var(--q-negative);
  /* border-top: 5px solid var(--q-negative); */
}

.warning {
  border-left: 5px solid var(--q-warning);
  /* border-top: 5px solid var(--q-warning); */
}
</style>
