<script setup lang="ts">
import { ref, Ref, provide, computed } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ImportFilesGlobal from './ImportFilesGlobal.vue'

interface Props {
  project: any
  currentDirectory: any
  dataTransferItems?: any
  types?: any
}

const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const filesToUpload: Ref<Array<File>> = ref([])

const elementsToUpload: Ref<Array<any>> = ref([])
provide('elementsToUpload', elementsToUpload)

const uploading = ref(false)
const progress1 = ref(0)
const progress2 = ref(0)

async function getFile(fileEntry) {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject))
  } catch (err) {
    console.log(err)
  }
}

let dataTransferItems
console.log('props.dataTransferItems', props.dataTransferItems)
// if (props.dataTransferItems) {
//   dataTransferItems = ref(props.dataTransferItems)
// } else {
dataTransferItems = ref([])
// }

readFiles(props.dataTransferItems)

provide('dataTransferItems', dataTransferItems)

function readFiles(items) {
  console.log('dataTransferItems', items)
  for (let i = 0; i < items.length; i++) {
    let item = items[i].webkitGetAsEntry()
    console.log('dataTransferItem', i, item)
    if (item != null) {
      console.log('found files')
      dataTransferItems.value.push(item)
      if (item) {
        scanFiles(item, undefined)
      }
    } else {
      console.log('other type. bypassing')
    }
  }
}

function scanFiles(item, parent) {
  if (item.isDirectory) {
    elementsToUpload.value.push({
      type: 'directory',
      item: item,
      parent: parent,
    })
  } else {
    elementsToUpload.value.push({
      type: 'file',
      item: item,
      parent: parent,
    })
  }
}

/**
 * Uploads a file or directory (recursively with its items)
 * Returns a list of the nodeIds created
 * @param item
 * @param parent
 */
async function uploadItem(item, parent) {
  if (item.isDirectory) {
    let newDirectory = await userApi.createDocumentTreeDirectory(props.project?.value.id, {
      name: item.name,
      order: 0,
      parentId: parent,
    })
    let nodeIds = [newDirectory.id]

    // Upload each item under the current directory
    let directoryReader = item.createReader()

    let entries = await new Promise((resolve, reject) => {
      directoryReader.readEntries(entries => resolve(entries), error => reject(error));
    });
    // Use Promise.all to upload items in parallel
    await Promise.all(entries.map(async (entry) => {
      let childrenNodeIds = await uploadItem(entry, newDirectory.id);
      nodeIds = nodeIds.concat(childrenNodeIds);
    }));
    return nodeIds
  } else {
    let file = await getFile(item) // Wait until we have the file
    let nodeIds = await userApi.uploadDocumentTreeFiles(props.project?.value.id, [file], parent, onUploadProgress)
    return nodeIds
  }
}

function onUploadProgress(progressEvent) {
  progress1.value = progressEvent.progress
}

async function uploadAllElements() {
  uploading.value = true

  console.log('uploading to', props.currentDirectory)

  try {
    const totalItems = dataTransferItems.value.length;
    let processedItems = 0;
    let nodeIds = [];

    await Promise.all(dataTransferItems.value.map(async (item) => {
      let nodeIdList = await uploadItem(item, props.currentDirectory?.id);
      nodeIds = nodeIds.concat(nodeIdList);

      // Update progress based on the number of processed items
      processedItems++;
      progress2.value = processedItems / totalItems;
    }));

    // TODO: Tell parent to freeze
    emit('ok', filesToUpload.value)

  } catch (error) {
    console.error('Error during upload:', error);
    // TODO: Handle inform user
  } finally {
    uploading.value = false;
  }
}

</script>

<template>
  <q-dialog ref="dialogRef" no-backdrop-dismiss no-esc-dismiss>
    <BaseDialog
      title="Importer des fichiers"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <!-- <ImportFilesGlobal></ImportFilesGlobal> -->

        <div v-if="elementsToUpload.length != 0">
          <div style="font-weight: 700; margin-top: 10px">Fichiers à importer :</div>
          <q-scroll-area style="height: 200px; max-width: 100%" visible>
            <q-list bordered separator>
              <q-item v-for="elementToUpload in elementsToUpload">
                <q-item-section>
                  <q-item-label>{{ elementToUpload.item.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </div>

        <div v-if="uploading">
          <div style="font-weight: 700; margin-top: 10px">Progression :</div>
          Fichier en cours :
          <q-linear-progress size="25px" :value="progress1" color="primary">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>

          Total :
          <q-linear-progress size="25px" :value="progress2" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>
        </div>
      </template>
      <template #actions>
        <q-btn color="primary" @click="uploadAllElements" flat label="Importer" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
