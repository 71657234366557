<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
    title: string
    buttonConfirmLabel: string
    mode: string
    clientId?: string | undefined
    selectedUser?: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const clientId = ref(props.clientId)

const isLoading: Ref<boolean> = ref(false)

const environmentSubscriptionModules = ref([])

function userHasModule(moduleName: string) {
    return props.selectedUser.subscriptionModules.find((userEnvironmentSubscriptionModule: any) => {
        return userEnvironmentSubscriptionModule.environmentSubscriptionModule.subscriptionModule.name == moduleName
    }) != undefined
}

async function beforeShow() {
    isLoading.value = true
    const environmentSubscriptionModulesResponse = await userApi.getEnvironmentSubscriptionModules(authenticatedUser.environment.id)
    props.selectedUser = await userApi.getUser(props.selectedUser.id)
    console.log("selectedUser", props.selectedUser)

    environmentSubscriptionModules.value = environmentSubscriptionModulesResponse.results.filter((environmentSubscriptionModule: any) => {
        return environmentSubscriptionModule.subscriptionModule.name != 'Invité'
    }).sort((a: any, b: any) => {
        return a.subscriptionModule.name.localeCompare(b.subscriptionModule.name)
    })

    environmentSubscriptionModules.value.forEach((module: any) => {
        console.log(module)
        module.enabled = userHasModule(module.subscriptionModule.name)
    })

    isLoading.value = false
}

function updateUser() {
    isLoading.value = true
    const modules = environmentSubscriptionModules.value.map((environmentSubscriptionModule: any) => {
        return {
            environmentSubscriptionModuleId: environmentSubscriptionModule.id,
            enabled: environmentSubscriptionModule.enabled
        }
    })
    userApi.updateUserSubscriptionModules(props.selectedUser.id, {
        modules: modules
    })
        .then((result) => {
            isLoading.value = false
            emit('ok')
        })
}

function onConfirmClick() {
    updateUser()
}
</script>
<template>
    <q-dialog ref="dialogRef" @before-show="beforeShow">
        <BaseDialog title="Gérer les modules de l'utilisateur" :dialog-width="500" :is-dialog-loading="isLoading"
            @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
            <template #body>
                <q-list>
                    <q-item v-for="environmentSubscriptionModule in environmentSubscriptionModules">
                        <q-checkbox v-model="environmentSubscriptionModule.enabled"
                            :label="environmentSubscriptionModule.subscriptionModule.name"></q-checkbox>
                    </q-item>
                </q-list>
            </template>
            <template #actions>
                <q-btn flat label="Valider" v-close-popup @click="onConfirmClick" color="primary" />
            </template>
        </BaseDialog>
    </q-dialog>
</template>
