<script setup lang="ts">
import { ref, Ref, watch, inject, onMounted } from 'vue'
import { QTableProps, Dialog, Notify } from 'quasar'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { Client } from '@/models/core/Client'
import { AddEditMode } from '@/components/base/AddEditMode.enum'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseTable from '../common/Table/BaseTable.vue'
import ItemActionMenuItem from '../common/Table/ItemActionMenuItem.vue'
import ClientAddEditForm from './ClientAddEditForm.vue'
import ObjectUserPermissionsForm from '../common/dialog/ObjectUserPermissionsForm.vue'
import DeepRights from '../common/dialog/DeepRights.vue'
import { isMobile } from '@/utils/detectMobile'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'
import { toggleFavorite } from '@/utils/favoritesUtils'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const userApi: UserApi = useApi()
const router = useRouter()

const columns: QTableProps['columns'] = [
  {
    name: 'favorite',
    label: '',
    field: 'favorite',
    align: 'center',
    style: 'width: 20px; padding-left: 0px; padding-right: 0px;',
  },
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]

const rows: Ref<Array<Client>> = ref([])

const selectedItems: Ref<Array<Client>> = ref([])
const selectedRows = ref([])
const isLoading = ref(true)


onMounted(async () => {
  console.log('ClientsTable - mounted')
  await refreshClients()
  isLoading.value = false
})

const favorites = inject('favorites')
const slotedColumns = ref([
  {
    name: 'favorite',
  },
])

function setFavorites() {
  console.log('ClientsTable - setFavorites', rows.value, favorites.value)
  rows.value.forEach((row) => {
    row.favorite = favorites.value.clients.some((favorite) => {
      return favorite.objectId === row.id
    })
  })
}

watch(favorites.value, async () => {
  console.log('ClientsTable - favorites changed')
  setFavorites()
})

async function refreshClients() {
  const clients = await userApi.getClients()
  rows.value = clients.results
  setFavorites()
}

async function onToggleFavorite(event, row) {
  console.log('toggleFavorite', event, row)
  event.preventDefault()
  event.stopPropagation()
  toggleFavorite(userApi, favorites, 'client', row)
  row.favorite = !row.favorite
}

function onEditClick() {
  const dialog = Dialog.create({
    component: ClientAddEditForm,
    componentProps: {
      selectedRows: selectedRows.value[0],
      mode: AddEditMode.EDIT,
    },
  })
    .onOk(async () => {
      await refreshClients()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onAddClick() {
  const dialog = Dialog.create({
    component: ClientAddEditForm,
    componentProps: {
      selectedRows: selectedRows.value[0],
      mode: AddEditMode.ADD,
    },
  })
    .onOk(async () => {
      await refreshClients()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function onDeleteClick() {
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message: 'Voulez-vous vraiment supprimer le client ' + selectedRows.value[0].name + ' ?',
    },
  })
    .onOk(async () => {
      await userApi.deleteClient(selectedRows.value[0].id)
      selectedRows.value = []
      await refreshClients()

      dialog.hide()
      Notify.create({
        message: 'Client supprimé',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageRightsClick() {
  const dialog = Dialog.create({
    component: ObjectUserPermissionsForm,
    componentProps: {
      selectedRows: selectedRows.value,
      rights: ['view_client', 'change_client', 'delete_client'],
      environment: 'Client',
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onManageDeepRightsClick() {
  const dialog = Dialog.create({
    component: DeepRights,
    componentProps: {
      clientId: selectedRows.value[0].id,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function rowClicked(event: any, row: any): void {
  console.log(row)
  store.state.currentClient = row
  authenticatedUser.clientId = row.id
  router.push({
    name: 'clients/client/projects',
    params: { clientId: row.id },
  })
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable v-if="!isMobile()" add-button-label="Ajouter un client" selection="single" :columns="columns" :rows="rows"
    :selected-rows="selectedItems" :enable-add="true" @on-row-selected="onRowSelected" @add-item-click="onAddClick"
    @on-row-click="rowClicked" :is-loading="isLoading" :slotedColumns="slotedColumns">
    <!-- :enable-add="authenticatedUser.hasPermission('add_clients')" -->
    <template #action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
      <ItemActionMenuItem label="Gérer les droits" @click="onManageRightsClick" />
      <ItemActionMenuItem label="Gérer les droits fins" @click="onManageDeepRightsClick" />
    </template>
    <template v-slot:body-cell-favorite="props">
      <q-td :props="props.item">
        <q-btn v-if="props.item.row.favorite != true" round flat color="grey" icon="o_star_border" size="md"
          @click="onToggleFavorite($event, props.item.row)">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
        <q-btn v-if="props.item.row.favorite == true" round flat style="color: #e3a93b" icon="o_star" size="md"
          @click="onToggleFavorite($event, props.item.row)">
          <q-tooltip>Favori</q-tooltip>
        </q-btn>
      </q-td>
    </template>
  </BaseTable>

  <BaseTable v-if="isMobile()" grid flat dense :enableActions="false" :enableAdd="false"
    add-button-label="Ajouter un client" :columns="columns" :rows="rows" class="table-mobile">
    <template v-slot:item="item">
      <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4" @click="($event) => rowClicked($event, item.item.row)">
        {{ item.item.row.name }}
        <q-separator />
      </div>
    </template>
  </BaseTable>
</template>

<style lang="scss" scoped>
.table-mobile {
  margin-top: 16px;
}
</style>
