<script async setup lang="ts">
import { ref, computed, watch, nextTick, inject, provide } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { removeAccents } from '@/utils/stringUtils'
import DocumentSectionNode from './DocumentSectionNode.vue'

const store = useStore()
store.commit('setPageTitleComponent', 'Modèles secs visibles dans la marque blanche')

const userApi: UserApi = useApi()
const route = useRoute()

const environment = await userApi.getEnvironment(route.params.environmentPkid)
const subscriptionsResponse = await userApi.getSubscriptionModules()
const subscriptions = ref(subscriptionsResponse.results)
const environmentSubscriptionModulesResponse = await userApi.getEnvironmentSubscriptionModules(environment.id)
console.log("environmentSubscriptionModulesResponse", environmentSubscriptionModulesResponse)
const environmentSubscriptionModules = environmentSubscriptionModulesResponse.results

const unsavedChanges = ref(false)
provide("unsavedChanges", unsavedChanges)

const columns: QTableProps['columns'] = [
    {
        name: 'name',
        label: 'Module',
        field: 'name',
        sortable: true,
        align: 'left',
    },
    {
        name: 'value',
        label: 'Accessible ?',
        field: 'value',
        sortable: true,
        align: 'center',
    },
]

const rows = ref([])

function isActive(subscriptionModuleId: string) {
    let result = false
    environmentSubscriptionModules.forEach(environmentSubscriptionModule => {
        if (environmentSubscriptionModule.subscriptionModule.id == subscriptionModuleId) {
            result = true
        }
    })
    return result
}

function updateRows() {
    subscriptions.value.forEach(subscription => {
        const row = {
            id: subscription.id,
            name: subscription.name,
            value: isActive(subscription.id),
        }
        rows.value.push(row)
    })
}

updateRows()

// https://www.sanwebe.com/2013/02/confirmation-dialog-on-leaving-page-javascript#:~:text=If%20you%20want%20to%20show,leave%20or%20reload%20the%20page.
window.onbeforeunload = function (e) {
    console.log('onbeforeunload', unsavedChanges.value)
    return unsavedChanges.value ? true : undefined
}

onBeforeRouteLeave(async (to, from) => {
    if (unsavedChanges.value == false) {
        return true
    }

    const answer = window.confirm('Les modifications que vous avez apportées ne seront pas enregistrées si vous quittez cette page.')
    if (!answer) {
        return false
    }
})

async function save() {
    console.log("save")
    // subscription.value.permissionsJson = rows.value.filter(row => row.value).map(row => row.id)
    // await userApi.updateSubscriptionModule(subscription.value)
    unsavedChanges.value = false
}

function onPermissionChange() {
    console.log("onPermissionChange")
    unsavedChanges.value = true
}
</script>

<template>
    <Teleport to="#pageTitleComponent">
        <div class="row items-center no-wrap">
            <div>
                Modules d'abonnements visibles dans la marque blanche {{ environment.name }}
            </div>

            <div class="col-auto">
                <q-btn dense label="Enregistrer" color="primary" @click="saveCatalog"
                    style="margin-left: 8px; padding-left: 8px; padding-right: 8px;" :disable="!unsavedChanges"></q-btn>
            </div>
        </div>
    </Teleport>
    <q-page class="page">
        <h5>Liste des modules accessibles</h5>
        <q-table :rows="rows" :columns="columns" row-key="id">
            <template v-slot:body-cell-value="props">
                <q-td :props="props">
                    <div>
                        <q-checkbox v-model="props.row.value" @click="onPermissionChange" />
                    </div>
                </q-td>
            </template>
        </q-table>
    </q-page>
</template>

<style lang="scss" scoped>
.text-h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
    cursor: grab;
}

.search-input {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-top: 10px;
}

.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}

.node-label {
    margin-right: 8px;
}

.ul {
    list-style-type: disc;
}

:deep(.q-checkbox__inner) {
    color: rgb(158, 158, 158);

    margin-right: 5px;
    font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
    height: 0px;
    width: 0px;
    overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
    border: 1.3px solid currentColor;
}

.search-input {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.subtitle {
    font-size: 18px;
}

.category-header {
    background-color: #227a68;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // cursor: pointer;
}

.category-subheader {
    background-color: #2a5d97;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    // cursor: pointer;
}

.project-category {
    color: rgb(61, 61, 63);
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
}

.available-project {
    cursor: pointer;
    font-weight: 500;
}

.page-content-header {
    // margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

