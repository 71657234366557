<script setup lang="ts">
import { useStore } from '@/store/store'
import UsersTable from '@/components/common/administration/users/UsersTable.vue'
import GroupsTable from '@/components/common/administration/users/GroupsTable.vue'
const store = useStore()
store.commit('setPageTitle', 'Gestion des utilisateurs et des groupes')
</script>

<template>
  <q-page padding class="page">
    <h5>Utilisateurs</h5>
    <UsersTable />

    <h5>Groupes</h5>
    <GroupsTable />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}
</style>
