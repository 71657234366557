<script setup lang="ts">
import { ref } from 'vue'
//import { useStripe, StripeElement } from 'vue-use-stripe'

import Credits from '@/pages/common/administration/Credits.vue'

const event = ref(null)

// const {
//   stripe,
//   elements: [cardElement],
// } = useStripe({
//   key: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
//   elements: [{ type: 'card', options: {} }],
// })

const registerCard = function () {
  // stripe.value.createPaymentMethod({
  //     type: "card",
  //     card: {
  //       "number": "4242424242424242",
  //       "exp_month": 10,
  //       "exp_year": 2022,
  //       "cvc": "314",
  //     },
  // }).then((result) => {
  //   // Handle result.error or result.paymentMethod
  // })
  // if (event.value?.complete) {
  //   // Refer to the official docs to see all the Stripe instance properties.
  //   // E.g. https://stripe.com/docs/js/setup_intents/confirm_card_setup
  //   return stripe.value?.confirmCardSetup('<client-secret>', {
  //     payment_method: {
  //       card: cardElement.value,
  //     },
  //   })
  // }
}
</script>

<template>
  <Credits></Credits>
  <!-- <q-page padding class="page">
    <StripeElement :element="cardElement" @change="event = $event" />
    <button @click="registerCard">Add</button>
    <div v-if="event && event.error">{{ event.error.message }}</div>
  </q-page> -->
</template>
