<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits(['toggle-local-video', 'quit-room', 'open-settings', 'toggle-local-microphone'])

const localVideoEnabled = ref(true)
const localMicrophoneEnabled = ref(true)

function quitRoom() {
  emit('quit-room')
}

function openSettingsModal() {
  emit('open-settings')
}

function toggleLocalVideo() {
  localVideoEnabled.value = !localVideoEnabled.value

  emit('toggle-local-video', localVideoEnabled.value)
}

function toggleLocalMicrophone() {
  localMicrophoneEnabled.value = localMicrophoneEnabled.value

  emit('toggle-local-microphone', localMicrophoneEnabled.value)
}
</script>

<template>
  <footer class="bottom-bar">
    <div class="bar-container">
      <div class="room-controls">
        <div class="control">
          <button
            type="button"
            class="btn btn-control"
            :class="{ 'control-disabled': !localVideoEnabled }"
            @click="toggleLocalVideo()"
          >
            <i class="fas fa-video" v-if="localVideoEnabled"></i>
            <i class="fas fa-video-slash" v-else></i>
          </button>
          <div class="control-title">Caméra</div>
        </div>
        <div class="control">
          <q-btn
            type="button"
            class="btn btn-control"
            :class="{ 'control-disabled': !localMicrophoneEnabled }"
            @click="toggleLocalMicrophone()"
          >
            <i class="fas fa-microphone" v-if="localMicrophoneEnabled"></i>
            <i class="fas fa-microphone-slash" v-else></i>
          </q-btn>
          <div class="control-title">Micro</div>
        </div>
        <div class="control">
          <button type="button" class="btn btn-control" @click="quitRoom()">
            <i class="fas fa-phone-slash text-danger"></i>
          </button>
          <div class="control-title">Quitter</div>
        </div>
      </div>
    </div>
  </footer>
</template>
