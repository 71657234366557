import Main from './Main.vue'
import Users from './Users.vue'
import Billing from './Billing.vue'
import Roles from './Roles.vue'
import Credits from './Credits.vue'

const routes = [
  { name: 'administration/main', component: Main, path: '/administration' },
  {
    name: 'administration/users',
    component: Users,
    path: '/administration/users',
  },
  {
    name: 'administration/billing',
    component: Billing,
    path: '/administration/billing',
  },
  {
    name: 'administration/credits',
    component: Credits,
    path: '/administration/credits',
  },
  {
    name: 'administration/roles',
    component: Roles,
    path: '/administration/roles',
  },
]

export { routes }
