import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let entity: ObjectType = {
  name: 'Entité',
  fields: [
    {
      label: 'Nom',
      type: FieldType.String,
    },
    {
      label: 'Forme juridique',
      type: FieldType.String,
    },
    {
      label: 'Capital social',
      type: FieldType.String,
    },
    {
      label: 'Date de création',
      type: FieldType.Date,
    },
    {
      label: 'Adresse du siège social',
      type: FieldType.Object,
      objectTypeName: 'Adresse',
    },
    {
      label: "Numéro d'immatriculation",
      type: FieldType.String,
    },
    {
      label: "Ville d'immatriculation",
      type: FieldType.String,
    },
    {
      label: 'Numéro FINESS',
      type: FieldType.String,
    },
    {
      label: 'Numéro RNA',
      type: FieldType.String,
    },
    {
      label: 'Représentant légal',
      type: FieldType.ObjectLink,
      authorizedObjectTypes: ['Personne physique', 'Entité', 'SAS'],
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[0].value
  },
}

export { entity }
