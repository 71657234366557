<script setup lang="ts">
interface Props {
  label: string
  disable?: boolean
}
const props = defineProps<Props>()

const emit = defineEmits(['click'])

function onClick() {
  emit('click')
}
</script>

<template>
  <q-btn color="primary" :label="props.label" :disable="props.disable" @click="onClick" />
</template>
