<script setup lang="ts">
import { watch, ref } from 'vue'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const store = useStore()
store.commit('setPageTitle', 'Profil')

const authenticatedUser = useAuthenticatedUser()

const firstName = ref(authenticatedUser.firstName)
const lastName = ref(authenticatedUser.lastName)
const email = ref(authenticatedUser.email)

watch(store.state, (newStore, _oldStore) => {
  firstName.value = newStore.user.firstName
  lastName.value = newStore.user.lastName
  email.value = newStore.user.email
})

const saveProfile = function () {
  const user = authenticatedUser

  user.first_name = firstName.value
  user.last_name = lastName.value
  user.email = email.value

  store.commit('setUser', user)
  store.state.api.getUserApi().updateUser(user)
}
</script>

<template>
  <q-page padding>
    <div class="q-pa-md page-content q-col-gutter-md" style="max-width: 600px">
      <div class="row q-col-gutter-md">
        <q-input class="col-md-6" filled v-model="firstName" label="Prénom" />
        <q-input class="col-md-6" filled v-model="lastName" label="Nom" />
      </div>
      <div class="row q-col-gutter-md">
        <q-input class="col-md-12" filled v-model="email" label="Email" />
      </div>
      <div class="row">
        <q-btn class="col-md-3 offset-md-9" color="primary" @click="saveProfile()">Enregistrer</q-btn>
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.page-content {
  margin: 30px;
}
</style>
