<script setup lang="ts">
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Gestion des contenus')
</script>

<template>
  <q-page padding class="page">
    <h5 style="margin-top: 20px">Modèles de contenus</h5>
    <ul>
      <li>
        <router-link :to="{ name: 'contents/projects' }">Gestion du catalogue d'opérations juridiques de Come On
          Law</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'contents/document_templates' }">Gestion du catalogue de modèles utilitaires de Come On
          Law</router-link>
      </li>
    </ul>


  </q-page>
</template>
