<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'

interface Props {
  database: Database
  node: Node
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

function findObjectType(objectTypeName): ObjectType {
  let result = null
  props.database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })
  return result
}

function onFieldChange(e) {
  if (props.node?.type === NodeType.Object) {
    let objectType = findObjectType(props.node?.objectType)
    // objectType.onChange(props.node);
  }

  emit('update:field', props.node, props.field)
}

function updatingModel(e) {
  emit('update:field', props.node, props.field)
}
</script>

<template>
  <q-input v-model="field.value" :label="showLabel ? field.label : undefined" @change="onFieldChange"
    @update:model-value="updatingModel" debounce="1000" />
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
