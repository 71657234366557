import Main from './Main.vue'

const routes = [
  {
    name: 'aitreatment/main',
    component: Main,
    path: '/aitreatment/main',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
