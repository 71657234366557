<script setup lang="ts">
import { ref, computed } from 'vue'
import HeadingBlockView from './elements/content/block/HeadingBlockView.vue'
import ParagraphBlockView from './elements/content/block/ParagraphBlockView.vue'
import ConditionBlockView from './elements/control/block/ConditionBlockView.vue'

interface Props {
  element: Element
}

const props = withDefaults(defineProps<Props>(), {})

const isHovering = ref(true)

const cardClass = computed(() => {
  let classes = 'full-width'
  if (props.element.type === 'ConditionBlock') {
    classes += ' block top-left-corner-angle'
  } else {
    classes += ' block-content'
  }

  return classes
})
</script>

<template>
  <div class="generator-element-view">
    <HeadingBlockView v-if="element.type === 'HeadingBlock'" :data="element"> </HeadingBlockView>
    <ParagraphBlockView v-if="element.type === 'ParagraphBlock'" :data="element"> </ParagraphBlockView>
    <ConditionBlockView v-if="element.type === 'ConditionBlock'" :data="element"> </ConditionBlockView>
  </div>
</template>

<style lang="scss" scoped>
.generator-element-view {
}

.top-left-corner-angle {
}

.element-hover {
}

.badge-corner {
}

.block {
}

.block-content {
}
</style>
