<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Traitement IA')

const userApi: UserApi = useApi()
const classificationRestrictivePrompt = ref('')
const extractionCreativePrompt = ref('')
const extractionRestrictivePrompt = ref('')

const fetchParameterValue = async () => {
  try {
    const response = await fetch(userApi.api.BASE_URL + 'api/v1/global_settings');
    const globalSettings = await response.json(); // Read the raw response data
    console.log('globalSettings:', globalSettings); // Log the raw response data
    classificationRestrictivePrompt.value = globalSettings.classificationRestrictivePrompt;
    extractionCreativePrompt.value = globalSettings.extractionCreativePrompt;
    extractionRestrictivePrompt.value = globalSettings.extractionRestrictivePrompt;
  } catch (error) {
    console.error('Error fetching parameter value:', error);
  }
};

const saveChanges = async () => {
  try {
    const requestData = {
      "classificationRestrictivePrompt": classificationRestrictivePrompt.value,
      "extractionCreativePrompt": extractionCreativePrompt.value,
      "extractionRestrictivePrompt": extractionRestrictivePrompt.value,
    }
    const response = await fetch(userApi.api.BASE_URL + 'api/v1/global_settings', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });
    if (response.ok) {
      console.log('Changes saved successfully.');
    } else {
      throw new Error('Failed to save changes.');
    }
  } catch (error) {
    console.error('Error saving changes:', error);
  }
};

onMounted(fetchParameterValue)
</script>

<template>
  <q-page padding class="page">
    <h5 style="margin-top: 20px">Prompt <q-btn style="margin: 10px;" color="primary" label="Sauvegarder" @click="saveChanges"/></h5>

    <h6 style="margin: 20px">Prompt pour la classification</h6>
    <textarea v-model="classificationRestrictivePrompt" rows="12" cols="80"></textarea>
    <p style="margin: 10px"><q-icon name="info"/> Ce prompt "système" est suivi d'un prompt utilisateur spécifiant simplement le document et les classes (chemins) dans lesquels le ranger.</p>
    <br>

    <h6 style="margin: 20px">Prompt pour l'extraction libre</h6>
    <textarea v-model="extractionCreativePrompt" rows="12" cols="80"></textarea>
    <p style="margin: 10px"><q-icon name="info"/> Ce prompt "système" est suivi d'un prompt utilisateur spécifiant simplement le document à traiter.</p>
    <br><br>

    <h6 style="margin: 20px; color: #888;">Prompt pour l'extraction guidée (pour les dossiers permanents, pas encore utilisé)</h6>
    <p style="margin: 10px; color: #888;">{{extractionRestrictivePrompt}}</p>
    <!-- <textarea v-model="extractionRestrictivePrompt" rows="12" cols="80"></textarea> -->

  </q-page>
</template>
