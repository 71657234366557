<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import Text from './variableType/Text.vue'
import Number from './variableType/Number.vue'
import Date from './variableType/Date.vue'
import Wysiwyg from './variableType/Wysiwyg.vue'
import ObjectType from './variableType/ObjectType.vue'
import Signature from './variableType/Signature.vue'
import SignatureMultiple from './variableType/SignatureMultiple.vue'
import YesNo from './variableType/YesNo.vue'
import Choice from './variableType/Choice.vue'
import ChoiceMultiple from './variableType/ChoiceMultiple.vue'
import Table from './variableType/Table.vue'
import TableMultiple from './variableType/TableMultiple.vue'
import DependantVariable from './DependantVariable.vue'

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const questionPage = ref('')
const questionpageOptions = ['Option 1', 'Option 2']
const global = ref(false)
const name = ref('')
const question = ref('')
const text = ref('')
const variableType = ref('')
const typeOptions = [
  'Texte',
  'Nombre',
  'Date',
  'Objet',
  'Signature',
  'Signatures multiples',
  'Wysiwyg',
  'Oui / non',
  'Choix',
  'Choix multiples',
  'Tableau',
  'Tableau multi-objets',
]
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Détails de la variable"
      :dialog-width="1000"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <div class="row items-center">
          <BaseSelect
            clearable
            dense
            v-model="questionPage"
            :options="questionpageOptions"
            emit-value
            map-options
            label="Page de questionnaire associée (facultatif)"
            class="col-8"
          />
          <BaseCheckbox v-model="global" label="Variable globale au projet" class="col-4" />
        </div>
        <q-separator class="q-mb-md"></q-separator>
        <BaseInput dense v-model="name" label="Nom de la variable*" class="q-mb-md" />
        <BaseInput dense v-model="question" label="Question pour l'utilisateur*" class="q-mb-md" />
        <BaseInput dense v-model="text" label="Texte explicatif pour remplir la variable" class="q-mb-md" />

        <q-separator class="q-mb-md"></q-separator>

        <DependantVariable></DependantVariable>

        <q-separator class="q-mb-md q-mt-sm"></q-separator>
        <BaseSelect
          dense
          clearable
          class="q-mb-md"
          v-model="variableType"
          :options="typeOptions"
          emit-value
          map-options
          label="Type de la variable"
        />

        <Text v-if="variableType === 'Texte'" />
        <Number v-if="variableType === 'Nombre'" />
        <Date v-if="variableType === 'Date'" />
        <Wysiwyg v-if="variableType === 'Wysiwyg'" />
        <ObjectType v-if="variableType === 'Objet'" />
        <Signature v-if="variableType === 'Signature'" />
        <SignatureMultiple v-if="variableType === 'Signatures multiples'" />
        <YesNo v-if="variableType === 'Oui / non'" />
        <Choice v-if="variableType === 'Choix'" />
        <ChoiceMultiple v-if="variableType === 'Choix multiples'" />
        <Table v-if="variableType === 'Tableau'" />
        <TableMultiple v-if="variableType === 'Tableau multi-objets'" />
      </template>
      <template #actions>
        <QBtn label="Ajouter" color="primary" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
