<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  selectedFile: GeneratedDocument
}
const props = defineProps<Props>()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

async function deleteFile() {
  emit('ok')
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Supprimer le document" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body> Êtes-vous sûr de vouloir supprimer ce document signé ? </template>
      <template #actions>
        <q-btn flat label="Supprimer" @click="deleteFile()" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
