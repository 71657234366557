<script setup lang="ts">
import { ref, Ref, provide } from 'vue'
import { useStore } from '@/store/store'

import { Document } from '@/models/v2/document/Document'
import { UndoRedo } from '@/models/v2/document/elements/Action'
import { ParagraphBlock } from '@/models/v2/document/elements/content/block/ParagraphBlock'
import { AddParagraphBlock } from '@/models/v2/document/elements/Action'

import ElementsMenu from './components/DocumentEditor/ElementsMenu.vue'
import DocumentView from './components/DocumentEditor/DocumentView.vue'
import VariablesList from './components/DocumentEditor/VariablesList.vue'
import { useVariablesStore } from './stores/variables'

const store = useStore()
const splitterModel = ref(50)
store.commit('setPageTitle', 'PoC v2 - Editeur de document')

const documentJson = {
  id: 'Document_1',
  name: 'CDI',
  sections: [
    {
      id: 'Section_1',
      header: {
        id: 'Header_1',
        elements: [],
      },
      footer: {
        id: 'Footer_1',
        elements: [],
      },
      elements: [
        {
          id: 'Heading_1',
          type: 'HeadingBlock',
          content: 'Article 1',
          size: 'h1',
          elements: [],
        },

        {
          id: 'Paragraph_1',
          type: 'ParagraphBlock',
          content:
            "Paragraphe avec une variable : <span inline-element-id='Variable_1'></span>. Et du texte après la variable.<span id='variable_insert_point'></span> Ainsi qu'une seconde variable : <span inline-element-id='Variable_2'></span>.",
          elements: [
            {
              id: 'Variable_1',
              type: 'InlineVariable',
              variableId: 1,
              elements: [],
            },
            {
              id: 'Variable_2',
              type: 'InlineVariable',
              variableId: 2,
              elements: [],
            },
          ],
        },

        {
          id: 'Paragraph_2',
          type: 'TableBlock',
          content: [
            ['John', 'Doe', 'john.doe@gmail.com'],
            ['Jane', 'Doe', 'jane.doe@gmail.com'],
          ],
          elements: [],
        },

        {
          id: 'Condition_1',
          type: 'ConditionBlock',
          label: 'Si le salarié est à temps plein',
          conditions: [
            {
              variableId: '1',
              operator: 'equals',
              variableValue: 'Temps plein',
            },
          ],
          elements: [
            {
              id: 'Heading_2',
              type: 'HeadingBlock',
              content: 'Titre 2',
              size: 'h2',
              elements: [],
            },
            {
              id: 'Paragraph_2',
              type: 'ParagraphBlock',
              content: 'Paragraphe 2',
              elements: [],
            },
          ],
        },

        {
          id: 'Heading_3',
          type: 'HeadingBlock',
          content: 'Organisation du travail',
          size: 'h3',
          elements: [],
        },

        {
          id: 'Alternative_1',
          type: 'AlternativeBlock',
          alternative: {
            label: "Selon choix d'organisation du travail",
            variableId: '1',
            operator: 'equals',
            variableValue: 'Temps plein',
          },
          elements: [
            {
              id: 'Alternative_1_Choice_1',
              type: 'AlternativeBlockChoice',
              label: 'Cas temps plein',
              elements: [
                {
                  id: 'Paragraph_3',
                  type: 'ParagraphBlock',
                  content: 'Paragraphe 3',
                  elements: [],
                },
              ],
            },
            {
              id: 'Alternative_1_Choice_2',
              type: 'AlternativeBlockChoice',
              label: 'Cas temps partiel',
              elements: [
                {
                  id: 'Paragraph_4',
                  type: 'ParagraphBlock',
                  content: 'Paragraphe 4',
                  elements: [],
                },
              ],
            },
          ],
        },

        {
          id: 'Loop_1',
          type: 'LoopBlock',
          label: 'Liste des actionnaires',
          variableId: 3,
          elements: [
            {
              id: 'Condition_2',
              type: 'ConditionBlock',
              label: 'Si le salarié est à temps plein',
              conditions: [
                {
                  variableId: '8',
                  operator: 'equals',
                  variableValue: 'Temps plein',
                },
              ],
              elements: [
                {
                  id: 'Paragraph_4',
                  type: 'ParagraphBlock',
                  content: 'Texte du cas personne physique',
                  elements: [],
                },
              ],
            },
            {
              id: 'Condition_3',
              type: 'ConditionBlock',
              label: 'Personne morale',
              conditions: [
                {
                  variableId: '1',
                  operator: 'equals',
                  variableValue: 'Temps plein',
                },
              ],
              elements: [
                {
                  id: 'Paragraph_5',
                  type: 'ParagraphBlock',
                  content: 'Texte du cas personne morale',
                  elements: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

const headings = ref([])
function findHeading(section) {
  section.elements.forEach((element) => {
    if (element.type === 'HeadingBlock') {
      return headings.value.push(element)
    }
    return findHeading(element)
  })
}

documentJson.sections.forEach(findHeading)

const showTitle = ref(false)

const document: Ref<Document> = ref(new Document())
document.value.deserialize(documentJson)

const undoRedo: UndoRedo = new UndoRedo(document.value)
provide('undoRedo', undoRedo)

const variablesStore = useVariablesStore()

const variables = ref(variablesStore.variables)

provide('document', document)
provide('variables', variables)

function toggleTitle() {
  showTitle.value = !showTitle.value
}

function addTestBlock() {
  const paragraph = new ParagraphBlock()
  paragraph.deserialize({
    id: 'Paragraph_X',
    type: 'ParagraphBlock',
    content: 'Paragraphe ajouté',
    elements: [],
  })

  const addParagraphBlock = new AddParagraphBlock(paragraph, [])
  undoRedo.do(addParagraphBlock)
}

function undo() {
  undoRedo.undo()
}
function redo() {
  undoRedo.redo()
}
</script>

<template>
  <q-page padding class="page document-editor-page">
    <div class="q-pa-sm">
      <q-btn label="T" outline round dense @click="toggleTitle">
        <q-tooltip v-if="!showTitle">Afficher les titres</q-tooltip>
        <q-tooltip v-if="showTitle">Cacher les titres</q-tooltip>
      </q-btn>
      <q-btn @click="undo" icon="undo" outline round dense class="q-ml-sm"></q-btn>
      <q-btn @click="redo" icon="redo" outline round dense class="q-ml-sm"></q-btn>
    </div>
    <q-splitter horizontal></q-splitter>
    <div class="row q-gutter-md">
      <ElementsMenu :document="document" :headings="headings" :showTitle="showTitle"></ElementsMenu>
      <q-splitter vertical style="margin-left: 0px"></q-splitter>
      <DocumentView :document="document"></DocumentView>
      <q-splitter vertical style="margin-left: 0px"></q-splitter>
      <VariablesList></VariablesList>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.document-editor-page {
  padding-left: 20px;
}
</style>
