<script async lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { store } from '@/store/store'

import { Database } from '@/models/database/Database'

interface Props {
  database: Database
  node: Node
  // field: Field;
}

const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()

const loading = ref(true)
const gdprProject = ref()
console.log('store', store.state)
const options = ref([])

console.log('node', props.node)

if (props.node.fields.length == 0) {
  props.node.fields.push({
    name: 'project',
    value: undefined,
  })
}

async function load() {
  console.log('loading')
  loading.value = true
  const clientProjects = await userApi.getClientProjects(1, undefined, 1000, store.state.currentClient.id)
  console.log(clientProjects)

  options.value = clientProjects.results.map((project) => {
    return {
      label: project.name,
      value: project.id,
    }
  })

  console.log(options)
  const testProject = options.value.find((option) => option.value == props.node.fields[0].value) //'2a97c65d-0582-425c-bb54-f3c596a7a5e7');
  console.log(testProject)
  gdprProject.value = testProject

  loading.value = false
}

onMounted(async () => {
  load()
})

watch(
  () => props.node,
  () => {
    load()
  },
)

function onChange() {
  console.log('onChange', gdprProject.value)
  props.node.fields[0].value = gdprProject.value.value
  console.log('props.node.fields[0].value', props.node)
  saveDatabase(props.database, undefined)
}

async function saveDatabase(database, clientId) {
  let saveResult = await userApi.updateDatabase(database, clientId)
}
</script>

<template>
  <!-- <suspense> -->
  <q-card flat bordered>
    <q-card-section>
      <div class="text-h6">Registres RGPD</div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <q-select
        v-model="gdprProject"
        :options="options"
        label="Dossier RGPD du client"
        :disable="loading"
        :loading="loading"
        @update:model-value="onChange"
      />
    </q-card-section>
  </q-card>

  <!-- <template #fallback>
            loading...
        </template>
    </suspense> -->
</template>

<style lang="scss" scoped></style>
