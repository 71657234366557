import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let subcontractorRegister: ObjectType = {
  name: 'Registre des sous-traitants',
  fields: [
    {
      type: FieldType.String,
      label: 'Nom',
    },
    {
      type: FieldType.String,
      label: 'Lien',
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[0].value
  },
}

export { subcontractorRegister }
