<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  message: string
  // isExecutingAction?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  // isExecutingAction: false,
  message: 'Confirmez-vous la réalisation de cette action ?',
})

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const isExecutingAction = ref(false)

async function confirmAction() {
  isExecutingAction.value = true
  emit('ok')
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Confirmation"
      :is-dialog-loading="isExecutingAction"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        {{ props.message }}
      </template>
      <template #actions>
        <q-btn flat label="OK" @click="confirmAction()" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
