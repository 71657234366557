<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

function updatingModel() {
  emit('update:field', props.field)
}

function onFieldChange() {
  emit('update:field', props.field)
}
</script>

<template>
  <q-input v-model="field.value" :label="showLabel ? field.name : undefined" @change="onFieldChange"
    @update:model-value="updatingModel" debounce="1000" />
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
