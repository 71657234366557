<script setup lang="ts">
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import { ref, Ref, inject, watch } from 'vue'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'

interface Props {
  task: Task | undefined
  borderless?: boolean
  isDisable?: boolean
  isReadonly?: boolean
  displayInitials?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  borderless: false,
  isDisable: false,
  isReadonly: false,
  displayInitials: false,
})

const emit = defineEmits(['updateEvents'])

const userApi = useApi()

const model: Ref<String | undefined> = ref('')
model.value = props.task?.assignee !== null ? props.task?.assignee.initials : '-'

const project = inject(ProjectKey)
const accountableOptions = inject(ProjectAccountableOptionsKey)

watch(
  () => accountableOptions.value,
  () => {
    updateUsers()
  },
)

const isLoading = ref(false)
const options: Ref<Array<UserAccountableOptions>> = ref([{ value: null, label: 'Non assignée' }])

function updateUsers() {
  if (accountableOptions) {
    options.value = []
    accountableOptions.value.map((option) => {
      options.value.push({ value: option, label: option.fullName })
    })
  }
}

updateUsers()

async function onChange(event) {
  console.log('onChange', event)
  // updateDisplayValue();
  try {
    isLoading.value = true
    await userApi.putProjectTask(project?.value.id, props.task?.id, {
      assigneeId: model.value ? model.value.id : null,
    })
    props.task.assignee = model.value

    // updateDisplayValue();

    const new_event = await userApi.createProjectEvent(project?.value.id, {
      type: 2,
      user_project_id: project?.value.id,
      legacy_task_id: props.task?.id,
    })
    emit('updateEvents', new_event)

    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}

function updateDisplayValue() {
  if (props.displayInitials) {
    if (props.task?.assignee === null) {
      model.value = ' -'
    } else {
      model.value = props.task?.assignee.initials
    }
  } else {
    if (props.task?.assignee === null) {
      model.value = '-'
    } else {
      model.value = props.task?.assignee.fullName
    }
  }
}

watch(props, () => {
  updateDisplayValue()
})

// function displayValue(): string {
//   if (props.displayInitials) {
//     if (props.task?.assignee === null) {
//       return " -";
//     } else {
//       return props.task?.assignee.initials;
//     }
//   } else {
//     if (props.task?.assignee === null) {
//       return "-";
//     } else {
//       return props.task?.assignee.fullName;
//     }
//   }
// }
</script>
<template>
  <q-chip outline color="grey-5" style="margin-left: 0px; padding-right: 0px">
    <q-select dense options-dense emit-value input-style="" map-options v-model="model" class="assignee-select"
      v-bind="$attrs" :readonly="isReadonly" :loading="isLoading" :disable="isLoading || isDisable"
      :rounded="!borderless" :outlined="!borderless" :borderless="borderless" :options="options"
      @update:model-value="onChange" />
    <q-tooltip v-if="displayInitials" class="tooltip">{{
      props.task?.assignee ? task?.assignee.fullName : 'Pas de responsable assigné'
    }}</q-tooltip>
  </q-chip>
</template>

<style lang="scss" scoped>
.assignee-select {
  // min-width: 20px;
  padding: 0em 0em;
  font-size: 13px;
}
</style>
