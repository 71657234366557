<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, QTableProps, Notify } from 'quasar'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import * as data from './group_permissions_tree.json'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  clientId: Ref<string | undefined>
  user: any
}
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const authenticatedUser: AuthenticatedUser = useAuthenticatedUser()

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Utilisateur',
    field: 'email',
    align: 'center',
    headerStyle: 'width:25%',
  },

  {
    name: 'clients',
    label: 'Tous les clients',
    field: 'clients',
    align: 'center',
  },
]

const rows: Ref<Array<any>> = ref([])

function beforeShow() {
  updateRows()
}

async function updateRows() {
  if (props.user == undefined) {
    return
  }

  const userGlobalPermissionsResponse = await userApi.getObjectPermissions(
    authenticatedUser.environment.id,
    'Environment',
    props.user.id,
  )
  const userGlobalPermissions = userGlobalPermissionsResponse.permissions

  rows.value = [
    {
      id: '1',
      email: props.user?.email,
      permissions: {
        // environment_users: {
        //   view: true,
        //   change: false,
        //   delete: false,
        //   add: true,
        // },
        // environment_groups: {
        //   view: true,
        //   change: false,
        //   delete: false,
        //   add: true,
        // },
        // payment: {
        //   view: true,
        //   change: false,
        //   delete: false,
        //   add: true,
        // },
        // subscription: {
        //   view: true,
        //   change: false,
        //   delete: false,
        //   add: true,
        // },
        // credits: {
        //   view: true,
        //   change: false,
        //   delete: false,
        //   add: true,
        // },
        clients: {
          view_clients: userGlobalPermissions.includes('view_clients'),
          change_clients: userGlobalPermissions.includes('change_clients'),
          delete_clients: userGlobalPermissions.includes('delete_clients'),
          add_clients: userGlobalPermissions.includes('add_clients'),
        },
      },
    },
  ]
}

async function updatePermissions() {
  try {
    isLoading.value = true
    const addPermissions = []
    const removePermissions = []

    for (const permission in rows.value[0].permissions.clients) {
      if (rows.value[0].permissions.clients[permission]) {
        addPermissions.push(permission)
      } else {
        removePermissions.push(permission)
      }
    }

    await userApi.updateObjectPermissions(
      authenticatedUser.environment.id,
      'Environment',
      [props.user.id],
      addPermissions,
      removePermissions,
    )

    isLoading.value = false
    Notify.create({
      message: `Modification des droits effectuée avec succès`,
      type: 'primary',
    })
    emit('ok')
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: `Une erreur est survenue`,
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow" full-width>
    <BaseDialog
      :title="`Permissions de l'utilisateur : ${user?.email}`"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <q-table
          :rows="rows"
          :columns="columns"
          row-key="id"
          separator="cell"
          class="sticky-header-table sticky-header-column-table"
          hide-pagination
          hide-bottom
          flat
          bordered
        >
          <template v-slot:header-cell-clients="props">
            <q-th :props="props" class="cell">
              <div class="column">
                <div class="col-12 q-pa-sm">{{ props.col.label }}</div>
                <q-separator />
                <div class="row justify-evenly col-12">
                  <span class="q-pa-sm">Voir</span>
                  <q-separator vertical />
                  <span class="q-pa-sm">Editer</span>
                  <q-separator vertical />
                  <span class="q-pa-sm">Supprimer</span>
                  <q-separator vertical />
                  <span class="q-pa-sm">Ajouter</span>
                </div>
              </div>
            </q-th>
          </template>

          <template #body="props">
            <q-tr :props="props" no-hover>
              <q-td key="name" :props="props">{{ props.row.email }}</q-td>
              <q-td key="clients" :props="props" class="cell">
                <div class="row justify-around">
                  <q-checkbox v-model="props.row.permissions.clients.view_clients" color="primary" />
                  <q-checkbox v-model="props.row.permissions.clients.change_clients" color="primary" />
                  <q-checkbox v-model="props.row.permissions.clients.delete_clients" color="primary" />
                  <q-checkbox v-model="props.row.permissions.clients.add_clients" color="primary" />
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </template>
      <template #actions>
        <q-btn label="Enregistrer" color="primary" flat v-close-popup @click="updatePermissions" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.cell {
  padding: 0px !important;
}

.tree {
  overflow: scroll;
}

.sticky-header-table {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #e4e4e4;
  }
}

.sticky-header-column-table {
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%;

  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: #e4e4e4 !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
