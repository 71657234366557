<script setup lang="ts">
import { ref } from 'vue'

interface Props {
  label: string
  disable?: boolean
}
const props = defineProps<Props>()
const label = ref(props.label)
const disable = ref(props.disable)

function onClick() {}
</script>

<template>
  <q-btn color="primary" flat :label="label" :disable="disable" @click="onClick" />
</template>
