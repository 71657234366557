<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject, watch } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import LogItem from '../log/LogItem.vue'
import EventItem from './EventItem.vue'
import Editor from '@tinymce/tinymce-vue'
import { Notify } from 'quasar'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import { lastEventId } from '@sentry/browser'

interface Props {
  task: Task | undefined
  isDisable?: boolean
  isReadonly?: boolean
  updateNb: any
}

const props = withDefaults(defineProps<Props>(), {
  isDisable: false,
  isReadonly: false,
})

console.log('init notif panel')

const route = useRoute()
const userApi: UserApi = useApi()

const timer = inject('timer')

const isLoading = ref(false)

const project = inject(ProjectKey)

const authenticatedUser = useAuthenticatedUser()
const accountableOptions = inject(ProjectAccountableOptionsKey)
const users: Ref<Array<any>> = ref([])

const events = ref([])

const newEvents = ref([])

async function onChange(event) {
  try {
    isLoading.value = true

    if (event !== undefined && event !== true) {
      event.send = true
      newEvents.value.push(event)
    }

    // const eventsResponse = await userApi.getProjectEvents(
    //   project?.value.id,
    //   props.task?.id
    // );
    // events.value = eventsResponse.results;

    // if (event && events.value.length > 0) {
    //   const newEvent = events.value[0];
    //   if (newEvent.type == 1) {
    //     newChatMessage.value =
    //       newChatMessage.value + "<p>- Etat de tâche modifié</p>";
    //   }
    //   if (newEvent.type == 2) {
    //     newChatMessage.value =
    //       newChatMessage.value + "<p>- Responsable de tâche modifié</p>";
    //   }
    //   if (newEvent.type == 3) {
    //     newChatMessage.value =
    //       newChatMessage.value + "<p>- Echéance de tâche modifiée</p>";
    //   }
    // }

    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}

const newChatMessage = ref('')
const lastEvent = inject('lastEvent')

// watch(
//   () => props.updateNb,
//   () => {
//     onChange(lastEvent.value);
//   }
// );

watch(
  () => lastEvent.value,
  () => {
    onChange(lastEvent.value)
  },
)

watch(
  () => timer.value,
  () => {
    if (timer.value === 0) {
      sendMessage(false)
    }
  },
)

onChange(undefined)

watch(
  () => accountableOptions.value,
  () => {
    updateUsers()
  },
)




async function updateUsers() {
  console.log("SendBoxPanel - updateUsers")
  users.value = await Promise.all(
    accountableOptions?.value.map(async (user) => {
      const notificationsEnabled = await userApi.getProjectNotification(project?.value.id, user.id)

      const userData = {
        id: user.id,
        fullName: user.fullName,
        notificationsEnabled,
        send: true,
      }

      if (authenticatedUser.id === user.id) {
        userData.send = false
      }

      return userData
    }),
  )

  // Set authenticated user on top of the list
  users.value = users.value.sort((a, b) => {
    if (a.id === authenticatedUser.id) {
      return -1
    }
    if (b.id === authenticatedUser.id) {
      return 1
    }
    return 0
  })
}

await updateUsers()

async function sendMessage(notify) {
  // await userApi.createProjectEvent(project?.value.id, {
  //   type: 5,
  //   legacy_task_id: props.task?.id,
  //   chat_message_content: newChatMessage.value,
  // });

  const recipients = []
  users.value.forEach((user) => {
    if (user.send) {
      recipients.push(user.id)
    }
  })

  let eventsContent = '<ul>'

  newEvents.value.forEach((event) => {
    if (event.send === false) {
      return
    }

    if (event.type == 1) {
      eventsContent = eventsContent + '<li>Etat de tâche modifié</li>'
    }
    if (event.type == 2) {
      eventsContent = eventsContent + '<li>Responsable de tâche modifié</li>'
    }
    if (event.type == 3) {
      eventsContent = eventsContent + '<li>Echéance de tâche modifiée</li>'
    }
  })

  eventsContent = eventsContent + '</ul>'

  await userApi.sendNotificationEmail(
    project?.value.id,
    props.task?.id,
    recipients,
    newChatMessage.value,
    eventsContent,
  )

  newEvents.value = []

  if (notify) {
    Notify.create({
      message: `Message envoyé`,
      type: 'primary',
    })
  }

  newChatMessage.value = ''

  onChange(undefined)
}

async function destroyMessage() {
  // await userApi.createProjectEvent(project?.value.id, {
  //   "type": 5,
  //   "legacy_task_id": props.task?.id,
  //   "chat_message_content": newChatMessage.value
  // });

  timer.value = -1

  newEvents.value = []

  newChatMessage.value = ''

  Notify.create({
    message: `Message détruit`,
    type: 'primary',
  })

  onChange(undefined)
}

function selectAllRecipients() {
  users.value.forEach((user) => {
    user.send = true
  })
}

function deselectAllRecipients() {
  users.value.forEach((user) => {
    user.send = false
  })
}
</script>

<template>
  <div class="" style="">
    <div>Destinataires :</div>
    <div class="row">
      <div class="col">
        <q-list dense style="margin-top: 0px; margin-bottom: 10px">
          <q-item dense clickable v-ripple v-for="recipient in users">
            <q-item-section dense>
              <q-checkbox dense :label="recipient.fullName" v-model="recipient.send"></q-checkbox>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="col">
        <!-- <q-btn dense label="Sélectionner tout" style="width: 150px;" @click="selectAllRecipients"></q-btn><br />
        <q-btn dense label="Désélectionner tout" style="width: 150px; margin-top: 10px; margin-bottom: 10px;"
          @click="deselectAllRecipients"></q-btn> -->
        <a href="#" class="button-link" @click="selectAllRecipients">Sélectionner tout</a>
        <br />
        <a href="#" class="button-link" @click="deselectAllRecipients">Désélectionner tout</a>
        <!-- <br />
        <a href="#" class="button-link" @click="addUser">Ajouter un utilisateur</a> -->
      </div>
    </div>

    <div style="margin-bottom: 10px">Evenements à notifier :</div>
    <div v-if="newEvents.length === 0" style="margin-bottom: 10px">
      &nbsp;&nbsp;&nbsp&nbsp;Aucun nouvel événement à notifier
    </div>
    <q-list v-if="newEvents.length !== 0" bordered separator style="margin-top: 5px; margin-bottom: 20px">
      <EventItem v-for="event in newEvents" :event="event" />
    </q-list>

    <div style="margin-bottom: 5px">Message complémentaire :</div>
    <editor api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44" :init="{
            height: 250,
            menubar: false,

            statusbar: true,
            elementpath: false,
            branding: false,
            resize: true,

            plugins: 'lists advlist link searchreplace',
            toolbar: 'undo redo | bold italic underline | align | bullist numlist | removeformat',
          }" v-model="newChatMessage" />
    <div style="margin-top: 8px">
      <q-btn dense rounded color="primary" style="
          height: 24px;
          min-height: 24px;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 0px;
          margin-right: 8px;
        " label="Envoyer" @click="sendMessage(true)"></q-btn>
      <q-btn dense rounded color="warning" style="
          height: 24px;
          min-height: 24px;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 0px;
          margin-right: 8px;
        " label="Détruire" @click="destroyMessage()"></q-btn>
    </div>

    <!-- <q-list bordered separator style="margin-top: 25px">
          <LogItem v-for="event in events" :event="event" />
        </q-list> -->
  </div>
</template>

<style lang="scss" scoped></style>
