<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { Dialog } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import SelectSubProjectsTreeComponent from '@/pages/common/projects/SelectSubProjectsTreeComponent.vue'

interface Props {
  onlyComposables?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  onlyComposables: false,
})


const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

function createSubProjects(subProjectTemplateIds) {
  emit('ok', subProjectTemplateIds)
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Ajouter des opérations juridiques" :showCancelButton="false" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <slot>
          <Suspense>
            <SelectSubProjectsTreeComponent mode="addSubProjects" @addSubProjects="createSubProjects"
              :onlyComposables="onlyComposables">
            </SelectSubProjectsTreeComponent>
            <template #fallback>
              <q-inner-loading showing color="primary" />
            </template>
          </Suspense>
        </slot>
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
