<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { ref, watch } from 'vue'

interface Props {
  database: Database
  environmentDatabase: Array<Database>
  node: Node
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

const selectModel = ref(null)
let options = []

let optionsRef = ref(options)

updateOptions()

function findOption(value) {
  let result = undefined
  options.forEach((option) => {
    if (option.value === value || option.value === value?.value) {
      result = option
    }
  })
  return result
}

function updateOptions() {
  selectModel.value = props.field.value
  let optionNodes = []

  options = []

  props.field.fieldModel.authorizedObjectTypes.forEach((authorizedType) => {
    // optionNodes = optionNodes.concat(getAllNodesByType(props.database, authorizedType));

    // props.environmentDatabase.forEach((clientDatabase) => {
    //   if (clientDatabase.clientId != props.database.clientId && clientDatabase.databaseJson != null) {
    //     let newOptionNodes = getAllNodesByType(clientDatabase.databaseJson, authorizedType)

    //     let newOptions = newOptionNodes.map((optionNode) => {
    //       return {
    //         label: optionNode.label + ' (client ' + clientDatabase.clientName + ')',
    //         value: optionNode.id,
    //       }
    //     })

    //     options = options.concat(newOptions)
    //   }
    // })
    console.log('props.database', props.database)
    let newOptionNodes = getAllNodesByType(props.database, authorizedType)

    let newOptions = newOptionNodes.map((optionNode) => {
      return {
        label: optionNode.label, //  + ' (client ' + props.database.clientName + ')',
        value: optionNode.id,
      }
    })

    options = options.concat(newOptions)
  })

  optionsRef.value = options

  selectModel.value = findOption(props.field.value)
}

function getAllNodesByType(database, nodeType) {
  let result = []
  database.nodes.forEach((node) => {
    result = result.concat(getAllNodesByTypeForNode(nodeType, node))
  })
  return result
}

function getAllNodesByTypeForNode(nodeType, node) {
  let result = []
  if (node.objectType === nodeType) {
    result.push(node)
  }
  node.children.forEach((child) => {
    result = result.concat(getAllNodesByTypeForNode(nodeType, child))
  })
  return result
}

watch(selectModel, () => {
  props.field.value = selectModel.value.value
  emit('update:field', props.node, props.field)
})

watch(props, () => {
  updateOptions()
})

function onFieldChange(e) {
  emit('update:field', props.node, props.field)
}

function filterFn(val, update) {
  if (val === '') {
    update(() => {
      updateOptions()
      // options = stringOptions

      // here you have access to "ref" which
      // is the Vue reference of the QSelect
    })
    return
  }

  // update(() => {
  //     const needle = val.toLowerCase()
  //     options = options.filter(v => v.toLowerCase().indexOf(needle) > -1)
  // })
  update(() => {
    updateOptions()
    const needle = val.toLowerCase()
    options = options.filter((v) => v.label.toLowerCase().indexOf(needle) > -1)
    optionsRef.value = options
  })
}

function onPopupShow() {
  updateOptions()
}
</script>

<template>
  <q-select outlined v-model="selectModel" :options="optionsRef" :label="showLabel ? field.label : undefined"
    class="select" @update:model-value="onFieldChange" @filter="filterFn" @popup-show="onPopupShow" use-input
    input-debounce="0" hide-selected fill-input>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">Aucun élément</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<style lang="scss" scoped>
.select {
  margin-top: 10px;
}
</style>
