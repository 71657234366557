<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { QTableProps, Dialog, useDialogPluginComponent } from 'quasar'
import { useAgoDatabase } from '@/store/useDatabase'
import { Node } from '@/models/database/Node'
import { Database } from '@/models/database/Database'
import { NodeType } from '@/models/database/NodeType'
import { Question } from '@/models/survey/Question'
import { ObjectType } from '@/models/database/ObjectType'
import { FieldType } from '@/models/database/FieldType'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ObjectChildSelectionDialog from '@/components/common/database/ObjectChildSelectionDialog.vue'
import { CPAMs } from './CPAM.ts'
import { sas } from '@/models/database/node_types/SAS'
import { entity } from '@/models/database/node_types/Entity'
import { physicalPerson } from '@/models/database/node_types/PhysicalPerson'

interface Props {
  question: Question
  databaseForInsert: Ref<Database>
}
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

let demoDatabase = useAgoDatabase()
const userApi: UserApi = useApi()

const databaseChildObject: Ref<Array<any>> = inject('databaseChildObject', ref([]))

// const databaseForInsert: Ref<Database> = inject("databaseForInsert");
console.log('databaseForInsert', props.databaseForInsert)

let databaseJson
userApi.getDatabase().then((result) => {
  databaseJson = result
  if (databaseJson === null) {
    databaseJson = JSON.parse(JSON.stringify(demoDatabase))
  }

  parseDatabase(databaseJson)
})
// let databaseJson = null;

const selectedNode = ref(undefined)
const filter = ref('')
const isChildsMapping = ref(false)
const rowSelected = ref()
const childsDataToDisplay: Ref<Array<any>> = ref([])
const selected: Ref<Array<Node>> = ref([])

const columns: QTableProps['columns'] = [
  {
    name: 'label',
    label: 'Objets',
    field: 'label',
    align: 'left',
  },
  {
    name: 'objectType',
    label: 'Types',
    field: 'objectType',
    align: 'left',
  },
  {
    name: 'informations',
    label: 'Informations',
    field: 'informations',
    align: 'right',
  },
]

const childsColumns: QTableProps['columns'] = []
const rows: Ref<Array<Node>> = ref([])

function beforeShow() {
  userApi.getDatabase().then((result) => {
    databaseJson = result
    if (databaseJson === null) {
      databaseJson = JSON.parse(JSON.stringify(demoDatabase))
    }
    parseDatabase(databaseJson)
    if (props.question.databaseObjectModelFieldsMapping) {
      props.question.databaseObjectModelFieldsMapping.forEach((databaseObjectModelField) => {
        if (
          props.question.id == 116168 ||
          props.question.originalId == 116168 ||

          props.question.id == 116170 ||
          props.question.originalId == 116170 ||

          props.question.id == 116172 ||
          props.question.originalId == 116172 ||

          props.question.id == 116174 ||
          props.question.originalId == 116174 ||


          props.question.id == 116169 ||
          props.question.originalId == 116169 ||

          props.question.id == 116171 ||
          props.question.originalId == 116171 ||

          props.question.id == 116173 ||
          props.question.originalId == 116173 ||

          props.question.id == 116175 ||
          props.question.originalId == 116175
        ) {
          childsColumns.push({
            name: "Date de début d'exercice",
            label: "Date de début d'exercice",
            field: "Date de début d'exercice",
            align: 'left',
          })
          childsColumns.push({
            name: "Date de fin d'exercice",
            label: "Date de fin d'exercice",
            field: "Date de fin d'exercice",
            align: 'left',
          })
        }

        console.log('databaseObjectModelField', databaseObjectModelField)
        databaseObjectModelField?.data?.forEach((d) => {
          if (
            d.databaseObjectModelFieldName?.includes('Adresse') ||
            d.databaseObjectModelFieldName?.includes('Représentant légal')
          ) {
            return
          }
          // Cas avec enfants
          if (d.databaseObjectModelFieldName?.includes('/')) {
            console.log("isChildMapping true", d.databaseObjectModelFieldName)
            isChildsMapping.value = true
            childsDataToDisplay.value.push(d.databaseObjectModelFieldName)
            const fields = d.databaseObjectModelFieldName.split('/')
            childsColumns.push({
              name: fields.at(-1),
              label: d.variableFieldName,
              field: fields.at(-1),
              align: 'left',
            })
          }
        })
      })
    }

    if (props.question.databaseObjectModelName === 'CPAM') {
      addCPAMs()
    }

    databaseJson.nodes.forEach((node) => {
      if (props.question.databaseObjectModelName === 'Personne physique') {
        addSelectableObjects(node, 'Personne physique')
      }

      if (props.question.databaseObjectModelName === 'SAS') {
        addSelectableObjects(node, 'SAS')
      }

      if (props.question.databaseObjectModelName === 'Entité') {
        addSelectableObjects(node, 'Entité')
      }

      if (props.question.databaseObjectModelName === 'Personne morale') {
        addSelectableObjects(node, 'SAS')
        addSelectableObjects(node, 'Entité')
      }

      if (
        props.question.databaseObjectModelName === 'Personne phyique | Personne morale' ||
        props.question.databaseObjectModelName === null
      ) {
        addSelectableObjects(node, 'Personne physique')
        addSelectableObjects(node, 'SAS')
        addSelectableObjects(node, 'Entité')
      }
      if (props.question.databaseObjectModelName === 'SAS/Personne physique') {
        addSelectableObjects(node, 'Personne physique')
        addSelectableObjects(node, 'SAS')
      }

      if (props.question.type === 'multiarray') {
        addSelectableObjects(node, 'Personne physique')
        addSelectableObjects(node, 'SAS')
        addSelectableObjects(node, 'Entité')
      }
    })
  })
}

function addCPAMs() {
  CPAMs.forEach((CPAM) => rows.value.push(CPAM))
}

function addSelectableObjects(node: Node, objectType: string) {
  if (node.type === NodeType.Object && node.objectType === objectType) {
    rows.value.push(node)
  }
  node.children?.forEach((childNode) => {
    addSelectableObjects(childNode, objectType)
  })
}

function onRowClicked(row) {
  emit('ok', row)
}

function onAddButtonClick() {
  emit('ok', selected.value)
}

function parseDatabase(database: Database) {
  database.nodes.forEach((node) => {
    parseNode(node, database)
  })

  props.databaseForInsert.value = database
}

function parseNode(node: Node, database: Database) {
  updateNode(node, database)
  node.children.forEach((node) => {
    parseNode(node, database)
  })
}

function updateNode(node: Node, database: Database, nodeObjectTypeName = undefined, baseFieldsData = undefined) {
  if (node.type === NodeType.Object) {
    let nodeObjectType
    if (nodeObjectTypeName === undefined) {
      nodeObjectType = findObjectType(node.objectType, database)
    } else {
      nodeObjectType = findObjectType(nodeObjectTypeName, database)
    }

    if (nodeObjectType?.parent !== undefined) {
      baseFieldsData = node?.fields
      node.fields = []
      updateNode(node, database, nodeObjectType.parent, baseFieldsData)
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    } else {
      if (baseFieldsData === undefined) {
        baseFieldsData = node?.fields
        node.fields = []
      }
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    }
  } else if (node.type === NodeType.Folder) {
    let nodeObjectType = findObjectType(node.objectType, database)
    //   node.
    // TODO: Check if necessary
  }
}

function updateNodeFields(node: Node, database: Database, nodeObjectType, baseFieldsData) {
  const newNodeFields = nodeObjectType?.fields.map((fieldModel) => {
    let fieldValue = getFieldValue(baseFieldsData, fieldModel.label)
    if (fieldModel.type === FieldType.Object) {
      updateNodeObjectField(fieldModel, fieldValue, database)
    }

    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: fieldValue,
    }
  })

  node.fields = node.fields.concat(newNodeFields)
}

function updateNodeObjectField(fieldModel, fieldValue, database) {
  const objectType = findObjectType(fieldModel.objectTypeName, database)
  const newFields = objectType.fields.map((fieldModel) => {
    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: getFieldValue(fieldValue.fields, fieldModel.label),
    }
  })

  fieldValue.fields = newFields
}

function getFieldValue(baseFieldsData, fieldLabel: string): any {
  let result = null
  baseFieldsData.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field.value
    }
  })
  return result
}

function findObjectType(objectTypeName: string, database: Database): ObjectType {

  if (objectTypeName === 'SAS') {
    return sas
  }

  if (objectTypeName === 'Entité') {
    return entity
  }

  if (objectTypeName === 'Personne physique') {
    return physicalPerson
  }

  let result = null
  database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })
  return result
}

function getChildFields(parent): Array<any> {
  console.log("getChildFields", parent)
  const childs: Array<any> = []
  if (parent) {
    const childsToDisplay: Array<any> = []
    parent?.fields?.forEach((field) => {
      props.question.databaseObjectModelFieldsMapping.forEach((databaseObject) => {
        if (databaseObject.data[0].databaseObjectModelFieldName.includes(field.label)) {
          childsToDisplay.push(field)
        }
      })

    })

    let i = 0

    console.log("childsToDisplay", childsToDisplay)
    childsToDisplay.forEach((c) => {
      if (c.value.links == undefined) {
        return
      }
      c.value.links.forEach((link) => {
        let data = {
          "id": i,
          "databaseObject": parent,
          "childsToDisplay": childsToDisplay,
        }
        i = i + 1
        link.fields.forEach((field) => {
          const checker: string | undefined = childsDataToDisplay.value.find((childDataToDisplay: string) => {
            return childDataToDisplay.includes(field.label)
          })

          if (checker && checker.split('/').at(-1) !== 'Label') {
            data[field.label] = field.value
          }
          if (checker && checker.split('/').at(-1) === 'Label') {
            const label = findObjectById(databaseJson.nodes, field.value)?.label
            data['Label'] = label
          }

          // console.log("field.label: -" + field.label + "-")
          if (field.label.startsWith("Date de")) {
            // console.log("found date", field)
            data[field.label] = field.value
          }
        })
        childs.push(data)
      })
    })
  }
  return childs
}

function findObjectById(nodes, objectId) {
  let result = undefined
  nodes.forEach((node) => {
    if (node.id === objectId) {
      result = node
    }
    const childResult = findObjectById(node.children, objectId)
    if (childResult !== undefined) {
      result = childResult
    }
  })
  return result
}

function openChildDialog(row): void {
  rowSelected.value = row
  const childrenRows = getChildFields(rowSelected.value)
  console.log("childrenRows", childrenRows)
  const dialog = Dialog.create({
    component: ObjectChildSelectionDialog,
    componentProps: {
      rows: childrenRows,
      columns: childsColumns,
      rowSelected: rowSelected.value,
      question: props.question,
    },
  })
    .onOk((data) => {
      addChildAnswer(data, childrenRows)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function addChildAnswer(rows, childrenRows) {
  if (props.question.type === 'object') {
    rows.forEach((row) => {
      let array: Array<any> = []
      const keys = Object.keys(row)
      keys.forEach((key) => {
        try {
          const variableKey = props.question.databaseObjectModelFieldsMapping[0].data.find((d) => {
            return d.databaseObjectModelFieldName.split('/').at(-1) === key
          })
          array.push({
            label: variableKey.variableFieldName,
            value: row[key],
          })
        } catch (error) {
          console.log('error', error)
        }
      })
      databaseChildObject.value = array
    })
  }
  if (props.question.type === 'array') {
    let arrayToReturn: Array<any> = []
    rows.forEach((row) => {
      let array: Array<any> = []
      const keys = Object.keys(row)
      keys.forEach((key) => {
        const variableKey = props.question.databaseObjectModelFieldsMapping[0].data.find((d) => {
          return d.databaseObjectModelFieldName.split('/').at(-1) === key
        })
        array.push({
          label: variableKey.variableFieldName,
          value: row[key],
        })
      })
      arrayToReturn.push(array)
    })
    databaseChildObject.value = arrayToReturn
  }
  if (props.question.type === 'multiarray') {
    let arrayToReturn: Array<any> = []
    rows.forEach((row) => {
      let array: Array<any> = []
      const keys = Object.keys(row)
      keys.forEach((key) => {
        props.question.databaseObjectModelFieldsMapping.forEach((databaseObjectModelField) => {
          const variableKey = databaseObjectModelField.data.find((d) => {
            return d.databaseObjectModelFieldName.split('/').at(-1) === key
          })
          array.push({
            label: variableKey.variableFieldName,
            value: row[key],
          })
        })
      })
      arrayToReturn.push(array)
    })
    databaseChildObject.value = arrayToReturn
  }
  emit('ok', { isChild: true, data: databaseChildObject.value, selectedRows: rows, childrenRows: childrenRows })
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog title="Sélection d'objet" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="col">
          <q-table v-if="isChildsMapping &&
            (question.type === 'object' ||
              question.type === 'unisign' ||
              question.type === 'text' ||
              question.type === 'number')
            " :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered
            v-model:selected="selectedNode">
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer" @click="openChildDialog(props.row)">
                <q-td key="label" :props="props">
                  {{ props.row.label }}
                </q-td>
                <q-td key="objectType" :props="props">
                  {{ props.row.objectType }}
                </q-td>
                <q-td key="informations" :props="props">
                  <q-btn flat round color="primary" icon="info_outline">
                    <q-popup-proxy>
                      <q-banner class="q-pa-none">
                        <q-list dense>
                          <div v-for="field in props.row.fields">
                            <q-item class="q-pa-none" v-if="typeof field.value !== 'object' && field.value">
                              <q-item-section side>
                                {{ field.label }}
                              </q-item-section>
                              <q-item-section>
                                {{ field.value }}
                              </q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </q-td>
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
          <q-table v-if="!isChildsMapping &&
            (question.type === 'object' ||
              question.type === 'unisign' ||
              question.type === 'text' ||
              question.type === 'number')
            " :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered
            v-model:selected="selectedNode" hide-bottom>
            <template #bottom></template>
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer">
                <q-td key="label" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.label }}
                </q-td>
                <q-td key="objectType" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.objectType }}
                </q-td>
                <q-td key="informations" :props="props">
                  <q-btn flat round color="primary" icon="info_outline">
                    <q-popup-proxy>
                      <q-banner class="q-pa-none">
                        <q-list dense>
                          <div v-for="field in props.row.fields">
                            <q-item v-if="typeof field.value !== 'object' && field.value">
                              <q-item-section side>
                                {{ field.label }}
                              </q-item-section>
                              <q-item-section> {{ field.value }}</q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </q-td>
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
          <q-table v-if="!isChildsMapping &&
            (question.type === 'array' || question.type === 'multiarray' || question.type === 'multisign')
            " :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered selection="multiple"
            v-model:selected="selected">
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
          <q-table v-if="isChildsMapping &&
            (question.type === 'array' || question.type === 'multiarray' || question.type === 'multisign')
            " :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="openChildDialog(props.row)">
                <q-td v-for="col in props.cols" :key="col.name" :props="props" class="cursor-pointer">
                  {{ col.value }}
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </template>
      <template #actions>
        <q-btn v-if="question.type === 'array' || question.type === 'multiarray' || question.type === 'multisign'"
          :disable="selected.length === 0" flat label="Ajouter" color="primary" @click="onAddButtonClick" v-close-popup />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.cell {
  padding: 0px !important;
}

.filter-input {
  width: 300px;
}

.tree {
  overflow: scroll;
}

.sticky-header-table {

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #e4e4e4;
  }
}

.sticky-header-column-table {
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%;

  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: #e4e4e4 !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
