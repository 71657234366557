<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, watchEffect, inject } from 'vue'
import { InlineCounter } from '@/models/v2/document/elements/content/inline/InlineCounter'

interface Props {
  data: InlineCounter
}

const props = withDefaults(defineProps<Props>(), {})

const variables = inject('variables')

const counterUpdateFlag = inject('counterUpdateFlag')

const counterValue = ref(0)

function isVisible() {
  return true
}

function updateCounter() {
  const counterWrappers = document.querySelectorAll('[counter-id="1"]')

  let count = 1
  for (let i = 0; i < counterWrappers.length; i++) {
    if (counterWrappers[i].getAttribute('inline-element-id') === props.data.id) {
      counterValue.value = count
    } else if (counterWrappers[i].childElementCount != 0) {
      count += 1
    }
  }
}

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

onMounted(() => {
  updateCounter()
  counterUpdateFlag.value += 1
})

onUnmounted(() => {
  updateCounter()
  counterUpdateFlag.value += 1
})

const conditionVariable = getVariableById(4)

watch(
  counterUpdateFlag,
  (newValue, oldValue) => {
    updateCounter()
  },
  {
    flush: 'post',
  },
)

// watch(conditionVariable, (newValue, oldValue) => {
//     updateCounter();
// }, {
//     flush: 'post'
// });
</script>

<template>
  {{ counterValue }}
</template>

<style lang="scss" scoped></style>
