<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent, Dialog } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  data: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const newSize = ref(props.data.size)

const selectOptions = [
  {
    label: 'Taille 1',
    value: 'h1',
  },
  {
    label: 'Taille 2',
    value: 'h2',
  },
  {
    label: 'Taille 3',
    value: 'h3',
  },
  {
    label: 'Taille 4',
    value: 'h4',
  },
  {
    label: 'Taille 5',
    value: 'h5',
  },
  {
    label: 'Taille 6',
    value: 'h6',
  },
]
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Modifier la taille du titre">
      <template #body>
        <BaseSelect clearable v-model="newSize" :options="selectOptions" emit-value map-options />

        <span :class="`text-${newSize} q-ma-none q-mt-lg heading-span`">
          {{ data.content }}
        </span>
      </template>
      <template #actions>
        <q-btn color="primary" @click="emit('ok', newSize)" flat label="Enregistrer" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
