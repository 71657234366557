<script setup lang="ts">
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Accueil')
</script>

<template>
  <q-page padding class="page">
    <h4>Bienvenue dans l'espace d'administration de Come On Law</h4>
    A terme, vous pourrez gérer à partir d'ici les éléments suivants :
    <ul>
      <li>Le catalogue de contenus Come On Law</li>
      <li>La configuration des dossiers permanents</li>
      <li>Les marques blanches</li>
    </ul>
  </q-page>
</template>
