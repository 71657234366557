import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class ImageBlock extends Element implements Serializable<ImageBlock> {
  content: string

  constructor() {
    super()
    this.src = ''
  }

  deserialize(input: Object): ImageBlock {
    super.deserialize(input)

    this.src = input.src

    return this
  }
}
