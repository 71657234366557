import Main from './Main.vue'
import SubscriptionModuleEditor from './SubscriptionModuleEditor.vue'

const routes = [
  {
    name: 'subscriptions/main',
    component: Main,
    path: '/subscriptions/main',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'subscriptions/module_editor',
    component: SubscriptionModuleEditor,
    path: '/subscriptions/module_editor/:subscriptionModuleId',
    props: (route) => ({
      subscriptionModuleId: route.query.subscriptionModuleId,
    }),
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
