<script lang="ts" setup>
import { watch, inject } from 'vue'
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'

interface Props {
  row?: any
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  row: undefined,
  showLabel: true,
})

const rows = inject("rows")
const rowsUpdated = inject("rowsUpdated")

function getRowColumnsValue(columnName: string) {
  let result = 0
  props.row.fields.forEach((field) => {
    // console.log(field, columnName)
    if (field.name == columnName) {
      // console.log(columnName, field.value, parseFloat(field.value))
      result = parseFloat(field.value)
    }
  })
  return result
}

function updateValue() {
  if (props.field.value == undefined) {
    props.field.value = "Indéfini"
  }

  if (props.field.name == "Prix total") {
    console.log(getRowColumnsValue("Prix unitaire"))
    props.field.value = (getRowColumnsValue("Prix unitaire") * getRowColumnsValue("Nombre d'actions")).toString()
    if (props.field.value == "NaN") {
      props.field.value = "Indéfini"
    }
  }

  if (props.field.name == "Nombre d'éléments") {
    console.log("updating number of lines")
    props.field.value = rows.value.length.toString()
  }

  if (props.field.name == "Montant total") {
    console.log("updating total")
    props.field.value = "Indéfini"
  }
}

updateValue()

watch(props.row, () => {
  console.log("updating function value")
  updateValue()
})

watch(rowsUpdated, () => {
  console.log("updating function value after rows update")
  updateValue()
})

function updatingModel() {

}

function onFieldChange() {

}
</script>

<template>
  <q-input v-model="field.value" :label="showLabel ? field.name : undefined" @change="onFieldChange"
    @update:model-value="updatingModel" debounce="1000" :disable="true" />
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
