<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import { LoopBlock } from '@/models/v2/document/elements/control/block/LoopBlock'
import BaseSelect from '@/components/base/BaseSelect.vue'

interface Props {
  loop: LoopBlock
  variables: []
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const loopData: Ref<LoopBlock> = ref(props.loop)
const variable: Ref<string> = ref('')
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Boucle" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="q-gutter-sm">
          <BaseInput v-model="loopData.label" label="Nom de la boucle"> </BaseInput>
          <BaseSelect v-model="variable" :options="variables"> </BaseSelect>
        </div>
      </template>
      <template #actions>
        <q-btn color="primary" label="Modifier" v-close-popup></q-btn>
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
