<script lang="ts" setup>
interface Props {
    element: any
    treeRef: any
    showPermissions: boolean
}

const props = withDefaults(defineProps<Props>(), {})

console.log("directory node", props.element)

function onClick(permission) {
    console.log("click")
    permission.value = !permission.value
}
</script>

<template>
    <div v-if="!showPermissions">
        {{ element.label }}
    </div>


    <div v-if="showPermissions"
        class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node"
        style="border-bottom: 0.5px solid; border-bottom-color: #9e9e9e;">

        <div class="col-auto" style="overflow: hidden">
            <q-icon :name="element.icon || 'description'" size="24px" class="q-mr-sm"
                style="padding-right: 0px; color: #434440" />
        </div>
        <div class="col-shrink" style="overflow: hidden">
            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ element.label }}

                <q-tooltip>
                    {{ element.label }}
                </q-tooltip>
            </div>
        </div>

        <!-- space filler -->
        <div class="col" style="overflow: hidden"></div>

        <div class="col-auto" style="overflow: hidden">
            <span class="row items-center" style="text-align: center; line-height: 14px">
                <span v-for="permission in element.permissions" class="column items-center" style="width: 150px;">
                    <span :class="permission.disabled ? 'disabled col' : 'col'">
                        {{ permission.label }}
                    </span>
                    <span class="col">
                        <q-checkbox v-model="permission.displayedValue"
                            :disable="permission.disabled || permission.fromGroup" @click="onClick(permission)">
                        </q-checkbox>
                        <q-icon v-if="permission.fromGroup" name="o_info" style="position: absolute; top: 24px;">
                            <q-tooltip>
                                Permission provenant d'un groupe
                            </q-tooltip>
                        </q-icon>
                    </span>

                </span>
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.disabled {
    color: #a1a1a1;
}
</style>
