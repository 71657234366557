<script setup lang="ts">
import { Section } from '@/models/v2/document/Section'
import { Element } from '@/models/v2/document/Element'
import draggable from 'vuedraggable'

import BlockElementView from './BlockElementView.vue'

interface Props {
  section: Section
}

const props = withDefaults(defineProps<Props>(), {})

function onClone(event) {
  const element = new Element()
  element.id = `${event.name} ${Math.random()}`
  element.type = event.type
  return element
}
</script>

<template>
  <q-card flat bordered>
    <q-card-section class="q-gutter-sm">
      <draggable
        class="dragArea"
        tag="div"
        :list="section.elements"
        :group="{ name: 'g1' }"
        item-key="id"
        :clone="onClone"
      >
        <template #item="{ element, index }">
          <BlockElementView class="q-pb-sm" :element="element"> </BlockElementView>
        </template>
      </draggable>
    </q-card-section>
  </q-card>
</template>

<style lang="scss" scoped>
.section-view {
  display: block;
  margin-left: 5%;
  width: 90%;
  border: 1px solid;
  margin-bottom: 5px;
}

.section-content {
  margin-left: 5%;
  width: 90%;
}
</style>
