<script lang="ts" setup>
import { computed } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { format } from 'date-fns'
import { Mail } from '@/models/core/Mail'
import { MailEvent } from '@/models/core/MailEvent'
import { MailStatusEnum } from '@/models/core/MailStatus.enum'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  mail: Mail | undefined
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const address = computed(() => {
  return `${props.mail?.deliveryAddress.addressLine1} - ${props.mail?.deliveryAddress.addressPostalcode} - ${props.mail?.deliveryAddress.addressCity} - ${props.mail?.deliveryAddress.addressCountry}`
})

function getStatus(mailStatus: MailEvent) {
  let status = ''
  if (mailStatus) {
    if (mailStatus.status === MailStatusEnum.Created) {
      status = 'Créé'
    }
    if (mailStatus.status === MailStatusEnum.Accepted) {
      status = 'Impression'
    }
    if (mailStatus.status === MailStatusEnum.FilingProof) {
      status = 'Preuve de dépôt'
    }
    if (mailStatus.status === MailStatusEnum.Sent) {
      status = 'Envoyé'
    }
    if (mailStatus.status === MailStatusEnum.Error) {
      status = 'Erreur'
    }
    if (mailStatus.status === MailStatusEnum.InTransit) {
      status = 'En transit'
    }
    if (mailStatus.status === MailStatusEnum.WaitingToBeWithdrawn) {
      status = 'En attente de réception'
    }
    if (mailStatus.status === MailStatusEnum.Distributed) {
      status = 'Distribué'
    }
    if (mailStatus.status === MailStatusEnum.ReturnedToSender) {
      status = "Retour à l'envoyeur"
    }
    if (mailStatus.status === MailStatusEnum.DeliveryProof) {
      status = 'Reçu avec accusé de réception'
    }
    if (mailStatus.status === MailStatusEnum.WrongAddress) {
      status = 'Mauvaise adresse'
    }
    if (mailStatus.status === MailStatusEnum.Canceled) {
      status = 'Annulé'
    }
    if (mailStatus.status === MailStatusEnum.NoStatus) {
      status = 'Aucun statut'
    }
  }

  return status
}

function openDeliveryProof(event) {
  window.open(event.documentUrl, '_blank')
}

function openFilingProof(event) {
  window.open(event.documentUrl, '_blank')
}
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Suivi du courrier recommandé" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <q-list bordered separator v-if="mail?.events && mail?.events.length > 0">
          <q-item>
            <q-item-section>
              <q-item-label>{{ mail.deliveryAddress?.name }}</q-item-label>
              <q-item-label caption>{{ address }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item-label header>Évènements</q-item-label>
          <q-item v-for="event in mail?.events" :key="event.status">
            <q-item-section>
              <q-item-label>{{ getStatus(event) }}</q-item-label>
              <q-item-label caption>{{ format(new Date(event.date), 'dd/MM/yyyy - HH:mm:ss') }}</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-btn
                v-if="event.status === MailStatusEnum.DeliveryProof"
                outline
                round
                color="primary"
                size="sm"
                padding="sm"
                icon="download"
                @click="openDeliveryProof(event)"
              />
              <q-btn
                v-if="event.status === MailStatusEnum.FilingProof"
                outline
                round
                color="primary"
                size="sm"
                padding="sm"
                icon="download"
                @click="openFilingProof(event)"
              />
            </q-item-section>
          </q-item>
        </q-list>
        <q-list bordered separator v-if="mail?.events && mail?.events.length === 0">
          <q-item v-ripple>
            <q-item-section>
              <q-item-label>Aucun évènement</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template #actions> </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
