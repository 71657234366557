<script setup lang="ts">
import { ref, watch } from 'vue'
import { useStore } from '@/store/store'
const store = useStore()
const pageTitle = ref('')
const pageTitleComponent = ref('')
const pageSubtitle = ref('')
const pageTitleLoading = ref(false)

watch(store.state, () => {
  pageTitle.value = store.state.pageTitle
  pageTitleComponent.value = store.state.pageTitleComponent
  pageSubtitle.value = store.state.pageSubtitle
  pageTitleLoading.value = store.state.pageTitleLoading
})
</script>

<template>
  <div class="page-title q-ml-md" v-if="!pageTitleLoading">
    <div v-if="pageTitle !== undefined">
      {{ pageTitle }}
    </div>
    <div v-if="pageTitleComponent !== undefined" id="pageTitleComponent"></div>
  </div>
  <div class="page-title q-ml-md" v-if="pageTitleLoading">
    <q-skeleton width="500px" height="35px" />
  </div>
  <div class="page-subtitle">{{ pageSubtitle }}</div>
</template>

<style lang="scss" scoped>
.page-title {
  font-weight: 500;
  font-size: 22px;
  margin-left: 0px;
  padding-left: 0px;
  line-height: 47px;
  color: 'black';
}

.page-subtitle {
  font-weight: 400;
  font-size: 18px;
  padding-left: 0px;
  line-height: 47px;
  color: grey;
}
</style>
