<script setup lang="ts">
import ProductsTable from './products/ProductsTable.vue'
import { useStore } from '@/store/store'
const store = useStore()
store.commit('setPageTitle', 'Catalogue de produits')
</script>

<template>
  <q-page padding class="page">
    <h5>Prix du crédit</h5>
    <p>Le prix du crédit est de 45 €.</p>
    <q-btn label="Modifier" color="primary"></q-btn>

    <h5>Produits</h5>
    <ProductsTable />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}
</style>
