<script setup lang="ts">
import { ref, watch, inject } from 'vue'
import { InlineAlternative } from '@/models/v2/document/elements/control/inline/InlineAlternative'

interface Props {
  data: InlineAlternative
}

const props = withDefaults(defineProps<Props>(), {})

const variables = inject('variables')

const alternativeValue = ref(undefined)

const alternativeVariable = getVariableById(props.data.variableId)

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

watch(alternativeVariable, () => {
  alternativeValue.value = undefined

  for (let i = 0; i < props.data.variableValues.length; i++) {
    if (props.data.variableValues[i] === alternativeVariable.value) {
      alternativeValue.value = props.data.values[i]
    }
  }
})
</script>

<template>
  <span v-if="alternativeValue === undefined || alternativeValue === ''" class="variable-name">{{
    props.data.emptyValueText
  }}</span>
  <span v-if="alternativeValue !== undefined && alternativeValue !== ''" class="variable-value">{{
    alternativeValue
  }}</span>
</template>

<style lang="scss" scoped>
.variable-name {
}

.variable-value {
}
</style>
