import Main from './Main.vue'
import Client from './Client.vue'
import ClientProjects from './ClientProjects.vue'
import ClientDatabase from './ClientDatabase.vue'
import ClientUsers from './ClientUsers.vue'
import Project from '../../common/projects/Project.vue'
import CreateProject from '../../common/projects/CreateProject.vue'
import Survey from '../../common/survey/Survey.vue'
import SurveySummary from '../../common/survey/SurveySummary.vue'

const routes = [
  {
    name: 'clients/main',
    component: Main,
    path: '/clients',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client',
    component: Client,
    path: '/clients/:clientId',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/users',
    component: ClientUsers,
    path: '/clients/:clientId/users',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/documents',
    component: Client,
    path: '/clients/:clientId/documents',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects',
    component: ClientProjects,
    path: '/clients/:clientId/projects',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects/project',
    component: Project,
    path: '/clients/:clientId/projects/:projectId',
    props: (route) => ({
      documentId: route.query.documentId,
      taskId: route.query.taskId,
      lateralTab: route.query.lateralTab,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/projects/create',
    component: CreateProject,
    path: '/clients/:clientId/projects/create',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/tasks/documents/survey',
    component: Survey,
    path: '/clients/:clientId/projects/:projectId/tasks/:taskId/documents/:documentId/survey',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: '/documents/survey',
    component: Survey,
    path: '/documents/:documentId/survey',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'projects/tasks/documents/survey/summary',
    component: SurveySummary,
    path: '/clients/:clientId/projects/:projectId/tasks/:taskId/documents/:documentId/survey/summary',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'documents/survey/summary',
    component: SurveySummary,
    path: '/documents/:documentId/survey/summary',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/database',
    component: ClientDatabase,
    path: '/clients/:clientId/database',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
