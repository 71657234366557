<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { ProjectKey } from '@/models/symbols'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  task: Task
  selectedFile: UploadedFile
  project: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const newFileName = ref(props.selectedFile.name)

async function renameFile() {
  try {
    isLoading.value = true
    await userApi.renameUploadTaskFile(props.task.id, props.selectedFile.id, newFileName.value)
    isLoading.value = false
    Notify.create({
      message: 'Document renommé',
      type: 'primary',
    })
    emit('ok')
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: 'Une erreur est survenue lors du renommage du document',
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Renommer un document"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        Nouveau nom du document :
        <br /><br />
        <q-input v-model="newFileName"></q-input>
      </template>
      <template #actions>
        <q-btn flat label="Renommer" @click="renameFile" :disable="newFileName == ''" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
