import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let address: ObjectType = {
  name: 'Adresse',
  fields: [
    {
      label: 'Numéro et rue',
      type: FieldType.String,
    },
    {
      label: 'Complément',
      type: FieldType.String,
    },
    {
      label: 'Boîte postale',
      type: FieldType.String,
    },
    {
      label: 'Code postal',
      type: FieldType.String,
    },
    {
      label: 'Ville',
      type: FieldType.String,
    },
    {
      label: 'Cedex',
      type: FieldType.String,
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[1].value + ' ' + object.fields[2].value
  },
}

export { address }
