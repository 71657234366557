<script setup lang="ts">
import { useStore } from '@/store/store'
import EnvironmentsTable from './EnvironmentsTable.vue'
const store = useStore()
store.commit('setPageTitle', 'Gestion des marques blanches')
</script>

<template>
  <q-page padding class="page">
    <h5 style="margin-top: 20px">Marques blanches</h5>
    <EnvironmentsTable></EnvironmentsTable>
  </q-page>
</template>
