<script setup lang="ts">
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { ref } from 'vue'
import UserApi from '@/services/api/core/UserApi'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const store = useStore()
store.commit('setPageTitle', 'Éditeur de projet')

const userApi: UserApi = useApi()

const list = ref([])
const rows = ref([])
const loading = ref(true)

fetch(userApi.api.BASE_URL + 'api/v1/legacy/projects_models').then((response) => {
  response.json().then((data) => {
    list.value = data.response.results
    loading.value = false
  })
})

function onRowClick(event, row) {
  router.push({
    name: 'v2/project/project_detail',
    params: { id: row.id },
  })
}
</script>

<template>
  <q-page padding class="page">
    <h5>Modèles de projets</h5>
    <BaseTable
      row-key="name"
      :rows="list"
      :is-loading="loading"
      :columns="[{ name: 'name', label: 'Name', field: 'name', sortable: true, align: 'left' }]"
      @row-click="onRowClick"
      :enable-add="false"
      :enable-actions="false"
    />
  </q-page>
</template>
