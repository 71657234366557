<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, inject, watch, provide } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import SubProject from './SubProject.vue'
import SubProjectsParameters from '../SubProjectsParameters.vue'

interface Props {
  subProjects: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['subProjectTaskModified', 'subProjectDeleted'])

const route = useRoute()
const userApi: UserApi = useApi()

function onSubProjectTaskModified(event) {
  emit('subProjectTaskModified', event)
}

function onSubProjectDeleted(deletedSubProjectId) {
  emit('subProjectDeleted', deletedSubProjectId)
}
const subProjectPersonalModels = await userApi.getSubProjectPersonalModels()
provide("subProjectPersonalModels", subProjectPersonalModels)
</script>

<template>
  <div>
    <SubProject v-for="subProjectId in subProjects" :projectId="subProjectId.id"  :key="subProjectId.id"
      @subProjectTaskModified="onSubProjectTaskModified"
      @subProjectDeleted="onSubProjectDeleted">
    </SubProject>
  </div>
</template>

<style lang="scss" scoped></style>
