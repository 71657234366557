<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  task: Task
  selectedFile: UploadedFile
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

async function deleteFile() {
  emit('ok')
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Supprimer le fichier"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body> Êtes-vous sûr de vouloir supprimer ce fichier ? </template>
      <template #actions>
        <q-btn flat label="Supprimer" @click="deleteFile" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
