<script setup lang="ts">
import { ref, watch, inject, provide, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useDialogPluginComponent, Notify, QTableProps, Dialog } from 'quasar'

import { useApi } from '@/store/useAppStore'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import UserApi from '@/services/api/core/UserApi'

import { useAgoDatabase } from '@/store/useDatabase'

import { Database } from '@/models/database/Database'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { FieldType } from '@/models/database/FieldType'
import { ObjectType } from '@/models/database/ObjectType'
import { Field } from '@/models/database/Field'
import NewObjectClientSelection from '@/components/common/database/NewObjectClientSelection.vue'

import { sas } from '@/models/database/node_types/SAS'
import { entity } from '@/models/database/node_types/Entity'
import { physicalPerson } from '@/models/database/node_types/PhysicalPerson'

interface Props {
  database: Database
  selectedNode: Node | null
}

const props = defineProps<Props>()

const nodesHashMap = inject('nodesHashMap')

const router = useRouter()
const route = useRoute()
const authenticatedUser = useAuthenticatedUser()
const userApi: UserApi = useApi()

const emit = defineEmits(['update:selectedNode'])

const environmentDatabase = inject('environmentDatabase')
const inEnvironmentDatabase = inject('inEnvironmentDatabase')

let demoDatabase = useAgoDatabase()

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const treeSelectedNode = ref(null)

const treeRef = inject('treeRef')

const currentNode = ref()

const allowedTypes = ref([])

allowedTypes.value = [
  'Personne physique',
  'Personne morale',
  // "SAS",
  // "SASU",
  // "SARL",
  // "Association",
  // "Tribunal",
  // "CPAM",
]

watch(treeSelectedNode, async (nodeId) => {
  // router.push({
  //   name: "clients/client/database",
  //   params: {
  //     clientId: route.params.clientId.toString(),
  //   },
  //   query: { nodeId: nodeId },
  // });

  currentNode.value = undefined
  emit('update:selectedNode', currentNode.value)

  await nextTick()

  currentNode.value = treeRef.value.getNodeByKey(nodeId)
  emit('update:selectedNode', currentNode.value)

  if (currentNode.value == undefined) {
    return
  }

  if (currentNode.value.type === NodeType.Folder) {
    // if (currentNode.value.authorizedObjectTypes) {
    //   allowedTypes.value = currentNode.value.authorizedObjectTypes;
    // } else {
    //   allowedTypes.value = [];
    // }
  }
})

let newFolderId = 1
let newNodeId = 1

let searchText = ref('')

function resetFilter() {
  searchText.value = ''
  // filterRef.value.focus()
}

function newFolder() {
  let node = treeRef.value.getNodeByKey(treeSelectedNode.value)
  node.children.push({
    id: 'Nouveau dossier ' + newFolderId,
    label: 'Nouveau dossier ' + newFolderId,
    type: NodeType.Folder,
    fields: [],
    children: [],
  })
  newFolderId++
}

function getObjectType(objectTypeName) {
  console.log('getObjectType', objectTypeName)

  if (objectTypeName === 'SAS') {
    return sas
  }

  if (objectTypeName === 'Entité') {
    return entity
  }

  if (objectTypeName === 'Personne physique') {
    return physicalPerson
  }

  for (let i = 0; i < props.database.objectTypes.length; i++) {
    let currentObjectType = props.database.objectTypes[i]
    if (currentObjectType.name === objectTypeName) {
      return currentObjectType
    }
  }
}

function createValueForObjectType(elementType) {
  let objectType = getObjectType(elementType)

  const fields = objectType?.fields.map((field) => {
    return {
      label: field.label,
      value: undefined,
    }
  })

  return {
    fields: fields,
  }
}

function createValueForObjectListType(linkFields) {
  // let objectType = getObjectType(elementType);

  // // const fields = objectType?.linkFields.map((field) => {
  // const fields = linkFields.map((field) => {
  //   return {
  //     label: field.label,
  //     value: {
  //       links: []
  //     },
  //   }
  // })

  // return {
  //   fields: fields
  // }
  return {
    links: [],
  }
}

function createFieldsForType(objectType): Array<Field> {
  const fields = []

  objectType?.fields.forEach((field) => {
    if (field.type == FieldType.Object) {
      fields.push({
        label: field.label,
        value: createValueForObjectType(field.objectTypeName),
      })
    } else if (field.type == FieldType.ObjectList) {
      fields.push({
        label: field.label,
        value: createValueForObjectListType(field.linkFields),
      })
    } else {
      fields.push({
        label: field.label,
        value: undefined,
      })
    }
  })

  return fields
}

function createNewElement(elementType) {
  if (inEnvironmentDatabase) {
    const dialog = Dialog.create({
      component: NewObjectClientSelection,
      componentProps: {
        environmentDatabase: environmentDatabase,
      },
    })
      .onOk((client) => {
        dialog.hide()

        let clientDatabase = environmentDatabase.find((cc) => cc.clientId == client.id)

        if (clientDatabase == undefined) {
          clientDatabase = {
            clientId: client.id,
            databaseJson: JSON.parse(JSON.stringify(demoDatabase)),
          }
          environmentDatabase.push(clientDatabase)
          saveDatabase(clientDatabase, client.id)
        }

        createNewElementReal(elementType, clientDatabase.databaseJson, clientDatabase.clientId)
      })
      .onCancel(() => {
        // dialog.hide();
      })

    return
  } else {
    createNewElementReal(elementType, props.database, undefined)
  }
}

function createNewElementReal(elementType, database, clientId) {
  if (elementType == 'Personne morale') {
    elementType = 'SAS'
  }

  // let node = treeRef.value.getNodeByKey(treeSelectedNode.value);
  let node
  if (elementType === 'Personne physique') {
    node = treeRef.value.getNodeByKey('b59ab461-1e83-4924-8786-ca12872b7480')
  }
  if (elementType === 'SAS') {
    node = treeRef.value.getNodeByKey('75d2179b-d25a-400b-bf05-41d723f6d0a9')
  }

  let objectType = getObjectType(elementType)

  console.log(objectType, objectType)

  let fields = []

  if (objectType?.parent !== undefined) {
    const parentType = findObjectType(objectType.parent, props.database)
    console.log("parentType", parentType)
    fields = fields.concat(createFieldsForType(parentType))
  }

  fields = fields.concat(createFieldsForType(objectType))

  console.log("fields", fields)

  const newNode = {
    id: createUUID(),
    label: 'Nouveau ' + newNodeId,
    type: NodeType.Object,
    objectType: elementType,
    fields: fields,
    children: [],
    clientId: clientId,
  }

  if (elementType === 'SAS') {
    newNode.children = [
      {
        // Begin fiche d'identité
        id: createUUID(),
        label: "Fiche d'identité",
        type: NodeType.FieldLink,
        objectLinkId: newNode.id,
        fieldLink: {
          objectId: newNode.id,
          fields: [
            'Nom',
            'Forme juridique',
            'Capital social',
            'Date de création',
            'Adresse du siège social',
            "Numéro d'immatriculation",
            "Ville d'immatriculation",
            'Numéro FINESS',
            'Numéro RNA',
            'Représentant légal',
          ],
        },
        fields: [],
        children: [
          {
            id: createUUID(),
            label: 'Statuts',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Statuts'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Extraits Kbis',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Extraits Kbis'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'RIB - IBAN',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['RIB - IBAN'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Commissaires aux comptes',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Commissaires aux comptes'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Baux',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Baux'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Sociétés filles',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Sociétés filles'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Actionnaires',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Actionnaires'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: "Pacte d'actionnaires",
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ["Pacte d'actionnaires"],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Assemblées générales',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Assemblées générales'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: "Conseils d'administration",
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ["Conseils d'administration"],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Conseils de surveillance',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Conseils de surveillance'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Table de capitalisation',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Table de capitalisation'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Bilans',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Bilans'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Comptes de résultat',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Comptes de résultat'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Opérations juridiques',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Opérations juridiques'],
            },
            fields: [],
            children: [],
          },

          {
            id: createUUID(),
            label: 'Membres du CSE',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Membres du CSE'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Délégués du personnel',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Délégués du personnel'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Membres du conseil stratégique',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Membres du conseil stratégique'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: "Contrats d'attribution",
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ["Contrats d'attribution"],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Contrats généraux',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Contrats généraux'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Conventions de compte courant',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Conventions de compte courant'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: "Conventions d'honoraires",
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ["Conventions d'honoraires"],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Délégations de pouvoirs',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Délégations de pouvoirs'],
            },
            children: [],
          },
          {
            id: createUUID(),
            label: 'Registre des mouvements de titres',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Registre des mouvements de titres'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'ODM et CERFAs',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['ODM et CERFAs'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Contrats mandataires',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Contrats mandataires'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Notes de travail',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Notes de travail'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Règlement intérieur',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Règlement intérieur'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: "Lettres d'accompagnement",
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ["Lettres d'accompagnement"],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Suivi des mandats',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Suivi des mandats'],
            },
            fields: [],
            children: [],
          },
          {
            id: createUUID(),
            label: 'Organigrammes',
            type: NodeType.FieldLink,
            objectLinkId: newNode.id,
            fieldLink: {
              objectId: newNode.id,
              fields: ['Organigrammes'],
            },
            fields: [],
            children: [],
          },
        ],
      }, // End fiche d'identité

      {
        // Begin registres
        id: createUUID(),
        label: 'Registres',
        type: NodeType.Folder,
        fields: [],
        children: [
          {
            id: createUUID(),
            label: 'Registres des personnes',
            type: NodeType.Folder,
            fields: [],
            children: [
              {
                id: createUUID(),
                label: 'Registres de gouvernance',
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registres du personnel',
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registres de la santé du personnel',
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registres des assemblées générales',
                type: NodeType.Object,
                objectType: 'Registres des assemblées générales',
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: "Registres des décisions de l'associé unique",
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registre IT IP',
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registre des mouvements de titre',
                type: NodeType.Folder,
                fields: [],
                children: [],
              },
            ],
          },
          {
            id: createUUID(),
            label: 'Registres RGPD',
            type: NodeType.GdprRegisters,
            fields: [
              {
                name: 'project',
                value: undefined,
              },
            ],
            children: [
              {
                id: createUUID(),
                label: 'Registre des traitements',
                type: NodeType.GdprTreatmentsRegister,
                objectType: 'Registre des traitements',
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registre des sous-traitants',
                type: NodeType.GdprSubContractorssRegister,
                objectType: 'Registre des sous-traitants',
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registre des requêtes',
                type: NodeType.GdprRequestsRegister,
                objectType: 'Registre des requêtes',
                fields: [],
                children: [],
              },
              {
                id: createUUID(),
                label: 'Registre des violations',
                type: NodeType.GdprViolationsRegister,
                objectType: 'Registre des violations',
                fields: [],
                children: [],
              },
            ],
          },
        ],
      }, // End registres
    ]
  }

  updateNode(newNode, database)
  parseNode(newNode, database)

  node.children.push(newNode)
  newNodeId++

  treeRef.value.setExpanded(node.id, true)
  treeSelectedNode.value = newNode.id

  if (inEnvironmentDatabase) {
    if (elementType === 'Personne physique') {
      database.nodes[0].children.push(newNode)
    }
    if (elementType === 'SAS') {
      database.nodes[1].children.push(newNode)
    }
  }

  saveDatabase(database, clientId)
}

function parseNode(node: Node, database: Database) {
  console.log('Parsing node', node)
  // updateNode(node, database);
  node.children.forEach((childNode) => {
    childNode.parentId = node.id
    parseNode(childNode, database)
  })
}

function updateNode(node: Node, database: Database, nodeObjectTypeName = undefined, baseFieldsData = undefined) {
  if (node.type === NodeType.Object) {
    let nodeObjectType
    if (nodeObjectTypeName === undefined) {
      nodeObjectType = findObjectType(node.objectType, database)
    } else {
      nodeObjectType = findObjectType(nodeObjectTypeName, database)
    }

    if (nodeObjectType?.parent !== undefined) {
      baseFieldsData = node?.fields
      node.fields = []
      updateNode(node, database, nodeObjectType.parent, baseFieldsData)
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    } else {
      if (baseFieldsData === undefined) {
        baseFieldsData = node?.fields
        node.fields = []
      }
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    }
  } else if (node.type === NodeType.Folder) {
    let nodeObjectType = findObjectType(node.objectType, database)
    //   node.
    // TODO: Check if necessary
  }
}

function updateNodeFields(node: Node, database: Database, nodeObjectType, baseFieldsData) {
  const newNodeFields = nodeObjectType.fields.map((fieldModel) => {
    let fieldValue = getFieldValue(baseFieldsData, fieldModel.label)
    if (fieldModel.type === FieldType.Object) {
      updateNodeObjectField(fieldModel, fieldValue, database)
    }

    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: fieldValue,
    }
  })

  node.fields = node.fields.concat(newNodeFields)
}

function updateNodeObjectField(fieldModel, fieldValue, database) {
  const objectType = findObjectType(fieldModel.objectTypeName, database)
  const newFields = objectType.fields.map((fieldModel) => {
    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: getFieldValue(fieldValue.fields, fieldModel.label),
    }
  })

  fieldValue.fields = newFields
}

function getFieldValue(baseFieldsData, fieldLabel: string): any {
  let result = null
  baseFieldsData.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field.value
    }
  })
  return result
}

function findObjectType(objectTypeName: string, database: Database): ObjectType {
  if (objectTypeName === 'SAS') {
    return sas
  }

  if (objectTypeName === 'Entité') {
    return entity
  }

  if (objectTypeName === 'Personne physique') {
    return physicalPerson
  }

  let result = null
  database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })
  return result
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

async function saveDatabase(database, clientId) {
  let saveResult = await userApi.updateDatabase(database, clientId)
}

function filterDatabaseObjectNode(node, filterStringLowerCase) {
  if (node.label.toLowerCase().indexOf(filterStringLowerCase) > -1) {
    return true
  } else {
    return false
  }
}

function filterDatabaseObjectChildNode(node, filterStringLowerCase) { }

function nodeFilter(node, filterString) {
  const filterStringLowerCase = filterString.toLowerCase()

  if (node.type === 'OBJECT') {
    if (node.objectType === 'SAS' || node.objectType === 'Personne physique') {
      return filterDatabaseObjectNode(node, filterStringLowerCase)
    } else {
      return false
    }
  } else {
    // const parentNode = treeRef.value.getNodeByKey(node.parentId);
    const parentNode = nodesHashMap[node.parentId]

    if (parentNode === null || parentNode === undefined) {
      return true
    }

    if (parentNode.type === 'OBJECT') {
      if (parentNode.objectType === 'SAS' || parentNode.objectType === 'Personne physique') {
        return filterDatabaseObjectNode(parentNode, filterStringLowerCase)
      } else {
        return false
      }
    }

    if (parentNode.type === 'FIELD_LINK') {
      // const grandParentNode =  treeRef.value.getNodeByKey(parentNode.parentId);
      const grandParentNode = nodesHashMap[parentNode.parentId]

      if (grandParentNode === null || grandParentNode === undefined) {
        return false
      }

      if (grandParentNode.type === 'OBJECT') {
        if (grandParentNode.objectType === 'SAS' || grandParentNode.objectType === 'Personne physique') {
          return filterDatabaseObjectNode(grandParentNode, filterStringLowerCase)
        } else {
          return false
        }
      }
    }
  }

  // return node.label && node.label.toLowerCase().indexOf(filt) > -1 && node.label.toLowerCase().indexOf('(*)') > -1

  //   const filt = filter.toLowerCase()
  // return node[ props.labelKey ]
  // && node[ props.labelKey ].toLowerCase().indexOf(filt) > -1
}
</script>

<template>
  <div class="q-gutter-y-md column" style="width: 100%; max-width: 100%">
    <q-toolbar class="text-primary">
      <q-btn color="primary" label="Nouveau">
        <q-menu v-if="authenticatedUser.clientId != '04381921-0947-4eb2-9140-ba7da238e383'">
          <q-list>
            <q-item v-for="type in allowedTypes" v-close-popup :clickable="true" @click="createNewElement(type)">
              <q-item-section>{{ type }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <q-menu v-if="authenticatedUser.clientId == '04381921-0947-4eb2-9140-ba7da238e383'">
          <q-list>
            <q-item v-close-popup :clickable="true" @click="newFolder">
              <q-item-section>Dossier</q-item-section>
            </q-item>
            <q-item v-close-popup :clickable="true" @click="newPhysicalPerson">
              <q-item-section>Personne physique</q-item-section>
            </q-item>
            <q-item v-close-popup :clickable="true" @click="newEntity">
              <q-item-section>Entité</q-item-section>
            </q-item>
            <q-item v-close-popup :clickable="true" @click="newCPAM">
              <q-item-section>CPAM</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-space />&nbsp;&nbsp;&nbsp;
      <q-input v-model="searchText" label="Rechercher" outlined dense filled style="width: 100%">
        <template v-slot:append>
          <q-icon v-if="searchText !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input>
    </q-toolbar>
  </div>
  <!-- <q-card> -->
  <q-tree :nodes="database.nodes" node-key="id" label-key="label" v-model:selected="treeSelectedNode" ref="treeRef"
    selected-color="red-10" :filter="searchText" :filter-method="nodeFilter" style="height: calc(100vh - 50px)"
    class="full-height" :default-expand-all="false" />
  <!-- </q-card> -->
</template>
