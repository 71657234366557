<script setup lang="ts">
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { AddEditMode } from '@/components/base/AddEditMode.enum'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
}

const props = withDefaults(defineProps<Props>(), {
})

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const name: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(false)

async function createClient() {
  try {
    isLoading.value = true
    Notify.create({
      message: `Entité créée avec succès`,
      type: 'primary',
    })
    emit('ok', name.value)
    isLoading.value = false
  } catch (e) {
    isLoading.value = false
    dialogRef.value?.hide()
    Notify.create({
      message: `Une erreur est survenue lors de la création de l'entité`,
      type: 'negative',
    })
  }
}

</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog title="Nouvelle entité" :is-dialog-loading="isLoading" @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()">
      <template #body>
        <BaseInput v-model="name" label="Nom *" lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Le nom est requis']" />
      </template>
      <template #actions>
        <q-btn color="primary" @click="createClient" flat label="Ajouter" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
