<script lang="ts" setup>
interface Props {
  title: string
  caption?: string
  isDialogLoading?: boolean
  loadingMessage?: string
  showActions?: boolean
  showCancelButton?: boolean
  dialogWidth?: number
  isFullContent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isDialogLoading: false,
  loadingMessage: 'Veuillez patienter...',
  caption: '',
  showActions: true,
  showCancelButton: true,
  dialogWidth: 700,
  isFullContent: false,
})

const emit = defineEmits(['beforeShow', 'hide', 'onDialogCancel'])

function getDialogWidth(): string {
  return `width: ${props.dialogWidth}px; max-width: 80vw`
}
</script>

<template>
  <q-card :style="getDialogWidth()">
    <q-card-section class="row items-center">
      <div class="column full-width">
        <div class="row items-center">
          <div class="text-h6">
            {{ title }}
          </div>
          <q-space />
          <q-btn icon="close" flat round @click="emit('onDialogCancel')" />
        </div>
        <div class="text-weight-thin">
          {{ caption }}
        </div>
      </div>
    </q-card-section>
    <slot v-if="isFullContent" name="full-content"></slot>
    <q-card-section v-if="!isFullContent" class="column q-pt-none">
      <slot name="body"></slot>
    </q-card-section>
    <q-card-actions align="right" v-if="showActions">
      <q-btn v-if="showCancelButton" flat label="Annuler" color="primary" @click="emit('onDialogCancel')" />
      <slot name="actions"></slot>
    </q-card-actions>
    <q-inner-loading :showing="isDialogLoading" :label="loadingMessage" color="primary" />
  </q-card>
</template>

<style lang="scss" scoped></style>
