<script setup lang="ts">
import { ref, watch, inject, provide, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useDialogPluginComponent, Notify, QTableProps, Dialog } from 'quasar'

import { useApi } from '@/store/useAppStore'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import UserApi from '@/services/api/core/UserApi'

import { useAgoDatabase } from '@/store/useDatabase'

import { Database } from '@/models/database/Database'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { FieldType } from '@/models/database/FieldType'
import { ObjectType } from '@/models/database/ObjectType'
import { Field } from '@/models/database/Field'

import { sas } from '@/models/database/node_types/SAS'
import { entity } from '@/models/database/node_types/Entity'
import { physicalPerson } from '@/models/database/node_types/PhysicalPerson'

interface Props {
  database: Database
  selectedNode: Node | null | undefined
}

const props = defineProps<Props>()

const nodesHashMap = inject('nodesHashMap')

const router = useRouter()
const route = useRoute()
const authenticatedUser = useAuthenticatedUser()
const userApi: UserApi = useApi()

const emit = defineEmits(['update:selectedNode'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const treeSelectedNode = ref(null)

const treeRef = inject('treeRef')
const databaseEntities = inject("databaseEntities")

const currentNode = ref()

watch(treeSelectedNode, async (nodeId) => {
  currentNode.value = undefined
  emit('update:selectedNode', currentNode.value)

  await nextTick()

  currentNode.value = treeRef.value.getNodeByKey(nodeId)
  emit('update:selectedNode', currentNode.value)

  if (currentNode.value == undefined) {
    return
  }
})

let searchText = ref('')

function resetFilter() {
  searchText.value = ''
  // filterRef.value.focus()
}

function getObjectType(objectTypeId) {
  console.log('getObjectType', objectTypeId)

  for (let i = 0; i < databaseEntities.length; i++) {
    let currentObjectType = databaseEntities[i]
    if (currentObjectType.id === objectTypeId) {
      return currentObjectType
    }
  }
}

function createValueForObjectType(elementType) {
  let objectType = getObjectType(elementType)

  const fields = objectType?.fields.map((field) => {
    return {
      label: field.label,
      value: undefined,
    }
  })

  return {
    fields: fields,
  }
}

function createValueForObjectListType(linkFields) {
  // let objectType = getObjectType(elementType);

  // // const fields = objectType?.linkFields.map((field) => {
  // const fields = linkFields.map((field) => {
  //   return {
  //     label: field.label,
  //     value: {
  //       links: []
  //     },
  //   }
  // })

  // return {
  //   fields: fields
  // }
  return {
    links: [],
  }
}

function createPagesForType(objectType): Array {
  const pages = []

  console.log("createPagesForType", objectType)

  objectType?.children.forEach((page) => {
    let fields = []
    if (page.fields) {
      fields = page.fields
    }
    const newPage = {
      id: createUUID(),
      label: page.label,
      type: "page",
      fields: fields,
      children: [],
    }
    newPage.children = createPagesForType(page)
    pages.push(
      newPage
    )
  })

  return pages
}

function createNewElement(elementType) {
  const newElement = {
    id: createUUID(),
    label: 'Nouveau "' + elementType.name + '"',
    type: NodeType.Object,
    objectType: elementType.id,
    fields: [],
    children: createPagesForType(elementType.definitionJson),
  }

  console.log('createNewElement', newElement)
  props.database.nodes.unshift(newElement)
  saveDatabase(props.database, authenticatedUser.clientId)
}

function parseNode(node: Node, database: Database) {
  console.log('Parsing node', node)
  // updateNode(node, database);
  node.children.forEach((childNode) => {
    childNode.parentId = node.id
    parseNode(childNode, database)
  })
}

function updateNode(node: Node, database: Database, nodeObjectTypeName = undefined, baseFieldsData = undefined) {
  if (node.type === NodeType.Object) {
    let nodeObjectType
    if (nodeObjectTypeName === undefined) {
      nodeObjectType = findObjectType(node.objectType, database)
    } else {
      nodeObjectType = findObjectType(nodeObjectTypeName, database)
    }

    if (nodeObjectType?.parent !== undefined) {
      baseFieldsData = node?.fields
      node.fields = []
      updateNode(node, database, nodeObjectType.parent, baseFieldsData)
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    } else {
      if (baseFieldsData === undefined) {
        baseFieldsData = node?.fields
        node.fields = []
      }
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    }
  } else if (node.type === NodeType.Folder) {
    let nodeObjectType = findObjectType(node.objectType, database)
    //   node.
    // TODO: Check if necessary
  }
}

function updateNodeFields(node: Node, database: Database, nodeObjectType, baseFieldsData) {
  const newNodeFields = nodeObjectType.fields.map((fieldModel) => {
    let fieldValue = getFieldValue(baseFieldsData, fieldModel.label)
    if (fieldModel.type === FieldType.Object) {
      updateNodeObjectField(fieldModel, fieldValue, database)
    }

    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: fieldValue,
    }
  })

  node.fields = node.fields.concat(newNodeFields)
}

function updateNodeObjectField(fieldModel, fieldValue, database) {
  const objectType = findObjectType(fieldModel.objectTypeName, database)
  const newFields = objectType.fields.map((fieldModel) => {
    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: getFieldValue(fieldValue.fields, fieldModel.label),
    }
  })

  fieldValue.fields = newFields
}

function getFieldValue(baseFieldsData, fieldLabel: string): any {
  let result = null
  baseFieldsData.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field.value
    }
  })
  return result
}


function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

async function saveDatabase(database, clientId) {
  let saveResult = await userApi.updateDatabase(database, clientId)
}

function filterDatabaseObjectNode(node, filterStringLowerCase) {
  if (node.label.toLowerCase().indexOf(filterStringLowerCase) > -1) {
    return true
  } else {
    return false
  }
}

function filterDatabaseObjectChildNode(node, filterStringLowerCase) { }

function nodeFilter(node, filterString) {
  const filterStringLowerCase = filterString.toLowerCase()

  console.log("nodeFilter", node, filterStringLowerCase)

  if (node.type === 'OBJECT') {
    return filterDatabaseObjectNode(node, filterStringLowerCase)
  } else {
    let parent = treeRef.value.getNodeByKey(node.parentId)
    while (parent.type != 'OBJECT') {
      parent = treeRef.value.getNodeByKey(parent.parentId)
    }
    console.log("parent", parent)
    if (parent == undefined) {
      return false
    }
    return filterDatabaseObjectNode(parent, filterStringLowerCase)
  }

}
</script>

<template>
  <div class="q-gutter-y-md column" style="width: 100%; max-width: 100%">
    <q-toolbar class="text-primary">
      <q-btn color="primary" label="Nouveau">
        <q-menu>
          <q-list>
            <q-item v-for="type in databaseEntities" v-close-popup :clickable="true" @click="createNewElement(type)">
              <q-item-section>{{ type.name }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-space />&nbsp;&nbsp;&nbsp;
      <q-input v-model="searchText" label="Rechercher" outlined dense filled style="width: 100%">
        <template v-slot:append>
          <q-icon v-if="searchText !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
        </template>
      </q-input>
    </q-toolbar>
  </div>

  <q-tree :nodes="database.nodes" node-key="id" label-key="label" v-model:selected="treeSelectedNode" ref="treeRef"
    selected-color="red-10" :filter="searchText" :filter-method="nodeFilter" style="height: calc(100vh - 50px)"
    class="full-height" :default-expand-all="false" />
</template>
