import { ObjectType } from '../ObjectType'
import { FieldType } from '../FieldType'
import { Node } from '../Node'

let ag: ObjectType = {
  name: 'Assemblée générale',
  fields: [
    {
      type: FieldType.Date,
      label: 'Date',
    },
    {
      type: FieldType.String,
      label: 'Nom',
    },
    // {
    //   type: FieldType.String,
    //   label: "Projet",
    // },
    // {
    //   type: FieldType.ObjectList,
    //   label: "Convocations",
    //   linkFields: [
    //     {
    //       label: "Nom",
    //       type: FieldType.String,
    //     },

    //     {
    //       label: "Lien",
    //       type: FieldType.String,
    //     },
    //   ],
    // },
    {
      type: FieldType.ObjectList,
      label: 'Documents',
      linkFields: [
        {
          label: 'Document',
          type: FieldType.String,
        },

        // {
        //   label: "Document",
        //   type: FieldType.String,
        // },
      ],
    },
  ],
  onChange: function (object: Node): void {
    object.label = object.fields[0].value
  },
}

export { ag }
