import { Serializable } from './Serializable'
import { Section } from './Section'

export class Document implements Serializable<Document> {
  id: string
  name: string
  sections: Section[]

  constructor() {
    this.id = ''
    this.name = ''
    this.sections = new Array()
  }

  deserialize(input: Object): Document {
    this.id = input.id
    this.name = input.name
    this.sections = new Array()
    input.sections.forEach((section) => {
      this.sections.push(new Section().deserialize(section))
    })

    return this
  }

  public appendSection(section: Section) {
    this.sections.push(section)
  }
}
