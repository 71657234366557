<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  // node: any;
}
const props = defineProps<Props>()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

async function deleteNode() {
  try {
    isLoading.value = true

    isLoading.value = false
    emit('ok')
    Notify.create({
      message: 'Elements supprimés',
      type: 'primary',
    })
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: 'Une erreur est survenue lors de la suppression',
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Supprimer"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body> Êtes-vous sûr de vouloir supprimer ces éléments ? </template>
      <template #actions>
        <q-btn flat label="Supprimer" @click="deleteNode()" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
