<script setup lang="ts">
import { ref, Ref, provide, watch } from 'vue'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'

import { Document } from '@/models/v2/document/Document'

import DocumentView from './components/DocumentGenerator/DocumentView.vue'

const store = useStore()
const userApi: UserApi = useApi()

store.commit('setPageTitle', 'PoC v2 - Document Generator')

const splitterModel = ref(25)

const counterUpdateFlag = ref(1)
provide('counterUpdateFlag', counterUpdateFlag)

const showDialog = ref(false)
const selectedElement = ref(undefined)

const documentJson = {
  id: 'Document_1',
  name: 'CDI',
  sections: [
    {
      id: 'Section_1',
      header: {
        id: 'Header_1',
        elements: [],
      },
      footer: {
        id: 'Footer_1',
        elements: [],
      },
      elements: [
        {
          id: 'Heading_1',
          type: 'HeadingBlock',
          content: "Article <span inline-element-id='Counter_1' counter-id='1'></span> - Missions",
          elements: [
            {
              id: 'Counter_1',
              type: 'InlineCounter',
              elements: [],
            },
          ],
        },
        {
          id: 'Paragraph_1',
          type: 'ParagraphBlock',
          content:
            "<span inline-element-id='Variable_1'></span> <span inline-element-id='Variable_2'></span> <span inline-element-id='Variable_3'></span> est <span inline-element-id='Embauche_1'></span> en tant que développeur. <span inline-element-id='IlElle_1'></span> devra être disponible à la date de démarrage de son contrat.",
          elements: [
            {
              id: 'Variable_1',
              type: 'InlineVariable',
              variableId: 1,
              elements: [],
            },
            {
              id: 'Variable_2',
              type: 'InlineVariable',
              variableId: 2,
              elements: [],
            },
            {
              id: 'Variable_3',
              type: 'InlineVariable',
              variableId: 3,
              elements: [],
            },
            {
              id: 'Embauche_1',
              type: 'InlineAlternative',
              variableId: 1,
              emptyValueText: 'embauché(e)',
              variableValues: ['Monsieur', 'Madame'],
              values: ['embauché', 'embauchée'],
              elements: [],
            },
            {
              id: 'IlElle_1',
              type: 'InlineAlternative',
              variableId: 1,
              emptyValueText: 'Il/Elle',
              variableValues: ['Monsieur', 'Madame'],
              values: ['Il', 'Elle'],
              elements: [],
            },
          ],
        },
        {
          id: 'Condition_1',
          type: 'ConditionBlock',
          label: 'Si clause de mobilité',
          conditions: [
            {
              variableId: 4,
              operator: 'equals',
              variableValue: 'Oui',
            },
          ],
          elements: [
            {
              id: 'Heading_2',
              type: 'HeadingBlock',
              content: "Article <span inline-element-id='Counter_2' counter-id='1'></span> - Clause de mobilité",
              elements: [
                {
                  id: 'Counter_2',
                  type: 'InlineCounter',
                  elements: [],
                },
              ],
            },
            {
              id: 'Paragraph_2',
              type: 'ParagraphBlock',
              content: 'Texte de la clause de mobilité',
              elements: [],
            },
          ],
        },
        {
          id: 'Heading_3',
          type: 'HeadingBlock',
          content: "Article <span inline-element-id='Counter_3' counter-id='1'></span>  - Congés",
          elements: [
            {
              id: 'Counter_3',
              type: 'InlineCounter',
              elements: [],
            },
          ],
        },
        {
          id: 'Paragraph_3',
          type: 'ParagraphBlock',
          content: "<span inline-element-id='IlElle_2'></span> pourra prendre des congés.",
          elements: [
            {
              id: 'IlElle_2',
              type: 'InlineAlternative',
              variableId: 1,
              emptyValueText: 'Il/Elle',
              variableValues: ['Monsieur', 'Madame'],
              values: ['Il', 'Elle'],
              elements: [],
            },
          ],
        },
      ],
    },
  ],
}

const document: Ref<Document> = ref(new Document())
document.value.deserialize(documentJson)

const variables = ref([
  {
    id: 1,
    name: 'Madame/Monsieur',
    value: null,
  },
  {
    id: 2,
    name: 'prénom',
    value: null,
  },
  {
    id: 3,
    name: 'nom',
    value: null,
  },
  {
    id: 4,
    name: 'Clause de mobilité',
    value: null,
  },
  {
    id: 5,
    name: 'Tableau',
    value: [
      {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
        email: 'john.doe@gmail.com',
      },
      {
        id: 2,
        firstName: 'Jane',
        lastName: 'Doe',
        email: 'jane.doe@gmail.com',
      },
    ],
  },
])

provide('variables', variables)

const genderOptions = ['', 'Monsieur', 'Madame']

const yesNoOptions = ['', 'Oui', 'Non']

function getVariableById(id) {
  return variables.value.find((variable) => variable.id === id)
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

const webSocketId = createUUID()
let webSocketUpdate = false

function updateVariables(newVariables) {
  for (let i = 0; i < variables.value.length; i++) {
    variables.value[i].value = newVariables._rawValue[i].value
  }
}

const webSocketBaseUrl = userApi.api.BASE_URL.replace('http', 'ws')

const documentGeneratorSocket = new WebSocket(webSocketBaseUrl + `ws/document_generator/`)

documentGeneratorSocket.onmessage = function (e) {
  const data = JSON.parse(e.data)
  if (!(data.sender === webSocketId)) {
    webSocketUpdate = true
    updateVariables(data.message)
  } else {
    webSocketUpdate = false
  }
}

documentGeneratorSocket.onclose = function (e) {
  console.error('documentGenerator socket closed unexpectedly')
}

watch(
  variables,
  (newValue, oldValue) => {
    if (!webSocketUpdate) {
      documentGeneratorSocket.send(
        JSON.stringify({
          sender: webSocketId,
          message: variables,
        }),
      )
    } else {
      webSocketUpdate = false
    }
  },
  {
    deep: true,
  },
)

function generateDocument() {
  userApi.v2GenerateDocument(42).then((response) => {
    console.log(response)
  })
}

function editElement(element) {
  showDialog.value = true
  selectedElement.value = { ...element }
}

function removeElement(index) {
  variables.value[4].value.splice(index, 1)
}

function saveElement(element) {
  variables.value[4].value.forEach((e) => {
    if (e.id === element.id) {
      Object.assign(e, element)
    }
    showDialog.value = false
  })
}

function addElement() {
  variables.value[4].value.push({
    id: variables.value[4].value.length + 1,
    firstName: '',
    lastName: '',
    email: '',
  })
  showDialog.value = true
  selectedElement.value = variables.value[4].value.at(-1)
}
</script>

<template>
  <q-page padding class="page document-generator-page">
    <header style="padding: 10px">
      <q-btn label="générer le document" color="primary" @click="generateDocument" />
    </header>
    <q-separator />
    <q-splitter v-model="splitterModel" style="height: 100%">
      <template v-slot:before>
        <q-scroll-area style="height: 93.6vh; width: 100%; padding-right: 16px" ref="scrollAreaRef">
          <div class="q-pa-md q-gutter-md">
            <q-card class="my-card" flat bordered>
              <q-card-section class="card-section-question">
                <div class="text-h6">Civilité</div>
              </q-card-section>
              <q-separator inset />
              <q-card-section class="card-section-input">
                <BaseSelect dense v-model="variables[0].value" :options="genderOptions"> </BaseSelect>
              </q-card-section>
            </q-card>

            <q-card class="my-card" flat bordered>
              <q-card-section class="card-section-question">
                <div class="text-h6">Prénom</div>
              </q-card-section>
              <q-separator inset />
              <q-card-section class="card-section-input">
                <BaseInput dense v-model="variables[1].value"> </BaseInput>
              </q-card-section>
            </q-card>

            <q-card class="my-card" flat bordered>
              <q-card-section class="card-section-question">
                <div class="text-h6">Nom</div>
              </q-card-section>
              <q-separator inset />
              <q-card-section class="card-section-input">
                <BaseInput dense v-model="variables[2].value"> </BaseInput>
              </q-card-section>
            </q-card>

            <q-card class="my-card" flat bordered>
              <q-card-section class="card-section-question">
                <div class="text-h6">Faut-il inclure une clause de mobilité ?</div>
              </q-card-section>
              <q-separator inset />
              <q-card-section class="card-section-input">
                <BaseSelect dense v-model="variables[3].value" :options="yesNoOptions"> </BaseSelect>
              </q-card-section>
            </q-card>

            <q-card class="my-card" flat bordered>
              <q-card-section
                class="card-section-question flex justify-between items-center"
                style="padding-bottom: 8px"
              >
                <div class="text-h6">Listes de utilisateurs</div>
              </q-card-section>
              <q-separator inset />
              <q-card-section v-for="(element, index) in variables[4].value">
                <div class="flex justify-between items-center">
                  <div>{{ element.firstName }} {{ element.lastName }}</div>
                  <div>
                    <q-btn icon="edit" round size="sm" @click="editElement(element)" />
                    <q-btn icon="delete" round size="sm" class="q-ml-sm" @click="removeElement(index)" />
                  </div>
                </div>
              </q-card-section>

              <q-card-section class="flex justify-center">
                <q-btn icon="add" round size="sm" @click="addElement()" />
              </q-card-section>
            </q-card>

            <!-- <BaseDialog
                  :show-dialog="showDialog"
                  :showActions="true"
                  @before-hide="showDialog = false"
                >
                  <template #title>Édition</template>
                  <template #body>
                    <div v-for="(value, key) in selectedElement" class="q-my-md">
                      <BaseInput
                        v-model="selectedElement[key]"
                        v-if="key !== 'id'"
                      />
                    </div>
                  </template>
                  <template #actions>
                    <q-btn
                      color="primary"
                      @click="saveElement(selectedElement)"
                      flat
                      label="Enregistrer"
                    />
                  </template>
                </BaseDialog> -->
          </div>
        </q-scroll-area>
      </template>

      <template v-slot:after>
        <div class="q-pa-md">
          <DocumentView :document="document"></DocumentView>
        </div>
      </template>
    </q-splitter>
  </q-page>
</template>

<style lang="scss" scoped>
.description {
  border: solid;
  background-color: #eeeeee;
  border-color: #cfd8dc;
  border-width: 0px 8px;
  border-radius: 4px;

  .info {
    color: rgba(64, 104, 200, 0.48);
  }
}

.document-generator-page {
  padding-left: 0;
}

.my-card {
}

.card-section-question {
  padding-top: 8px;
  padding-bottom: 0px;
}

.card-section-input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-h6 {
  font-size: 14px;
}
</style>
