<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const store = useStore()
store.commit('setPageTitle', 'Switching environment')

const route = useRoute()
const router = useRouter()
const authenticatedUser = useAuthenticatedUser()

const newEnvironmentId = route.params.environmentId
console.log("newEnvironmentId", newEnvironmentId)

authenticatedUser.environments.forEach((environment) => {
    if (environment.id == newEnvironmentId) {
        authenticatedUser.portal = environment.portalType
        if (environment.portalType == 'CLIENT') {
            authenticatedUser.clientId = environment.client.id
            authenticatedUser.client = environment.client
        } else {
            authenticatedUser.clientId = null
            authenticatedUser.client = null
        }

        authenticatedUser.environment = environment

        store.commit('setUser', authenticatedUser)
        router.push({ name: 'dashboard/main' })
    }
})

router.push({ name: 'dashboard/main' })

</script>

<template>
    <q-page padding> Switching environment </q-page>
</template>
