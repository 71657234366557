<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { useDialogPluginComponent, Notify, QTableProps } from 'quasar'

import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { Client } from '@/models/core/Client'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

import BaseTable from '@/components/common/Table/BaseTable.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  environmentDatabase: Database
}

const props = defineProps<Props>()

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const userApi: UserApi = useApi()

// const environmentDatabase = inject("environmentDatabase");
// const inEnvironmentDatabase = inject("inEnvironmentDatabase");

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const rows: Ref<Array<Client>> = ref([])

const selectedItems: Ref<Array<Client>> = ref([])
const selectedRows = ref([])

const emit = defineEmits([...useDialogPluginComponent.emits])

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
  },
]

// refreshClients();

async function refreshClients() {
  const clients = await userApi.getClients()
  const rowsValues = []
  clients.results.forEach((client) => {
    // const item = props.environmentDatabase.find(clientDatabase => clientDatabase.clientId == client.id);
    // if (item) {
    //     rowsValues.push(client);
    // }
    rowsValues.push(client)
  })

  rows.value = rowsValues
}

function beforeShow() {
  refreshClients()
}

function updateRows() {}

function selectClient() {
  dialogRef.value?.hide()
}

function onRowSelected(event, row) {
  emit('ok', row)
  // dialogRef.value?.hide();
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog
      :title="`Sélection du client`"
      @on-dialog-cancel="onDialogCancel()"
      @on-dialog-ok="onDialogOK()"
      @hide="onDialogHide()"
    >
      <template #body>
        <div>
          <BaseTable
            add-button-label="Ajouter un client"
            selection="none"
            :columns="columns"
            :rows="rows"
            :selected-rows="selectedItems"
            :enable-add="false"
            :enable-actions="false"
            @on-row-selected="onRowSelected"
            @on-row-click="onRowSelected"
          >
            <template #action-menu-items>
              <!-- <ItemActionMenuItem label="Modifier" @click="onEditClick" />
                        <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
                        <ItemActionMenuItem
                            label="Gérer les droits"
                            @click="onManageRightsClick"
                        /> -->
            </template>
          </BaseTable>
        </div>
      </template>
      <template #actions>
        <!-- <q-btn
              flat
              label="Sélectionner"
              color="primary"
              @click="selectClient"
            /> -->
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
