<script lang="ts" setup>
interface Props {
    element: any
    treeRef: any
    showPermissions: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['update:view', 'update:edit'])

function onViewUpdate() {
    emit('update:view', props.element.view)
}

function onEditUpdate() {
    emit('update:edit', props.element.edit)
}
</script>

<template>
    <div v-if="!showPermissions" class="title">
        Documents
    </div>

    <div v-if="showPermissions"
        class="row no-wrap justify-start content-start full-width items-center drag-el drop-zone tree-node"
        style="border-bottom: 0.5px solid; border-bottom-color: #9e9e9e;">

        <div class="col-shrink title" style="overflow: hidden">
            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                Documents
            </div>
        </div>

        <!-- space filler -->
        <div class="col" style="overflow: hidden"></div>

        <div class="col-auto" style="overflow: hidden">
            <span class="row items-center" style="text-align: center; line-height: 14px">
                <span class="column items-center" style="width: 150px;">
                    <span class="col">Tout voir</span>
                    <span class="col"><q-checkbox v-model="element.view"
                            @update:model-value="onViewUpdate"></q-checkbox></span>
                </span>
                <span class="column items-center" style="width: 150px;">
                    <span class="col">Tout modifier</span>
                    <span class="col"><q-checkbox v-model="element.edit"
                            @update:model-value="onEditUpdate"></q-checkbox></span>
                </span>
                <!-- <span class="col" style="margin-left: 0px">
                    <span class="row" style="width: 180px;">Créer des dossiers</span>
                    <span class="row" style="margin-left: 44px;"><q-checkbox v-model="element.create_directory"
                            @update:model-value="onEditUpdate"></q-checkbox></span>
                </span> -->
            </span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.title {
    font-size: 1rem;
    font-weight: bold;
    // margin-bottom: 1rem;
}
</style>
