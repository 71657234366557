<script setup lang="ts">
import { ref, provide } from 'vue'
import { useStore } from '@/store/store'
import { QTableProps } from 'quasar'
import { useApi } from '@/store/useAppStore'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'

const store = useStore()
const userApi = useApi()

const columns: QTableProps['columns'] = [
  {
    name: 'specialty',
    label: 'Spécialité',
    field: 'specialty',
    sortable: true,
    align: 'left',
  },
  {
    name: 'project',
    label: 'Projet',
    field: 'project',
    sortable: true,
    align: 'left',
  },
  {
    name: 'availability',
    label: 'Disponibilité',
    field: 'availability',
    sortable: true,
    align: 'left',
  },
  {
    name: 'minimum_price',
    label: 'Prix minimum',
    field: 'minimum_price',
    sortable: true,
    align: 'left',
  },
  {
    name: 'number_of_credits',
    label: 'Nombre de crédits',
    field: 'number_of_credits',
    sortable: true,
    align: 'left',
  },
  {
    name: 'price',
    label: 'Prix de vente',
    field: 'price',
    sortable: true,
    align: 'left',
  },
  {
    name: 'visio',
    label: 'Visioconférence incluse',
    field: 'visio',
    sortable: true,
    align: 'left',
  },
]

const showForm = ref(false)
provide('showForm', showForm)
const mode = ref('ADD')
provide('mode', mode)
const selectedRows = ref([])

const rows = ref([
  {
    id: 1,
    specialty: 'Corporate',
    project: 'Accord de confidentialité',
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
  {
    id: 2,
    specialty: 'Corporate',
    project: 'Approbation des comptes annuels',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '90 €',
    visio: '30 min',
  },
  {
    id: 3,
    specialty: 'Corporate',
    project: 'Augmentation / Réduction de capital',
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '45 min',
  },
  {
    id: 4,
    specialty: 'Corporate',
    project: "Convention de compte courant d'associé",
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
  {
    id: 5,
    specialty: 'Corporate',
    project: 'Changement / Nomination de dirigeant',
    availability: 'Disponible',
    minimum_price: '135 €',
    number_of_credits: '3',
    price: '135 €',
    visio: '45 min',
  },
  {
    id: 6,
    specialty: 'Corporate',
    project: 'Création de société (hors frais)',
    availability: 'Disponible',
    minimum_price: '135 €',
    number_of_credits: '3',
    price: '135 €',
    visio: '45 min',
  },

  {
    id: 7,
    specialty: 'Social',
    project: 'Contrat de travail (CDI)',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '90 €',
    visio: '30 min',
  },
  {
    id: 8,
    specialty: 'Social',
    project: 'Contrat de travail (CDD)',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '90 €',
    visio: '30 min',
  },
  {
    id: 9,
    specialty: 'Social',
    project: "Entretien annuel d'évaluation",
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '90 €',
    visio: '30 min',
  },
  {
    id: 10,
    specialty: 'Social',
    project: 'Licenciement économique individuel',
    availability: 'Disponible',
    minimum_price: '135 €',
    number_of_credits: '3',
    price: '135 €',
    visio: '45 min',
  },
  {
    id: 11,
    specialty: 'Social',
    project: "Offre d'embauche",
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
  {
    id: 12,
    specialty: 'Social',
    project: 'Règlement intérieur',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '90 €',
    visio: '30 min',
  },
  {
    id: 13,
    specialty: 'Social',
    project: "Renouvellement de période d'essai",
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
  {
    id: 14,
    specialty: 'Social',
    project: "Rupture de période d'essai",
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },

  {
    id: 15,
    specialty: 'IP-IT-DATA',
    project: 'Cession des droits de propriété intellectuelle',
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
  {
    id: 16,
    specialty: 'IP-IT-DATA',
    project: 'Cession de marque',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '45 €',
    visio: '30 min',
  },
  {
    id: 17,
    specialty: 'IP-IT-DATA',
    project: 'Conditions générales de vente',
    availability: 'Disponible',
    minimum_price: '90 €',
    number_of_credits: '2',
    price: '45 €',
    visio: '30 min',
  },
  {
    id: 18,
    specialty: 'IP-IT-DATA',
    project: 'Mentions légales',
    availability: 'Disponible',
    minimum_price: '45 €',
    number_of_credits: '1',
    price: '45 €',
    visio: '15 min',
  },
])

provide('rows', rows)

// selected_rows: computed(() => store.state.selectedProducts),

function refreshProducts() {
  userApi.getProducts().then((response) => {
    rows.value = response.results
  })
}

function onAddClick() {
  mode.value = 'ADD'
  showForm.value = true
}

function onAddItemClick() {
  refreshProducts()
}

function onEditClick() {
  mode.value = 'EDIT'
  showForm.value = true
}

function onManageRightsClick() {}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>
<template>
  <BaseTable
    add-button-label="Ajouter un produit"
    selection="single"
    :columns="columns"
    :rows="rows"
    @add-item-click="onAddClick"
    @on-row-selected="onRowSelected"
  >
    <template v-slot:action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
    </template>
  </BaseTable>
</template>
