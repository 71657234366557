<script setup lang="ts">
import { useStore } from '@/store/store'
import ProjectsTable from '@/components/projects/management/ProjectsTable.vue'
import { isMobile } from '@/utils/detectMobile'

const store = useStore()
if (isMobile()) {
  store.commit('setPageTitle', 'Dossiers')
} else {
  store.commit('setPageTitle', 'Gestion des dossiers')
}

const client = store.state.currentClient
</script>

<template>
  <q-page padding class="page" v-if="!isMobile()">
    <h5>Dossiers</h5>
    <ProjectsTable :client-id="client.id" />
  </q-page>

  <q-page class="page-mobile" v-if="isMobile()">
    <!-- <h5>Dossiers</h5> -->
    <h5 class="h5-mobile">Client : {{ client.name }}</h5>
    <ProjectsTable :client-id="client.id" />
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

.h5-mobile {
  font-weight: 450;
  font-size: 16px;
  padding-right: 8px;
  margin-bottom: 0px;
}
</style>
