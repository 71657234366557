import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class ParagraphBlock extends Element implements Serializable<ParagraphBlock> {
  content: string

  constructor() {
    super()
    this.content = ''
  }

  deserialize(input: Object): ParagraphBlock {
    super.deserialize(input)

    this.content = input.content

    return this
  }
}
