import { routes as dashboardRoutes } from '../dashboard/routes.js'
import { routes as projectsRoutes } from '../projects/routes.js'
import { routes as databaseRoutes } from '../database/routes.js'
import { routes as documentsRoutes } from '../documents/routes.js'
import { routes as organizationRoutes } from '../organization/routes.js'
import { routes as settingsRoutes } from '../settings/routes.js'
import { routes as administrationRoutes } from '../administration/routes.js'

let routes = []

routes = routes.concat(dashboardRoutes)
routes = routes.concat(projectsRoutes)
routes = routes.concat(databaseRoutes)
routes = routes.concat(documentsRoutes)
routes = routes.concat(organizationRoutes)
routes = routes.concat(settingsRoutes)
routes = routes.concat(administrationRoutes)

export { routes }
