<script setup lang="ts">
import { ref, Ref, provide, onMounted, watch, inject } from 'vue'
import { ProjectKey, ProjectAccountableOptionsKey } from '@/models/symbols'
import { useRoute, useRouter } from 'vue-router'
import { Project } from '@/models/projects/Project'
import { Task } from '@/models/projects/Task'
import { TaskFilters } from '@/models/projects/TaskFilters'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
// import ProjectTaskDetails from "./ProjectTaskDetails.vue";
// import TitleBar from "./TitleBar.vue";
// import ProjectExecutionOptions from "@/components/projects/execution/ProjectExecutionOptions.vue";
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import Documents from './Documents.vue'
// import SubProjects from "./subprojects/SubProjects.vue";

interface Props {
  projectId: string
  documentId?: string
}

const props = withDefaults(defineProps<Props>(), {
  projectId: '',
})

const store = useStore()
const route = useRoute()
const router = useRouter()
const userApi: UserApi = useApi()

const project = ref<Project>()
const scrollAreaRef = ref(null)
const isPurchaseDialogVisible = ref(false)

const documentTreeNodes = ref([
  {
    id: 0,
    type: 0,
    children: [],
  },
])
provide('documentTreeNodes', documentTreeNodes)

const selectedNode = ref(null)
provide('documentTreeSelectedNode', selectedNode)

provide('isPurchaseDialogVisible', isPurchaseDialogVisible)
const usersFilter: Ref<Array<UserAccountableOptions>> = ref([])
provide('usersFilter', usersFilter)
const progressionsFilter: Ref<Array<any>> = ref([])
provide('progressionsFilter', progressionsFilter)
const filters: Ref<TaskFilters> = ref({
  hasFiles: false,
  isDeadlineOnly: false,
  assignees: [],
  status: [],
})
provide('globalFilters', filters)
const filter = ref('')

const authenticatedUser = useAuthenticatedUser()

project.value = await userApi.getProjectDetails(props.projectId)

project.value.isReadOnly = false

const documentTreeUpdateCounter = ref(0)
provide('documentTreeUpdateCounter', documentTreeUpdateCounter)

const documentSelected = ref(undefined)
provide('documentSelected', documentSelected)

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  // backgroundColor: '#027be3',
}

// Set read only for ayming users
if (authenticatedUser.email.includes('ayming')) {
  project.value.isReadOnly = false
}

onMounted(() => {
  if (scrollAreaRef.value !== null && taskDetailsSelected.value !== undefined) {
    // const offset = document.getElementById(taskDetailsSelected.value.id);
    // const rect = offset.getBoundingClientRect();
    // scrollAreaRef.value.setScrollPosition("vertical", rect.y - 200, 300);
  }
})

const accountableOptions = await userApi.getUsersWithProjectPermissions(props.projectId)

provide(ProjectKey, project)
provide(ProjectAccountableOptionsKey, accountableOptions)

const collapseTrees = ref(false)
provide('collapseTrees', collapseTrees)

const taskDetailsSelected = ref<Task>()
provide('taskDetailsSelected', taskDetailsSelected)

function onToggleCollapse(data) {
  collapseTrees.value = data
}

function onToggleOnlyShowDocuments(data) {
  filters.value.hasFiles = data
}

function onToggleOnlyDeadline(data) {
  filters.value.isDeadlineOnly = data
}

watch(usersFilter, () => {
  if (usersFilter.value === null) {
    usersFilter.value = []
  }
  if (Array.isArray(usersFilter.value)) {
    filters.value.assignees = usersFilter.value.map((user) => {
      return user.value?.fullName
    })
  }
})

watch(progressionsFilter, () => {
  if (progressionsFilter.value === null) {
    progressionsFilter.value = []
  }
  filters.value.status = progressionsFilter.value.map((progression) => {
    return progression.value
  })
})

watch(filters.value, () => {
  filter.value = Math.random().toString()
})

const updateNb = ref(0)
const lastEvent = ref()
provide('lastEvent', lastEvent)

function onProjectNameClicked() {
  taskDetailsSelected.value = undefined
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
    })
  }
}

let subProjects = project.value.subProjects.sort((a, b) => (a.order > b.order ? 1 : -1))

console.log("subProjects", subProjects)

if (project.value.sections.length !== 0) {
  subProjects.push({
    id: project.value.legacyId,
    name: project.value.name,
    sections: project.value.sections,
    user_project_id: project.value.id,
    legacyId: project.value.legacyId,
  })
}

function onSubProjectTaskModified(event) {
  updateNb.value = updateNb.value + 1
  lastEvent.value = event
}
</script>

<template>
  <div class="no-wrap">
    <h5>{{ project.name }}</h5>
    <Documents v-if="project?.id.length === 36" :documentId="documentId"></Documents>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

.splitter {
  height: calc(100vh - $header_height);
}

h5 {
  font-weight: 450;
  font-size: 16px;
  padding-right: 8px;
}
</style>
