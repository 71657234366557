import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class LoopBlock extends Element implements Serializable<LoopBlock> {
  variableId: number
  label: string

  constructor() {
    super()

    this.variableId = 0
    this.label = ''
  }

  deserialize(input: Object): LoopBlock {
    super.deserialize(input)

    this.variableId = input.variableId
    this.label = input.label

    return this
  }
}
