<script lang="ts" setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useDialogPluginComponent } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
    variables: any
    answers: any
}
const props = defineProps<Props>()
const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

// Define the form data
const form = ref<Record<number, { id: string; value: string }[]>>({});
props.answers.forEach(answer => {
    if (Array.isArray(answer.value)) {
        form.value[answer.variable] = [
            { 'id': 'email', 'value': answer.value.find(option => option.id === 'email')?.value },
            { 'id': 'first_name', 'value': answer.value.find(option => option.id === 'first_name')?.value },
            { 'id': 'last_name', 'value': answer.value.find(option => option.id === 'last_name')?.value }
        ]
    }
})
console.log('form', form.value)

// Submit form function
const submitForm = () => {
    emit('ok', form.value)
};

</script>

<template>
    <q-dialog ref="dialogRef">
    <BaseDialog title="Définir les signataires" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()" :dialog-width="800">
        <template #body>
            <div v-for="(variable, index) in props.variables" :key="index" class="q-ma-sm">
                <q-card flat bordered>
                    <q-card-section>
                    <div class="text-h6 q-mb-md">{{ variable.name }}</div>

                    <q-form class="row q-gutter-md">
                        <q-col cols="12" md="6">
                            <q-input
                            v-model="form[variable.id][2]['value']"
                            label="Nom"
                            required
                            dense
                            class="q-pa-sm"
                            />
                        </q-col>

                        <q-col cols="12" md="6">
                            <q-input
                            v-model="form[variable.id][1]['value']"
                            label="Prénom"
                            required
                            dense
                            class="q-pa-sm"
                            />
                        </q-col>

                        <q-col cols="12" md="6">
                            <q-input
                                v-model="form[variable.id][0]['value']"
                                label="Email"
                                type="email"
                                required
                                dense
                                class="q-pa-sm"
                            />
                        </q-col>
                    </q-form>
                    </q-card-section>
                </q-card>
            </div>

        </template>
        <template #actions>
            <q-btn flat color="primary" label="Enregistrer" @click="submitForm()"></q-btn>
        </template>
    </BaseDialog>
    </q-dialog>
</template>
