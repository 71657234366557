<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { ProjectKey } from '@/models/symbols'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import { Task } from '@/models/projects/Task'
import { UploadedFile } from '@/models/files/UploadedFile'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  task: Task
  selectedFile: UploadedFile
  project: any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const uploadTasks = []

getUploadTasks(props.project.value)

function getUploadTasks(project) {
  project.subProjects.forEach((subProject) => {
    subProject.sections.forEach((section) => {
      section.tasks.forEach((task) => {
        if (task.type == 'document_upload') {
          uploadTasks.push({
            label: subProject.name + ' - ' + task.name,
            value: task,
          })
        }
      })
    })
  })
}

const destinationTask = ref(null)

async function moveFile() {
  try {
    isLoading.value = true
    await userApi.moveUploadTaskFile(props.task.id, destinationTask.value.value.id, props.selectedFile.id)
    destinationTask.value.value.hasFiles = true
    emit('ok')
    isLoading.value = false
    Notify.create({
      message: 'Document déplacé',
      type: 'primary',
    })
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: 'Une erreur est survenue lors du déplacement du document',
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Déplacer un document"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        Sélectionnez la tâche dans laquelle vous voulez déplacer le document
        <br /><br />
        <q-select :options="uploadTasks" v-model="destinationTask"> </q-select>
      </template>
      <template #actions>
        <q-btn flat label="Déplacer" @click="moveFile" :disable="destinationTask == null" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
