<script setup lang="ts">
import { inject, Ref, ref } from 'vue'
import { Document } from '@/models/v2/document/Document'
import { Section } from '@/models/v2/document/Section'
import { Element } from '@/models/v2/document/Element'
import { UndoRedo } from '@/models/v2/document/elements/Action'
import { ParagraphBlock } from '@/models/v2/document/elements/content/block/ParagraphBlock'
import { HeadingBlock } from '@/models/v2/document/elements/content/block/HeadingBlock'
import { TableBlock } from '@/models/v2/document/elements/content/block/TableBlock'
import { ListBlock } from '@/models/v2/document/elements/content/block/ListBlock'
import { ImageBlock } from '@/models/v2/document/elements/content/block/ImageBlock'
import { LoopBlock } from '@/models/v2/document/elements/control/block/LoopBlock'
import { ConditionBlock } from '@/models/v2/document/elements/control/block/ConditionBlock'
import { AlternativeBlock } from '@/models/v2/document/elements/control/block/AlternativeBlock'
import {
  AddParagraphBlock,
  AddHeadingBlock,
  AddTableBlock,
  AddListBlock,
  AddImageBlock,
  AddLoopBlock,
  AddConditionBlock,
  AddAlternativeBlock,
} from '@/models/v2/document/elements/Action'
import draggable from 'vuedraggable'

import BlockElementView from './BlockElementView.vue'

interface Props {
  section: Section
}
const undoRedo: UndoRedo = inject('undoRedo', new UndoRedo())
const props = withDefaults(defineProps<Props>(), {})
const document: Ref<Document> = inject('document', ref(new Document()))

function onClone(event) {
  const element = new Element()
  element.id = `${event.name} ${Math.random()}`
  element.type = event.type

  return element
}

function onChange(event) {
  if (event.added) {
    if (event.added.element.type === 'ParagraphBlock') {
      const paragraph = new ParagraphBlock()
      paragraph.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        content: event.added.element.content,
        elements: event.added.element.elements,
      })

      const addParagraphBlock = new AddParagraphBlock(paragraph, [event.added.newIndex])
      undoRedo.do(addParagraphBlock)
    }

    if (event.added.element.type === 'HeadingBlock') {
      const heading = new HeadingBlock()
      heading.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        content: event.added.element.content,
        size: event.added.element.size,
        elements: event.added.element.elements,
      })

      const addHeadingBlock = new AddHeadingBlock(heading, [event.added.newIndex])
      undoRedo.do(addHeadingBlock)
    }

    if (event.added.element.type === 'TableBlock') {
      const table = new TableBlock()
      table.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        content: event.added.element.content,
        elements: event.added.element.elements,
      })

      const addTableBlock = new AddTableBlock(table, [event.added.newIndex])
      undoRedo.do(addTableBlock)
    }

    if (event.added.element.type === 'ListBlock') {
      const list = new ListBlock()
      list.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        content: event.added.element.content,
        showNumbers: event.added.element.showNumbers,
        elements: event.added.element.elements,
      })

      const addListBlock = new AddListBlock(list, [event.added.newIndex])
      undoRedo.do(addListBlock)
    }

    if (event.added.element.type === 'ImageBlock') {
      const image = new ImageBlock()
      image.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        src: event.added.element.src,
        elements: event.added.element.elements,
      })

      const addImageBlock = new AddImageBlock(image, [event.added.newIndex])
      undoRedo.do(addImageBlock)
    }

    if (event.added.element.type === 'LoopBlock') {
      const loop = new LoopBlock()
      loop.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        elements: event.added.element.elements,
      })

      const addLoopBlock = new AddLoopBlock(loop, [event.added.newIndex])
      undoRedo.do(addLoopBlock)
    }

    if (event.added.element.type === 'ConditionBlock') {
      const condition = new ConditionBlock()
      condition.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        elements: event.added.element.elements,
        conditions: event.added.element.conditions,
      })

      const addConditionBlock = new AddConditionBlock(condition, [event.added.newIndex])
      undoRedo.do(addConditionBlock)
    }

    if (event.added.element.type === 'AlternativeBlock') {
      const alternative = new AlternativeBlock()
      alternative.deserialize({
        id: event.added.element.id,
        type: event.added.element.type,
        label: event.added.element.label,
        elements: event.added.element.elements,
        conditions: event.added.element.conditions,
      })

      const addAlternativeBlock = new AddAlternativeBlock(alternative, [event.added.newIndex])
      undoRedo.do(addAlternativeBlock)
    }
  }
}
</script>

<template>
  <q-card flat bordered>
    <!-- <q-card-section class="q-pa-none q-pl-sm">
          <div class="text-overline">Section</div>
        </q-card-section> -->
    <q-card-section class="q-gutter-sm">
      <draggable
        class="dragArea"
        tag="div"
        item-key="id"
        :list="section.elements"
        :group="{ name: 'g1' }"
        :clone="onClone"
        @change="onChange"
        handle=".handle"
      >
        <template #item="{ element, index }">
          <div>
            <BlockElementView class="q-pb-sm" :element="element"> </BlockElementView>
          </div>
        </template>
      </draggable>
    </q-card-section>
  </q-card>
  <!-- <div v-for="element in section.elements"> -->
  <!-- </div> -->
  <!-- <div class="section-content q-gutter-sm">
          <div v-for="element in section.elements">
            <BlockElementView :element="element"></BlockElementView>
          </div>
        </div> -->
</template>

<style lang="scss" scoped>
.section-view {
  display: block;
  margin-left: 5%;
  width: 90%;
  border: 1px solid;
  margin-bottom: 5px;
}

.section-content {
  margin-left: 5%;
  width: 90%;
}
</style>
