<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'
import { Client } from '@/models/core/Client'
import { useDialogPluginComponent, useQuasar } from 'quasar'
import { useStore } from '@/store/store'

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogCancel } = useDialogPluginComponent()
const store = useStore()
const userApi = store.state.api.getUserApi()

const clientList: Array<Client> = [] // constant list of clients
const clientOptions = ref<Array<{ label: string; value: number }>>([]) // for client selection
getClients()
const selectedClient = ref<Array<{ label: string; value: number }>>() // existing client selected, with name and id
const newClientName = ref('') // name entered for new client
const clientObject = ref<Client>()

const projectList = ref([]) // list of projects in the client (empty if new client)

async function getClients() {
  const clients = await userApi.getClients()
  clients.results.forEach((client) => {
    clientList.push(client);
    clientOptions.value.push({label: client.name, value: client.id })
  })
  console.log("clientOptions", clientOptions)
}

function clientFilterFn(val, update) {
  if (val === '') {
    update(() => {
      clientOptions.value = clientList.map(client => ({ label: client.name, value: client.id }))
    })
    return
  }
  update(() => {
    const needle = val.toLowerCase()
    clientOptions.value = clientList
      .filter(client => client.name.toLowerCase().includes(needle))
    // .filter(v => v.toLowerCase().indexOf(needle) > -1)s
      .map(client => ({ label: client.name, value: client.id }));
  })
}

async function selectOrCreateClient() {
  // Create client if needed
  if (newClientName.value !== '') {
    console.log('Creating client', newClientName.value)
    clientObject.value = await userApi.createClient({
      name: newClientName.value,
     })
  }
  else {
    clientObject.value = await userApi.getClient(selectedClient?.value?.value)
    // let results = await userApi.getClientProjects(1, '', 100, clientObject.value?.id)
    // projectList.value = results.results
    // console.log('projectList', projectList)
  }
  store.state.user.clientId = clientObject.value?.id
  store.state.currentClient = clientObject.value
  emit('ok', clientObject.value?.id)
}

</script>

<template>
  <q-dialog ref="dialogRef">
    <q-card style="width: 700px;">
      <q-card-section class="row items-center">
          <div class="column full-width">
              <div class="row items-center">
                <div class="text-h6">
                  Choisissez un client
                </div>
                <q-space />
                <q-btn icon="close" flat round @click="onDialogCancel()"/>
              </div>
          </div>
        </q-card-section>

      <q-card-section class="column q-pt-none">
        <div class="q-pa-md page-content q-col-gutter-md" style="max-width: 1000px; margin-left: 50px">
          <p>Sélectionnez un client existant</p>
          <q-select v-model="selectedClient" use-input input-debounce="0" label="Nom du client" @filter="clientFilterFn"
            :options="clientOptions" clearable/>
          </div>
      </q-card-section>

      <q-card-section class="column q-pt-none">
        <div class="q-pa-md page-content q-col-gutter-md" style="max-width: 1000px; margin-left: 50px">
          <p>Ou créez un nouveau client</p>
          <q-input v-model="newClientName" label="Nom du client" @click:enter="selectOrCreateClient"/>
        </div>
      </q-card-section>
      <q-card-actions align="right">
          <q-btn flat label="Annuler" color="primary" @click="onDialogCancel()" />
          <q-btn @click="selectOrCreateClient" color="primary" label="Continuer"
            :disable="!selectedClient && !newClientName"/>
        </q-card-actions>
        <q-inner-loading :showing="isLoading" label="Veuillez patienter..." color="primary" />
      </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
p {
  font-size: 16px;
  font-weight:500;
}

ul {
  list-style-type: none;
}

li {
  cursor: pointer;
}

</style>
