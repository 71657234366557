import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class InlineCounter extends Element implements Serializable<InlineCounter> {
  constructor() {
    super()
  }

  deserialize(input: Object): InlineCounter {
    super.deserialize(input)

    return this
  }
}
