<script async setup lang="ts">
import { useRouter } from 'vue-router'
import { ref, computed, watch, nextTick, inject, provide } from 'vue'
import { Notify, Dialog } from 'quasar'
import { removeAccents } from '@/utils/stringUtils'
import { getNode } from '@/components/projects/execution/documents/DocumentsCommon'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import SectionNode from './SectionNode.vue'
import ProjectNode from './ProjectNode.vue'

interface Props {
}

const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()

const expandedNodes = ref([])
const searchText = ref('')
const treeFilter = ref('')
const treeRef = ref(null)
provide("treeRef", treeRef)

const noCodeProjectCatalogResponse = inject("noCodeProjectCatalogResponse")
const noCodeProjectCatalog = inject("noCodeProjectCatalog")

const nodes = inject("nodes")

const platformCatalogResponse = await userApi.getPlatformProjectCatalog()
nodes.value = platformCatalogResponse.catalogJson

console.log("nodes", nodes.value)

// nodes.value = [
//   {
//     id: 'root',
//     label: '',
//     type: 'root',
//     children: [
//       {
//         id: 'genericModels',
//         label: 'Modèles génériques',
//         type: 'genericModels',
//         children: JSON.parse(JSON.stringify(projectCatalog[0].children)),
//       },
//     ]
//   }
// ]

function addSearchText(node) {
  let currentNode = node

  if (node.searchText == undefined) {
    node.searchText = ''
  }
  node.searchText = node.searchText + ' ' + node.label

  while (currentNode != undefined) {
    node.searchText = node.searchText + ' ' + currentNode.label
    currentNode = currentNode.parent
  }
}

function parseNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }
  nodes.forEach((node) => {
    node.parent = parent
    if (node.type === 'root') {
      node.tickable = false
      node.noTick = true
    } else if (node.type === 'genericModels') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 700;'
    } else if (node.type === 'subject') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;'
    } else if (node.type === 'category') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;'
    } else if (node.type === 'subCategory') {
      node.tickable = false
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;' // font-style: italic;"
    } else if ((node.type === 'project') || (node.type === 'personalProject')) {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_source'
      node.iconColor = 'primary'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #4068c8;'
      node.textStyle = 'color: #4068c8;'
    } else if (node.type === 'basicModels') {
      node.tickable = true
      node.noTick = true
      node.textStyle = 'font-size: 16px; font-weight: 500;'
    } else if (node.type === 'basicModel') {
      node.tickable = true
      node.noTick = false
      node.icon = 'o_description'
      node.iconStyle = 'margin-top: -4px; margin-right: 5px; color: #22633f;'
      node.textStyle = 'color: #22633f;'
    }
    addSearchText(node)
    parseNodes(node.children, node)
  })
}

parseNodes(nodes.value, undefined)

function filterNodes(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  const childrenToRemove = []

  nodes.forEach((node) => {
    if (node.type == 'basicModels' || node.type == 'basicModel') {
      childrenToRemove.push(node)
    }

    filterNodes(node.children, node)
  })

  if (parent != undefined) {
    console.log('childrenToRemove', childrenToRemove)
    parent.children = nodes.filter((node) => {
      return !childrenToRemove.includes(node)
    })
  }
}

filterNodes(nodes.value, undefined)

function removeEmptySections(nodes, parent) {
  if (nodes == undefined) {
    return
  }

  nodes.forEach((node) => {
    removeEmptySections(node.children, node)
  })

  console.log(parent, nodes.length)
  if (parent && parent.children.length == 0) {
    if (parent.parent) {
      parent.parent.children = parent.parent.children.filter((node) => {
        return node !== parent
      })
    }
  }
}

removeEmptySections(nodes.value, undefined)


async function updateNonClassifiedNoCodeProjects() {
  noCodeProjectCatalog.value = noCodeProjectCatalogResponse.response.results
  noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
    return !(project.name.startsWith('Modèle sec') || project.name.startsWith('Test'))
  })

  noCodeProjectCatalog.value = noCodeProjectCatalog.value.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  filterNonClassifiedNoCodeProjects()
}

function filterNonClassifiedNoCodeProjects() {
  const projectIds = getProjectIds(nodes.value[0])
  console.log('subprojects - projectIds', projectIds, noCodeProjectCatalog.value)
  noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
    return !projectIds.includes(project.id.toString())
  })
}

function getProjectIds(node) {
  const ids = []
  if (node.children) {
    for (const child of node.children) {
      ids.push(child.id)
      ids.push(...getProjectIds(child))
    }
  }
  return ids
}




updateNonClassifiedNoCodeProjects()

function searchProjects() {
  console.log('searchProjects', searchText.value)

  if (searchText.value === '') {
    // treeRef.value.collapseAll();
    expandedNodes.value = ['root']
    treeRef.value.expandAll()
  } else {
    treeRef.value.expandAll()
  }

  treeFilter.value = searchText.value
}

function resetSearch() {
  searchText.value = ''
  searchProjects()
}

function applyTreeFilter(node, filter) {
  // console.log("applyTreeFilter", node, filter);

  const nodeNameLowerCase = removeAccents(node.searchText.toLowerCase())
  const filterLowerCase = removeAccents(filter.toLowerCase())

  const filterWords = filterLowerCase.split(' ')
  const foundAllWords = filterWords.every((filterWord) => {
    return removeAccents(nodeNameLowerCase).includes(filterWord)
  })

  if (foundAllWords && filter !== '') {
    // expandToNode2(documentTreeNodes, node.id, expandedNodes);
  }

  return foundAllWords
}

const scrollAreaOffset = ref(0)

const scrollAreaStyle = computed(() => {
  console.log('computed', scrollAreaOffset.value)
  let width = '100%'
  if (treeRef.value != null) {
    console.log('treeRef', treeRef.value.$el, treeRef.value.$el.clientWidth)
    width = '100%'
  }
  return {
    width: width,
    height: 'calc(100vh - 220px - ' + scrollAreaOffset.value + 'px)',
    paddingRight: '5px',
    backgroundColor: 'white',
    marginRight: '8px',
  }
})

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  zIndex: 10,
}
</script>

<template>
  <q-input dense outlined v-model="searchText" @keydown.enter.prevent="searchProjects" class="search-input"
    @update:model-value="searchProjects" bg-color="white">
    <template v-slot:prepend>
      <q-icon v-if="searchText === ''" name="search" size="" />
      <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
    </template>
  </q-input>
  <q-scroll-area :style="scrollAreaStyle" :thumb-style="thumbStyle">
    <q-tree dense :nodes="nodes" node-key="id" v-model:expanded="expandedNodes" default-expand-all :filter="treeFilter"
      :filter-method="applyTreeFilter" ref="treeRef" no-results-label="Aucun modèle trouvé">
      <template v-slot:default-header="prop">
        <SectionNode v-if="prop.node.type !== 'project'" :node="prop.node"></SectionNode>
        <ProjectNode v-if="prop.node.type === 'project'" :node="prop.node"></ProjectNode>
      </template>
    </q-tree>
  </q-scroll-area>
</template>

<style lang="scss" scoped>
.node-button {
  visibility: hidden;
}

.node:hover .node-button {
  visibility: visible;
}

.node-label {
  margin-right: 8px;
}

.ul {
  list-style-type: disc;
}

:deep(.q-checkbox__inner) {
  color: rgb(158, 158, 158);

  margin-right: 5px;
  font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
  height: 0px;
  width: 0px;
  overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
  border: 1.3px solid currentColor;
}

.search-input {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.subtitle {
  font-size: 18px;
}

.category-header {
  background-color: #227a68;
  color: white;
  font-size: 18px;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  // cursor: pointer;
}

.category-subheader {
  background-color: #2a5d97;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  margin-top: 10px;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  // cursor: pointer;
}

.project-category {
  color: rgb(61, 61, 63);
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}

.available-project {
  cursor: pointer;
  font-weight: 500;
}

.page-content-header {
  // margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
