import { Element } from './Element'

import { HeadingBlock } from './elements/content/block/HeadingBlock'
import { ParagraphBlock } from './elements/content/block/ParagraphBlock'
import { ListBlock } from './elements/content/block/ListBlock'
import { TableBlock } from './elements/content/block/TableBlock'
import { ImageBlock } from './elements/content/block/ImageBlock'
import { InlineVariable } from './elements/content/inline/InlineVariable'
import { InlineCounter } from './elements/content/inline/InlineCounter'
import { ConditionBlock } from './elements/control/block/ConditionBlock'
import { AlternativeBlock } from './elements/control/block/AlternativeBlock'
import { AlternativeBlockChoice } from './elements/control/block/AlternativeBlockChoice'
import { LoopBlock } from './elements/control/block/LoopBlock'
import { InlineAlternative } from './elements/control/inline/InlineAlternative'

export class ElementUtils {
  static deserializeElement(element): Element {
    // https://stackoverflow.com/questions/15338610/dynamically-loading-a-typescript-class-reflection-for-typescript
    // const elementObject = Object.create(window[element.type].prototype);
    // elementObject.constructor.apply(elementObject, new Array());

    // https://stackoverflow.com/questions/15338610/dynamically-loading-a-typescript-class-reflection-for-typescript
    // var elementObject = eval("new " + element.type + "()");

    var elementObject

    switch (element.type) {
      case 'HeadingBlock':
        elementObject = new HeadingBlock()
        break
      case 'ParagraphBlock':
        elementObject = new ParagraphBlock()
        break
      case 'ListBlock':
        elementObject = new ListBlock()
        break
      case 'TableBlock':
        elementObject = new TableBlock()
        break
      case 'ImageBlock':
        elementObject = new ImageBlock()
        break
      case 'ConditionBlock':
        elementObject = new ConditionBlock()
        break
      case 'AlternativeBlock':
        elementObject = new AlternativeBlock()
        break
      case 'AlternativeBlockChoice':
        elementObject = new AlternativeBlockChoice()
        break
      case 'LoopBlock':
        elementObject = new LoopBlock()
        break
      case 'InlineVariable':
        elementObject = new InlineVariable()
        break
      case 'InlineCounter':
        elementObject = new InlineCounter()
        break
      case 'InlineAlternative':
        elementObject = new InlineAlternative()
        break
      default:
        throw new Error('Unhandled block type in deserialize: ' + element.type)
    }

    elementObject.deserialize(element)

    return elementObject
  }
}
