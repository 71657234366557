<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue: string | number | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (value: any | undefined) => {
    emit('update:modelValue', value)
  },
})
</script>
<template>
  <q-input outlined v-bind="$attrs" v-model="value" /> <!-- type="number" -->
</template>
<style lang="scss" scoped></style>
