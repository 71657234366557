<script setup lang="ts">
import { plainToInstance, Expose } from 'class-transformer'

import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import { ref } from 'vue'
import { Notify } from 'quasar'
import UserApi from '@/services/api/core/UserApi'

const store = useStore()
store.commit('setPageTitle', 'PoC v2 - WebSocket')

const userApi: UserApi = useApi()

fetch(userApi.api.BASE_URL + 'api/v1/notifications/').then((response) => {
  if (response.ok) {
    response.json().then((data) => {
      notifications.value = data
    })
  }
})
const text = ref('')
const hasNotification = ref(false)
const notifications = ref([])

const webSocketBaceUrl = userApi.api.BASE_URL.replace('http', 'ws')

const chatSocket = new WebSocket(webSocketBaceUrl + `ws/chat/`)
const notificationSocket = new WebSocket(webSocketBaceUrl + `ws/notification/`)
notificationSocket.onmessage = function (e) {
  const data = JSON.parse(e.data)
  notifications.value.push({ content: data.message })
}
chatSocket.onmessage = function (e) {
  const data = JSON.parse(e.data)
  Notify.create({
    message: data.message,
    type: 'primary',
  })
  hasNotification.value = true
}

chatSocket.onclose = function (e) {
  console.error('Chat socket closed unexpectedly')
}

function send() {
  const content = text.value

  // chatSocket.send(JSON.stringify({
  //   message: content
  // }));

  notificationSocket.send(
    JSON.stringify({
      message: content,
    }),
  )

  // text.value = '';
}

let userJson = {
  id: 1,
  firstName: 'Cedric',
  lastName: 'Dinont',
}

class User {
  id: number
  firstName: string
  lastName: string

  @Expose()
  get name() {
    return this.firstName + ' ' + this.lastName
  }
}

let user: User = plainToInstance(User, userJson)

const desktopApplicationSocket = new WebSocket(webSocketBaceUrl + `ws/desktop_application/`)
desktopApplicationSocket.onmessage = function (e) {
  const data = JSON.parse(e.data)
}

function launchWord() {
  desktopApplicationSocket.send(
    JSON.stringify({
      type: 'command',
      message: {
        command: 'open_word',
        document_download_url: 'download_url',
        document_upload_url: 'upload url',
      },
    }),
  )
}
</script>

<template>
  <q-page padding class="page">
    <q-btn @click="launchWord" label="Launch Word"></q-btn>
    <input type="text" v-model="text" />
    <button @click="send(text)">send</button>
    <br />
    <q-btn round icon="notifications">
      <q-badge floating color="red" rounded v-if="hasNotification" />
    </q-btn>
    <br />
    notifications: {{ notifications }} |
    <ul>
      <li v-for="notification in notifications">{{ notification.content }}</li>
    </ul>
  </q-page>
</template>
