import Main from './Main.vue'
import Environment from './Environment.vue'
import Client from './Client.vue'

const routes = [
  {
    name: 'support/main',
    component: Main,
    path: '/support',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'support/environment',
    component: Environment,
    path: '/support/environment',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'support/client',
    component: Client,
    path: '/support/client',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
