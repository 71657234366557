<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import { Project } from '@/models/projects/Project'
import ProjectPermissionsDialogContent from './ProjectPermissionsDialogContent.vue'

interface Props {
  project: Project
}
const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

function save() {
  console.log('save')
  // dialogRef.value.hide()
  // onDialogOK()
}

function close() {
  console.log('close')
  emit('ok')
}
</script>

<template>
  <q-dialog ref="dialogRef" full-width full-height>
    <BaseDialog class="" :show-cancel-button="false" title="Permissions du dossier" :is-dialog-loading="isLoading"
      @on-dialog-cancel="close" @hide="close">
      <template #body>
        <Suspense>
          <ProjectPermissionsDialogContent :project="project"></ProjectPermissionsDialogContent>
          <template #fallback>
            Chargement en cours...
            <!-- <div class="relative-position" style="width: 100%; height: 100%;">
              <q-inner-loading showing size="96px" color="primary" label-style="font-size: 1.1em">
                <q-spinner-gears size="50px" color="primary" />
              </q-inner-loading>
            </div> -->
          </template>
        </Suspense>
      </template>
      <template #actions>
        <!-- <q-btn flat label="Enregistrer" color="primary" v-close-popup @click="save" /> -->
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
