import TaskPane from '../TaskPane.vue'
import MessageComposeTaskPane from '../MessageComposeTaskPane.vue'

const routes = [
  {
    name: 'outlook-add-in/taskpane',
    component: TaskPane,
    path: '/outlook-add-in/taskpane',
  },
  {
    name: 'outlook-add-in/messagecomposetaskpane',
    component: MessageComposeTaskPane,
    path: '/outlook-add-in/messagecomposetaskpane',
  },
]

export { routes }
