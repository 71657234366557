<script setup lang="ts">
import { ref, provide } from 'vue'
import { useApi } from '@/store/useAppStore'
import { QTableProps } from 'quasar'
import BaseTable from '../common/Table/BaseTable.vue'
import ItemActionMenuItem from '../common/Table/ItemActionMenuItem.vue'
import VisioAvailabilityForm from './VisioAvailabilityForm.vue'

const userApi = useApi()
const clientId = ref('')
const selectedRows = ref([])

const columns: QTableProps['columns'] = [
  {
    name: 'day',
    label: 'Jour',
    field: 'username',
    sortable: true,
    align: 'left',
    format: (day, row) => row.day.label,
  }, // format_day(row.day)
  {
    name: 'start_time',
    label: 'Heure début',
    field: 'start_time',
    sortable: true,
    align: 'left',
  },
  {
    name: 'end_time',
    label: 'Heure fin',
    field: 'end_time',
    sortable: true,
    align: 'left',
  },
]

const showForm = ref(false)
provide('showForm', showForm)
const mode = ref('ADD')
provide('mode', mode)

const rows = ref([
  {
    id: 1,
    day: {
      label: 'Lundi',
      value: 1,
    },
    start_time: '10:00',
    end_time: '12:00',
  },
  {
    id: 2,
    day: {
      label: 'Mercredi',
      value: 3,
    },
    start_time: '14:00',
    end_time: '16:00',
  },
])
provide('rows', rows)

// selected_rows: computed(() => store.state.selectedVisioAvailabilitySlots),

function refreshUsers() {
  userApi.getPortalUsers(clientId.value).then((response) => {
    rows.value = response.results
  })
}

function onAddClick() {
  mode.value = 'ADD'
  showForm.value = true
}

function onAddItemClick() {
  refreshUsers()
}

function onEditClick() {
  mode.value = 'EDIT'
  showForm.value = true
}

function onEditItemClick() {
  refreshUsers()
}

function onDeleteClick() {
  if (confirm('Voulez-vous vraiment supprimer ce créneau ?')) {
    userApi.deletePortalUser(selectedRows.value[0].id, clientId.value).then((result) => {
      refreshUsers()
    })
  }
}

function formatDay(day) {
  if (day == 1) {
    return 'Lundi'
  }
  if (day == 2) {
    return 'Mardi'
  }
  if (day == 3) {
    return 'Mercredi'
  }
  if (day == 4) {
    return 'Jeudi'
  }
  if (day == 5) {
    return 'Vendredi'
  }
  if (day == 6) {
    return 'Samedi'
  }
  if (day == 7) {
    return 'Dimanche'
  }
}

function onRowSelected(rows) {
  selectedRows.value = { ...rows }
}
</script>

<template>
  <BaseTable
    add-button-label="Ajouter un créneau"
    selection="single"
    :columns="columns"
    :rows="rows"
    @add-item-click="onAddClick"
    @on-row-selected="onRowSelected"
  >
    <template v-slot:action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
    </template>
  </BaseTable>

  <VisioAvailabilityForm
    add-title="Nouveau créneau"
    edit-title="Modifier le créneau"
    add-confirm-label="Ajouter"
    edit-confirm-label="Modifier"
    :client-id="clientId"
    :selected-row="selectedRows[0]"
    @add-item-click="onAddItemClick"
    @edit-item-click="onEditItemClick"
  ></VisioAvailabilityForm>
</template>
