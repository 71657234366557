<script setup lang="ts">
import { inject, ref, Ref, onMounted } from 'vue'
import { useApi } from '@/store/useAppStore'
import BaseTable from '@/components/common/Table/BaseTable.vue'

const client = inject('client')
const step = inject('step')
const project = inject('project')
const documentTreeNodes = inject('documentTreeNodes')

const userApi = useApi()
const filter = 'client'
const tFilter = ref('')

const columns = [
  {
    name: 'name',
    label: 'Nom du dossier',
    field: 'name',
    align: 'left',
    format: formatProjectName,
  },
]

function formatProjectName(val, row) {
  // if (row.lopMatterId) {
  //   return row.name + " (LOP " + row.lopMatterId + ")";
  // }

  return row.name
}

const selectedRows = ref([])
const isTableLoading = ref(false)
const clientId: Ref<string | undefined> = ref(client.value.id)
const rows = ref([])

const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 100,
})

const rowsPerPageOptions = [5, 10, 20, 50]

onMounted(() => {
  onRequest({
    pagination: pagination.value,
    filter: null,
  })
})

async function refreshProjects() {
  const { page, rowsPerPage } = pagination.value
  isTableLoading.value = true
  let response
  if (filter === 'user') {
    response = await userApi.getAllUserProjects(page, tFilter.value, rowsPerPage)
  } else {
    response = await userApi.getClientProjects(page, tFilter.value, rowsPerPage, client.value.id)
  }
  rows.value = response.results
  isTableLoading.value = false
}

async function onRequest(props) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination
  const tableFilter = props.filter

  isTableLoading.value = true
  let response: any

  if (filter === 'user') {
    response = await userApi.getAllUserProjects(page, tableFilter, rowsPerPage)
  } else {
    response = await userApi.getClientProjects(page, tableFilter, rowsPerPage, client.value.id)
  }

  rows.value = response.results
  pagination.value.rowsNumber = response.total
  pagination.value.page = page
  pagination.value.rowsPerPage = rowsPerPage
  pagination.value.sortBy = sortBy
  pagination.value.descending = descending

  isTableLoading.value = false
}

function onRowClick(event, row) {
  console.log('project', row)
  project.value = row
  documentTreeNodes.value = [
    {
      id: 0,
      type: 1,
      label: 'Racine',
      children: [],
    },
  ]
  step.value = 2
}

function filterUpdated(emit) {
  tFilter.value = emit
}
</script>

<template>
  <q-page class="page-mobile">
    <!-- <h5>Dossiers</h5> -->
    <!-- <h5 class="h5-mobile">Client : {{ client.name }}</h5> -->
    <BaseTable
      dense
      flat
      grid
      :enableActions="false"
      :enableAdd="false"
      table-label="project"
      name="project-table"
      v-model:pagination="pagination"
      :columns="columns"
      :rows="rows"
      :is-loading="isTableLoading"
      :rows-per-page-options="rowsPerPageOptions"
      @request="onRequest"
      @filter-updated="filterUpdated"
      class="table-mobile"
      :isFilterInput="false"
    >
      <template v-slot:item="item">
        <div
          class="q-pa-xs col-xs-12 col-sm-6 col-md-4 cursor-pointer"
          @click="($event) => onRowClick($event, item.item.row)"
        >
          {{ item.item.row.name }}
          <q-separator />
        </div>
      </template>
    </BaseTable>
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

.h5-mobile {
  font-weight: 450;
  font-size: 16px;
  padding-right: 8px;
  margin-bottom: 0px;
}
</style>
