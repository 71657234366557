<script lang="ts" setup>
import { ref, Ref } from 'vue'
import { useDialogPluginComponent, Notify } from 'quasar'
import { GeneratedDocument } from '@/models/files/GeneratedDocument'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseInput from '@/components/base/BaseInput.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  node: any
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const newDocumentName: Ref<string> = ref(props.node.name)
const isLoading: Ref<boolean> = ref(false)

function ok() {
  try {
    isLoading.value = true
    isLoading.value = false
    // Notify.create({
    //   message: "Fichier déverrouillé",
    //   type: "primary",
    // });
    emit('ok', newDocumentName.value)
  } catch (e) {
    dialogRef.value?.hide()
    isLoading.value = false
    Notify.create({
      message: "Une erreur est survenue lors du déverrouillage de l'élément",
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Erreur"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
      :showCancelButton="false"
    >
      <template #body>
        Le fichier est verrouillé.<br /><br />
        Il est préférable de demander à l'éditeur actuel de sauvegarder son travail et de fermer le fichier avant de le
        l'éditer.<br /><br />
        Vous pouvez également le déverrouiller avec le menu "Déverrouiller le fichier" pour récupérer les droits
        d'édition, mais l'éditeur actuel risque de perdre ses modifications en cours.
      </template>
      <template #actions>
        <q-btn flat label="Annuler" @click="ok()" color="negative" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
