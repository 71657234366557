<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from '@/store/store'
import draggable from 'vuedraggable'
const store = useStore()
store.commit('setPageTitle', 'PoC v2 - Drag and Drop')

const list = ref([
  {
    id: 1,
    type: 'task',
    label: 'Element 1',
  },
  {
    id: 2,
    type: 'task',
    label: 'Element 2',
  },
  {
    id: 3,
    type: 'task',
    label: 'Element 3',
  },
  {
    id: 4,
    type: 'task',
    label: 'Element 4',
  },
])

const list3 = ref([
  {
    id: 10,
    type: 'section',
    label: '1 - Section 1',
    elements: [
      {
        id: 1,
        type: 'section',
        label: '1.1 - Sous-section 3',
        elements: [
          {
            id: 2,
            type: 'task',
            label: 'Sous-Element 2',
          },
          {
            id: 6,
            type: 'task',
            label: 'Sous-Element 5',
          },
        ],
      },
      {
        id: 9,
        type: 'section',
        label: '1.2 - Sous-section 312',
        elements: [
          {
            id: 22,
            type: 'task',
            label: 'Sous-Element 12',
          },
        ],
      },
    ],
  },
  {
    id: 11,
    type: 'section',
    label: '2 - Section 2',
    elements: [
      {
        id: 3,
        type: 'task',
        label: 'Element 3',
      },
      {
        id: 4,
        type: 'task',
        label: 'Element 4',
      },
    ],
  },
])
</script>

<template>
  <q-page padding class="page">
    <q-list bordered separator class="q-mt-lg q-mb-lg">
      <draggable :list="list" ghost-class="ghost">
        <template #item="{ element, index }">
          <q-item clickable v-ripple>
            <q-item-section> Single line item {{ element.label }} - {{ index }} </q-item-section>
          </q-item>
        </template>
      </draggable>
    </q-list>

    <q-list bordered class="rounded-borders">
      <draggable :list="list3" ghost-class="ghost" :group="{ name: 'g2' }">
        <template #item="{ element, index }">
          <q-expansion-item
            switch-toggle-side
            expand-icon-toggle
            expand-separator
            :label="element.label"
            caption="Info"
          >
            <draggable class="q-gutter-sm q-pa-sm" :list="element.elements" ghost-class="ghost" :group="{ name: 'g2' }">
              <template #item="{ element, index }">
                <q-card flat bordered>
                  <q-expansion-item
                    switch-toggle-side
                    expand-icon-toggle
                    expand-separator
                    :label="element.label"
                    caption="Info"
                    v-if="element.type === 'section'"
                  >
                    <draggable
                      class="drag-area q-gutter-sm q-pa-sm"
                      :list="element.elements"
                      ghost-class="ghost"
                      :group="{ name: 'g2' }"
                    >
                      <template #item="{ element, index }">
                        <q-card flat bordered class="q-mb-sm">
                          <q-list separator>
                            <q-item clickable v-ripple>
                              <q-item-section avatar>
                                <q-icon color="primary" name="info_outline" />
                              </q-item-section>
                              <q-item-section>
                                {{ element.label }} -
                                {{ index }}
                              </q-item-section>
                            </q-item>
                          </q-list>
                        </q-card>
                      </template>
                    </draggable>
                  </q-expansion-item>
                  <div v-if="element.type === 'task'">
                    <q-list separator>
                      <q-item clickable v-ripple>
                        <q-item-section avatar>
                          <q-icon color="primary" name="info_outline" />
                        </q-item-section>
                        <q-item-section>
                          {{ element.label }} -
                          {{ index }}
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </q-card>
              </template>
            </draggable>
          </q-expansion-item>
        </template>
      </draggable>
    </q-list>
  </q-page>
</template>
<style lang="scss" scoped>
.drag-area {
  min-height: 50px;
}

.sub-section {
  margin-left: 10px;
}
</style>
