<script setup lang="ts">
import { ref } from 'vue'
import { useDialogPluginComponent, Notify, QTableProps } from 'quasar'
import { globalPermissionGroups } from './global_permission_groups.ts'
import { platformPermissionGroups } from './platform_permission_groups.ts'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'

interface Props {
  clientId?: string | undefined
  group: any
}
const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const authenticatedUser: AuthenticatedUser = useAuthenticatedUser()
const permissionGroups = platformPermissionGroups // globalPermissionGroups

const columns: QTableProps['columns'] = [
  {
    name: 'name',
    label: 'Groupe',
    field: 'name',
    align: 'center',
    headerStyle: 'min-width: 150px',
  },
]

permissionGroups.forEach((permissionGroup) => {
  columns.push({
    name: permissionGroup.name,
    label: permissionGroup.label,
    field: permissionGroup.name,
    align: 'center',
    headerStyle: 'min-width: 150px',
  })
})

const rows: any = ref([])

function beforeShow() {
  updateRows()
}

console.log("props.group", props.group)

async function updateRows() {
  console.log("updating rows")
  if (props.group == undefined) {
    console.log("group is undefined")
    return
  }

  const groupGlobalPermissionsResponse = await userApi.getObjectGroupPermissions(
    authenticatedUser.environment.id,
    'Environment',
    props.group.id,
  )
  console.log("groupGlobalPermissionsResponse", groupGlobalPermissionsResponse)
  const groupGlobalPermissions = groupGlobalPermissionsResponse.permissions

  console.log("groupGlobalPermissions", groupGlobalPermissions)

  rows.value = [
    {
      id: '1',
      name: props.group?.name,
      permissionGroups: [],
    },
  ]

  permissionGroups.forEach((permissionGroup) => {
    const permissionValues: any = []

    permissionGroup.permissions.forEach((permission) => {
      permissionValues.push({
        name: permission.name,
        value: groupGlobalPermissions.includes(permission.name),
      })
    })

    rows.value[0].permissionGroups.push({
      name: permissionGroup.name,
      permissionValues: permissionValues,
    })
  })

  console.log("rows.value", rows.value)
}

async function updatePermissions() {
  try {
    const addPermissions = []
    const removePermissions = []

    rows.value[0].permissionGroups.forEach((permissionGroup) => {
      permissionGroup.permissionValues.forEach((permissionValue) => {
        if (permissionValue.value) {
          addPermissions.push(permissionValue.name)
        } else {
          removePermissions.push(permissionValue.name)
        }
      })
    })

    await userApi.updateObjectGroupsPermissions(
      authenticatedUser.environment.id,
      'Environment',
      [props.group.id],
      addPermissions,
      removePermissions,
    )
    Notify.create({
      message: `Permissions modifiées avec succès`,
      type: 'primary',
    })
    emit('ok')
  } catch (e) {
    Notify.create({
      message: `Une erreur est survenue`,
      type: 'negative',
    })
  }
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow" full-width>
    <BaseDialog
      :title="`Permissions du groupe ${group?.name}`"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <div class="col q-pa-none">
          <q-table
            :rows="rows"
            :columns="columns"
            row-key="id"
            separator="cell"
            class="sticky-header-table sticky-header-column-table"
            hide-pagination
            hide-bottom
            flat
            bordered
          >
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th v-for="(col, index) in props.cols" :key="col.name" :props="props" class="cell">
                  <span v-if="index == 0">{{ col.label }}</span>
                  <div v-if="index != 0" class="column">
                    <div class="col-12 q-pa-sm">{{ col.label }}</div>
                    <q-separator />
                    <div class="row justify-evenly col-12">
                      <span v-for="permission in permissionGroups[index - 1].permissions">
                        <span class="q-pa-sm">{{ permission.label }}</span>
                        <q-separator vertical />
                      </span>
                    </div>
                  </div>
                </q-th>
              </q-tr>
            </template>

            <template #body="props">
              <q-tr :props="props" no-hover>
                <q-td key="name" :props="props">{{ props.row.name }}</q-td>
                <q-td
                  v-for="permissionGroup in props.row.permissionGroups"
                  :key="permissionGroup.name"
                  :props="props"
                  class="cell"
                >
                  <div class="row justify-around">
                    <q-checkbox
                      v-for="permission in permissionGroup.permissionValues"
                      v-model="permission.value"
                      color="primary"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </template>
      <template #actions>
        <q-btn flat label="Enregistrer" color="primary" @click="updatePermissions" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.cell {
  padding: 0px !important;
}

.tree {
  overflow: scroll;
}

.sticky-header-table {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #e4e4e4;
  }
}

.sticky-header-column-table {
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%;

  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: #e4e4e4 !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
