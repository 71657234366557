<script setup lang="ts">
import { ref, Ref, watch, inject, computed } from 'vue'
import { useApi } from '@/store/useAppStore'
import { useStore } from '@/store/store'

interface Props {
  addTitle: string
  editTitle: string
  addConfirmLabel: string
  editConfirmLabel: string
  clientId: string
  selectedRow: any
}
const props = withDefaults(defineProps<Props>(), {})

const store = useStore()
const userApi = useApi()

const visible: Ref<boolean> = inject('showForm', ref(false))
const mode = inject('mode') as Ref<String>

const day = ref('Lundi')
const startTime = ref('09:00')
const endTime = ref('11:00')

const dayOptions = [
  {
    label: 'Lundi',
    value: 1,
  },
  {
    label: 'Mardi',
    value: 2,
  },
  {
    label: 'Mercredi',
    value: 3,
  },
  {
    label: 'Jeudi',
    value: 4,
  },
  {
    label: 'Vendredi',
    value: 5,
  },
  {
    label: 'Samedi',
    value: 6,
  },
  {
    label: 'Dimanche',
    value: 7,
  },
]

watch(visible, (new_value, old_value) => {
  if (mode.value == 'ADD') {
    day.value = 'Lundi'
    startTime.value = '09:00'
    endTime.value = '11:00'
  } else {
    const selectedItem = props.selectedRow
    if (selectedItem == undefined) {
      return
    }
    day.value = selectedItem.day
    startTime.value = selectedItem.start_time
    endTime.value = selectedItem.end_time
  }
})

const title = computed(() => {
  return mode.value === 'ADD' ? props.addTitle : props.editTitle
})

const confirmLabel = computed(() => {
  return mode.value === 'ADD' ? props.addConfirmLabel : props.editConfirmLabel
})

function createItem() {
  //   this.userApi.createPortalGroup({
  //       "name": this.name,
  //   }, this.clientId).then((result) => {
  //     this.$emit('add_item_click');
  //   })
}

function updateItem() {
  const selectedItem = props.selectedRow
  selectedItem.day = day.value
  selectedItem.start_time = startTime.value
  selectedItem.end_time = endTime.value
}

function onConfirmClick() {
  if (mode.value === 'ADD') {
    createItem()
  } else {
    updateItem()
  }
}
</script>

<template>
  <!-- <BaseDialog :show-dialog="visible" @before-hide="visible = false">
    <template #title>{{ title }}</template>
    <template #body>
      <q-select
        outlined
        v-model="day"
        label="Jour"
        autofocus
        @keyup.enter="prompt = false"
        :options="dayOptions"
        class="q-mb-md"
      />

      <q-input
        label="Heure de début"
        outlined
        v-model="startTime"
        mask="time"
        :rules="['time']"
      >
        <template v-slot:append>
          <q-icon name="access_time" class="cursor-pointer">
            <q-popup-proxy transition-show="scale" transition-hide="scale">
              <q-time v-model="startTime">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-time>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-input
        label="Heure de fin"
        outlined
        v-model="endTime"
        mask="time"
        :rules="['time']"
      >
        <template v-slot:append>
          <q-icon name="access_time" class="cursor-pointer">
            <q-popup-proxy transition-show="scale" transition-hide="scale">
              <q-time v-model="endTime">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-time>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </template>
    <template #actions>
      <q-btn
        flat
        color="primary"
        :label="confirmLabel"
        v-close-popup
        @click="onConfirmClick"
      />
    </template>
  </BaseDialog> -->
</template>
