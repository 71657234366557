import { Serializable } from '../../../Serializable'
import { Element } from '../../../Element'

export class TableBlock extends Element implements Serializable<TableBlock> {
  content: string

  constructor() {
    super()
    this.content = ''
  }

  deserialize(input: Object): TableBlock {
    super.deserialize(input)

    this.content = input.content

    return this
  }
}
