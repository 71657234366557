import { Database } from '../Database'
import { Node } from '../Node'
import { NodeType } from '../NodeType'
import { FieldType } from '../FieldType'

let demoAtDatabase: Database = {
  clientId: 'client_id',
  objectTypes: [
    {
      name: 'Personne physique',
      fields: [
        {
          type: FieldType.String,
          label: 'Civilité',
        },
        {
          type: FieldType.String,
          label: 'Prénom',
        },
        {
          type: FieldType.String,
          label: 'Nom',
        },
        {
          type: FieldType.String,
          label: 'Email',
        },
        {
          type: FieldType.String,
          label: 'Adresse',
        },
        {
          type: FieldType.String,
          label: 'Date de naissance',
        },
        {
          type: FieldType.String,
          label: 'Lieu de naissance',
        },
        {
          type: FieldType.String,
          label: 'Profession',
        },
        {
          type: FieldType.String,
          label: 'Nationalité',
        },
        {
          type: FieldType.String,
          label: 'Numéro de sécurité sociale',
        },
      ],
      onChange: function (object: Node): void {
        let firstName = ''
        let lastName = ''

        if (object.fields[1].value != undefined && object.fields[1].value != '') {
          firstName = object.fields[1].value
        }
        if (object.fields[2].value != undefined && object.fields[2].value != '') {
          lastName = object.fields[2].value
        }

        if (firstName != '' && lastName != '') {
          object.label = firstName + ' ' + lastName
        }
        if (firstName != '' && lastName == '') {
          object.label = firstName
        }
        if (firstName == '' && lastName != '') {
          object.label = lastName
        }
      },
    },
    {
      name: 'Entité',
      fields: [
        {
          type: FieldType.String,
          label: 'Dénomination sociale',
        },
        {
          type: FieldType.String,
          label: 'Forme juridique',
        },
        {
          type: FieldType.String,
          label: 'SIRET',
        },
        {
          type: FieldType.String,
          label: 'Adresse',
        },
      ],
      onChange: function (object: Node): void {
        object.label = object.fields[0].value
      },
    },
    {
      name: 'CPAM',
      fields: [
        {
          type: FieldType.String,
          label: 'Nom',
        },
        {
          type: FieldType.String,
          label: 'Adresse',
        },
      ],
      onChange: function (object: Node): void {
        object.label = object.fields[0].value
      },
    },
  ],
  nodes: [
    {
      id: 'Organisation',
      label: 'Organisation',
      type: NodeType.Folder,
      fields: [],
      children: [
        {
          id: 'Carrefour',
          label: 'Carrefour',
          type: NodeType.Object,
          objectType: 'Entité',
          fields: [
            {
              type: FieldType.String,
              label: 'Dénomination sociale',
              value: 'Come On Law',
            },
            {
              type: FieldType.String,
              label: 'Forme juridique',
              value: 'SAS',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '7 avenue Ingres 75016 PARIS',
            },
          ],
          children: [
            {
              id: 'Etablissements',
              label: 'Etablissements',
              type: NodeType.Folder,
              fields: [],
              children: [
                {
                  id: 'Carrefour Lomme',
                  label: 'Carrefour Lomme',
                  type: NodeType.Object,
                  objectType: 'Entité',
                  fields: [
                    {
                      type: FieldType.String,
                      label: 'Dénomination sociale',
                      value: 'Carrefour Lomme',
                    },
                    {
                      type: FieldType.String,
                      label: 'Forme juridique',
                      value: 'SAS',
                    },
                    {
                      type: FieldType.String,
                      label: 'SIRET',
                      value: '45132133500536',
                    },
                    {
                      type: FieldType.String,
                      label: 'Adresse',
                      value: '150 rue du Grand But 59160 LOMME',
                    },
                  ],
                  children: [
                    {
                      id: 'Salariés',
                      label: 'Salariés',
                      type: NodeType.Folder,
                      fields: [],
                      children: [
                        {
                          id: 'Jean Dupont',
                          label: 'Jean Dupont',
                          type: NodeType.Object,
                          objectType: 'Personne physique',
                          fields: [
                            {
                              type: FieldType.String,
                              label: 'Civilité',
                              value: 'Monsieur',
                            },
                            {
                              type: FieldType.String,
                              label: 'Prénom',
                              value: 'Jean',
                            },
                            {
                              type: FieldType.String,
                              label: 'Nom',
                              value: 'Dupont',
                            },
                            {
                              type: FieldType.String,
                              label: 'Adresse',
                              value: '7 avenue Ingres 75016 PARIS',
                            },
                          ],
                          children: [],
                        },
                        {
                          id: 'Cédric Dinont',
                          label: 'Cédric Dinont',
                          type: NodeType.Object,
                          objectType: 'Personne physique',
                          fields: [
                            {
                              type: FieldType.String,
                              label: 'Civilité',
                              value: 'Monsieur',
                            },
                            {
                              type: FieldType.String,
                              label: 'Prénom',
                              value: 'Cédric',
                            },
                            {
                              type: FieldType.String,
                              label: 'Nom',
                              value: 'Dinont',
                            },
                            {
                              type: FieldType.String,
                              label: 'Adresse',
                              value: '149 Bis rue Poincaré 59160 CAPINGHEM',
                            },
                          ],
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 'CPAM',
      label: 'CPAM',
      type: NodeType.Folder,
      fields: [],
      children: [
        {
          id: 'CPAM Armentières',
          label: 'CPAM Armentières',
          type: NodeType.Object,
          objectType: 'CPAM',
          fields: [
            {
              type: FieldType.String,
              label: 'Nom',
              value: 'CPAM Armentières',
            },
            {
              type: FieldType.String,
              label: 'Adresse',
              value: '6 Rue des Nieulles 59280 Armentières',
            },
          ],
          children: [],
        },
      ],
    },
  ],
}

export { demoAtDatabase }
