<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import Editor from '@tinymce/tinymce-vue'
import { ref } from 'vue'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const newChatMessage = ref("")
</script>

<template>
  <div style="">
    <div style="font-size: 16px; font-weight: 500; margin-bottom: 8px">
      {{ field.name }}
    </div>
    <editor api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44" :init="{
      height: 250,
      menubar: false,

      statusbar: true,
      elementpath: false,
      branding: false,
      resize: true,

      plugins: 'lists advlist link searchreplace',
      toolbar: 'undo redo | bold italic underline | align | bullist numlist | removeformat',
    }" v-model="newChatMessage" />
  </div>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
