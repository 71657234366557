<script setup lang="ts">
import { useStore } from '@/store/store'

import DatabaseLayout from '@/components/common/database/DatabaseLayout.vue'

const store = useStore()
store.commit('setPageTitle', 'Dossiers permanents cabinet')
</script>

<template>
  <q-page padding class="page">
    <Suspense>
      <DatabaseLayout environment="true" />
      <template #fallback>
        <q-inner-loading showing color="primary" label="Chargement en cours..." />
      </template>
    </Suspense>
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

.page {
  padding: 0px;
}
</style>
