<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const text = ref('')

const router = useRouter()
const route = useRoute()

function submit() {
  router.push({
    name: 'dashboard/search',
    params: {
      // text: text.value,
    },
    query: { q: text.value },
  })
}
</script>

<template>
  <q-input dense outlined v-model="text" @keydown.enter.prevent="submit" class="input">
    <template v-slot:prepend>
      <q-icon v-if="text === ''" name="search" size="" />
      <q-icon v-else name="clear" class="cursor-pointer" @click="text = ''" />
    </template>
  </q-input>
</template>

<style lang="scss" scoped>
label {
}

.input {
  display: inline-flex;
  vertical-align: middle;
  padding-right: 0px;
  font-size: 14px;
}

:deep(.q-field__control) {
  height: 34px;
}

:deep(.q-field__marginal) {
  height: 34px;
  font-size: 22px;
}

:deep(.q-field__prepend) {
  padding-left: 2px;
}

:deep(.q-field__control) {
  padding-left: 2px;
  padding-right: 8px;
}
</style>
