<script lang="ts" setup>
import { Notify } from 'quasar'
import { ref, Ref, watch } from 'vue'

interface Props {
  modelValue: File[]
  showImportButton?: boolean
  rejectedMessage?: string
  clearable?: boolean
  hintMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
  showImportButton: true,
  rejectedMessage: 'Le fichier dépasse la taille limite imposée (100Mo)',
  clearable: true,
  hintMessage: '100Mo max.',
})

const emit = defineEmits(['update:modelValue', 'uploadFile'])

const files: Ref<Array<File>> = ref([])

function addFile() {
  emit('uploadFile')
}

async function updateModelValue() {
  emit('update:modelValue', files.value)
}

function onRejected(rejectedEntries) {
  Notify.create({
    message: props.rejectedMessage,
    type: 'negative',
  })
}

watch(
  () => props.modelValue,
  (n, o) => {
    if (props.modelValue?.length === 0) {
      files.value = []
    }
  },
)
</script>

<template>
  <div class="row items-center">
    <q-file
      filled
      append
      dense
      bottom-slots
      v-model="files"
      color="primary"
      max-file-size="104857600"
      v-bind="$attrs"
      class="col q-pa-none"
      :clearable="clearable"
      @rejected="onRejected"
      @update:model-value="updateModelValue"
    >
      <template v-slot:prepend>
        <q-icon name="attach_file" :color="modelValue?.length === 0 || modelValue === null ? '' : 'primary'" />
      </template>
      <template v-slot:hint>{{ hintMessage }}</template>
    </q-file>
    <q-btn
      v-if="showImportButton"
      @click="addFile()"
      :disable="modelValue?.length === 0 || modelValue === null"
      color="primary"
      class="q-ml-sm"
      >Importer</q-btn
    >
  </div>
</template>
<style lang="scss" scoped></style>
