<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import draggable from 'vuedraggable'
import EntityField from './EntityField.vue'

const unsavedChanges = inject("unsavedChanges")
const selectedPage = inject("selectedPage")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function getCardClass(type) {
    return "drag-cursor"
}

function onChange() {
    console.log("onChange")
    unsavedChanges.value = true
}

function onClone() {
    console.log("onClone")
    unsavedChanges.value = true
}

console.log("ColumnsField", props.element)
</script>


<template>
    <div class="row" style="border: 1px solid; border-color: grey; margin-top: 8px;">
        <div class="col">
            <div style="margin: 8px; font-weight: 500;">
                Colonne 1
            </div>
            <q-list dense class="draggable-area" style="margin: 8px;">
                <draggable :list="element.columns[0].fields" :group="{ name: 'g1', pull: 'clone', put: true }"
                    ghost-class="ghost" item-key="id" element="q-gutter-sm" :clone="onClone" @change="onChange"
                    class="draggable-area">
                    <template #item="{ element, index }">
                        <EntityField :element="element" style="margin-bottom: 16px;"></EntityField>
                    </template>
                    <template #header>
                        <div v-if="element.columns[0].fields.length === 0" style="height: 150px;">
                            Aucun élément pour le moment<br />
                            Glissez un premier élément
                            <div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </q-list>
        </div>
        <div class="col">
            <div style="margin: 8px; font-weight: 500;">
                Colonne 2
            </div>
            <q-list dense style="margin: 8px;" class="draggable-area">
                <draggable :list="element.columns[1].fields" :group="{ name: 'g1', pull: 'clone', put: true }"
                    ghost-class="ghost" item-key="id" element="q-gutter-sm" :clone="onClone" @change="onChange"
                    class="draggable-area">
                    <template #item="{ element, index }">
                        <EntityField :element="element" style="margin-bottom: 16px;"></EntityField>
                    </template>
                    <template #header>
                        <div v-if="element.columns[1].fields.length === 0" style="height: 150px;">
                            Aucun élément pour le moment<br />
                            Glissez un premier élément
                            <div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </q-list>
        </div>
    </div>
</template>

<style lang="scss">
.draggable-area {
    height: calc(100% - 36px);
}
</style>Date